import React, {Component, lazy, Suspense} from 'react'
import booksport from "../../../api/booksport";

const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));
const renderLoader = () => <p>Loading</p>;

class Preturi extends Component{
    constructor(props){
        super(props)
        this.state = {
            preturi: null,
        }
    }


    render(){
        var club = this.props.location.state
        var preturi = null
        var element={
            token: localStorage.getItem('jwtToken'),
            clublocation_id :club.clublocation_id,
            sport_id:club.sport_id
        }
        if(this.state.preturi === null) {
            booksport.get('/getclubprices', {params: element})
                .then((res) =>
                    this.setState({preturi: res.data})
                )
                .catch((err) =>
                    this.setState({succes: false, error: err.response.data})
                )
        }
        if(this.state.preturi){
            preturi = this.state.preturi
        }
        return(
            <div className="main-panel">
                <Suspense fallback={renderLoader()}>
                    <TopNavAdmin title='Preturi'/>
                </Suspense>
                <div className="content p-0-mobile">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1">
                                <h3 className="text-center mb-3 mb-md-5">Preturi {club.club_name + ' - ' + club.location_name} </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1">
                                <div className="table-responsive mb-5">
                                    <table className="table table-prices table-hover">
                                        <thead className="text-success" id="stick">
                                        <tr>
                                            <th>Pret</th>
                                            <th>Denumire</th>
                                            <th>ZILE</th>
                                            <th>ORE</th>
                                            <th>UM</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            preturi  && preturi.map(rowpret => {
                                                return (
                                                    <tr key={rowpret.price + rowpret.days}>
                                                        <td>{rowpret.price + ' ' + rowpret.currency}</td>
                                                        <td>{rowpret.details}</td>
                                                        <td>{rowpret.days}</td>
                                                        <td>{rowpret.start_time + ' - ' + rowpret.stop_time}</td>
                                                        <td>{rowpret.um}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Preturi
