import React,{Component} from 'react'
import Navbar from '../navs/Navbar'
import passwordReset from '../../resources/images/password-reset.jpeg'
import ResetPassword from '../forms/ResetPassword'
import Footer from "../reusable/Footer";
import {Helmet} from "react-helmet";

class ResetPass extends Component{
    constructor(props){
        super(props)
        this.state = {
            token: this.props.match.params.token
        };
    }

    componentDidMount(){
        window.dataLayer.push({
            event: 'pageview',
            page: {
              url: window.location.pathname,
              title: 'Reset password'
            }
          });
    }

    render(){
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Booksport - Resetare parola </title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description" content="Reseteaza parola" />
                    <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                    <meta property="og:site_name" content="booksport.ro" />
                    <meta property="og:title" content="Booksport - Resetare parola" />
                    <meta property="og:description" content="Reseteaza parola" />
                </Helmet>
                <Navbar key={new Date()} logoW={true}/>
                <div className="page-header header-filter"
                     style={{backgroundImage: `url(${passwordReset})`, backgroundSize: 'cover', backgroundPosition: 'top center'}}>
                    <div className="container wrapper-pannel-content" style={{minHeight: 800}}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                                <ResetPassword token={this.state.token}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default React.memo(ResetPass)
