import React from 'react'
import TopNavAdmin from '../../../adminRefactored/components/navigations/TopNavAdmin'
import {Link} from "react-router-dom";

const AdminIntegration = () => {

    return (
        <div className="main-panel">
            <TopNavAdmin title= 'Integrari Booksport'/>
            <div className="content p-0-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="card card-stats card-admin mt-0 mb-4">
                                <div className="card-header card-header-success card-header-icon teren-card d-block">
                                    <h5 className="card-title mt-4 mb-0 mh-5">Integrare facturare prin provideri</h5>
                                    <div className="row">
                                            <div className="col-12 col-md-6 offset-md-3 text-center">
                                                <Link to={{
                                                    pathname: '/dashboard/admin-integrare/facturare'
                                                }}>
                                                    <div className="btn btn-success w-100 mb-4 tw-btn">
                                                        <h5 className="card-title p-0 text-uppercase">Vezi detalii</h5>
                                                    </div>
                                                </Link>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="card card-stats card-admin mt-0 mb-4">
                                <div className="card-header card-header-success card-header-icon teren-card d-block">
                                    <h5 className="card-title mt-4 mb-0 mh-5">Integrare plugin rezervari</h5>
                                    <div className="row">
                                        <div className="col-12 col-md-6 offset-md-3 text-center">
                                            <Link to={{
                                                pathname: '/dashboard/admin-integrare/plugin'
                                            }}>
                                                <div className="btn btn-success w-100 mb-4 tw-btn">
                                                    <h5 className="card-title p-0 text-uppercase">Vezi detalii</h5>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminIntegration
