export const addVoucherItems = (items) => {
    return{
        type: 'ADD_ITEMS',
        items
    }
}

export const showVouchersModal = (show) => {
    return{
        type: 'SHOW_MODAL',
        show
    }
}

export const showUserVouchersModal = (show) => {
    return{
        type: 'SHOW_USER_MODAL',
        show
    }
}

export const refreshVouchers = (refresh) => {
    return{
        type: 'REFRESH_VOUCHERS',
        refresh
    }
}
