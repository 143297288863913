import React from 'react'
import moment from 'moment'
import { openMap } from '../../store/actions/clubsFilter'
import { useDispatch } from 'react-redux';
import showMapIcon from '../../resources/images/icon_show_map.jpg'

function MapControls() {
    const dispatch = useDispatch()

    const openMapMobile = () =>{
        dispatch(openMap())
    }

    return (
        <div className="row filters-bar">
          <div>{ moment().format("DD.MM.YYYY")}</div>
          <div className="open-map" onClick={openMapMobile}><img className="img-fluid" src={showMapIcon} alt="map"/></div>
        </div>
    )
}

export default MapControls
