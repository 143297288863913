import React, { useState, useEffect, useCallback } from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import queryString from 'query-string';

// Styled component to change the focus color to green and adjust height
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const RadiusFilter = () => {
    const location = useLocation();
    const history = useHistory();
    const [radius, setRadius] = useState('');

    const radiusOptions = [
        { label: '5 km', value: 5 },
        { label: '10 km', value: 10 },
        { label: '20 km', value: 20 },
        { label: '50 km', value: 50 },
    ];

    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        const { location: locationValue, radius: queryRadius } = queryParams;

        if (locationValue && locationValue.startsWith('@')) {
            if (!queryRadius) {
                queryParams.radius = '10';
                setRadius('10');
                history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
            } else {
                setRadius(queryRadius);
            }
        } else {
            if (queryRadius) {
                delete queryParams.radius;
                history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
            }
            setRadius('');
        }
    }, [location.search, history]);

    const handleRadiusChange = useCallback((event) => {
        const newRadius = event.target.value;
        const queryParams = queryString.parse(location.search);
        if (newRadius) {
            queryParams.radius = newRadius;
        } else {
            delete queryParams.radius;
        }
        history.push(`${location.pathname}?${queryString.stringify(queryParams)}`);
        setRadius(newRadius);
    }, [location, history]);

    return (
        <FormControl fullWidth>
            <GreenSelect
                value={radius}
                onChange={handleRadiusChange}
                displayEmpty
                aria-label="Radius Filter"
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                {radiusOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </GreenSelect>
        </FormControl>
    );
};

export default RadiusFilter;
