import React, { useEffect } from 'react';
import Countdown from "react-countdown";
import PayPendingBooking from "./PayPendingBooking";
import CancelPendingBooking from "./CancelPendingBooking";
import CancelPaidBooking from "./CancelPaidBooking";
import Button from "@mui/material/Button";
import IconUnpaid from '../../../../../resources/images/paper-money.png'
import {useDispatch} from "react-redux";
import {refreshBookings} from "../../../../../store/actions/admin/bookings";
import QRCode from "react-qr-code";
import MessageRefundUnavailable from "./MessageRefundUnavailable";
import CancelPaidByVoucher from "./CancelPaidByVoucher";

const ActiveBookingCard = ({booking}) => {
    const dispatch = useDispatch()
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            setTimeout(() => {
                dispatch(refreshBookings(true));
            }, "100");
            return (
                <span>
                    Rezervarea expira in{" "}<strong>{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")} minute</strong>
                </span>
            );
        } else {
            return (
                <span>
                    Rezervarea expira in{" "}<strong>{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")} minute</strong>
                </span>
            );
        }
    };

    useEffect(() => {
        if (booking.time_from_payment) {
            const [minutes, seconds] = booking.time_from_payment.split(":").map(Number);
            const totalSeconds = minutes * 60 + seconds;
            const targetSeconds = Math.abs(totalSeconds - 600);

            if (targetSeconds > 0) {
                const timeoutId = setTimeout(() => {
                    dispatch(refreshBookings(true));
                }, targetSeconds * 1000);

                return () => clearTimeout(timeoutId);
            }
        }
    }, [booking.time_from_payment]);

    const setTimer = (timeString) => {
        const [minutes, seconds] = timeString.split(":");
        const now = new Date();
        now.setMinutes(now.getMinutes() + parseInt(minutes, 10));
        now.setSeconds(now.getSeconds() + parseInt(seconds, 10));
        return now;
    };

    let qrlink = 'https://display.booksport.ro/t/'+ booking.secureid


    return (
        <div  key={booking.id} className="col-md-6 col-xl-4" style={{display: 'flex', flexWrap: 'wrap'}}>
            <div className="card card-rezervare" style={{flex: 1}}>
                <div className="card-band-header succes">Activ {booking.minutes_pending !== '00:00' && !booking.refund_url ? ' - Neachitat' : ' - Achitat'}</div>
                <div className="wrapper-rez-infos">
                        { booking.minutes_pending !== '00:00' && !booking.refund_url &&
                            <div style={{textAlign: "center", fontSize: '16px', color: 'red', padding: '5.5px 0'}}>
                                <div className="countodwn">
                                    <Countdown date={setTimer(booking.minutes_pending)}  intervalDelay={0} precision={1} renderer={renderer}/>
                                </div>
                            </div>
                        }
                    {booking.qrcode !== '' ? <div className="left">
                        <QRCode
                            size={200}
                            style={{ height: 200, maxWidth: "100%", width: 200 }}
                            value={qrlink}
                            viewBox={`0 0 200 200`}
                        />
                    </div>: <div style={{height: '205px', width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={IconUnpaid} className="w-100" style={{maxWidth: '150px'}} alt="unpaid"/></div>}
                    <div className="right">
                        <h4>{booking.club} - {booking.sport}</h4>
                        <h5>{booking.item}</h5>
                        <ul>
                            <li>Id booking  <span>{booking.id}</span></li>
                            <li>Data  <span>{booking.dataora.replace(/ .*/, '')}</span></li>
                            <li>Ora  <span>{booking.dataora.split(" ").splice(-1)}</span></li>
                            <li>Item rezervat  <span>{booking.element}</span></li>
                            <li>Durata  <span>{booking.minutes} min</span></li>
                            <li>{booking.minutes_pending !== '00:00' ? 'De achitat' : 'Achitat'} {booking.rest && booking.rest > 0 ? 'avans' :false} <span>{booking.price} RON</span></li>
                            {booking.extraoptions && booking.extraoptions.length > 0 && booking.extraoptions.map(option => {
                                return(
                                    <li key={option.denumire}>{option.denumire}
                                        <span>
                                                                        {option.value}
                                            {option.price > 0 ? `+${option.price} RON` : null}
                                                                    </span>
                                    </li>
                                )})
                            }

                            {booking.rest && booking.rest > 0 ?
                                <li style={{color : 'red'}}>Rest de plată la club  <span>{booking.rest} RON</span></li> : false }
                            {booking.discount && booking.discount > 0 ?
                                <li style={{color : '#12803c'}}>Discount  <span>{booking.discount} RON</span></li>
                                : false
                            }
                        </ul>
                    </div>
                </div>
                <div className="wrapper-btns d-flex" style={{ minHeight: "70px" }}>
                    {booking.waze_link && !booking.time_from_payment && (
                        <Button
                            variant="contained"
                            onClick={() => window.open(booking.waze_link)}
                            style={{ background: '#00a4eb', margin: '0 10px', width: '100%' }}
                        >
                            Waze
                        </Button>
                    )}

                    {booking.minutes_pending !== '00:00' && (
                        <>
                            <CancelPendingBooking booking={booking} />
                            <PayPendingBooking booking={booking} />
                        </>
                    )}

                    {booking.refund_url && (!booking.voucherencrypted || booking.time_from_payment) && (
                        <CancelPaidBooking booking={booking} />
                    )}

                    {booking.voucherencrypted && (
                        <CancelPaidByVoucher booking={booking} />
                    )}

                </div>
                {booking.refund_url && (!booking.voucherencrypted || booking.time_from_payment) && <MessageRefundUnavailable/>}
            </div>
        </div>
    )
}

export default ActiveBookingCard
