export default (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_TEMP_REVIEW':
            return {...state, review:action.payload}
        case 'REMOVE_TEMP_REVIEW':
            return {...state, review:null}
        case 'OPEN_MODAL_DELETE_REVIEW':
            return {...state, review:action.payload}
        case 'CLOSE_MODAL_DELETE_REVIEW':
            return {...state, review:action.payload}
        default :
            return state
    }
}
