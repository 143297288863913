import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { styled } from "@mui/system";
import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ro';

// Extend dayjs with custom parse format
dayjs.extend(customParseFormat);
dayjs.locale('ro'); // Set dayjs to use the Romanian locale

const GreenTextField = styled(TextField)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '50px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '50px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '50px',
        display: 'flex',
        alignItems: 'center',
    },
});

const DateCarousel = () => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dateFromURL = dayjs(params.get('date'), 'YYYY-MM-DD', true);
    const [selectedDate, setSelectedDate] = useState(() =>
        dateFromURL.isValid() ? dateFromURL : dayjs()
    );
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 575);
        };

        handleResize(); // Initial check on component mount
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDateAccept = () => {
        history.push(
            `${history.location.pathname}?date=${selectedDate.format('YYYY-MM-DD')}`
        );
    };

    if (!isMobile) {
        return null; // Render nothing if not on mobile
    }

    return (
        <div className="date-carousel-container">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
                <Box className="form-group" sx={{ width: '100%' }}>
                    <DatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        onAccept={handleDateAccept}
                        minDate={dayjs()}
                        maxDate={dayjs().add(14, 'day')}
                        textField={(params) => (
                            <GreenTextField
                                {...params}
                                fullWidth
                                label="Data"
                                variant="outlined"
                                inputProps={{ ...params.inputProps, readOnly: true }} // Make input read-only
                            />
                        )}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                sx: {
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: "green"
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "green"
                                    },
                                    "& .MuiInputBase-root": {
                                        height: '50px',
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        height: '50px',
                                    },
                                }
                            }
                        }}
                        format="DD.MM.YYYY"
                    />
                </Box>
            </LocalizationProvider>
        </div>
    );
};

export default DateCarousel;
