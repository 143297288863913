import React from 'react';
import phone from '../../resources/images/phone.png'
import StarRatings from 'react-star-ratings';
import appstore from '../../resources/images/appstore.png'
import googleplay from '../../resources/images/googleplay.png'

const MobileAppSection = () => {
    return (
        <div className="app-section">
            <div className='container'>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-center app-image">
                            <img className='img-fluid' src={phone} alt="" />
                        </div>
                    
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="app-description">
                            <h2>Descarcă aplicația</h2>
                            <div className="reviews-app">
                                <p>"Cea mai buna aplicație de acest gen din țară!"</p>
                                <StarRatings
                                    rating={5}
                                    starRatedColor="#f9bf3b"
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="25px"
                                    starHoverColor="#f9bf3b"
                                    starSpacing='0px'
                                />
                            </div>
                            <ul>
                                <li>Geolocalizare și afișarea cluburilor pe hartă</li>
                                <li>Lista cluburilor disponibile în apropiere</li>
                                <li>Rezervi în mai puțin de 5 minute</li>
                                <li>Plata rapida și 100% sigură</li>
                                <li>Istoric de rezervări</li>
                            </ul>
                            <div className="d-flex wrapper-applogos">
                                <a href="https://apps.apple.com/us/app/booksport/id1585479595"> <img className="img-fluid" src={appstore}/></a>
                                <a href="https://play.google.com/store/apps/details?id=ro.Booksport.Booksport"> <img className="img-fluid" src={googleplay}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileAppSection
