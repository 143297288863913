import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../api/booksport";
import {PulseLoader} from "react-spinners";
import {refreshTotal} from "../../../store/actions/rezervareActions";

const ShowTotal = ({club, date, hour}) =>{
    const [total, setTotal] = useState(0)
    const rezervare = useSelector(state => state.rezervare)
    const refresh = useSelector(state => state.rezervare.refreshTotal)
    const sportId = useSelector(state => state.showModal.rezerva?.clubData?.sport_id)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()


    useEffect(() => {
        refresh && getTotalFromApi()
    },[rezervare])

    const getTotalFromApi = () => {
        setLoading(true)
        const query = {
            item_id: rezervare.item,
            time: hour,
            day: date,
            sport_id: sportId,
            clublocation_id: club.id,
            element_id: rezervare.element,
            minutes: rezervare.price?.split('_')[0],
            avans: rezervare.price?.split('_')[1] === 'f' ? 0 : 1,
            simple: 1
        }

        // eslint-disable-next-line array-callback-return
        Object.entries(rezervare).map(([key,value]) => {
            if(key.split('_')[0] === 'extra' || key.split('_')[0] === 'check' ){
                query[key] = value
            }
        })

        // eslint-disable-next-line no-unused-expressions
        rezervare.voucher ? query['voucher'] = rezervare.voucher : null

        booksport.get('/gettotal', {params: query})
            .then((res) => {
                setTotal(res.data.total)
                setLoading(false)
                dispatch(refreshTotal(false))
            })
    }

    return(
        <div className="col-12 mt-3 text-right">
            {loading ?
                <h4> <PulseLoader color='#12803c' size={12}/> </h4> :
                <h4 className="font-weight-bold" >Total: {total} RON</h4>
            }
        </div>

    )


}

export default ShowTotal
