import React, { useEffect, useState } from 'react'
import booksport from '../../../api/booksport'
import { useLocation } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import { openModalRezerva } from '../../../store/actions/modalActions'
import { useDispatch } from 'react-redux'
import { history } from '../../../utils/history'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { showLoaderWindow } from "../../../store/actions/loader";
import queryString from 'query-string';

const ClubAvailability = (props) => {
    const { club, clublink } = props;
    const location = useLocation();
    const [availability, setAvailability] = useState();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const searchParams = queryString.parse(location.search);
    const { sport, date, hour } = searchParams;

    useEffect(() => {
        getAvailability();
        // eslint-disable-next-line
    }, [date, hour]);

    const getAvailability = async () => {
        setLoading(true)
        const clubData = {
            sport_id: parseInt(sport),
            clublocation_id: parseInt(club.id),
            date: date,
            time: hour
        };

        try {
            const availability = await booksport.get('/listclubavailability', { params: clubData });
            setAvailability(availability.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleModal = (modalData, hour) => {
        var clubData = {
            sport_id: parseInt(sport),
            clublocation_id: parseInt(club.id),
            date: date,
            time: hour,
            clubname: club.name + ' ' + club.locationname
        };
        dispatch(openModalRezerva(modalData, clubData));
    };

    const getRezOptions = (hour) => {
        const token = localStorage.getItem('jwtToken');

        if (token == null) {
            const location = {
                pathname: '/login',
                state: { from: window.location.pathname }
            };
            history.push(location);
        } else {
            token && dispatch(showLoaderWindow(true));
            var clubData = {
                sport_id: parseInt(sport),
                clublocation_id: parseInt(club.id),
                date: date,
                time: hour
            };

            booksport.get('/listelementavailability', { params: clubData })
                .then((res) =>
                    handleModal(res.data, hour)
                )
                .catch((err) =>
                    console.log(err)
                );
        }
    };

    if (loading) {
        return (
            <div className='d-flex align-items-center justify-content-center pt-5 pb-5'>
                <SyncLoader color='#12803c' loading={loading} size={10} />
            </div>
        );
    } else {
        return (
            <>
                <div className="card-footer">
                    {
                        availability.length ? availability.map((hour) => {
                            return (
                                <div className="wrapper-btn-book" key={hour.ora} onClick={() => getRezOptions(hour.ora)}>
                                    <span key={hour.ora} className="btn btn-book">
                                        {hour.ora}
                                        <br />
                                        {hour.pricefrom && parseInt(hour.pricefrom) !== 0 ? <span className="from">de la {hour.pricefrom} Ron</span> : ''}
                                    </span>
                                </div>
                            )
                        }) : <p className="navailable"><ReportGmailerrorredIcon style={{ fontSize: 18, marginRight: 5 }} /> Indisponibil pentru filtrele selectate</p>}
                </div>
                <a href={clublink} className="text-right btn-more mt-2 mb-2">Vezi alte disponibilități</a>
            </>
        );
    }
}

export default ClubAvailability;
