import * as React from "react";
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {showModalReturn} from "../../../../../store/actions/admin/transactions";

export const ReturnButton = ({params}) => {
    const dispatch = useDispatch()
    const openConfirmationModal = () => {
        dispatch(showModalReturn(true, params.row.price_processed, params.row.refundlink))
    }

    return (
        params.row.refund> 0 && params.row.refundlink !== '' ?
            <Button variant="contained" style={{background: '#ff9800', fontSize: '.75rem', lineHeight: '.1 rem'}} onClick={openConfirmationModal}>
                Ramburseaza suma<br/>{params.row.price_processed} RON
             </Button>
        : false
    );
};
