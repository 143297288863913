import React, {useState, useEffect} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import booksport from "../../../../../api/booksport";
import {PulseLoader} from 'react-spinners'
import {InputAdornment, IconButton} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import {CustomInput} from "../../../components/reusable/CustomInput";
import {ColorButton} from "../../../components/reusable/ColorButton";



const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('Parola este obligatorie').min(6, "Minim 6 caractere"),
    password: Yup.string().required('Campul parola noua este obligatoriu').min(6, "Minim 6 caractere"),
    password_confirmation: Yup.string().required('Câmpul repeta parola este obligatoriu')
        .min(6, "Minim 6 caractere")
        .test('passwords-match', 'Parolele introduse sunt diferite', function(value){
            return this.parent.password === value
        })
})


const ChangePasswordForm = () => {
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordP, setShowPasswordP] = useState(false)
    const [ivalues, setIvalues] = useState(null)

    useEffect(() => {
        setInitialValues()
    }, [])


    const handleSubmit = async(values) => {
        setLoading(true)
        values.token = localStorage.getItem('jwtToken')

        try{
            const response = await booksport.post( '/user_password_update',  values)
            if(response.data){
                localStorage.setItem('usInf', response.data)
                toast.success('Am salvat cu succes');
            }
            setLoading(false)
        }catch(err){
            setLoading(false)
            toast.error(err.response.data);
            return {error: err.response}
        }
    }

    const setInitialValues = () => {
        const initialValues = {
            old_password: '' ,
            password: '',
            password_confirmation:  '',
        }
        setIvalues(initialValues)
        formik.setValues(initialValues)
    }

    const formik = useFormik({
        initialValues: ivalues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        },
    });


    return(
            <>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                { ivalues && formik.values &&
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 mt-4">
                            <CustomInput
                                name = 'old_password'
                                focusColor='#12803c'
                                type = 'password'
                                fullWidth
                                label="Parola"
                                onChange={formik.handleChange}
                                value={formik.values.old_password}
                                error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                                helperText={formik.touched.old_password && formik.errors.old_password}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-4">
                            <CustomInput
                                name = 'password'
                                focusColor='#12803c'
                                type={showPassword?"text":"password"}
                                fullWidth
                                label="Parola noua"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputProps={{
                                    endAdornment :
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                }
                                }
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mt-4">
                            <CustomInput
                                name = 'password_confirmation'
                                focusColor='#12803c'
                                type={showPasswordP?"text":"password"}
                                fullWidth
                                label="Repetă parola"
                                onChange={formik.handleChange}
                                value={formik.values.password_confirmation}
                                error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                InputProps={{
                                    endAdornment :
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                onClick={() => setShowPasswordP(!showPasswordP)}
                                            >
                                                {showPasswordP ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <ColorButton variant="contained" size="large" type="submit" disabled = {loading}>
                                {loading ?
                                    <PulseLoader color='#ffffff' size={10}></PulseLoader>
                                    : "Schimba parola" }
                            </ColorButton>
                        </div>
                    </div>
                </form>
               }
            </>
    )
}

export default ChangePasswordForm
