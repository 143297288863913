import React, {useState, useEffect} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {IconButton, Slide} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ServicesModal = ({services}) => {
    const [showDialog, setShowDialog] = useState(false)

    return (
        services &&  services.length > 0 ? <>
            <Button variant='contained' fullWidth onClick={() => setShowDialog(true)} style={{background: '#12803c'}}>Vezi serviciile</Button>
            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"md"}
                scroll={"body"}
            >
                <DialogTitle sx={{textAlign:'center', marginBottom: '30px'}}>
                    Codul discount este aplicabil pentru
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowDialog(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {services && services.map(item => (
                        <p className="text-center" key={item}>{item}</p>
                    ))}
                </DialogContent>
                <DialogActions sx={{marginTop: '30px', padding: '16px 24px', justifyContent:'flex-end'}}>
                    <Button variant='contained' onClick={() => setShowDialog(false)} style={{background: '#e95e4e'}}>Inchide</Button>
                </DialogActions>
            </Dialog>
        </> : <></>

    );
}

export default ServicesModal
