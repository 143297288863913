import React, {lazy, Suspense, useEffect} from 'react'
import {Helmet} from "react-helmet";

const Footer = lazy(() => import('../reusable/Footer'));
const renderLoader = () => <p>Loading</p>;
const Confidentiality = (props) => {

    useEffect(() => {
        window.dataLayer.push({
         event: 'pageview',
         page: {
           url: window.location.pathname,
           title: 'Confidentiality policy'
         }
       });
     }, [])
    return (
        <div className="static-text">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ 'Booksport - Politica de confidentialitate' }</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Politica de confiudentialitate e platformei Booksport" />
            </Helmet>
            <div className="title-content">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <hr/>
                        </div>
                        <div className="col-md-6 offset-md-6 text-right">
                            <a href="/">Inapoi la pagina principala</a>
                        </div>
                        <div className="col-lg-12 intro">
                            <h1>Politica de confidentialitate</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row c-section">
                    <div className="col-12 cpt">
                        <p><strong>Ultima actualizare: 04 Martie 2024</strong></p>
                        <p>La BOOKSPORT, respectul pentru intimitatea dumneavoastră este o prioritate. Această Politică de Confidențialitate descrie tipurile de informații pe care le colectăm de la dumneavoastră și de la dispozitivele dumneavoastră atunci când vizitați site-ul nostru <a href="https://booksport.ro">https://booksport.ro</a>, cum utilizăm aceste informații, cu cine le împărtășim și opțiunile pe care le aveți în ceea ce privește utilizarea și accesul la informații.</p>
                    </div>
                </div>
                <hr className="nosp" />
                <div className="row c-section">
                    <div className="col-lg-4 cpt"><h2>Informații pe care le colectăm</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Colectăm informații care ne ajută să vă oferim cea mai bună experiență online posibilă, inclusiv:
                            <ul>
                                <li> <strong>Informații furnizate direct de către dumneavoastră:</strong> Acestea includ informații pe care ni le furnizați atunci când utilizați serviciile noastre, cum ar fi adresa de e-mail sau alte detalii de contact atunci când vă abonați la newsletter-ul nostru sau când solicitați informații.
                                </li>
                                <li><strong>Informații colectate automat:</strong> Colectăm anumite informații în mod automat atunci când vizitați site-ul nostru, inclusiv adresa IP, tipul de browser, sistemul de operare, paginile pe care le vizitați și ora și data accesării.
                                </li>
                                <li><strong>Informații de la terți:</strong> Putem primi informații despre dumneavoastră de la terți, inclusiv de la partenerii noștri și de la serviciile de rețele sociale, în conformitate cu setările pe care le-ați configurat pe acele platforme.

                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-4 cpt"><h2>Cum utilizăm informațiile</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Utilizăm informațiile colectate pentru a:</p>
                        <ul>
                            <li> Oferi, menține și îmbunătăți serviciile noastre;</li>
                            <li>Răspunde solicitărilor și întrebărilor dumneavoastră;</li>
                            <li>Îmbunătăți securitatea site-ului;</li>
                            <li>Analiza utilizarea site-ului și înțelegerea nevoilor și preferințelor utilizatorilor;</li>
                            <li>Informa despre modificările serviciilor noastre și oferte speciale care ar putea fi de interes.</li>
                        </ul>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Partajarea informațiilor cu terți</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Nu vindem, nu închiriem și nu împărtășim informațiile dumneavoastră cu terți, cu excepția cazurilor descrise în această politică. Informațiile pot fi împărtășite cu furnizori de servicii terți pentru a ne ajuta să furnizăm și să îmbunătățim serviciile noastre, atât timp cât acești furnizori păstrează informațiile în siguranță și confidențialitate.</p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Conformitatea cu Google API Services User Data Policy</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Utilizăm Google APIs pentru a îmbunătăți serviciile oferite pe site-ul nostru. În conformitate cu Google API Services User Data Policy, inclusiv cerințele de Utilizare Limitată, ne angajăm să protejăm datele colectate de la utilizatorii Google și să limităm utilizarea acestor date strict pentru îmbunătățirea serviciilor noastre. Nu folosim datele utilizatorilor Google pentru publicitate sau alte scopuri neautorizate.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Drepturile dumneavoastră</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Aveți dreptul să solicitați acces la informațiile personale pe care le deținem despre dumneavoastră, să cereți corectarea sau ștergerea acestora, sau să restricționați prelucrarea. De asemenea, aveți dreptți să vă opuneți prelucrării, precum și să solicitați portabilitatea datelor. Pentru a exercita aceste drepturi, vă rugăm să ne contactați folosind detaliile de contact furnizate mai jos.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Modificări ale Politicii de Confidențialitate</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Ne rezervăm dreptul de a modifica această Politică de Confidențialitate la anumite intervale. Orice modificări vor fi postate pe această pagină, împreună cu data ultimei actualizări. Vă încurajăm să revizuiți periodic această pagină pentru a fi la curent cu ultimele informații privind practicile noastre de confidențialitate.</p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Contactați-ne</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Pentru orice întrebări sau nelămuriri legate de această Politică de Confidențialitate sau practicile noastre de colectare și utilizare a datelor, vă rugăm să ne contactați la <a href="mailto:office@booksport.ro">office@booksport.ro</a></p>
                    </div>
                </div>
            </div>
            <Suspense fallback={renderLoader()}>
                <Footer/>
            </Suspense>
        </div>
    )
}

export default React.memo(Confidentiality)



