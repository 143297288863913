import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { openMap } from '../../../store/actions/clubsFilter';
import { useLocation } from 'react-router-dom';
import filtersIcon from '../../../resources/images/icon_filters.png';
import showMapIcon from '../../../resources/images/icon_show_map.jpg';
import 'react-datepicker/dist/react-datepicker.css';

import LocationFilter from './filters/LocationFilter';
import SportFilter from './filters/SportFilter';
import DateFilter from './filters/DateFilter';
import HourFilter from './filters/HourFilter';
import RadiusFilter from './filters/RadiusFilter';
import queryString from "query-string";

const ClubFilters = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [filters, setFilters] = useState(false);
  const [radius, setRadius] = useState(false);
  const currentParams = queryString.parse(location.search);
  const { date, hour } = currentParams;

  useEffect(() => {
    setRadius(!!currentParams.radius);
  }, [currentParams.radius]);


  useEffect(() => {
    const body = document.querySelector('body')
    filters ? body.classList.add('fixed') : body.classList.remove('fixed')
  }, [filters]);


  const handleOpenMap = useCallback(() => {
    dispatch(openMap());
  }, [dispatch]);

  const toggleFilters = useCallback(() => {
    setFilters(prevFilters => !prevFilters);
  }, []);

  return (
      <div className='d-flex w-100'>
        <div className="filters-bar">
          <div>{DateTime.fromISO(date).toFormat("dd.MM.yyyy")}</div>
          <div>{hour}</div>
          <div className="open-map" onClick={handleOpenMap}>
            <img className="img-fluid" src={showMapIcon} alt="map" />
          </div>
          <div className="open-filters" onClick={toggleFilters}>
            <img className="img-fluid" src={filtersIcon} alt="filters" />
          </div>
        </div>
        <div className={`filters ${filters ? 'active' : ''}`}>
            <div className="m-0">
              <div className="close" onClick={toggleFilters}>
                <span className="material-icons">close</span>
              </div>
              <div className="filters-wrapper">
                <div className="row d-lg-none">
                  <div className="col-12 text-center pb-3">
                    <h3>Filtrează rezultatele</h3>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-6 col-lg-3 mb-md-0 mb-3">
                    <LocationFilter />
                  </div>
                  {radius &&
                      <div className="col-sm-6 col-lg-2 mb-md-0 mb-3">
                        <RadiusFilter />
                      </div>
                  }
                  <div className={`col-sm-6 mb-lg-0 mb-3 ${radius ? 'col-lg-2' : 'col-lg-3'}`}>
                    <SportFilter />
                  </div>
                  <div className="col-sm-6 col-lg-3 mb-lg-0 mb-3">
                    <DateFilter />
                  </div>
                  <div className={`col-sm-6 mb-lg-0 mb-3 ${radius ? 'col-lg-2' : 'col-lg-3'}`}>
                    <HourFilter />
                  </div>
                </div>
                <div className="row d-lg-none">
                  <div className="col-12 text-center pb-3">
                    <button onClick={toggleFilters} className="btn btn-success w-100 mt-3">Cauta</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  );
};

export default React.memo(ClubFilters);
