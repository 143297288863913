import React, {useState, useEffect} from "react";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import booksport from "../../../../api/booksport";
import {AdminRoutes} from "../../../../api/admin-routes";
import SpinnerContainer from "../../components/loading-spinner";
import TableTransactionsUser from "./components/TableTransactionsUser";


const UserTransactions = () => {
    const [loading, setLoading] = useState(false)
    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        getUserTransactions()
    }, []);


    const getUserTransactions = async () =>{
        setLoading(true)
        const query={
            token: localStorage.getItem('jwtToken'),
        }
        try{
            const response = await booksport.get(`${AdminRoutes.userTransactions}`, {params: query})
            setTransactions(response.data)
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }

    return(
        <div className="main-panel">
            <TopNavAdmin title='Tranzactiile mele'/>
            <div className="content">
                {loading && <SpinnerContainer/>}
                <div className="container-fluid">
                    {transactions.length > 0 && <TableTransactionsUser transactions={transactions}/>}
                </div>
            </div>
        </div>
    )
}

export default UserTransactions
