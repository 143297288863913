
const INITIAL_STATE = {
    openPanel : false, 
 }
 
 export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
         case 'OPEN_SEARCH_PANEL':
             return {...state, openPanel:true}
         case 'CLOSE_SEARCH_PANEL':
             return {...state, openPanel:false} 
        default :
            return state
     }
 } 