import * as React from "react";
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {showModalReturnVoucher} from "../../../../../store/actions/admin/transactions";

export const ReturnVoucher = ({params, active}) => {
    const dispatch = useDispatch()
    const openConfirmationModal = () => {
        dispatch(showModalReturnVoucher(true, params.row.voucher_refund, params.row.price_processed))
    }

    return (
        params.row.price_processed > 0 && params.row.voucher_refund !== '' ?
            params.row.voucher_refund === 'rambursat' ?
                <p style={{color: '#12803c'}}>Rambursat cu cod discount</p>
                :
                <Button
                    disabled={!active}
                    variant="contained"
                    style={{
                        background: `${active ? '#12803c' : '#cecece'}`,
                        fontSize: '.75rem',
                    }}
                    onClick={openConfirmationModal}
                >
                    Ramburs voucher<br />{active ? params.row.price_processed + ' RON' : 'INACTIV'}
                </Button>
            : false
    );
};
