import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import {refreshTransactions, showModalReturnVoucher} from "../../../../../store/actions/admin/transactions";
import booksport from "../../../../../api/booksport";
import {AdminRoutes} from "../../../../../api/admin-routes";
import {toast, ToastContainer} from "react-toastify";
const ModalReturnVoucherConfirmation = () => {
    const modal = useSelector(state => state.admin?.transactions?.modalVoucher)
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(showModalReturnVoucher(false))
    }

    const returnVoucher= async () => {
        const query={
            token: localStorage.getItem('jwtToken'),
            encrypted: modal?.encrypted
        }
        try{
            const response = await booksport.post(`${AdminRoutes.returnVoucher}`, query)
            if(response){
                dispatch(showModalReturnVoucher(false))
                dispatch(refreshTransactions(true))
                toast.success('Codul discount a fost creat si alocat cu succes!')
            }
        }catch(err){
            dispatch(showModalReturnVoucher(false))
            dispatch(refreshTransactions(true))
            err.response?.data && toast.error(`${err.response.data}`);
        }
    }

    return (

        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Dialog
                open={modal?.show && modal.encrypted ? modal.show : false}
                onClose={closeModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Esti sigur ca vrei sa rambursezi un voucher?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Prin aceasta actiune utilizatorul va primi in contul sau un cod discount in valoare de <strong>{modal?.amount} RON</strong>.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{marginTop: '30px', padding: '16px 24px', justifyContent:'flex-start'}}>
                    <Button variant='contained' style={{background: '#12803c'}} onClick={returnVoucher}>Ramburseaza</Button>
                    <Button variant='contained' onClick={closeModal} style={{background: '#e95e4e'}}>Anuleaza</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ModalReturnVoucherConfirmation
