import React, {Component, lazy, Suspense} from 'react'
import {connect} from 'react-redux'
import {openModal} from "../../../store/actions/modalActions";
import {disRefresh} from "../../../store/actions/refreshActions";

const ModalRezervari = lazy(() => import('../modals/ModalRezervari'));
const SliderTableRezervari = lazy(() => import('../sliders/SliderTableRezervari'));
const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));
const renderLoader = () => <p>Loading</p>;

class Rezervari extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

      refresh=()=>{
        this.props.disRefresh()
      }

  componentDidMount(){
    document.title = 'Booksport - Rezervari'
  }

    render(){
        var current = this.props.location.state
      var dateNow = new Date().getTime();
        return(
            <div className="main-panel">
                <Suspense fallback={renderLoader()}>
                    <TopNavAdmin title='Rezervari'/>
                </Suspense>
                <div className="content p-0-mobile">
                    <div className="container-fluid">
                        <Suspense fallback={renderLoader()}>
                        <SliderTableRezervari key={this.props.refresh.refresh ? dateNow : ''} current={current} />
                        </Suspense>
                    </div>
                </div>
                <Suspense fallback={renderLoader()}>
                 <ModalRezervari showModal={this.props.showModal}/>
                </Suspense>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        showModal:state.showModal.showModal,
        refresh: state.refresh
    }
}


export default connect(mapStateToProps,{openModal,disRefresh}) (Rezervari)
