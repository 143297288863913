import React,{ useState, useEffect } from 'react';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import {IconButton, Slide} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import SavedCardListing from "./SavedCardListing";
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../../../api/booksport";
import {AdminRoutes} from "../../../../../api/admin-routes";
import {setSelectedPaymentCard} from "../../../../../store/actions/admin/cards";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const PayPendingBooking = ({booking}) => {
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cards, setCards] = useState(null)
    const selectedPaymentCard = useSelector(state => state.admin.cards?.paymentCard)
    const dispatch = useDispatch()

    const PayPending = () => {
        setLoading(true)
          if(booking.plateste && selectedPaymentCard !== null && typeof selectedPaymentCard !== 'undefined'){
              window.location = `${booking.plateste}/${selectedPaymentCard}`
          }
        setLoading(false)
    }


    const getUserSavedCards = async () =>{
        const query={
            token: localStorage.getItem('jwtToken'),
        }
        try{
            const response = await booksport.get(`${AdminRoutes.getUserSavedCards}`, {params: query})
            if(response.data.length && response.data.length > 0){
                setCards(response.data)
                dispatch(setSelectedPaymentCard(response.data[0].payment_instrument_id))
                setShowModal(true)
            } else {
                window.location = `${booking.plateste}/0`
            }
        }catch(err){
            return {error: err.response}
        }
    }

    return (
        <>
            <Button variant='contained' onClick={() => getUserSavedCards()} style={{background: '#12803c', margin:'0 10px', width:'100%'}}>Achita</Button>
            <Dialog
                open={showModal ? showModal : false}
                onClose={() => setShowModal(false)}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                scroll={"body"}
            >
                <DialogTitle sx={{textAlign:'center'}}>
                    Metoda de plata
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowModal(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                  <SavedCardListing cards={cards}/>
                </DialogContent>
                <DialogActions style={{marginBottom: '10px', paddingRight:'10px', justifyContent:'center'}}>
                    {!loading && <Button variant='contained' onClick={() => PayPending()} style={{background: '#12803c', minWidth: '200px', minHeight: '40px'}} >Achita</Button>}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PayPendingBooking
