import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setTerms, setTermsError} from "../../../store/actions/rezervareActions";


const Terms = ({club}) =>{
    const [value, setValue] = useState(false)
    const termsError = useSelector(state => state.rezervare.accordTermsError)
    const dispatch = useDispatch()

    const handleCheckboxChange = () => {
        setValue(!value)
        dispatch(setTerms(!value))
        !value && dispatch(setTermsError(value))
    }

    return(
        <div className="col-12 save-card">
            <div className="form-group">
                <div className="form-check ml-1">
                    <label className="form-check-label">
                        <input name="terms" type="checkbox" className="form-check-input" onChange={handleCheckboxChange} />
                        <span className="form-check-sign"><span className="check"> </span></span>
                        Sunt de acord cu <a href={ `/terms-club/${club.id}/${club.sport_id}`} target="_blank" rel="noopener noreferrer">termenii si conditiile </a>clubului {club.clubname}
                    </label>
                </div>
                {termsError && <div className="cerror">Campul este obligatoriu</div> }
            </div>
        </div>

    )
}

export default Terms
