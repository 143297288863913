export const openModal = (hour, location, day, day_nr, ref_teren) => {
    return{
        type: 'OPEN_MODAL',
        showModal : {hour: hour, location:location, day:day, day_nr: day_nr, ref_teren:ref_teren, showModal:true},
    }
}

export const closeModal = () => {
    return{
        type: 'CLOSE_MODAL',
        showModal : false
    }
}

export const openModalRezerva = (modalData, clubData) => {
    return{
        type: 'OPEN_MODAL_REZERVA',
        rezerva : {data:modalData, clubData:clubData, openModal:true},
    }
}

export const showModalDeleteAccount = (open) => {
    return{
        type: 'SHOW_MODAL_DELETE_ACCOUNT',
        open
    }
}

export const closeModalRezerva = () => {
    return{
        type: 'CLOSE_MODAL_REZERVA',
        openModal : false
    }
}

export const openModalRezervaTable = (modalData, clubData) => {
    return{
        type: 'OPEN_MODAL_REZERVA_TABLE',
        rezerva : {data:modalData, clubData:clubData, openModalTable:true},
    }
}

export const openModalVoucher = (open) => {
    return{
        type: 'OPEN_MODAL_VOUCHER',
        open,
    }
}


export const closeModalRezervaTable = () => {
    return{
        type: 'CLOSE_MODAL_REZERVA_TABLE',
        openModal : false
    }
}
