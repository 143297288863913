import React, {useState, useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import {useSelector, useDispatch} from "react-redux";
import {
    setSelectedCity,
    setSelectedCityPJ,
    setSelectedCounty,
    setSelectedCountyPJ
} from "../../../../../store/actions/userActions";
import booksport from "../../../../../api/booksport";

const CountySelect = ({pj, user, error}) => {
    const [county, setCounty] = useState([]);
    const selcounty = useSelector(pj ? state => state.userStatus.countyPJ : state => state.userStatus.county)
    const dispatch = useDispatch()
    const userCounty = pj ? user.userpj?.county_id : user.county


    useEffect(() => {
        getCounties()
    }, [])
    const setSelCounty = (value) => {
        if(selcounty !== value){
            if(pj){
                dispatch(setSelectedCountyPJ(value))
                dispatch(setSelectedCityPJ(null))
            } else {
                dispatch(setSelectedCounty(value))
                dispatch(setSelectedCity(null))
            }
        }
    }

    const getCounties = async () =>{
        try{
            const response = await booksport.get('/get_county')
            if(response.data){
                setCounty(response.data)
                response.data.map(judet => {
                    judet.id === userCounty && setSelCounty(judet.id)
                })
            }
        }catch(err){
            return {error: err.response}
        }
    }

    return (
         <div className="col-12 col-md-6 col-lg-4 mt-4">
            <FormControl fullWidth error={error && !selcounty}>
                <InputLabel sx={{
                    '&.Mui-focused.MuiInputLabel-shrink':{
                        color:'#12803c'
                    }
                }} id="county-label">Judet</InputLabel>
                {county && county.length > 0 &&  <Select
                    labelId="county-label"
                    id="demo-simple-select"
                    value={selcounty ? selcounty : ''}
                    label="Judet"
                    defaultValue=""
                    onChange={(e) => setSelCounty(e.target.value)}
                    sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#12803c',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#12803c',
                        },
                        '&.Mui-focused .MuiInputLabel-outlined': {
                            color: '#12803c',
                        }
                    }}
                >
                    {county.map(count => {
                        return <MenuItem key={count.id} value={count.id}>{count.name}</MenuItem>
                    })}
                </Select> }
                {error && !selcounty &&  <FormHelperText>Campul Judet este obligatoriu</FormHelperText>}
            </FormControl>
        </div>
    );
}

export default CountySelect
