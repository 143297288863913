import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, CircularProgress, Select } from '@mui/material';
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import booksport from "../../api/booksport";
import RegistrationClubConfirm from "../panels/RegistrationClubConfirm";
import {CustomInput} from "../adminRefactored/components/reusable/CustomInput";
import Box from "@mui/material/Box";


const RegisterClubForm = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const reachOptions = [
        { label: 'Google', value: 'google' },
        { label: 'Facebook', value: 'facebook' },
        { label: 'Linkedin', value: 'linkedin' },
        { label: 'Instagram', value: 'insta' },
        { label: 'Prieteni', value: 'prieteni' },
        { label: 'Presa', value: 'presa' },
        { label: 'Campanie email', value: 'email' },
        { label: 'TV', value: 'tv' },
        { label: 'Reprezentant Booksport', value: 'reprezentant' },
        { label: 'Alta modalitate', value: 'alta_modalitate' },
    ];

    const onSubmit = async (formValues) => {
        setLoading(true);
        window.dataLayer.push({
            event: 'register_club',
        });
        setLoading(true);
        try {
            const response = await booksport.post('/registerclubrequest', formValues);
            response && setSuccess(true);
        } catch (err) {
            setError(err.response.data);
        } finally {
            setLoading(false);
        }
    };

    const handleErrors = (fieldName) => {
        if (error) {
            const errorObject = JSON.parse(error);
            if (errorObject[fieldName]) {
                return (
                    <div className="has-danger text-danger" style={{ fontSize: '12px', marginTop: '-10px' }}>
                        {errorObject[fieldName].map(errorMessage => (
                            <div>{errorMessage}</div>
                        ))}
                    </div>
                );
            }
        }
        return null;
    };

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                phone: '',
                localitate: '',
                clubname: '',
                sport: '',
                sursa: '',
            }}
            validationSchema={Yup.object({
                name: Yup.string().required('Adaugă numele'),
                email: Yup.string().email('Adresa nu este validă').required('Introdu adresa de email'),
                phone: Yup.string().matches(/^[0-9]+$/, 'Numărul de telefon trebuie să conțină doar cifre').required('Adaugă numărul de telefon'),
                localitate: Yup.string().required('Adaugă localitatea'),
                clubname: Yup.string().required('Adaugă numele clubului'),
                sport: Yup.string().required('Adaugă sportul'),
                sursa: Yup.string().required('Selectează cum ai aflat de platforma Booksport'),
            })}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, setFieldValue }) => (
                <Form>
                    <Box
                        className="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                    {!success ?
                        <div className="card card-signup">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="card-title mt-4 text-center font-weight-bold mb-4"> Hai să ne cunoaștem!</h3>
                                        <p className='m-0'>Dacă deții sau administrezi un club sportiv pe care îl dorești înscris în platforma BOOKSPORT, completează câmpurile de mai jos și trimite o cerere. Vei fi contactat în cel mai scurt timp de un reprezentant BOOKSPORT care îți va oferi detalii și îți va răspunde la întrebări.</p>
                                        <p className='m-0 mb-3'>De asemenea, vei primi și un cont demo prin care vei putea accesa toate funcționalitățile platformei.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mr-auto">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Field
                                                    as={CustomInput}
                                                    type="text"
                                                    name="name"
                                                    label="Nume"
                                                    focusColor='#12803c'
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="name" /></span>}
                                                />
                                                {handleErrors("name")}
                                            </div>
                                            <div className="col-md-6">
                                                <Field
                                                    as={CustomInput}
                                                    type="email"
                                                    name="email"
                                                    label="Email"
                                                    focusColor='#12803c'
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="email" /></span>}
                                                />
                                                {handleErrors("email")}
                                            </div>
                                            <div className="col-md-6">
                                                <Field
                                                    as={CustomInput}
                                                    type="text"
                                                    name="phone"
                                                    label="Telefon"
                                                    focusColor='#12803c'
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="phone" /></span>}
                                                />
                                                {handleErrors("phone")}
                                            </div>
                                            <div className="col-md-6">
                                                <Field
                                                    as={CustomInput}
                                                    type="text"
                                                    name="localitate"
                                                    label="Localitate"
                                                    focusColor='#12803c'
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="localitate" /></span>}
                                                />
                                                {handleErrors("localitate")}
                                            </div>
                                            <div className="col-md-6 mt-md-2">
                                                <Field
                                                    as={CustomInput}
                                                    type="text"
                                                    name="clubname"
                                                    label="Nume club"
                                                    focusColor='#12803c'
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="clubname" /></span>}
                                                />
                                                {handleErrors("clubname")}
                                            </div>
                                            <div className="col-md-6 mt-md-2">
                                                <Field
                                                    as={CustomInput}
                                                    type="text"
                                                    name="sport"
                                                    label="Sport"
                                                    focusColor='#12803c'
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="sport" /></span>}
                                                />
                                                {handleErrors("sport")}
                                            </div>
                                            <div className="col-md-12 mt-md-2">
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel sx={{
                                                        '&.Mui-focused.MuiInputLabel-shrink':{
                                                            color:'#12803c'
                                                        }}} id="sursa-label">Cum ai aflat de platforma Booksport?</InputLabel>
                                                    <Field
                                                        as={Select}
                                                        labelId="sursa-label"
                                                        sx={{
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#12803c',
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#12803c',
                                                            },
                                                            '&.Mui-focused .MuiInputLabel-outlined': {
                                                                color: '#12803c',
                                                            }
                                                        }}
                                                        id="sursa"
                                                        name="sursa"
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            setFieldValue("sursa", value);
                                                        }}
                                                        label="Cum ai aflat de platforma Booksport?"
                                                    >
                                                        <MenuItem value="">
                                                            <em>Selectează</em>
                                                        </MenuItem>
                                                        {reachOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                                <ErrorMessage name="sursa" component="div" style={{ color: 'red', fontSize: '0.75rem', marginTop: '8px' }} />
                                                {handleErrors('sursa')}
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="success"
                                                disabled={loading}
                                                fullWidth
                                                style={{ height: '45px', marginBottom: 30, marginTop: 20, backgroundColor: "#12803c" }}
                                            >
                                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Trimite'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <RegistrationClubConfirm/>
                    }
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default RegisterClubForm;
