import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../api/booksport";
import {PulseLoader} from "react-spinners";
import moment from "moment";
import ConfirmationFooter from "./ConfirmationFooter";


const ConfirmationPannel = ({club, date, hour}) =>{
    const rezervare = useSelector(state => state.rezervare)
    const refreshTotal = useSelector(state => state.rezervare.refreshTotal)
    const sportId = useSelector(state => state.showModal.rezerva?.clubData?.sport_id)
    const [orderDetails, setOrderDetails] = useState(null)
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    useEffect(() => {
        getTotalFromApi()
        getUserData()
        // eslint-disable-next-line 
    },[refreshTotal])

    const getUserData = () => {
        setUser(JSON.parse(atob(localStorage.getItem('usInf'))))
    }


    const getTotalFromApi = () => {
        setLoading(true)
        const query = {
            item_id: rezervare.item,
            time: hour,
            day: date,
            sport_id: sportId,
            clublocation_id: club.id,
            element_id: rezervare.element,
            minutes: rezervare.price?.split('_')[0],
            avans: rezervare.price?.split('_')[1] === 'f' ? 0 : 1,
            simple: 0
        }

        Object.entries(rezervare).map(([key,value]) => {
            if(key.split('_')[0] === 'extra' || key.split('_')[0] === 'check' ){
                query[key] = value
            }

        })

        // eslint-disable-next-line no-unused-expressions
        rezervare.voucher ? query['voucher'] = rezervare.voucher : null

        booksport.get('/gettotal', {params: query})
            .then((res) => {
                setOrderDetails(res.data)
                setLoading(false)
            })
    }

    return(
        <div className={rezervare.showConfirmation ? '' : 'hidden'}>
            { loading ? <div className="wrapper-loader"> <PulseLoader color='#12803c' size={12}/> </div> :
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="row row-confirmation">
                            <div className="col-6">Locatie</div>
                            <div className="col-6 font-weight-bold text-right">{orderDetails?.clublocation_name}</div>
                        </div>
                        <div className="row row-confirmation">
                            <div className="col-6">Data</div>
                            <div className="col-6 font-weight-bold text-right">{moment(orderDetails?.data).format('DD MMMM YYYY')}</div>
                        </div>
                        <div className="row row-confirmation">
                            <div className="col-6">Ora</div>
                            <div className="col-6 font-weight-bold text-right">{orderDetails?.ora}</div>
                        </div>
                        <div className="row row-confirmation">
                            <div className="col-6">Item rezervat</div>
                            <div className="col-6 font-weight-bold text-right">{orderDetails?.element_name}</div>
                        </div>
                        <div className="row row-confirmation">
                            <div className="col-6">Optiune rezervare</div>
                            <div className="col-6 font-weight-bold text-right">{orderDetails?.item_name}</div>
                        </div>
                        <div className="row row-confirmation">
                            <div className="col-6">Durata</div>
                            <div className="col-6 font-weight-bold text-right">{orderDetails?.minutes} minute</div>
                        </div>
                        <div className="row row-confirmation">
                            <div className="col-6">Cost</div>
                            <div className="col-6 font-weight-bold text-right">{orderDetails?.fullprice} RON</div>
                        </div>
                        {
                            orderDetails?.voucher_data && <div className="row row-confirmation">
                                <div className="col-6">{orderDetails.voucher_data.voucher?.name}</div>
                                <div className="col-6 font-weight-bold text-right text-green-400">-{orderDetails.voucher_data.discount} RON</div>
                            </div>
                        }
                        {
                            orderDetails?.options && orderDetails.options.map(option => {
                                return(
                                    <div className="row row-confirmation">
                                        <div className="col-6">{option.denumire}</div>
                                        <div className="col-6 font-weight-bold d-flex align-items-center justify-content-end">
                                            <p className="small m-0 font-weight-bold">{option.value}</p>
                                            {option.price > 0 ? <p className="small m-0 font-weight-bold">+{option.price} RON</p> : null }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {orderDetails?.rest > 0 &&
                            <div className="row row-confirmation text-danger">
                                <div className="col-6">Rest de plata la club</div>
                                <div className="col-6 font-weight-bold text-right">{orderDetails?.rest} RON</div>
                            </div>
                        }
                    </div>
                    <div className="col-12">
                        <h3 className="font-weight-bold text-right mt-1" ><span className="text-gray">Total:</span> <span className="text-danger">{orderDetails?.total} RON</span></h3>
                    </div>

                    {club.factura === 0 ?
                        <p className="text-center w-100 mt-3 text-danger">Pentru factura fiscala adresati-va clubului. Clubul nu foloseste optiunea de emitere factura online!</p>
                        :
                        <p className="text-center w-100 mt-3 text-danger">Factura se va emite DOAR pe persoana fizica. Optiunea de emitere a facturilor pentru persoane juridice nu este disponibilă în acest moment.</p>
                        // user && user.factureazapj === 1 &&
                        // <>
                        //     <p className="text-center w-100 mt-3 text-danger mb-0">Factura se va emite pentru persoana juridica {user.userpj?.firma}!</p>
                        //     <p className="text-center w-100 mt-0 mb-3">Pentru a modifica apasa <a className="text-green" href='/dashboard/user'>aici</a></p>
                        // </>
                    }

                    <ConfirmationFooter/>
                </div>
            </> }
        </div>
    )
}

export default ConfirmationPannel
