import React, {lazy, Suspense, useEffect} from 'react'
import {Helmet} from "react-helmet";

const Footer = lazy(() => import('../reusable/Footer'));
const renderLoader = () => <p>Loading</p>;

const Cookies = (props) => {
    useEffect(() => {
        window.dataLayer.push({
         event: 'pageview',
         page: {
           url: window.location.pathname,
           title: 'Cookies policy'
         }
       });
     }, [])
    return (
        <div className="static-text">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ 'Booksport - Politica de cookies' }</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Politica de cookies e platformei Booksport" />
            </Helmet>
            <div className="title-content">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <hr/>
                        </div>
                        <div className="col-md-6 offset-md-6 text-right">
                            <a href="/">Inapoi la pagina principala</a>
                        </div>
                        <div className="col-lg-12 intro">
                            <h1>Politica de cookies</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <hr className="nosp" />
                <div className="row c-section">
                    <div className="col-12">
                    </div>
                    <div className="col-lg-4 cpt"><h2>Ce sunt cookies?</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <strong>Vă rugăm să citiți cu atenție această politică de cookies înainte de a utiliza siteul</strong>
                        <p>Cookies sunt fișiere mici care conțin litere și cifre care pot fi plasate pe dispozitivul dvs. când vizitați o pagină de internet sau utilizați o aplicație. Utilizăm cookies pentru a vă îmbunătăți experiența pe siteul nostru, ținând minte preferințele dvs. De exemplu, pe multe pagini de internet de comerț electronic, dacă adăugați un articol la un coș de cumpărături online fără a finaliza procesul de ieșire, data viitoare când vizitați aceeași pagină de internet, puteți vedea același articol în coșul de cumpărături. Acesta este un exemplu despre modul în care cookies sunt folosite pentru a vă aminti preferințele. Fără utilizarea cookies experiența dvs. pe siteul nostru va fi mai puțin personalizată. <br/>
                            Pentru mai multe informații despre cookies, consultați <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-4 cpt"><h2>De ce folosim cookies?</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Cookies realizează diverse funcții, inclusiv facilitarea navigării și stocarea preferințelor dvs. pentru a vă arăta conținutul relevant și pentru a vă îmbunătăți experiența generală de utilizator. <br/>

                            Utilizăm cookies: <br/>
                            <ul>
                                <li>  Pentru a vă gestiona preferințele și a îmbunătăți anumite funcții ale siteului nostru. Aceasta poate include colectarea de informații despre data și ora vizitei, istoricul de navigare și preferința dvs. de limbă.</li>
                                <li>Pentru a susține măsurile de securitate și a detecta orice activități frauduloase.</li>
                                <li>Pentru a analiza modul în care siteul nostru este accesată sau utilizată și performanța acesteia. Utilizăm aceste informații pentru a menține, opera și îmbunătăți în mod continuu serviciile noastre.</li>
                                <li> Pentru a difuza reclamele relevante pentru dvs., cookies ne ajută să reglementăm reclamele pe care le vedeți și să le măsurăm eficiența.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Ce tip de cookies și alte tehnologii folosim?</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Siteul nostru utilizează următoarele categorii de cookies și tehnologii: <br/>

                            <strong>Cookies persistente</strong> - aceste cookies sunt stocate în browserul dispozitivului dvs. și sunt utilizate ori de câte ori vizitați siteul nostru. Aceste cookies ne ajută să reținem setările și preferințele utilizatorilor, pentru a facilita vizita următoare a utilizatorilor noștri. De exemplu, aceste cookies vor asigura că utilizatorii nu vor mai trebui să se conecteze din nou.<br/>
                            <strong>Cookies pentru sesiuni</strong> - acestea sunt cookies temporare care rămân în arhiva cookies a browserului dvs. până când părăsiți siteul nostru. Informațiile pe care le primim cu ajutorul acestor cookies ne ajută să analizăm modelele de trafic web, permițându-ne să identificăm și să rezolvăm problemele și să oferim o experiență de navigare mai bună.<br/>
                            <strong>Cookies publicitare</strong> - aceste cookies ne ajută să furnizăm reclame relevante pentru dvs., pentru măsurarea performanței campaniei agenților de publicitate analizând interacțiunea cu reclamele agentului de publicitate. De exemplu, ne ajută să numărăm de câte ori utilizatorii au făcut clic pe reclama agentului de publicitate și au vizitat pagina de internet a agentului de publicitate.<br/>
                            Folosim balize web care sunt mici imagini grafice (cunoscute și ca "etichete pixel" sau "GIF-uri clare") pe siteul nostru. Sunt utilizate împreună cu cookies pentru a identifica utilizatorii și comportamentul acestora.<br/>


                            Siteul nostru poate conține referințe (links) către pagini de internet sau aplicații terțe, inclusiv cele ale partenerilor noștri. Rețineți că aceste pagini de internet terțe pot utiliza și cookies. Nu controlăm aceste pagini de internet terțe și nu suntem responsabili pentru cookies pe care le stabilesc sau le accesează. Dacă faceți clic pe una din aceste referințe sau aplicații, rețineți că fiecare dintre ele va avea politica proprie a cookies. Prin urmare, citiți politica de cookies a altor pagini de internet sau aplicații înainte de a le utiliza.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Avem cookies terțe sau plug-ins pe Platforma noastră?</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Terții, cum ar fi rețelele de publicitate și furnizorii de servicii externe, precum analiza traficului de internet, pot utiliza cookies, balize web, kituri de dezvoltare software (SDK) sau tehnologii similare pentru a colecta informații despre vizita dvs. pe siteul nostru. Informațiile sunt folosite pentru a vă oferi publicitate mai relevantă pe siteul nostru sau în altă parte pe Internet. Nu avem control asupra utilizării acestor cookies terțe, care sunt guvernate de politicile de confidențialitate/cookies ale acelor terți. <br/>



                            Iată lista de terți cărora le permitem să seteze cookies sau alte tehnologii pe dispozitivul dvs.. <br/>

                            <strong>Parteneri Analize</strong>: Pentru a înțelege mai bine cum utilizați siteul nostru, colaborăm cu diverși parteneri de analiză. Permitem următorilor parteneri de analiză să utilizeze cookies, SDKs și alte tehnologii corespunzătoare: <br/>
                            Analiza Google Analytics <br/>
                            <strong>Plug-ins de la terți:</strong> <br/>siteul nostru folosește și plug-in de la terți sub formă de buton de distribuire pentru Facebook, Twitter, Telegram sau Whatsapp. Cu ajutorul acestui buton de partajare puteți distribui anunțurile noastre pe platforma terților, cu condiția să fiți conectat(ă) la acești terți. Acești terți ar putea lega interacțiunea cu platforma noastră cu contul dvs. Prin urmare, citiți politicile de confidențialitate ale acestor terți înainte de a da clic pe butonul de distribuire.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Ce puteți face pentru a șterge, dezactiva sau bloca cookies?</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Browserul dvs. va furniza, de obicei, informații despre cum să refuzați, să ștergeți sau să blocați cookies. Următoarele referințe vă vor duce la acele informații despre unele browsere utilizate în mod obișnuit:<br/>

                            <a href="https://support.google.com/chrome/answer/95647?hl=en-GB" target="_blank" rel="noopener noreferrer">Google Chrome</a>
                            <br/>
                            <a href="https://support.microsoft.com/en-in/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">Internet Explorer</a><br/>
                            <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a><br/>
                            <a href="https://support.apple.com/en-in/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a> <br/>
                            <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank" rel="noopener noreferrer">Opera</a> <br/>

                            Puteți să renunțați la cookies de direcționare pe bază de interese setate, prin serverele de anunțuri participante, prin următoarele pagini de internet, alimentate de Digital Advertising Alliance:<br/>

                            <a href="http://youradchoices.com" target="_blank" rel="noopener noreferrer">http://youradchoices.com</a><br/>
                            <a href="http://www.youronlinechoices.com" target="_blank" rel="noopener noreferrer">http://www.youronlinechoices.com</a><br/>
                            <a href="http://optout.aboutads.info" target="_blank" rel="noopener noreferrer">http://optout.aboutads.info</a><br/>
                            În plus, puteți schimba setările dispozitivului mobil a avea controlul asupra anunțurilor de care sunteți interesat.<br/>

                            <strong>Rețineți</strong>: dacă alegeți să blocați cookies pe care le-am setat pe siteul nostru, să nu puteți accesa toate sau o parte din serviciile noastre, de exemplu, este posibil să nu puteți salva setări personalizate, cum ar fi informații de autentificare.</p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Detalii de contact</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Pentru informații suplimentare sau pentru a vă exercita drepturile, vă rugăm să ne contactați în orice moment la adresa <a
                            href="mailto:office@booksport.ro">office@booksport.ro</a>.
                        </p>
                    </div>
                </div>
            </div>
            <Suspense fallback={renderLoader()}>
                <Footer/>
            </Suspense>
        </div>
    )
}

export default React.memo(Cookies)



