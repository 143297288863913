import React, { useEffect } from 'react';
import netopia from '../../resources/images/netopia_banner_blue.jpg';
import logoImg from '../../resources/images/logo.svg';
import sol from '../../resources/images/SOL.png';
import sal from '../../resources/images/SAL.png';
import CookieConsent from "react-cookie-consent";
import SearchForm from '../search/SearchForm';
import { MessengerChat, showMessenger } from 'react-messenger-chat-plugin';
import RefreshPage from './RefreshPage';
import { PulseLoader } from "react-spinners";
import { useSelector } from "react-redux";
import AppBanner from "./AppBanner";
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import useMediaQuery from '@mui/material/useMediaQuery';

const Footer = () => {
    const loaderSpinner = useSelector(state => state.loader?.loading);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        showMessenger(true);
    }, []);
    RefreshPage();

    const iconStyle = {
        height: 30,
        width: 30,
        color: '#000',
        marginRight: isMobile ? '10px' : '20px',
        marginLeft: isMobile ? '10px' : '0'
    };

    return (
        <div className="footer">
            <MessengerChat
                pageId='444878259387423'
                htmlRef={window.location.pathname}
                autoExpand={false}
            />
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-lg-3 copy pl-lg-0">
                        <p><img className="img-fluid" src={logoImg} alt="logo footer" /></p>
                        <p>Copyright {new Date().getFullYear()} ©Booksport</p>
                        <div className="social-media">
                            <a href="https://www.facebook.com/robooksport" target="_blank" rel="noopener noreferrer">
                                <FacebookSharpIcon style={iconStyle} />
                            </a>
                            <a href="https://www.instagram.com/booksportro" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon style={iconStyle} />
                            </a>
                            <a href="https://www.youtube.com/channel/UCjeAe00qWoGsG3yNvZH4QNA" target="_blank" rel="noopener noreferrer">
                                <YouTubeIcon style={iconStyle} />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <ul>
                            <li><a href="mailto:office@booksport.ro">office@booksport.ro</a></li>
                            <li>0770113322 (WhatsApp)</li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0">
                        <ul>
                            <li><a href="/terms">Termeni si conditii</a></li>
                            <li><a href="/cookies">Politica de cookies</a></li>
                            <li><a href="/privacy-policy">Politica de confidentialitate</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-lg-2 offset-lg-1 mt-4 netopia mt-lg-0">
                        <img className="img-fluid" src={netopia} alt="netopia" />
                        <div className="mt-2 anpc">
                            <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid" src={sal} alt="" />
                            </a>
                        </div>
                        <div className="mt-2 anpc">
                            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid" src={sol} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {loaderSpinner &&
                <div className="loading-panel-full-window">
                    <PulseLoader color='#ffffff' size={16} />
                </div>
            }
            <CookieConsent
                location="bottom"
                buttonText="Accepta"
                cookieName="cConsent"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#ffffff", fontSize: "14px", backgroundColor: "#12803c", padding: "8px 15px", borderRadius: "3px", fontWeight: 400, margin: "0 15px" }}
                expires={150}
            >
                Acest site web folosește cookie-uri prin intermediul cărora se stochează și se prelucrează informații, în scopul îmbunătățirii experienței dumneavoastră. Mai multe detalii <a href="/cookies" style={{ color: "#fff", textDecoration: "underline" }}>aici</a>.
            </CookieConsent>
            <SearchForm />
            <AppBanner />
        </div>
    );
}

export default Footer;
