export const saveReviewTemporary = (stars, review_id, title, description, orderid, clublocation_id, element_id, clubname) => ({
    type:'SAVE_TEMP_REVIEW',
    payload: {stars, review_id, title, description, orderid, clublocation_id, element_id, clubname}
})

export const removeReviewTemporary = () => ({
    type:'REMOVE_TEMP_REVIEW',
})

export const openModalDeleteReview = (reviewHash) => ({
    type:'OPEN_MODAL_DELETE_REVIEW',
    payload: {reviewHash, openModal:true}
})

export const closeModalDeleteReview = (refreshReviews) => ({
    type:'CLOSE_MODAL_DELETE_REVIEW',
    payload: {reviewHash: null, openModal:false, refresh : refreshReviews}
})
