import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { resetPassword } from "../../store/actions/userActions";
import { resetErrors } from "../../store/actions/resetErrors";
import {Button, CircularProgress, InputAdornment, IconButton} from '@mui/material';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {CustomInput} from "../adminRefactored/components/reusable/CustomInput";

const ResetPassword = ({ token }) => {
    const [reset, setReset] = useState();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const res = useSelector(state => state.userStatus.res);

    useEffect(() => {
        dispatch(resetErrors());
        if (res) {
            setReset(res);
        }
    }, [res]);

    const initialValues = {
        password: '',
        password_confirmation: ''
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Introdu parola'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Parolele introduse sunt diferite')
            .required('Introdu parola')
    });

    const onSubmit = (values, { setSubmitting }) => {
        setLoading(true);
        window.dataLayer.push({ event: 'pass_reset' });
        dispatch(resetPassword({
            password: values.password,
            password_confirmation: values.password_confirmation,
            remember_token: token
        }));
        setSubmitting(false);
    };

    const handleErrors = (name) => {
        if (res && res[name]) {
            return <div className="has-danger text-danger text-center">{res[name]}</div>;
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="form">
                    <div className="card card-login card-hidden">
                        <div className="card-header p-0">
                            <div className="bg-gradient-info shadow-info border-radius-lg py-3 text-center py-4"
                                 style={{backgroundImage: 'linear-gradient(to bottom, #12803c, #4CAF50)', borderRadius: '0.5rem'}}>
                                <h4 className="font-weight-bolder text-white mt-1 mb-0" style={{fontSize: 18, fontWeight: 600, letterSpacing: 1}}>Reseteaza parola</h4>
                                <p className="mb-1 text-sm text-white">Bine ai revenit!</p>
                            </div>
                        </div>
                        <div className="card-body pl-3 pr-3">
                            {reset ? (
                                <div className="row">
                                    <div className="col-12 text-center"><h4 className='m-0 mt-5 mb-3 font-weight-bold'>{reset}</h4> </div>
                                    <div className="text-center col-12">
                                        <a className="btn btn-success mt-5 mb-5 w-100" href="/">Prima pagina</a>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <Field
                                            as={CustomInput}
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            label="Parolă"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            helperText={<div style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="password" /></div>}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            edge="end"
                                                        >
                                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {handleErrors("password")}
                                        <Field
                                            as={CustomInput}
                                            type={showConfirmPassword ? "text" : "password"}
                                            name="password_confirmation"
                                            label="Repetă parola"
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            helperText={<div style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="password_confirmation" /></div>}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            edge="end"
                                                        >
                                                            {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {handleErrors("password_confirmation")}
                                    </div>

                                    <div className="text-center col-12 mt-3 mb-4">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="success"
                                            disabled={loading || isSubmitting}
                                            fullWidth
                                            style={{ height: '45px', marginBottom: 30 }}
                                        >
                                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Reseteaza'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ResetPassword;
