import React, {useEffect, useState} from 'react'
import visa from "../../../../../resources/images/visa.svg";
import mastercard from "../../../../../resources/images/mastercard.svg";
import {useDispatch} from "react-redux";
import {setSelectedPaymentCard} from "../../../../../store/actions/admin/cards";


const SavedCardsListing = ({cards}) =>{
    const [selectedCard, setSelectedCard] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
       if(cards.length > 0){
           setSelectedCard(cards[0].payment_instrument_id)
       }
    },[])

    const setCard = (card) => {
        setSelectedCard(card)
        dispatch(setSelectedPaymentCard(card))
    }

    return(
       cards && <div className="col-12 mt-4 mb-4">
            <div className="row">
                {
                    cards && cards.map((card) => {
                        let image = parseInt(card.pan_masked.charAt(0)) === 4 ? visa : mastercard
                        return(
                            <div key ={card.pan_masked} className="col-12 card-option">
                                <div className="form-group w-100">
                                    <div className="form-check mt-0 w-100 pb-0 mb-0">
                                        <div className="form-check w-100 mb-0 ">
                                            <label className="form-check-label">
                                                <input className="form-check-input" value={card.payment_instrument_id} checked={selectedCard === card.payment_instrument_id}  onChange={() => setCard(card.payment_instrument_id)} type="radio" name="card" />
                                                {parseInt(card.pan_masked.charAt(0)) === 4 ? 'Visa' : 'Mastercard'} {card.pan_masked} <br/> Expira in {card.exp_date}
                                                <span className="circle">
                                                    <span className="check"> </span>
                                                </span>
                                            </label>
                                            <img className="img-card" src={image} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="col-12 card-option">
                    <div className="form-group">
                        <div className="form-check mt-0 mb-0">
                            <div className="form-check w-100 mb-0">
                                <label className="form-check-label">
                                    <input className="form-check-input" onChange={() => setCard(0)}  type="radio" name="card" value={0} checked = { parseInt(selectedCard) === 0 || parseInt(selectedCard) === 1}/>
                                    Achita cu alt card
                                    <span className="circle">
                                                <span className="check"> </span>
                                            </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default SavedCardsListing
