import React, { useState, useEffect } from "react";
import { Button, IconButton, Slide, DialogTitle, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomInput } from "../../../components/reusable/CustomInput";
import Dialog from "@mui/material/Dialog";
import SpinnerContainer from "../../../components/loading-spinner";
import booksport from "../../../../../api/booksport";
import { AdminRoutes } from "../../../../../api/admin-routes";
import { useDispatch } from "react-redux";
import { refreshDocuments } from "../../../../../store/actions/admin/documents";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ButtonSign = ({ params }) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [responseMsg, setResponseMsg] = useState(null);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    let timeoutId = null;

    useEffect(() => {
        return () => {
            // Funcție de curățare pentru a șterge timeout-ul când componenta este dezmontată
            clearTimeout(timeoutId);
        };
    }, [timeoutId]);

    const handleSignDocument = async () => {
        if (!name.trim()) {
            setError("Numele este obligatoriu");
            return;
        }

        setLoading(true);
        setError(null);
        const query = {
            token: localStorage.getItem("jwtToken"),
            name: name,
            id: params.row.id,
        };
        try {
            const response = await booksport.post(AdminRoutes.signDocument, query);
            if (response.data?.message) {
                setResponseMsg(response.data.message);
                timeoutId = setTimeout(() => {
                    dispatch(refreshDocuments(true));
                    closeModal();
                }, 3000);
            }
        } catch (error) {
            setError(error.response?.data.message || "A apărut o eroare");
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        dispatch(refreshDocuments(false));
        setShowModal(false);
        setName("");
        setResponseMsg(null);
        setError(null);
        clearTimeout(timeoutId);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-center pt-7 pb-7">
                        <SpinnerContainer />
                    </div>
                </div>
            );
        }
        return (
            <>
                <DialogTitle sx={{ textAlign: "center", marginBottom: "10px", paddingTop: '40px', fontSize: '18px' }}>
                    {!responseMsg && <>Confirmare Semnătură Electronică pentru "{params.row.title}"</>}
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {!responseMsg && (
                        <div style={{fontSize: '13px'}}>
                            <p>Prin completarea numelui dumneavoastră în câmpul de mai jos și apăsarea butonului "SEMNEAZĂ", dumneavoastră, în calitate de semnatar, confirmați expres și fără echivoc că ați citit, înțeles și acceptat în totalitate termenii și condițiile documentului intitulat "{params.row.title} nr {params.row.documentNumber} din data de {formatDate(params.row.documentDate)}“, aferent societății {params.row.societate}.</p>
                            <p>De asemenea, recunoașteți că această acțiune de semnătură electronică constituie un angajament legal echivalent cu semnătura dumneavoastră manuscrisă și vă obligă în fața legii. Confirmați astfel că toate informațiile furnizate sunt corecte și complete, și acceptați că această semnătură electronică este validă și obligatorie pentru executarea documentului menționat.</p>
                        </div>
                    )}
                    <>
                        {responseMsg ? (
                            <h3 className="text-green-400 mt-0 mb-4 text-center">{responseMsg}</h3>
                        ) : (
                            <>
                                <CustomInput
                                    fullWidth
                                    focusColor="#12803c"
                                    name="name"
                                    label="Nume Prenume"
                                    value={name}
                                    onChange={(evt) => setName(evt.target.value)}
                                />
                                {error && <p className="text-danger mt-2 small">{error}</p>}
                                <Button
                                    variant="contained"
                                    onClick={handleSignDocument}
                                    style={{
                                        background: "#12803c",
                                        marginTop: "20px",
                                        marginBottom: "30px",
                                        width: "100%",
                                        padding: "10px",
                                    }}
                                >
                                    Semnează
                                </Button>
                            </>
                        )}
                    </>
                </DialogContent>
            </>
        );
    };

    return (
        <>
            <Button
                variant="contained"
                style={{
                    background: loading ? "gray" : "#12803c",
                    fontSize: ".75rem",
                    lineHeight: ".1 rem",
                    textAlign: "center",
                }}
                onClick={() => setShowModal(true)}
                disabled={loading}
            >
                Semnează
            </Button>
            <Dialog
                open={showModal}
                onClose={closeModal}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                scroll={"body"}
            >
                {renderContent()}
            </Dialog>
        </>
    );
};

export default ButtonSign;
