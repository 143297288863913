import booksport from '../../api/booksport'
import setAutorizationToken from '../../utils/setAuthorizationToken'


export function setCurrentUser(user) {
    return{
        type: 'SET_CURRENT_USER',
        user: user
    }
}

export function logOut() {
    return dispatch => {
        localStorage.removeItem('jwtToken')
        localStorage.removeItem('usInf')
        localStorage.removeItem('isAuth')
        setAutorizationToken()
        dispatch(setCurrentUser({}))
    }
}

export const loginUser = formValues => async dispatch => {
    return booksport.post('/login', formValues)
        .then(res => {
            const token = res.data.token
            const user = res.data.user
            localStorage.setItem('jwtToken', token)
            localStorage.setItem('usInf', user)
            localStorage.setItem('isAuth', true)
            dispatch(setCurrentUser(user))
            setAutorizationToken()
        })
        .catch(err=>{dispatch({type:'LOGIN', payload:err.response.data})})
}

export const getClubsByToken = () => async dispatch => {
    var token = {
        token : localStorage.getItem('jwtToken')}

    return booksport.get('/clubs', token)
        .then(res => {
            dispatch({type:'CLUBS', payload:res.data})
        })
        .catch(err=>{dispatch({type:'CLUBS', payload:err.response})})
}

export const recoverPassword = formValues => async dispatch => {
    const response = await booksport.post('/login', formValues)
    const token = response.data.token
    localStorage.setItem('jwtToken', token)

    dispatch({type:'LOGIN', payload:response.data})
}
export const resetPassword = formValues => async dispatch => {
    return booksport.post('/user_validate', formValues)
        .then(res => {
            dispatch({type:'RECOVER_PASS', payload:res.data})
        })
        .catch(err=>{dispatch({type:'RECOVER_PASS', payload:err.response.data})})
}

export const registerUser = formValues => async dispatch => {
    return booksport.post('/register', formValues)
        .then(res => {
            dispatch({type:'REGISTER', payload:res.data})
        })
        .catch(err=>{dispatch({type:'REGISTER', payload:JSON.parse(err.response.data)})})
}

export const registerClub = formValues => async dispatch => {
    return booksport.post('/registerclubrequest', formValues)
        .then(res => {
            dispatch({type:'REGISTER_CLUB', payload:res.data})
        })
        .catch(err=>{dispatch({type:'REGISTER_CLUB', payload:JSON.parse(err.response.data)})})
}

export const setSelectedCounty = (county) => ({
    type: 'SET_COUNTY',
    payload: {county : county}
})

export const setSelectedCity = (city) => ({
    type: 'SET_CITY',
    payload: {city : city}
})

export const setSelectedCountyPJ = (county) => ({
    type: 'SET_COUNTY_PJ',
    payload: {county : county}
})

export const setSelectedCityPJ = (city) => ({
    type: 'SET_CITY_PJ',
    payload: {city : city}
})
