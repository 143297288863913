import React, {useEffect} from 'react'

import appstore from '../../resources/images/appstore.png'
import googleplay from '../../resources/images/googleplay.png'
import {Helmet} from "react-helmet";
import MapSection from '../sections/MapSection';
import MobileAppSection from '../sections/MobileAppSection'
import AboutUs from '../sections/AboutUsSection'
import ThreeStepsSection from '../sections/ThreeStepsSection'

import HomepageBookingCard from '../panels/HomepageBookingCard'
import Navbar from '../navs/Navbar'
import Footer from '../reusable/Footer'
import BackgroundHome from '../panels/BackgroundHome';
import HomepageText from '../panels/HomepageText';
import PromoClubsCarousel from "../sections/PromoClubsCrousel";
import MediaSection from "../sections/MediaSection";




const Homepage = () => {

    useEffect(() => {
       window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location.pathname,
          title: 'Homepage'
        }
      });
    }, [])


        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Booksport.ro | Rezervă online</title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                    <meta name="description" content="Booksport este o platforma online pentru inchieri de terenuri de tenis, terenuri de fotbal, saloane de masaj, mese de ping-pong si multe altele ce permite plata online cu cardul" />
                    <meta property="og:site_name" content="booksport.ro" />
                    <meta property="og:title" content="Booksport" />
                    <meta property="og:description" content="Booksport este o platforma online pentru inchieri de terenuri de tenis, terenuri de fotbal, saloane de masaj, mese de ping-pong si multe altele ce permite plata online cu cardul" />
                </Helmet>
                    <Navbar key={new Date()} logoW={true}/>
                <div className="page-header  header-filter home-panel" data-parallax="true">
                <BackgroundHome/>
                    <div className="container wrapper-pannel-content">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto">
                                <div className="brand">
                                    <h1><HomepageText/> mai aproape</h1>
                                </div>
                            </div>

                                <HomepageBookingCard />

                            <div className="col-md-8 ml-auto mr-auto d-flex wrapper-applogos mb-4">
                                <a href="https://apps.apple.com/us/app/booksport/id1585479595"> <img className="mr-md-3 mb-2 mb-md-0 app-img" src={appstore}/></a>
                                <a href="https://play.google.com/store/apps/details?id=ro.Booksport.Booksport"> <img className="app-img" src={googleplay}/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <PromoClubsCarousel/>
                <MapSection/>
                <ThreeStepsSection/>
                <MobileAppSection/>

                <Footer/>

            </div>

        )
}

export default Homepage
