import React, { useEffect, useState } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import cardbk from '../../../resources/images/cards.jpg';
import L from 'leaflet';
import marker from '../../../resources/images/map-icon_green.svg';
import markerdisabled from '../../../resources/images/map-icon_disabled.svg';
import booksport from '../../../api/booksport';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {PulseLoader} from "react-spinners";

const pointerIcon = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [50, 50],
    shadowSize: [68, 95],
    shadowAnchor: [20, 92],
});

const disabledIcon = new L.Icon({
    iconUrl: markerdisabled,
    iconRetinaUrl: markerdisabled,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [50, 50],
    shadowSize: [68, 95],
    shadowAnchor: [20, 92],
});

const LMap = () => {
    const location = useLocation();
    const { date, sport, hour } = queryString.parse(location.search);
    const [clubs, setClubs] = useState([]);
    const [loading, setLoading] = useState(true);

    const currentParams = queryString.parse(location.search);
    const radius = currentParams.radius || 20;

    const allParamsAvailable = sport && currentParams.location && date && hour;

    const [paramsReady, setParamsReady] = useState(false);

    useEffect(() => {
        if (allParamsAvailable) {
            setParamsReady(true);
        }
    }, [allParamsAvailable]);

    useEffect(() => {
        const fetchClubs = async () => {
            if (!paramsReady) {
                return;
            }

            try {
                const userId = localStorage.getItem('usInf')
                    ? JSON.parse(atob(localStorage.getItem('usInf'))).id
                    : 99999999;

                const queryParams = {
                    user_id: userId,
                    sport_id: sport,
                    location_id: currentParams.location,
                    radius: radius,
                    date: date,
                    time: hour,
                };

                const { data } = await booksport.get('/listclubs', { params: queryParams });
                setClubs(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchClubs();
    }, [paramsReady, sport, date, hour, currentParams.location, radius]);

    if (loading) {
        return  <div className="col-12">
            <div className="d-flex align-items-center justify-content-center pt-5 pb-5 wrapperloader">
                <PulseLoader color='#12803c' size={20} />
            </div>
        </div>;
    }

    const position = [clubs.centermap?.lat || 44.439663, clubs.centermap?.lng || 26.096306];

    return (
        <Map center={position} zoom={12}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {clubs.clubs?.map((club) => {
                const point = [club.lat, club.lng];
                const bk = club.filename ? `https://img.booksport.ro/clubs/${club.id}/small/${club.filename}` : cardbk;
                const clublink = club.slug ? `/${club.slug}?date=${date}` : '';

                return (
                    <Marker
                        position={point}
                        key={club.id}
                        icon={club.booking === 0 ? disabledIcon : pointerIcon}
                        zIndexOffset={club.booking === 0 ? 0 : 3000}
                    >
                        <Popup>
                            <a href={clublink}>
                                <div className="card" style={{ background: `url(${bk}) rgba(0, 0, 0, 0.4)` }}>
                                    {club.name}
                                    <br />
                                    {club.locationname}
                                    <p className="mb-0">
                                        <span className="green">{club.elements}</span>
                                    </p>
                                </div>
                            </a>
                        </Popup>
                    </Marker>
                );
            })}
        </Map>
    );
};

export default LMap;
