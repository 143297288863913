
export default (state = {}, action) => {
  switch (action.type) {
    case 'START_FILTER':
      return {...state, refreshFilter: action.startFilter}
    case 'STOP_FILTER':
      return {...state, refreshFilter: action.startFilter}
    case 'OPEN_MAP':
      return {...state, openMap: action.openMap}
    case 'CLOSE_MAP':
      return {...state, openMap: action.openMap}
    default :
      return state
  }
}
