import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../api/booksport";
import {setShowConfirmation} from "../../../store/actions/rezervareActions";
import {PulseLoader} from "react-spinners";


const ConfirmationFooter = () =>{
    const clubdata = useSelector(state => state.showModal.rezerva?.clubData)
    const rezervare = useSelector(state => state.rezervare)
    const [loading, setLoading] = useState()
    const dispatch = useDispatch()


    const returnToSelection = () => {
        dispatch(setShowConfirmation(false))
    }

    const storeSelection = () => {
        setLoading(true)
        let card = rezervare.card ? rezervare.card : 0;

        if(!rezervare.card || parseInt(rezervare.card) === 0){
            card = rezervare.saveCard ? 1 : 0;
        }

        const query = {
            token: localStorage.getItem('jwtToken'),
            sport_id: clubdata.sport_id,
            clublocation_id: clubdata.clublocation_id,
            element_id: rezervare.element,
            item_id: rezervare.item,
            dataora: `${clubdata.date} ${clubdata.time}`,
            minutes: parseInt(rezervare.price.split('_')[0]),
            notes: 'Rezervare site / Plata in asteptare',
            element_status_id: 2,
            schedule_source_id: 1,
            payment_instrument_id: card,
            avans: rezervare.price.split('_')[1] === 'p' ? 1 : 0,
        }

        // eslint-disable-next-line array-callback-return
        Object.entries(rezervare).map(([key,value]) => {
            if(key.split('_')[0] === 'extra' || key.split('_')[0] === 'check' ){
                query[key] = value
            }
        })

        // eslint-disable-next-line no-unused-expressions
        rezervare.voucher ? query['voucher'] = rezervare.voucher : null


        booksport.post('/schedule/store',   query)
            .then((res) =>
                    window.location.assign(res.data)
            )
            .catch( (err) =>
                setLoading(false)
            )
    }


    return(
        <div className="col-12 mb-3 mt-4">
            <div className="row ">
                <div className="col-6">
                    <button className="btn btn-warning mt-1 w-100" style={{fontSize: '16px'}} onClick={returnToSelection}>Inapoi</button>
                </div>
                <div className="col-6">
                    <button className="btn btn-success mt-1 w-100" disabled={loading} style={{fontSize: '16px'}} onClick={storeSelection}>Achita</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationFooter
