export const openModalDeleteComment = (commentHash, reviewID) =>{
    return{
        type: 'OPEN_MODAL_DELETE_COMMENT',
        payload : {commentHash, reviewID,  open: true}
    }
}

export const closeModalDeleteComment = () =>{
    return{
        type: 'CLOSE_MODAL_DELETE_COMMENT',
    }
}
 
export const refreshComments = (reviewID) =>{
    return{
        type: 'REFRESH_COMMENTS',
        payload : {reviewID}
    }
}
