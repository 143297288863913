
export default (state = {}, action) => {
    switch (action.type) {
        case 'OPEN_MODAL':
            return {...state, showModal: action.showModal}
        case 'OPEN_MODAL_REZERVA':
            return {...state, rezerva: action.rezerva}
        case 'OPEN_MODAL_REZERVA_TABLE':
            return {...state, rezerva: action.rezerva}
        case 'CLOSE_MODAL':
            return {...state, showModal: action.showModal}
        case 'CLOSE_MODAL_REZERVA':
            return {...state, rezerva: action.rezerva}
        case 'CLOSE_MODAL_REZERVA_TABLE':
            return {...state, rezerva: action.rezerva}
        case 'OPEN_MODAL_VOUCHER':
            return {...state, modalVoucher: action.open}
        case 'SHOW_MODAL_DELETE_ACCOUNT':
            return {...state, modalDeleteAccount: action.open}
        default :
            return state
    }
}
