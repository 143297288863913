import React, { useState, useEffect } from 'react'
import TopNavAdmin from '../../components/navigations/TopNavAdmin'
import booksport from "../../../../api/booksport";
import SpinnerContainer from "../../components/loading-spinner";
import {AdminRoutes} from "../../../../api/admin-routes";
import ActiveBookingCard from "../user-bookings/components/ActiveBookingCard";
import {useDispatch, useSelector} from "react-redux";
import {refreshBookings} from "../../../../store/actions/admin/bookings";

const UserMain = () => {
    const [activeBookings, setActiveBookings] = useState([])
    const [loading, setLoading] = useState(true)
    const refreshBookingsSelector = useSelector(state => state.admin.bookings?.refreshBookings)
    const dispatch = useDispatch()

    useEffect(() => {
        refreshBookingsSelector && getBookingsFromApi()
    }, [refreshBookingsSelector]);

    useEffect(() => {
        getBookingsFromApi()
    }, []);


    const getBookingsFromApi = async () =>{
        setLoading(true)
        const query={
            token: localStorage.getItem('jwtToken'),
        }
        try{
            const response = await booksport.get(`${AdminRoutes.getUserBookings}`, {params: query})
            const { active } = response.data;
            active && setActiveBookings(active);
            refreshBookingsSelector && dispatch(refreshBookings(false))
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }

    const renderBookings = () => {
        if (activeBookings.length === 0){
            return(
                <div className="row">
                    <div className="col-12">
                        <h3>Nu exista rezervari active</h3>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <div className="row">
                        <div className="col-12">
                            <h3>Rezervari active</h3>
                        </div>
                        {activeBookings.map(activeBooking => (
                            <ActiveBookingCard key={activeBooking.id} booking={activeBooking} />
                        ))}
                    </div>
                </>
            );
        }
    }

    return(
        <div className="main-panel">
            <TopNavAdmin title= 'Dashboard'/>
            <div className="content p-0-mobile">
                <div className="container-fluid">
                    {loading ? <SpinnerContainer/> : renderBookings() }
                </div>
            </div>
        </div>
    )
}

export default UserMain
