import React, {useState, useEffect} from "react";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import booksport from "../../../../api/booksport";
import {AdminRoutes} from "../../../../api/admin-routes";
import ClubCard from "./components/ClubCard";
import SpinnerContainer from "../../components/loading-spinner";


const AdminClub = () => {
    const [loading, setLoading] = useState(false)
    const [clubs, setClubs] = useState([])

    useEffect(() => {
        getAdminClubs()
    }, []);


    const getAdminClubs = async () =>{
        setLoading(true)
        try{
            const response = await booksport.get(`${AdminRoutes.clubs}`)
            setClubs(response.data)
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }


    return(
        <div className="main-panel">
            <TopNavAdmin title='Administrare club'/>
            <div className="content">
                {loading && <SpinnerContainer/>}
                {
                    clubs && clubs.length > 0 ?
                        clubs.map(club => {
                            return club.sports.map( sport => {
                                return <ClubCard key={club.club_id?.id} sport={sport} club={club}/>
                            })
                        })
                        : false

                }
            </div>
        </div>
    )
}

export default AdminClub
