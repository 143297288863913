import React, {useEffect, useState} from 'react'
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../../api/booksport";
import {refreshTotal, setVoucher} from "../../../../store/actions/rezervareActions";
import ModalAddVoucher from "../../../adminRefactored/pages/user-vouchers/components/ModalAddVoucher";
import {showUserVouchersModal} from "../../../../store/actions/admin/vouchers";

const VouchersSelect = ({time, date}) =>{
    const [vouchers, setVouchers] = useState([])
    const [selectedVoucher, setSelectedVoucher] = useState(null);
    const clublocation_id = useSelector(state => state.showModal?.rezerva?.clubData?.clublocation_id)
    const item_id =  useSelector(state => state.rezervare.item)
    const refreshVcs = useSelector(state => state.admin.vouchers?.refresh)
    const dispatch = useDispatch()


    const setVoucherSelection = (selectedOption) => {
        setSelectedVoucher(selectedOption)
        dispatch(setVoucher(selectedOption.value))
        dispatch(refreshTotal(true))
    }

    const openVoucherModal = () => {
        dispatch(showUserVouchersModal(true))
    }

    useEffect(() => {
       getVouchersFromApi()
    }, [refreshVcs])

    const getVouchersFromApi = () => {
        const query = {
            token: localStorage.getItem('jwtToken'),
            clublocation_id: clublocation_id,
            item_id: item_id,
            time: time,
            day: date

        }
        const vcs = []
        booksport.get('/getuservouchers',  {params: query})
            .then((res) =>{
                res.data.length && vcs.push({label: 'Fara aplicare cod de reducere', value: null})
                    res.data && res.data.map(voucher => {
                        vcs.push({label: `${voucher.name} (${voucher.valoare})`, value: voucher.serie })
                    })
                    setVouchers(vcs)
                vcs[1] && setVoucherSelection(vcs[1])
                }
            )
            .catch( (err) =>
                console.log(err)
            )
    }

    return(
            <div className="col-12 mt-4 mb-3">
                <div className="row">
                    <div className="col-12 small pb-1">Ai un cod de discount? Selecteaza-l din lista sau adauga unul nou!</div>
                    <div className="col-12 col-md-9">
                        <Select
                            isSearchable={ false }
                            className="w-100 mb-2"
                            options={vouchers}
                            placeholder = {'Selectează cod de discount'}
                            onChange = {(selOption) => setVoucherSelection(selOption)}
                            value = {selectedVoucher}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <span className="btn btn-link btn-success w-100 m-0 btn-voucher" onClick={openVoucherModal}>Adauga cod</span>
                    </div>
                </div>
                <ModalAddVoucher/>
            </div>

    )
}

export default VouchersSelect
