import React,{Component} from 'react'
import booksport from "../../../api/booksport";
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import {openModalRezerva} from "../../../store/actions/modalActions";
import moment from 'moment'
import {showLoaderWindow} from "../../../store/actions/loader";

class TableCellTerenSite extends Component {
    constructor(props) {
        super(props);
            this.state= {
                sport_id: parseInt(this.props.sport),
                clublocation_id: parseInt(this.props.clublocation),
            }
    }


    handleModal = (modalData, hour) => {
        var clubData = {
            sport_id: parseInt(this.props.sport_id),
            clublocation_id: parseInt(this.props.clublocation_id),
            date: this.props.date,
            time: hour,
            clubname: this.props.clubName + ' ' + this.props.clublocationname,
            teren:  {label:this.props.terenName, value:this.props.terenId}
        }
        this.props.openModalRezerva(modalData, clubData)
    }
    getRezOptions = (hour,e) => {
        const token = localStorage.getItem('jwtToken');
        let user = null;

        token && this.props.showLoaderWindow(true)

        if(localStorage.getItem('usInf')){
            user= JSON.parse(atob(localStorage.getItem('usInf')));
        }
        if(user && user.user_type !== 0){
            return false
        }

        if(token == null ){
            const location = {
                pathname: '/login',
                state: { from: window.location.pathname + '?date=' + this.props.date}
            }
            this.props.history.push(location)
        }else{
            if (hour.split(':')[0] <= 9){
                hour='0'+hour
            }
            var clubData = {
                sport_id: parseInt(this.props.sport_id),
                clublocation_id: parseInt(this.props.clublocation_id),
                date: this.props.date,
                time: hour,
            }

            booksport.get('/listelementavailability', {params: clubData})
                .then((res) =>
                    this.handleModal(res.data, hour)
                )
                .catch((err) =>
                    console.log(err)
                )
        }
    }



    render() {
        var d = new Date();
        var n = d.getHours();
        var dateSmaller = moment(this.props.date).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD')

            if(dateSmaller && parseInt(this.props.hour) < parseInt(n)){
                return (
                    <span className="past"></span>
                )
            }else {
                return (
                    this.props.element.min && this.props.element.min.map(min => {
                        if(this.props.element.booking === 0){
                            return  <span key={Math.random()} className="ocupat"> </span>
                        }else{
                            if(min.status === 0){
                                let minute = '30'
                                if(min.minut_curent === 0){
                                    minute = '00'
                                }
                                return  <span key={Math.random()} className="liber" onClick={() => this.props.booking !== 0 ? this.getRezOptions(this.props.hour + ':' + minute) : ''}> </span>
                            }else{
                                return  <span key={Math.random()} className="ocupat"> </span>
                            }
                        }
                    })
                )
            }
    }
}

const mapStateToProps = (state) => {
    return{
        rezerva: state.showModal.rezerva
    }
}

export default withRouter(connect(mapStateToProps,{openModalRezerva, showLoaderWindow}) (TableCellTerenSite));
