import React, {useEffect, useState} from 'react'
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from 'react-redux';
import booksport from '../../api/booksport';
import {showModalDeleteAccount} from "../../store/actions/modalActions";
import {CustomInput} from "../adminRefactored/components/reusable/CustomInput";
import {PulseLoader} from "react-spinners";
import {useFormik} from "formik";
import * as Yup from "yup";
import {logOut} from "../../store/actions/userActions";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../utils/history";

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Campul parola este obligatoriu').min(6, "Minim 6 caractere"),
})

const ModalDeleteAccount = () => {
    const[error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [ivalues, setIvalues] = useState(null)
    const openModal = useSelector(state => state.showModal.modalDeleteAccount)
    const dispatch = useDispatch()

    const closeModal = () => {
        formik.resetForm();
        setError('')
        dispatch(showModalDeleteAccount(false))
    }

    useEffect(() => {
        setInitialValues()
    }, [])


    const handleSubmit = async (values) => {
        setLoading(true)
        values.token = localStorage.getItem('jwtToken')

        try {
            await booksport.delete('/delete_account',  {params: values})
            dispatch(showModalDeleteAccount(false))
            toast.success("Contul a fost sters cu succes!", {
                onClose: () => {
                    history.push('/')
                    dispatch(logOut())
                }
            });
        }catch(err){
            setError(err.response.data.error)
            setLoading(false)
        }
    }

    const setInitialValues = () => {
        const initialValues = {
            password: ''
        }
        setIvalues(initialValues)
        formik.setValues(initialValues)
    }

    const formik = useFormik({
        initialValues: ivalues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmit(values)
        },
    });


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Modal show={openModal} animation={true} className="modal-anulare">
                <div className="modal-confirm-anulare">
                    <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                        <i className="material-icons">clear</i>
                    </button>
                    <h5 className="font-weight-bold mt-4">Pentru stergerea contului introdu parola</h5>
                    <div className="col-12">
                        { ivalues && formik.values && <form onSubmit={formik.handleSubmit}>
                            <div className="col-12 mt-4">
                                <CustomInput
                                    name = 'password'
                                    focusColor='#12803c'
                                    type={"password"}
                                    fullWidth
                                    label="Parola"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    error={formik.touched.password && Boolean(formik.errors.password) || Boolean(error)}
                                    helperText={formik.touched.password && formik.errors.password || error}
                                />
                            </div>
                            <div className="wrapper-btns d-flex">
                                <div className="btn btn-warning text-center w-100" target="_blank" rel="noopener noreferrer" onClick={closeModal}>Anuleaza</div>
                                <button className="btn btn-danger text-center w-100 btn btn-book" type='submit' rel="noopener noreferrer">
                                    {loading ?
                                        <PulseLoader color='#ffffff' size={10}></PulseLoader>
                                        : "Sterge cont" }
                                </button>
                            </div>
                        </form> }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalDeleteAccount
