import React from 'react';
import { useDispatch } from 'react-redux';
import { openSearchPanel } from '../../store/actions/search';
import SearchIcon from '../../resources/images/search-icon.svg';
import SearchIconWhite from '../../resources/images/search-icon-white.svg';
import Box from '@mui/material/Box';

const OpenSearch = ({ white }) => {
    const dispatch = useDispatch();

    const openSearch = () => {
        dispatch(openSearchPanel());
    };

    return (
        <Box
            onClick={openSearch}
            sx={{
                cursor: 'pointer'
            }}
        >
            <img className='search-button' src={white ? SearchIconWhite : SearchIcon} alt="Search" style={{ height: 25, width: 25 }} />
        </Box>
    );
};

export default OpenSearch;
