import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { Slide } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useDispatch, useSelector } from "react-redux";
import booksport from "../../../../../api/booksport";
import { AdminRoutes } from "../../../../../api/admin-routes";
import { refreshBookings } from "../../../../../store/actions/admin/bookings";
import { toast, ToastContainer } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CancelPaidByVoucher = ({ booking }) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const refreshBookingsSelector = useSelector(state => state.admin.bookings?.refreshBookings);
    const dispatch = useDispatch();

    useEffect(() => {
        refreshBookingsSelector && setShowModal(false);
    }, [refreshBookingsSelector]);

    const returnVoucher = async () => {
        const query = {
            token: localStorage.getItem('jwtToken'),
            encrypted: booking.voucherencrypted
        };

        try {
            setLoading(true);
            const response = await booksport.post(`${AdminRoutes.returnVoucher}`, query);
            if (response) {
                setShowModal(false);
                toast.success('Codul discount a fost creat si alocat cu succes!', {
                    onClose: () => {
                        dispatch(refreshBookings(true));
                    }
                });
            }
        } catch (err) {
            setShowModal(false);
            err.response && toast.error(`${err.response?.error}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                variant='contained'
                style={{ background: '#ff6600', margin: '0 10px', width: '100%', flexDirection: 'column', fontSize: 12, lineHeight: '20px' }}
                onClick={() => setShowModal(true)}
                disabled={loading} // Disable the button when loading
            >
                {loading ? '...' : (
                    <>
                        Anuleaza cu <br />
                        <span className="small" style={{lineHeight: '14px'}}>cod discount la club {booking.refund_amount} RON</span>
                    </>
                )}
            </Button>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Dialog
                open={showModal ? showModal : false}
                onClose={() => setShowModal(false)}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                scroll={"body"}
            >
                <DialogTitle sx={{ textAlign: 'center' }}>
                    Anulare rezervare
                </DialogTitle>
                <DialogContent>
                    <p style={{ marginBottom: 0 }}>Esti sigur ca vrei sa anulezi rezervarea?</p>
                    <p style={{ marginBottom: 0 }}>Anularea rezervarii se va face cu alocarea unui <strong>COD DISCOUNT</strong> in valoare de <strong>{booking.refund_amount} RON</strong></p>
                </DialogContent>
                <DialogActions style={{ marginBottom: '10px', paddingRight: '10px' }}>
                    <Button variant='contained' onClick={() => setShowModal(false)} style={{ background: '#12803c' }} >Inchide</Button>
                    <Button variant='contained' onClick={() => returnVoucher()} style={{ background: '#e95e4e' }} disabled={loading}>Adauga cod discount</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CancelPaidByVoucher;
