import React from 'react'
import Navbar from './Navbar'
import {useDispatch} from 'react-redux'
import {setSidebarStatus} from "../../../../store/actions/admin/sidebar";


const TopNavAdmin = ({title}) => {
    const dispatch = useDispatch()

    const openSidebar = () => {
        dispatch(setSidebarStatus(true))
    }

    return(
        <div>
            <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top">
                <div className="container-fluid">
                    <div className="navbar-wrapper">
                        <h1 className="navbar-brand">{title}</h1>
                    </div>
                    <button className="navbar-toggler" onClick={openSidebar}>
                        <span className="sr-only">Toggle navigation</span>
                        <span className="navbar-toggler-icon icon-bar"> </span>
                        <span className="navbar-toggler-icon icon-bar"> </span>
                        <span className="navbar-toggler-icon icon-bar"> </span>
                    </button>
                </div>
            </nav>
            <Navbar/>
        </div>
    )
}

export default TopNavAdmin
