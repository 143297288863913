import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Reviews from '../cards/Reviews';
import ClubGallery from '../ClubsRefactored/components/ClubGallery';
import loginbk from "../../resources/images/login-bk.jpg";

const ElementDescription = ({clubdetails}) => {
    return (
        clubdetails.booking === 1 &&  clubdetails.cardreview === 1 ?
        <Tabs>
            <TabList>
                <Tab>Descriere</Tab>
                <Tab>Review-uri {clubdetails.element_nr_review ? `(${clubdetails.element_nr_review})` : ''}</Tab>
            </TabList>

            <TabPanel>
                <div className="element-description">
                    <div className="image">
                        <img src={`https://img.booksport.ro/clubs/${clubdetails.id}/instructori/${clubdetails.element_main_image[0].filename}`} alt="" />
                    </div>
                    <div className="about" dangerouslySetInnerHTML={{ __html: clubdetails.element_description }}>
                    </div>
                </div>

                <div className="pics" >
                    {clubdetails.images_gallery && clubdetails.images_gallery.length > 0 ?<ClubGallery pics={clubdetails.images_gallery} clubId={clubdetails.id}/>: <img className="img-fluid" src={clubdetails.main_image && clubdetails.main_image.length !== 0 ? 'https://img.booksport.ro/clubs/'+clubdetails.id +'/big/'+clubdetails.main_image[0].filename  : loginbk} alt=""/>}
                </div>
            </TabPanel>
            <TabPanel>
                <Reviews element={clubdetails.element_id} ratingNr={clubdetails.element_nr_review} rating={clubdetails.element_review} club={clubdetails.id} clubname={`${clubdetails.element_name}` }/>
            </TabPanel>
        </Tabs> :
        <>
            <div className="element-description">
                    <div className="image">
                        <img src={`https://img.booksport.ro/clubs/${clubdetails.id}/instructori/${clubdetails.element_main_image[0].filename}`} alt="" />
                    </div>
                    <div className="about" dangerouslySetInnerHTML={{ __html: clubdetails.element_description }}>
                    </div>
                </div>

                <div className="pics" >
                    {clubdetails.images_gallery && clubdetails.images_gallery.length > 0 ?<ClubGallery pics={clubdetails.images_gallery} clubId={clubdetails.id}/>: <img className="img-fluid" src={clubdetails.main_image && clubdetails.main_image.length !== 0 ? 'https://img.booksport.ro/clubs/'+clubdetails.id +'/big/'+clubdetails.main_image[0].filename  : loginbk} alt=""/>}
            </div>
        </>
    )
}

export default ElementDescription
