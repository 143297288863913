import React, {Component, lazy, Suspense} from 'react'
import {connect} from 'react-redux'
import {openModal} from "../../../store/actions/modalActions";


const ModalSablon = lazy(() => import('../modals/ModalSablon'));
const SliderTable = lazy(() => import('../sliders/SliderTable'));
const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));
const renderLoader = () => <p>Loading</p>;

class Sablon extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            render:true
        }
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

  componentDidMount(){
    document.title = 'Booksport - Sablon orar'
  }

    render(){
        var current = this.props.location.state
        return(
            <div className="main-panel">
                <Suspense fallback={renderLoader()}>
                    <TopNavAdmin title='Sablon orar'/>
                </Suspense>
                <div className="content p-0-mobile">
                    <div className="container-fluid">
                        <Suspense fallback={renderLoader()}>
                             <SliderTable current={current} />
                        </Suspense>
                    </div>
                </div>
                <Suspense fallback={renderLoader()}>
                    <ModalSablon showModal={this.props.showModal}/>
                </Suspense>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        showModal:state.showModal.showModal,
    }
}


export default connect(mapStateToProps,{openModal}) (Sablon)
