import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import {showModalReturn} from "../../../../../store/actions/admin/transactions";

const ModalReturnConfirmation = () => {
    const modal = useSelector(state => state.admin?.transactions?.modal)
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(showModalReturn(false))
    }

    const returnMoney = () => {
        dispatch(showModalReturn(false))
        window.location.href = `${modal?.url}${window.location.href}`
    }

    return (
        <Dialog
                open={modal?.show && modal.amount ? modal.show : false}
                onClose={closeModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Esti sigur ca vrei sa rambursezi suma?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Rambursarea va avea loc cu un transfer de <strong style={{minWidth: 70, display: 'inline-block', textAlign: 'center'}}>{modal?.amount} RON</strong> catre client.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{marginTop: '30px', padding: '16px 24px', justifyContent:'flex-start'}}>
                    <Button variant='contained' style={{background: '#12803c'}} onClick={returnMoney}>Ramburseaza</Button>
                    <Button variant='contained' onClick={closeModal} style={{background: '#e95e4e'}}>Anuleaza</Button>
                </DialogActions>
            </Dialog>
    );
}

export default ModalReturnConfirmation
