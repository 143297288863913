import React,{useEffect, useState, useRef} from 'react'
import booksport from '../../api/booksport'
import CardReview from './CardReview'
import StarRatings from 'react-star-ratings';
import { history } from '../../utils/history';
import Pagination from '@mui/material/Pagination';
import ModalConfirmDeleteComment from '../modals/ModalConfirmDeleteComment';
import {PulseLoader} from "react-spinners";

const Reviews = (props) => {
    const [reviews, setReviews] = useState()
    const {club, clubname, rating, element} = props
    const userStorage = localStorage.getItem('usInf')
    const user = userStorage ? JSON.parse(atob(localStorage.getItem('usInf'))) : null
    const [rate, setRate] = useState(0)
    const [rateName, setRateName] = useState()
    const [loading, setLoading] = useState(true)
    const reviewsWrapper = useRef(null)

    useEffect(()=>{
        let query = {
            clublocation_id:club
        }
        if(element){
            query = {
                element_id: element
            }
        }
        booksport.get('/getreview', {params: query})
        .then((res) => setReviews(res.data))
        .then(()=>setLoading(false))
    }, [])

    const scrollTo = () => reviewsWrapper.current.scrollIntoView()

    const changeRating = (newRating) => {
        setRate(newRating)
        switch(newRating){
            case 1 :
                setRateName('Nu recomand')
                break;
            case 2 :
            setRateName('Slab')
                break;
            case 3 :
                setRateName('Acceptabil')
                break;
            case 4 :
                setRateName('Bun')
                break;
            case 5 :
                setRateName('Excelent')
                break;
            default:
                setRateName('Adaugă o notă')
        }
      }

      const submitReview = () => {
          let location = ''
          if(element){
            location = { pathname: `/review/MDFvWU9PQTNmQ0QvaExtVEU1Y3hvdz09/${club}/${element}/${rate}`}
          }else{
            location = { pathname: `/review/MDFvWU9PQTNmQ0QvaExtVEU1Y3hvdz09/${club}/0/${rate}`}
          }
            history.push(location)
      }

      const handleChange = (event, value) => {
          setLoading(true);
          let query = {
            clublocation_id:club,
            page: value
        }
        if(element){
            query = {
                element_id: element,
                page: value
            }
        }
        booksport.get('/getreview', {params: query})
        .then((res) => setReviews(res.data))
        .then(()=>scrollTo())
        setLoading(false)
      };

      if(loading){
          return(
              <div className='d-flex align-items-center justify-content-center pt-5 pb-5'>
                <PulseLoader color='#12803c' loading={loading}  size={15} />
              </div>
          )
      }

    if(reviews && reviews.data.length){
        return(
            <div>
                <div  ref={reviewsWrapper} className="row club-ratings ml-0 mr-0 mb-3 mt-3 pt-3 border-bottom border-top">
                    <div className="col-md-6 mb-3 border-bottom bb-md-right">
                        <div className="rating-wrapper text-center">
                        <h5 className='m-0 rating-number-club text-center'>{rating}</h5>
                        <StarRatings
                            rating={rating}
                            starRatedColor="#f9bf3b"
                            numberOfStars={5}
                            name='rating'
                            starDimension="26px"
                            starHoverColor="#f9bf3b"
                            starSpacing='0px'
                        />
                        <p className="rating-number">{reviews.data && reviews.total} review-uri</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="add-review text-center">
                            <h5 className='fw-600 m-0'>Adaugă o notă</h5>
                            <div className="d-flex align-items-center mt-2 mb-2 justify-content-center">
                                <StarRatings
                                    rating={parseFloat(rate)}
                                    starRatedColor="#f9bf3b"
                                    changeRating={changeRating}
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="33px"
                                    starHoverColor="#f9bf3b"
                                    starSpacing='0px'
                                />
                                <p className='m-0 ml-2 fw-600 rate-name'>{rateName}</p>
                            </div>
                            <p className="btn btn-success btn-small mt-lg-0 mb-4 add-review" onClick={submitReview}>Adauga review</p>
                        </div>

                    </div>
                </div>
                {reviews && reviews.data.map((review) => <CardReview key={review.id} review={review} user={user} clubname={clubname}/>) }
                {reviews.last_page > 1 ?
                <div className="pagination mt-5 mb-5 d-flex justify-content-center">
                    <Pagination count={reviews && reviews.last_page} color="primary"  showFirstButton showLastButton onChange={handleChange} />
                </div> : '' }
                <ModalConfirmDeleteComment />
            </div>



        )

    }else{
        return (
            <div className="col-12">
                <div className="wrapper-no-reviews">
                    <div className="row">
                        <div className="col-xl-6">
                            <h3 className='fw-600 border-bottom-0 text-left'>Fii primul care lasă un review</h3>
                            <p className='fw-600 m-0'>Spune-ți părerea despre acest club acordându-i o notă</p>
                        </div>
                        <div className="col-xl-6">
                        <div className="d-flex align-items-center mt-2 mb-2">
                            <StarRatings
                                rating={parseFloat(rate)}
                                starRatedColor="#f9bf3b"
                                changeRating={changeRating}
                                numberOfStars={5}
                                name='rating'
                                starDimension="30px"
                                starHoverColor="#f9bf3b"
                                starSpacing='0px'
                            />
                            <p className='m-0 fw-600 rate-name'>{rateName}</p>
                        </div>
                        <p className="btn btn-success btn-small mt-2 mt-lg-0 mb-4 add-review" onClick={submitReview}>Adaugă review</p>

                        </div>

                    </div>
                </div>

            </div>

        )
    }
}

export default Reviews
