import * as React from 'react';
import { DataGrid, roRO, GridToolbar } from '@mui/x-data-grid';
import moment from "moment";
import ServicesModal from "./ServicesModal";
import DeleteVoucher from "./DeleteVoucher";

const columns = [
    { field: 'name', headerName: 'Nume', minWidth: 170,renderCell: (params) => (
            <div style={{display: "flex", alignItems: 'center', flexDirection:'column', margin: 0, flexGrow: 1}}>
                <p style={{marginBottom: '5px', width: '100%'}}>{params.row.name}</p>
                {
                    params.row.deleted_at ? <p style={{width: '100%', color:'#f44336', fontWeight:600}}>STERS</p> :
                        params.row.download === 1 && <p style={{display:"flex", width: '100%', margin: 0, alignItems: 'center'}}>
                            <i className="material-icons" style={{fontSize:'17px', color: '#12803c', marginRight: 5}}>get_app</i>
                            <a href={`${process.env.REACT_APP_API_URL}/download-voucher-png/${params.row.pdf_id}`}>Download promo</a>
                        </p>
                }
            </div>
        ), flex: 1 },
    { field: 'serie', headerName: 'Serie', minWidth: 140, flex: 1 },
    { field: 'valabilitate_de_la', headerName: 'Valabil de la', minWidth: 130,renderCell: (params => moment(params.row.valabilitate_de_la).format('DD.MM.YYYY H:ss')), flex: 1 },
    { field: 'valabilitate_pana_la', headerName: 'Valabil pana la', minWidth: 130,renderCell: (params => moment(params.row.valabilitate_pana_la).format('DD.MM.YYYY H:ss')), flex: 1 },
    { field: 'valoare', headerName: 'Valoare', minWidth: 80, flex: 1 },
    { field: 'emitent', headerName: 'Emitent', minWidth: 150,renderCell: (params) => (
            <div style={{display: "flex", alignItems: 'flex-start', flexDirection:'column', margin: 0}}>
                <p style={{marginBottom: '5px'}}>{params.row.emitent?.club}</p>
                {params.row.emitent?.user ? <p style={{marginBottom: '5px'}}>{params.row.emitent.user}</p> : false }
            </div>
        ), flex: 1 },
    { field: 'uses', headerName: 'Activari', minWidth: 60,renderCell: (params) => (
                <p>{params.row.count}/{params.row.uses === 0 ? '∞' : params.row.uses}</p>
        ), flex: 1 },
    { field: 'created_at', headerName: 'Creat la', minWidth: 150, renderCell: (params => moment(params.row.created_at).format('DD.MM.YYYY H:ss')), flex: 1 },
    { field: 'securedelete', headerName: 'Sterge', minWidth: 100, renderCell: (params) => (
            <DeleteVoucher hash={params.row.securedelete} name={params.row.name} series={params.row.serie} deleted={params.row.deleted_at}/>
        ), flex: 1 },
    { field: 'item', headerName: 'Servicii', minWidth: 170, renderCell: (params) => (
             <ServicesModal services={params.row.items}/>
        ), flex: 1 },
];


export default function VouchersTable({vouchers}) {
    return (
        vouchers &&  <div className='transactions-table' style={{ height: '60vh', minHeight: 600, width: '100%' }}>
            <style>
                {`
                    .deleted-voucher-row {
                        background-color: #dedede;
                        opacity: .8;
                    }
                `}
            </style>
            <DataGrid
                disableColumnMenu
                slots={{ toolbar: GridToolbar }}
                rows={vouchers}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[10]}
                getRowHeight={() => 'auto'}
                localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) => params.row.deleted_at ? 'deleted-voucher-row' : ''}
            />
        </div>
    );
}
