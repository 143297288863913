export default (state = {}, action) => {
    switch (action.type) {
        case 'SHOW_MODAL':
            return {...state, modal: action.payload}
        case 'SHOW_MODAL_VOUCHER':
            return {...state, modalVoucher: action.payload}
        case 'REFRESH_TRANSACTIONS':
            return {...state, refresh: action.payload}
        default :
            return state
    }
}
