import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../api/booksport";
import {showPayment} from "../../../store/actions/rezervareActions";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import ExtraOptionSelect from "./selects/ExtraOptionSelect";
import ExtraOptionCheckbox from "./checkbox/ExtraOptionCheckbox";


const OptionsSelection = ({club}) =>{
    const [options, setOptions] = useState([])
    const item = useSelector(state => state.rezervare.item)
    const price = useSelector(state => state.rezervare.price)
    const dispatch = useDispatch()


    useEffect(() => {
        price && getOptionsFromApi()
        // eslint-disable-next-line 
    }, [item])


    const showPaymentMethods = (show) => {
        dispatch(showPayment(show))
    }

    const getOptionsFromApi = () => {
        booksport.get('/getoptions',  {params: {item_id: item}})
            .then((res) => {
                if(res.data.length === 0) showPaymentMethods(true)
                if(Object.keys(res.data).length === 1 && Object.keys(res.data)[0] === 'extra_message') showPaymentMethods(true)
                setOptions(res.data)
            })
            .catch( (err) =>
                console.log(err)
            )
    }

    const renderOptions = (option, key) => {
        if(option.type === 'select'){
            return <ExtraOptionSelect key={key} option={option} name={key}/>
        }
        if(option.type === 'checkbox'){
            return <ExtraOptionCheckbox key={key} option={option} name={key}/>
        }
        if (option.type === 'message'){
            let placeholder = JSON.parse(option.placeholder)
            return(
                <p key={key} style={{backgroundColor: placeholder.message_bkg_color, color:placeholder.message_color, textAlign:'center', margin:'10px 0 20px 0', padding: '5px', width:'100%'}}>{placeholder.message}</p>
            )
        }
    }

    return(
        <>
            { options && Object.entries(options).map(([key,value]) => {
                    return(
                        renderOptions(value, key)
                    )
                }
            ) }
        </>

    )
}

export default OptionsSelection
