import React, {useState, useEffect} from "react";
import {ColorButton} from "../../../components/reusable/ColorButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {IconButton, Slide} from "@mui/material";
import CreateVoucherForm from "./CreateVoucherForm";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {showVouchersModal} from "../../../../../store/actions/admin/vouchers";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const AddVoucher = () => {
    const showModal = useSelector(state => state.admin.vouchers?.modal)
    const dispatch = useDispatch()


    const openModalCreateVoucher = (open) => {
        dispatch(showVouchersModal(open))
    }


    return (
        <>
            <ColorButton onClick={() => openModalCreateVoucher(true)}>Creeaza cod discount</ColorButton>
            <Dialog
                open={showModal ? showModal : false}
                onClose={() => openModalCreateVoucher(false)}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"lg"}
                scroll={"body"}
            >
                <DialogTitle sx={{textAlign:'center'}}>
                   Genereaza cod discount
                    <IconButton
                        aria-label="close"
                        onClick={() => openModalCreateVoucher(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <CreateVoucherForm/>
                </DialogContent>
            </Dialog>
        </>

    );
}

export default AddVoucher
