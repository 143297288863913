import React from 'react'
import homebg from '../../resources/images/home-bg.jpg'
import homebg2 from '../../resources/images/massage.jpg'
import homebg3 from '../../resources/images/sanatate.jpg'
import BackgroundSlider from 'react-background-slider'

const BackgroundHome = () => {
    return (
        <BackgroundSlider
        className="background"
        id="background"
        images={[homebg, homebg2, homebg3,]}
        duration={5} transition={.5} />
    )
}

export default BackgroundHome
