import React,{Component} from 'react'
import Countdown from 'react-countdown';

class CancelPayment extends Component{

    postmessage = () =>{
        window.location.href = "/dashboard/bookings"
    }
    render(){
        const renderer = ({seconds, completed }) => {
            if(completed){
                window.location.href = "/dashboard/bookings"
            }else{
                return  <p>Veti fi redirectionat in <span style={{fontWeight:'bold', fontSize:'20px', color:'#12803c'}}>{seconds}</span> secunde</p>;
            }
          };
        return(
            <div className="payment">
                <Countdown
                    date={Date.now() + 3000}
                    renderer={renderer}
                />
                <button className="btn btn-success mb-4 d-none" onClick={this.postmessage}>Către rezervări</button>
            </div>
        )
    }
}


export default  React.memo(CancelPayment)
