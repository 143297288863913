import React, { Component } from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet'
import L from 'leaflet'
import marker from '../../resources/images/map-icon_green.svg'
import {withRouter} from 'react-router-dom';


class CardMapClub extends Component {
    constructor(props){
        super(props)
        this.state = {
            zoom: 15,
            usrLat:false
        }
    }

    render() {
        const pointerIcon = new L.Icon({
            iconUrl: marker,
            iconRetinaUrl: marker,
            iconAnchor: [5, 55],
            popupAnchor: [10, -44],
            iconSize: [50, 50],
            shadowSize: [68, 95],
            shadowAnchor: [20, 92],
        })
        const position = [this.props.lat, this.props.lng]
        return (
            <Map center={position} zoom={this.state.zoom} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"                        />
                <Marker position={[this.props.lat,this.props.lng]} key={this.props.lng} icon={pointerIcon}>
                </Marker>
            </Map>
        )
    }
}

export default withRouter (CardMapClub)
