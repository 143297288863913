import React from "react";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import ReactPlayer from "react-player";


const AdminAcademy = () => {

    return(
        <div className="main-panel">
            <TopNavAdmin title='Tutoriale Booksport'/>
            <div className="content p-0-mobile">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-12 col-md-6 col-lg-4 mb-5">
                            <p className="text-center font-weight-bold">Creare rezervare</p>
                            <ReactPlayer width={'100%'} height={300} controls={true} url='https://youtu.be/-bEQUjV5uPE' />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-5">
                            <p className="text-center font-weight-bold">Creare rezervare in sablon</p>
                            <ReactPlayer width={'100%'} height={300} controls={true} url='https://youtu.be/EjxEGpX3iDE' />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-5">
                            <p className="text-center font-weight-bold">Stergere rezervare</p>
                            <ReactPlayer width={'100%'} height={300} controls={true} url='https://youtu.be/Zntu1AGYkSk' />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-5">
                            <p className="text-center font-weight-bold">Stergere rezervare in sablon</p>
                            <ReactPlayer width={'100%'} height={300}controls={true} url='https://youtu.be/0DB7Anjners' />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-5">
                            <p className="text-center font-weight-bold">Blocare rezervari</p>
                            <ReactPlayer width={'100%'} height={300} controls={true} url='https://youtu.be/zoudfiSa_SY' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminAcademy
