import React, { useEffect, useState } from 'react';
import booksport from "../../../api/booksport";
import TableCellTerenSite from "../../admin/tables/TableCellTerenSite";
import down from '../../../resources/images/down-gif.gif';
import { PulseLoader } from "react-spinners";

const ClubTable = (props) => {
    const date = props.date;
    const [schedule, setSchedule] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const getClubSchedule = async (page) => {
            setLoading(true);
            const q = {
                token: localStorage.getItem('jwtToken'),
                clublocation_id: props.clublocation,
                sport_id: props.sport,
                dataora: date
            };
            try {
                const response = await booksport.get(`/schedules_day_site`, { params: q });
                if (isMounted) {
                    setSchedule(response.data);
                    setLoading(false);
                }
            } catch (err) {
                console.error(err);
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        if (schedule === null) {
            getClubSchedule(date);
        }

        return () => {
            isMounted = false;
        };
    }, [schedule, date, props.clublocation, props.sport]);


    let hours = [];
    if (schedule) {
        for (let i = schedule.start_hour; i <= schedule.stop_hour; i++) {
            hours.push(i);
        }
    }

    return (
        <>
            {loading ? (
                <div className='col-12'>
                    <div className='d-flex align-items-center justify-content-center pt-5 pb-5'>
                        <PulseLoader color='#12803c' size={20} />
                    </div>
                </div>
            ) : (
                <div className="card orar">
                    {props.booking === 0 ? <div className="inactive">In curând</div> : ''}
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 d-lg-none swipe-an">
                                Swipe for more
                                <img src={down} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="wrapper-schedule">
                        <div className="row-teren head">
                            <div className="name cellHour">Ora</div>
                            {hours.map((hour) => (
                                <div key={hour} className="cellHour">
                                    {hour < 10 ? '0' + hour : hour}
                                </div>
                            ))}
                        </div>
                        {schedule &&
                            schedule.terenuri &&
                            schedule.terenuri.map((teren) => {
                                let step = teren.intervals / 60;
                                return (
                                    <div key={teren.element_id} className="row-teren">
                                        <div className="name cellHour">{teren.element_name}</div>
                                        {teren.rezervari &&
                                            teren.rezervari.map((rezervare, index) => {
                                                if (index % step === 0) {
                                                    return (
                                                        <div key={rezervare.hour} className="cellHour">
                                                            {rezervare.element &&
                                                                rezervare.element.map((element) => (
                                                                    <TableCellTerenSite
                                                                        booking={schedule.booking}
                                                                        clubName={props.clubname}
                                                                        terenName={teren.element_name}
                                                                        clublocationname={props.clublocationname}
                                                                        terenId={teren.element_id}
                                                                        key={Math.random()}
                                                                        element={element}
                                                                        date={date}
                                                                        hour={rezervare.hour}
                                                                        clublocation_id={props.clublocation}
                                                                        sport_id={props.sport}
                                                                    />
                                                                ))}
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                    </div>
                                );
                            })}
                    </div>
                    <div className="legend">
                        <p>
                            <span className="bullet white"> </span>Liber
                        </p>
                        <p>
                            <span className="bullet ocupat"> </span>Ocupat
                        </p>
                        <p>
                            <span className="bullet indisponibil"> </span>Indisponibil
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default ClubTable;
