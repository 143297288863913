import React, {useState, useEffect} from "react";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import EditProfileForm from "./components/EditProfileForm";
import ChangePasswordForm from "./components/ChangePasswordForm";
import 'react-toastify/dist/ReactToastify.css';


const UserProfile = () => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        setValue(0)
    }, [])

    return(
        <div className="main-panel">
            <TopNavAdmin title='Editeaza cont'/>
            <div className="content">
                <div className="container-fluid admin-profile">
                    <Tabs selectedIndex={value} onSelect={index => setValue(index)}>
                        <TabList>
                            <Tab style={{fontWeight: '400'}}>Date personale</Tab>
                            <Tab style={{fontWeight: '400'}}>Schimba parola</Tab>
                        </TabList>
                        <TabPanel>
                            <EditProfileForm/>
                        </TabPanel>
                        <TabPanel>
                            <ChangePasswordForm/>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
        )
}

export default UserProfile
