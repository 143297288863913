import React from 'react'
import Navbar from '../navs/Navbar'
import {Link} from 'react-router-dom'

import notfound from '../../resources/images/404.jpg'
import {Helmet} from "react-helmet";

import { MessengerChat } from 'react-messenger-chat-plugin'

const NotFound = () => {
    return(
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Booksport - 404 </title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Pagina nu a fost gasita" />
                <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                <meta property="og:site_name" content="booksport.ro" />
                <meta property="og:title" content="Booksport - 404" />
                <meta property="og:description" content="Pagina nu a fost gasita" />
            </Helmet>
            <Navbar key={new Date()}/>
            <div className="page-header  header-filter home-panel" data-parallax="true">
                <div className="background" id="background"
                     style={{backgroundImage: `url(${notfound})`}}></div>
                <div className="container wrapper-pannel-content">
                    <div className="row">
                        <div className="col-md-8 ml-auto mr-auto">
                            <div className="brand">
                                <h2>404</h2>
                                <h1>Pagina nu a fost gasită</h1>
                                <p className='mt-5'>Intoarce-te la <span className='btn btn-link'><Link to="/"><span className='text-white'>Prima pagină</span></Link></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MessengerChat
                    pageId='444878259387423'
                    language='ro_RO'
                    height={20}
                    autoExpand={false}
                    debugMode={false}
                    />
        </div>
    )
}

export default React.memo(NotFound)
