import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../../store/actions/userActions';
import { Helmet } from 'react-helmet';
import loginbk from '../../resources/images/login.jpg';
import Navbar from '../navs/Navbar';
import LoginForm from '../forms/LoginForm';
import Footer from '../reusable/Footer';
import {history} from "../../utils/history";

const Login = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.userInfo.isAuth);

    useEffect(() => {
        window.localStorage.clear();
        dispatch(logOut());
        window.dataLayer.push({
            event: 'pageview',
            page: {
                url: window.location.pathname,
                title: 'Login'
            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            const { from } = window.location.state || { from: { pathname: '/' } };
            history.push(from);
        }
    }, [isAuthenticated]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Booksport - Autentificare </title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Autentifica-te in contul tau booksport" />
                <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                <meta property="og:site_name" content="booksport.ro" />
                <meta property="og:title" content="Booksport - Autentificare" />
                <meta property="og:description" content="Autentifica-te in contul tau booksport" />
            </Helmet>
            <Navbar key={new Date()} logoW={true} />
            <div className="page-header header-filter"
                 style={{ backgroundImage: `url(${loginbk})`, backgroundSize: 'cover', backgroundPosition: 'top center', height: 'auto', minHeight: '100vh' }}>
                <div className="container wrapper-pannel-content" style={{minHeight: 800}}>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default React.memo(Login);
