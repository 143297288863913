import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setShowConfirmation, setTermsError} from "../../../store/actions/rezervareActions";
import MessageRefundUnavailable from "../../adminRefactored/pages/user-bookings/components/MessageRefundUnavailable";


const SendSummaryButton = () =>{
    const accord = useSelector(state => state.rezervare.accordTerms)
    const showPayment = useSelector(state => state.rezervare?.showpayment)
    const dispatch = useDispatch()

    const sendAllSelections = () => {
        !accord && dispatch(setTermsError(true))
        showPayment && accord && dispatch(setShowConfirmation(true))
    }

    return(
        <div className="rezerva-btns col-12">
            <MessageRefundUnavailable/>
            <button style={{fontSize: '16px'}} className={`${!showPayment ? "btn btn-success mt-1 w-100 disabled" : "btn btn-success mt-1 w-100"}`} disabled={!showPayment} onClick={sendAllSelections}>Rezerva</button>
        </div>

    )


}

export default SendSummaryButton
