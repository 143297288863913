import * as React from 'react';
import { DataGrid, roRO } from '@mui/x-data-grid';
import { ButtonPreview } from "./ButtonPreview";
import ButtonSign from "./ButtonSign";

export default function TableDocuments({ documents }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const columns = [
        {
            field: 'clubLocationName',
            headerName: 'Club',
            minWidth: 250,
            flex: 1,
            renderCell: (params) => (
                <div style={{ lineHeight: '1.2' }}>
                    <div>{params.row.societate}</div>
                    <div style={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.54)' }}>
                        {params.value}
                    </div>
                </div>
            ),
        },
        { field: 'title', headerName: 'Nume document', minWidth: 160, flex: 1 },
        { field: 'documentNumber', headerName: 'Număr document', minWidth: 100, flex: 1 },
        {
            field: 'documentDate',
            headerName: 'Data',
            minWidth: 130,
            flex: 1,
            valueFormatter: (params) => formatDate(params.value),
        },
        { field: 'status', headerName: 'Status', minWidth: 130, flex: 1 },
        { field: 'hash', headerName: 'MD5 HASH', minWidth: 300, flex: 1 },
        { field: 'path', headerName: 'Previzualizare', minWidth: 200, renderCell: (params => <ButtonPreview params={params}/>), flex: 1  },
        { field: 'id', headerName: 'Semnează', minWidth: 200, renderCell: (params => params.row.status === 'Pentru semnat' && <ButtonSign params={params}/>), flex: 1  }
    ];

    return (
        documents &&
        <div className='transactions-table' style={{ height: '60vh', minHeight: 600, width: '100%' }}>
            <DataGrid
                disableColumnMenu
                rows={documents}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[10]}
                getRowHeight={() => 'auto'}
                localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
    );
}
