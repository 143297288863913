import React, {Component, lazy, Suspense} from 'react'
import booksport from "../../../api/booksport";

const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));

const renderLoader = () => <p>Loading</p>;

class DetaliiClub extends Component{
    constructor(props){
        super(props)
        this.state = {
            club: null,
            terenuri: null,
            terms: null
        }
    }

    componentDidMount() {
        if(this.state.terms === null){
            this.getTerms()
        }
    }

    getTerms = () => {
        let q = {
            clublocation_id : this.props.location.state.clublocation_id,
            sport_id: this.props.location.state.sport_id,
        }
        booksport.get('/get_terms_club',  {params: q})
            .then((res) =>
                this.setState({terms: res.data})
            )
            .catch( (err) =>
                this.setState({ error : err.response})
            )
    }

    render(){
        var club = this.props.location.state
        var terenuri = null
        let terms = ''
        if(this.state.terms && this.state.terms.terms){
            terms = this.state.terms.terms
        }

        var element={
            token: localStorage.getItem('jwtToken'),
            clublocation_id :club.clublocation_id
        }
        if(this.state.club === null){
            booksport.get('/getclubdetails', {params: element})
                .then((res) =>
                    this.setState({ club : res.data.club, terenuri:res.data.elements})
                )
                .catch( (err) =>
                    this.setState({ succes : false, error : err.response.data})
                )
        }
        if(this.state.club){
            club = this.state.club
        }
        if(this.state.terenuri){
            terenuri = this.state.terenuri
        }


        return(
            <div className="main-panel">
                <Suspense fallback={renderLoader()}>
                    <TopNavAdmin title='Detalii Club'/>
                </Suspense>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="card col-12 col-xl-10 offset-xl-1">
                                <div className="card-header card-header-success text-center">
                                    <h4>Informatii {this.props.location.state.club_name + ' - ' + this.props.location.state.location_name}</h4>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-4">
                                        <div className="form-group label-floating bmd-form-group">
                                            <label className="form-control-label bmd-label-floating"
                                                   htmlFor="descriere">Descriere club</label>
                                            <div className="mt-2" dangerouslySetInnerHTML={{ __html: club.description }}>

                                            </div>
                                            {/*<textarea className="form-control" rows="4" id="descriere" defaultValue={club.description}></textarea>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="ol-12 col-md-6 col-lg-4">
                                        <div className="form-group label-floating bmd-form-group">
                                            <label className="form-control-label bmd-label-floating"
                                                   htmlFor="adresa">Adresa</label>
                                            <p className="mt-2">{club.address}</p>
                                            {/*<textarea className="form-control" rows="4" id="adresa" defaultValue={club.address}></textarea>*/}
                                        </div>
                                    </div>
                                    <div className="ol-12 col-md-6 col-lg-4">
                                        <div className="form-group label-floating bmd-form-group">
                                            <label className="form-control-label bmd-label-floating"
                                                   htmlFor="email">Email</label>
                                            <p className="mt-2">{club.email}</p>
                                            {/*<input className="form-control" id="email" defaultValue={club.email}/>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="form-group label-floating bmd-form-group">
                                            <label className="form-control-label bmd-label-floating"
                                                   htmlFor="telefon">Telefon</label>
                                            <p className="mt-2">{club.phone}</p>
                                            {/*<input className="form-control" id="telefon" defaultValue={club.phone}/>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="form-group label-floating bmd-form-group">
                                            <label className="form-control-label bmd-label-floating"
                                                   htmlFor="website">Website</label>
                                            <p className="mt-2">{club.website}</p>
                                            {/*<input className="form-control" id="website" defaultValue={club.website}/>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="form-group label-floating bmd-form-group">
                                            <label className="form-control-label bmd-label-floating"
                                                   htmlFor="coords">Coordonate</label>
                                            <p className="mt-2">{club.geolocation}</p>
                                            {/*<input className="form-control" id="coords" defaultValue={club.geolocation}/>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="card col-12 col-xl-10 offset-xl-1">
                                <div className="card-header card-header-success text-center">
                                    <h4>Configuratie club</h4>
                                </div>
                                <div className="row mt-5 pb-5">
                                    {
                                        terenuri  && terenuri.map(teren => {
                                            return (
                                                <div key={teren.name} className="col-12 col-md-6 col-lg-3 text-md-center">
                                                    <h5 className="d-flex align-items-center justify-content-center"><i
                                                        className="material-icons mr-2">sports_baseball</i>{teren.sport + ' - ' + teren.name}</h5>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="card col-12 col-xl-10 offset-xl-1">
                                <div className="card-header card-header-success text-center">
                                    <h4>Termeni si conditii club</h4>
                                </div>
                                <div className="row mt-5 pb-5" dangerouslySetInnerHTML={{ __html: terms }}>

                                </div>

                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="col-12 col-xl-10 offset-xl-1">*/}
                        {/*        <div className="row">*/}
                        {/*            <div className="col-lg-3 m-auto">*/}
                        {/*                <button className="btn btn-success btn-lg w-100" data-dismiss="modal">Salveaza*/}
                        {/*                    <div className="ripple-container"></div>*/}
                        {/*                </button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>
        )
    }
}

export default DetaliiClub
