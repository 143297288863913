import React,{useState,useEffect} from 'react'
import Countdown from 'react-countdown';
import booksport from '../../api/booksport';

const Payment = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('orderId');

    useEffect(() => {
        getOrderValue();
     }, [])

     const getOrderValue = () => {
         const q = {
            order_id : id
         }
        booksport.get('/getorderprice',  {params: q})
        .then(res => {
            window.dataLayer.push({
                event: 'payment_completed',
                page: {
                  url: window.location.pathname,
                  title: "Payment completed"
                },
                transaction: {
                    value: res.data.price,
                    id: id
                }
              });
        })
     }

     const postmessage = () => {
        window.location.href = "/dashboard/bookings"
    }



    const renderer = ({seconds, completed }) => {
        if(completed){
            window.location.href = "/dashboard/bookings"
        }else{
            return  <p>Veti fi redirectionat in <span style={{fontWeight:'bold', fontSize:'20px', color:'#12803c'}}>{seconds}</span> secunde</p>;
        }
        };

    return(
        <div className="payment">
            <Countdown
                date={Date.now() + 3000}
                renderer={renderer}
            />
            <button className="btn btn-success mb-4 d-none" onClick={() => postmessage()}>Către rezervări</button>
        </div>
    )
}


export default  Payment
