import React, {Component, lazy, Suspense} from 'react'
import booksport from "../../../api/booksport";
import moment from 'moment';
import 'moment/locale/ro';
import DatePicker, { registerLocale } from "react-datepicker";
import ro from "date-fns/locale/ro";
import {addDays, subDays} from "date-fns";
import Modal from "react-bootstrap/Modal";

const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));

const renderLoader = () => <p>Loading</p>;

class BlockRezervariClub extends Component{
    constructor(props){
        super(props)
        this.state = {
            program: null,
            error: null,
            from: null,
            to: null,
            succes: false,
            emptyfrom:false,
            emptyto:false,
            canulare : false,
            deletid : null,
            succesdel : false,
            dateerr :false
        }
    }

    componentDidMount(){
        document.title = 'Booksport - Blocare rezervari'
        const datePickers = document.getElementsByClassName("react-datepicker__input-container");
        Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
        this.getProgram()
    }

    getProgram = () => {
        var element={
            token: localStorage.getItem('jwtToken'),
            clublocation_id : this.props.location.state.clublocation_id,
        }
        booksport.get('/blockedschedule',  {params: element})
            .then((res) =>
                this.setState({program: res.data})
            )
            .catch( (err) =>
                this.setState({ error : err.response})
            )
    }

    handleChangeFom = date => {
        this.setState({
            from: date,
            emptyfrom: false
        });
    }
    handleChangeTo = date => {
        this.setState({
            to: date,
            emptyto:false
        });
    }

    savePeriod = () =>{
        if(this.state.from === null){
            this.setState({emptyfrom: true})
        } else if(this.state.to === null){
            this.setState({emptyto: true})
        }else if(moment(this.state.to) <  moment(this.state.from)){
            this.setState({dateerr: true})
        }else{
            var q={
                token: localStorage.getItem('jwtToken'),
                clublocation_id : this.props.location.state.clublocation_id,
                dela: moment(this.state.from).format('YYYY-MM-DD') + ' 0:00',
                panala: moment(this.state.to).format('YYYY-MM-DD') + ' 23:59',
            }
            booksport.post('/saveblockedschedule',  q)
                .then((res) =>
                    this.setState({succes: true, emptyfrom:false, emptyto:false})
                )
                .then(
                    setTimeout(() => {
                        this.getProgram()
                    }, 1000)
                )
                .catch( (err) =>
                    this.setState({ error : err.response})
                )
        }
    }

    closeModal = () =>{
        this.setState({succes:false, from:null, to:null, canulare:false, succesdel: false, emptyfrom:false, emptyto:false, dateerr:false})
        this.getProgram()

    }

    deletePeriod = (id) => () => {
        this.setState({canulare:true, deletid : id})
    }

    saveDelete = () => {
        var q={
            token: localStorage.getItem('jwtToken'),
            clublocation_id : this.props.location.state.clublocation_id,
            id: this.state.deletid
        }
        booksport.delete('/deleteblockedschedule',  {params:q})
            .then((res) =>
                this.setState({succesdel: true, canulare:false})
            )
            .then(
                this.getProgram()
            )
            .catch( (err) =>
                this.setState({ error : err.response})
            )
    }


    render(){
        registerLocale("ro", ro);
        return(
            <div className="main-panel">
                <Suspense fallback={renderLoader()}>
                    <TopNavAdmin title= {'Blocare rezervari'}/>
                </Suspense>
                <div className="content">
                    <div className="row">
                        <div className="col-12 col-md-4 mb-2">
                            <div className="form-group bmd-form-group m-0 p-0 d-flex align-items-center">
                                <DatePicker
                                    locale="ro"
                                    id="datefrom"
                                    calendarClassName="datepicker"
                                    showPopperArrow ={false}
                                    todayButton="Selecteaza data de azi"
                                    selected={this.state.from}
                                    onChange={this.handleChangeFom}
                                    placeholderText="De la"
                                    minDate={subDays(new Date(), 40)}
                                    maxDate={addDays(new Date(), 40)}
                                    dateFormat="dd MMMM yyyy"
                                    popperPlacement='bottom-start'
                                    popperModifiers={{
                                        flip: {
                                            behavior: ['bottom'] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                                <div className="w-100 ml-2"> ora : 00:00</div>
                            </div>
                            {this.state.emptyfrom ? <p className="text-danger mt-2">Camp obligatoriu</p> : ''}
                            {this.state.dateerr ? <p className="text-danger mt-2">Interval gresit!</p> : ''}
                        </div>
                        <div className="col-12 col-md-4 mb-2">
                            <div className="form-group bmd-form-group m-0 p-0 d-flex align-items-center">
                                <DatePicker
                                    locale="ro"
                                    id="dateto"
                                    calendarClassName="datepicker"
                                    showPopperArrow ={false}
                                    todayButton="Selecteaza data de azi"
                                    selected={this.state.to}
                                    onChange={this.handleChangeTo}
                                    placeholderText="Pana la"
                                    minDate={subDays(new Date(), 40)}
                                    dateFormat="dd MMMM yyyy"
                                    popperPlacement='bottom-start'
                                    popperModifiers={{
                                        flip: {
                                            behavior: ['bottom'] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                                <div className="w-100 ml-2"> ora : 23:59</div>
                            </div>
                            {this.state.emptyto ? <p className="text-danger mt-2">Camp obligatoriu</p> : ''}
                        </div>
                        <div className="col-12 col-md-4 ">
                            <div className="btn btn-save-block" onClick={this.savePeriod}>Salveaza</div>
                        </div>
                        <div className="col-12 p-0">
                            <h3 className="text-center mt-5"> {this.state.program !== null && this.state.program.length > 0 ? 'Zile blocate' : 'Nu exista zile blocate'} </h3>
                            {this.state.program !== null && this.state.program.length > 0 ?
                                <div className="table-responsive mb-5">
                                    <table className="table table-prices table-hover">
                                        <thead className="text-success" id="stick">
                                        <tr>
                                            <th>De la</th>
                                            <th>Pana la</th>
                                            <th>Status</th>
                                            <th>Sterge</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.program.map(rowcomanda => {
                                                return (
                                                    <tr key={rowcomanda.id}>
                                                        <td>{moment(rowcomanda.dela).format('DD.MM.YYYY H:mm')}</td>
                                                        <td>{moment(rowcomanda.panala).format('DD.MM.YYYY H:mm')}</td>
                                                        <td>{moment(rowcomanda.panala) < moment() ? 'expirat' : 'activ'}</td>
                                                        <td><i className="material-icons delperiod" onClick={this.deletePeriod(rowcomanda.id)}>delete</i></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div> : ''

                            }
                        </div>
                    </div>
                </div>
                <Modal show={!!this.state.succes} animation={true} className="modal-anulare">
                    <div className="modal-confirm-anulare">
                        <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>
                            <i className="material-icons">clear</i>
                        </button>
                        <h4>Rezervari blocate pentru perioada: <br/> {moment(this.state.from).format('DD.MM.YYYY') + ' 0:00'} - {moment(this.state.to).format('DD.MM.YYYY') + ' 23:59'}</h4>
                    </div>
                </Modal>
                <Modal show={!!this.state.canulare} animation={true} className="modal-anulare">
                    <div className="modal-confirm-anulare">
                        <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>
                            <i className="material-icons">clear</i>
                        </button>
                        <h4>Confirmare anulare</h4>
                        <p>Esti sigur ca vrei sa deblochezi perioada selectata?</p>
                        <div className="wrapper-btns d-flex">
                            <a className="btn btn-warning text-center w-100 btn btn-book" rel="noopener noreferrer" onClick={this.saveDelete}>Da</a>
                            <button className="btn btn-success text-center w-100"  onClick={this.closeModal} target="_blank" rel="noopener noreferrer">Nu</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={!!this.state.succesdel} animation={true} className="modal-anulare">
                    <div className="modal-confirm-anulare">
                        <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>
                            <i className="material-icons">clear</i>
                        </button>
                        <h4>Perioada a fost deblocata cu succes</h4>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default  BlockRezervariClub
