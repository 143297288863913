import React, {useEffect, useState} from 'react'
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {erazeBooking, setElement} from "../../../../store/actions/rezervareActions";

const ElementSelect = (props) =>{
    const [elements, setElements] = useState([])
    const [selectedElement,setSelectedElement] = useState(null);
    const element = useSelector(state => state.showModal?.rezerva?.clubData?.teren)
    const elementSelected = useSelector(state => state.rezervare?.element)
    const dispatch = useDispatch()

    useEffect(() => {
        const elems = []
        if(props.elements){
            props.elements.map(element => {
                elems.push({
                    label: element.name,
                    value: element.id,
                },)
            })
        }

        setElements(elems)
        if(element){
            setSelectedElement(element)
            dispatch(setElement(element.value))
        }
    }, [dispatch, element, props.elements])

    const setElementSelection = (selectedOption) => {
        if(elementSelected !== selectedOption.value) {
            dispatch(erazeBooking())
            setSelectedElement(selectedOption)
            dispatch(setElement(selectedOption.value))
        }
    }

    return(
        <div className="col-12">
            <Select
                isSearchable={ false }
                className="w-100 mb-2"
                options={elements}
                placeholder = {'Selectează o opțiune'}
                onChange = {(selOption) => setElementSelection(selOption)}
                value = {selectedElement}
            />
        </div>

    )
}

export default ElementSelect
