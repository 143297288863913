import React,{Component} from 'react'
import {connect} from 'react-redux'
import {openModal} from "../../../store/actions/modalActions";
import TableCellTeren from './_TableCellTeren'
import booksport from "../../../api/booksport";

class TableColumnZi extends Component{

    constructor(props) {
        super(props);
        this.state = {
            day: [],
            update:false,
            blocked: false
        };
    }

    handleModal = () => {
        if(!this.props.showModal){
            this.props.openModal()
        }
    }

    checkBlockedSchedule = () => {
        var q={
            clublocation_id : this.props.current.clublocation_id,
            data: this.props.day_nr
        }
        booksport.get('/checkblockedschedule',  {params:q})
            .then((res) =>
                this.setState({blocked: res.data})
            )
    }

    componentDidMount(){
        var token = localStorage.getItem('jwtToken')
        let elem = {
            token: token,
            clublocation_id : this.props.current.clublocation_id,
            sport_id : this.props.current.sport_id,
            dataora :this.props.day_nr,
            element_id: this.props.teren.id
        }
        booksport.get('/schedules_element', { params : elem })
            .then((res) => this.setState({
                day: res.data,
            }))
        this.checkBlockedSchedule()
    }

    render(){
        let day = []
        if(this.state.day){
            day = this.state.day
        }
        return(
            <div>
                <div className={this.state.blocked ? "cell head-cell blocked" : "cell head-cell"}>{this.props.day_name} <br/> <span className="small">{this.state.blocked ? 'rezervari blocate' : ''}</span></div>
                {
                    day && day.map(hour => {
                return (
                <TableCellTeren key={hour.hour} hour = {hour} day_name={this.props.day_name} day_nr={this.props.day_nr} location_name={this.props} ref_teren={this.props.ref_teren}/>
                )
            }) }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        showModal:state.showModal.showModal,
        day:state.userStatus.template,
    }
}

export default connect(mapStateToProps,{openModal}) (TableColumnZi)
