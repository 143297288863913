export default (state = {}, action) => {
    switch (action.type) {
        case 'SET_ELEMENT':
            return {...state, element: action.element}
        case 'SET_ITEM':
            return {...state, item: action.item}
        case 'SET_PRICE':
            return {...state, price: action.price}
        case 'SHOW_PAYMENT':
            return {...state, showpayment: action.show}
        case 'SET_EXTRA_OPTIONS':
            state[action.option.name] = action.option.value;
            return state
        case 'DELETE_EXTRA_OPTION':
            delete state[action.option.name]
            return state
        case 'DELETE_ITEM':
            return {...state, item: null}
        case 'DELETE_PRICE':
            return {...state, price: null}
        case 'SET_CARD':
            return {...state, card: action.card}
        case 'SET_SAVE_CARD':
            return {...state, saveCard: action.save}
        case 'SET_TERMS':
            return {...state, accordTerms: action.terms}
        case 'SET_VOUCHER':
            return {...state, voucher: action.voucher}
        case 'SET_SHOW_CONFIRMATION':
            return {...state, showConfirmation: action.show}
        case 'SET_TERMS_ERROR':
            return {...state, accordTermsError: action.error}
        case 'REFRESH_TOTAL':
            return {...state, refreshTotal: action.refresh}
        case 'REFRESH_VOUCHERS':
            return {...state, refreshVouchers: action.refresh}
        case 'DELETE_ALL':
            return {}
        default :
            return state
    }
}
