import React,{Component, lazy, Suspense} from 'react'
import booksport from "../../api/booksport";
import loginbk from '../../resources/images/login-bk.jpg'
import {Helmet} from "react-helmet";

const Navbar = lazy(() => import('../navs/Navbar'));
const Footer = lazy(() => import('../reusable/Footer'));

const renderLoader = () => <p>Loading</p>;

class ActivateAccount extends Component{

    constructor(props){
        super(props)
        this.state={
            activated : false
        }
    }

    componentDidMount(){
        document.title = "Booksport - Confirmare e-mail"
        this.getToken()
        window.dataLayer.push({
            event: 'activate_account',
            page: {
            url: window.location.pathname,
            title: 'Activate account'
            }
        });
    }



    getToken = () => {
        let q = {
            remember_token  : this.props.match.params.token,
        }
        booksport.post('/user_validate',  q)
            .then((res) =>
                this.setState({activated: res.data})
            )
            .catch( (err) =>
                this.setState({ activated : err.response.data})
            )
    }




    render(){
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ 'Booksport - Activare cont'}</title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description" content='Activeaza contul tau booksport pentru a beneficia de toate functionalitatie' />
                </Helmet>
                <Suspense fallback={renderLoader()}>
                    <Navbar key={new Date()}/>
                </Suspense>
                <div className="page-header header-filter"
                     style={{backgroundImage: `url(${loginbk})`, backgroundSize: 'cover', backgroundPosition: 'top center'}}>
                    <div className="container wrapper-pannel-content">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                                <div className="card card-login card-hidden">
                                    <h4 className=  "card-title mb-3 mt-5 text-center">{this.state.activated}</h4>
                                    <div className="card-footer justify-content-center">
                                        <a href="/" className="btn btn-success btn-round mb-4" type="submit">Prima pagina</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Suspense fallback={renderLoader()}>
                    <Footer/>
                </Suspense>
            </div>
        )
    }
}


export default  React.memo(ActivateAccount)
