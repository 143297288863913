import React, {useState, useEffect} from "react";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import SpinnerContainer from "../../components/loading-spinner";
import booksport from "../../../../api/booksport";
import {AdminRoutes} from "../../../../api/admin-routes";
import VouchersTable from "./components/VouchersTable";
import AddVoucher from "./components/AddVoucher";
import {useDispatch, useSelector} from "react-redux";
import {refreshVouchers} from "../../../../store/actions/admin/vouchers";

const AdminVouchers = () => {
    const [loading, setLoading] = useState(false)
    const [vouchers, setVouchers] = useState([])
    const refresh = useSelector(state => state.admin.vouchers?.refresh)
    const dispatch = useDispatch()

    useEffect(() => {
        refresh && getAdminVouchers()
    },[refresh])

    useEffect(() => {
         getAdminVouchers()
    },[])

    const getAdminVouchers = async () =>{
        setLoading(true)
        try{
            const response = await booksport.get(`${AdminRoutes.getAdminVouchers}`)
            setVouchers(response.data)
            dispatch(refreshVouchers(false))
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }

    return(
        <div className="main-panel">
            <TopNavAdmin title='Discount-uri'/>
            <div className="content">
                {loading ? <SpinnerContainer/> :
                     <>
                         <div>
                             <div className="col-lg-3 offset-lg-9 mb-4">
                                 <AddVoucher/>
                             </div>
                             <div className="col-12">
                                 <VouchersTable vouchers={vouchers}/>
                             </div>
                         </div>
                     </>
                }
            </div>
        </div>
    )
}

export default AdminVouchers
