import * as React from "react";
import { Button } from "@mui/material";
import booksport from "../../../../../api/booksport";
import { AdminRoutes } from "../../../../../api/admin-routes";
import { useState } from "react";


export const ButtonPreview = ({ params }) => {
    const [loading, setLoading] = useState(false);

    const getAdminDocument = async () => {
        if(params.row.path){
            window.open(params.row.path, '_blank')
        } else {
            setLoading(true);
            const query = {
                token: localStorage.getItem("jwtToken"),
                id: params.row.id,
            };
            try {
                const response = await booksport.get(AdminRoutes.getDocument, {
                    responseType: 'blob',
                    headers: {
                        'Accept': 'application/pdf',
                    },
                    params: query,
                });

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const blobUrl = window.URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            } catch (error) {
                console.error('Error fetching PDF', error);
            }
            setLoading(false);
        }
    };

    return (
        <Button
            variant="contained"
            style={{
                background: loading ? "#808080" : "#ff9800",
                color: '#ffffff',
                fontSize: ".75rem",
                lineHeight: ".1 rem",
                textAlign: "center"
            }}
            onClick={getAdminDocument}
            disabled={loading}
        >
         Vezi document
        </Button>
    );
};
