import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../navs/Navbar'
import {useParams} from 'react-router-dom'
import Footer from '../reusable/Footer'
import StarRatings from 'react-star-ratings';
import jwt from 'jsonwebtoken'
import setAuthorizationToken from '../../utils/setAuthorizationToken'
import { setCurrentUser } from '../../store/actions/userActions'
import { saveReviewTemporary, removeReviewTemporary } from '../../store/actions/review'
import booksport from '../../api/booksport'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../../utils/history'

const Review = (props) => {
    const { id, rating, clublocation, element } = useParams();
    const stateReview = useSelector(state => state.review.review)
    const [rate, setRate] = useState(rating)
    const [rateName, setRateName] = useState()
    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [clubname, setClubname] = useState()
    const [rateErr, setRateErr] = useState(false)
    const [details, setDetails] = useState(null)
    const dispatch = useDispatch()

    const editReview = props.location.state ? props.location.state.review : null

    const changeRating = (newRating) => {
        setRate(newRating)
        setRateErr(false)
        switch(newRating){
            case 1 :
                setRateName('Nu recomand')
                break;
            case 2 :
            setRateName('Slab')
                break;
            case 3 :
                setRateName('Acceptabil')
                break;
            case 4 :
                setRateName('Bun')
                break;
            case 5 :
                setRateName('Excelent')
                break;
            default:
                setRateName('Adaugă o notă')
        }
      }

      useEffect(() => {
        window.scrollTo(0, 0)
        let q ={
            hash_order_id : id
          }
        booksport.get('/getdataforreview',  {params: q})
        .then(
            (res) => {
                setDetails(res.data[0])
            })
        if(editReview){
            setTitle(editReview.title)
            setDescription(editReview.description)
        }else if (stateReview) {
            setTitle(stateReview.title)
            setDescription(stateReview.description)
            setClubname(stateReview.clubname)
        }
        // eslint-disable-next-line default-case
        switch(parseInt(rating)){
            case 1 :
                setRateName('Nu recomand')
                break;
            case 2 :
            setRateName('Slab')
                break;
            case 3 :
                setRateName('Acceptabil')
                break;
            case 4 :
                setRateName('Bun')
                break;
            case 5 :
                setRateName('Excelent')
                break;
        }
      }, [])

      const submitReview = () => {
          if(parseInt(rate) === 0){
              setRateErr(true)
          }else{

            dispatch(saveReviewTemporary(rate, null, title, description, details.orderid, clublocation, element, details.clubname ? details.clubname : details.club_name))

            let reviewPost = {
                token: localStorage.getItem('jwtToken'),
                stars: parseInt(rate),
                orderid : details && details.orderid ? details.orderid : details.id,
                title: title,
                description: description,
                clublocation_id: parseInt(clublocation)!== 0 ? parseInt(clublocation) : details.clublocation_id,
                element_id: parseInt(element),
                review_id: details && details.id ? details.id : null
            }

            var curentUser = localStorage.getItem('jwtToken')
            const token = jwt.decode(localStorage.jwtToken,{complete: true});
            var dateNow = new Date().getTime();

            if(token && token.payload.exp < dateNow/1000){
                localStorage.removeItem('jwtToken')
                localStorage.removeItem('usInf')
                setAuthorizationToken()
                setCurrentUser({})
                const location = {
                  pathname: '/login',
                  state: { from: window.location.pathname }
              }
              props.history.push(location)
            }
            if (!curentUser) {
              const location = {
                  pathname: '/login',
                  state: { from: window.location.pathname }
              }
              props.history.push(location)
            }

          booksport.post('/postreview',  reviewPost)
          .then(() => {
              dispatch(removeReviewTemporary())
              history.push('/dashboard/review')
          })
          }

      }
      const setTitleInput = (e) => {
          setTitle(e.target.value)
      }
      const setDescriptionInput = (e) => {
        setDescription(e.target.value)
    }

    return(
        <div>
            <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ 'Booksport - adauga un review'}</title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                    <meta property="og:site_name" content="booksport.ro" />
                    <meta property="og:title" content={ 'Booksport - adauga un review'} />
                </Helmet>
                <Navbar class="clubs club" key={new Date()}/>
                <div className="container mb-5 pb-5">
                    <div className="card p-4 pt-0">
                        <div>
                            <h4 className='text-center mt-2 mb-4 fw-600'> Scrie un review pentru {details && details.orderid ? `comanda: ${details.orderid}` : ''} {clubname && details && !details.orderid ? clubname : details && !details.orderid &&  details.clubname } </h4>
                                {details && details.element ?
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="border-top pt-2"></div>
                                    </div>
                                    <div className={details && details.data !== null ? 'col-lg-4' : 'col-lg-6'} >
                                        <h4 className='text-center fw-600'>{details && details.clubname}</h4>
                                    </div>
                                    {
                                        details && details.data!== null ?
                                        <div className='col-lg-4'>
                                        <h4 className='text-center fw-600'>{details && details.data}</h4>
                                        </div> : ''
                                    }
                                    <div className={details && details.data !== null ? 'col-lg-4' : 'col-lg-6'}>
                                        <h4 className='text-center fw-600'>{details && details.element}</h4>
                                    </div>
                                    <div className="col-12">
                                        <div className="border-bottom"></div>
                                    </div>
                                    </div> : ''
                                }


                        </div>
                        <div className="rating pt-3">
                            <h4 className='m-0 fw-600 mb-2'>Rating<span className='text-danger'>*</span></h4>
                            <div className="d-flex align-items-center">
                            <StarRatings
                                rating={parseFloat(rate)}
                                starRatedColor="#f9bf3b"
                                changeRating={changeRating}
                                numberOfStars={5}
                                name='rating'
                                starDimension="30px"
                                starHoverColor="#f9bf3b"
                                starSpacing='0px'
                            />
                                <p className='m-0 fw-600 rate-name'>{rateName}</p>
                            </div>
                            {rateErr && <p className='text-danger m-0 mt-2'>Va rugam sa adaugati o nota</p>}
                        </div>
                        <div className="title pt-1">
                            <h4 className='m-0 fw-600 mb-2'>Titlu</h4>
                            <input type="text" className='w-100' value={title ? title : ''} onChange={setTitleInput} />
                        </div>
                        <div className="review pt-1">
                            <h4 className='m-0 fw-600 mb-2'>Recenzie</h4>
                            <textarea name="descriere" value={description} id="" onChange={setDescriptionInput}></textarea>
                            <p className='text-danger'>Câmpurile marcate cu * sunt obligatorii</p>
                        </div>
                        <span className="btn btn-success btn-small mt-3 mb-4 submit-review" onClick={submitReview}>Salvează review</span>
                    </div>
                </div>
                <Footer/>
        </div>
    )
}

export default Review
