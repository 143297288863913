import React,{Component} from 'react'
import Modal from "react-bootstrap/Modal";
import { connect } from 'react-redux'
import {closeModal} from "../../../store/actions/modalActions";
import ModalElementRezervari from "./ModalElementRezervari"


class ModalTeren extends Component{

  closeModal=()=>{
    this.props.closeModal()
  }
  render(){
    const showModal = this.props.showModal.showModal

    let hour = {}
    let location_name = {}
    let day = ''
    let day_nr = 0
    let items = []
    let ref_teren
    if(this.props.showModal.showModal){
      hour = this.props.showModal.showModal.hour
      location_name =  this.props.showModal.showModal.location
      day = this.props.showModal.showModal.day
      day_nr = this.props.showModal.showModal.day_nr
      ref_teren = this.props.showModal.showModal.ref_teren
      if(this.props.showModal.showModal.hour.items){
        items = this.props.showModal.showModal.hour.items
      }
    }
    var i =0
    return(
      <Modal show={showModal ? true : false} animation={false} className="teren-m">
        <div className="modal-dialog tables-modal" role="document">
          <div className="modal-content">
            <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>
              <i className="material-icons">clear</i>
            </button>
            <div className="modal-body pt-2">
              <div className="row pt-2 mb-3 align-items-center">
                <div className="col-12 col-md-6">
                  <h4 className="pt-md-3">{location_name.club_name +' - '+ location_name.location_name}</h4>
                </div>
                <div className="col-12 col-md-6 text-md-right">
                  <p className="m-0">{hour.hour}:00 / {day} / {day_nr}</p>
                </div>
              </div>
              <div className="row">
                {
                  hour && hour.element && hour.element.map(element => {
                    i++
                    return (
                      <ModalElementRezervari from={'teren'} key={ element.min00_detail ?  element.min00_detail.id + i : element.id + i} element = {element} hour={hour.hour} day_nr={day_nr} items={items} ref_teren={ref_teren}/>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}


const mapStateToProps =(state) =>{
  return {showModal: state.showModal}
}

export default connect(mapStateToProps,{closeModal}) (ModalTeren)
