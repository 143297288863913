import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import cardbk from '../../resources/images/cards.jpg';
import L from 'leaflet'
import marker from '../../resources/images/map-icon_green.svg'
import markerdisabled from '../../resources/images/map-icon_disabled.svg'
import moment from 'moment';

const ZoneMap = (props) => {
    const {clubs, map} = props

      const pointerIcon = new L.Icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [50, 50],
        shadowSize: [68, 95],
        shadowAnchor: [20, 92],
    })
    const disabledIcon = new L.Icon({
        iconUrl: markerdisabled,
        iconRetinaUrl: markerdisabled,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [50, 50],
        shadowSize: [68, 95],
        shadowAnchor: [20, 92],
    })

    const position = [map && map.center_lat, map && map.center_lng]
    return (
        <Map center={position} zoom={12}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        {
            clubs && clubs.map(loc => {

                return loc.localitate.clubs && loc.localitate.clubs.map(club => {
                    const point = [club.lat,club.lng]
                    let bk = club.filename ? 'https://img.booksport.ro/clubs/'+club.id +'/small/'+club.filename  : cardbk
                    const clublink = `/${club.slug}`
                    return (
                        <Marker position={point} key={club.id} icon={pointerIcon} zIndexOffset={3000}>
                            <Popup>
                                <a href={clublink}>
                                    <div className="card" style={{background: `url(${bk}) rgba(0, 0, 0, 0.4)`}}>
                                        {club.name}<br/>{club.locationname}
                                        <p className="mb-0"><span className="green">{club.elements}</span></p>
                                    </div>
                                </a>
                            </Popup>
                        </Marker>
                    )
                })
            })
        }

    </Map>
    )
}

export default ZoneMap
