import React,{Component} from 'react'
import {withRouter} from 'react-router-dom';
import Footer from "../reusable/Footer";
import booksport from "../../api/booksport";
import {Helmet} from "react-helmet";

class ClubTerms extends Component{
    constructor(props){
        super(props)
        this.state={
            terms: null
        }
    }

    componentDidMount() {
        if(this.state.terms === null){
            this.getTerms()
        }
            window.dataLayer.push({
             event: 'pageview',
             page: {
               url: window.location.pathname,
               title: 'Club terms'
             }
           });
    }

    getTerms = () => {
        let q = {
            clublocation_id : this.props.match.params.clublocation,
            sport_id: this.props.match.params.sport,
        }
        booksport.get('/get_terms_club',  {params: q})
            .then((res) =>
                this.setState({terms: res.data})
            )
            .catch( (err) =>
                this.setState({ error : err.response})
            )
    }

    render(){
        let terms = ''
        if(this.state.terms && this.state.terms){
            terms = this.state.terms
        }
        return(
            <div className="static-text">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Booksport - Termeni si conditii club </title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description" content="Citeste cu atentie termenii si conditiile clubului" />
                    <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                    <meta property="og:site_name" content="booksport.ro" />
                    <meta property="og:title" content="Booksport - Termeni si conditii club" />
                    <meta property="og:description" content="Citeste cu atentie termenii si conditiile clubului" />
                </Helmet>
                <div className="title-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <hr/>
                            </div>
                            <div className="col-lg-12 intro">
                                <h1>{terms.title}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <hr className="nosp" />
                        <div className="row c-section">
                            <div className="col-lg-4 cpt"> </div>
                            <div className="col-lg-8 cpt cols-2" dangerouslySetInnerHTML={{ __html: terms.terms }}>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default withRouter (React.memo(ClubTerms))


