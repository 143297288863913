import React from 'react';

const RegistrationClubConfirm = () => {
    return (
        <div className="card card-login card-hidden">
            <h3 className="card-title mb-3 mt-4 text-center font-weight-bold">Vă mulțumim!</h3>
            <div className="card-body">
                <p className="text-center">Datele au fost trimise cu succes. Un reprezentant Booksport vă va contacta în cel mai scurt timp.</p>
            </div>
            <div className="card-footer justify-content-center">
                <a className="btn btn-success mt-3 mb-4" href="/">Prima pagină</a>
            </div>
        </div>
    );
};

export default RegistrationClubConfirm;
