import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import booksport from '../../../../../api/booksport';
import { PulseLoader } from 'react-spinners'
import {refreshVouchers, showUserVouchersModal} from "../../../../../store/actions/admin/vouchers";
import DialogTitle from "@mui/material/DialogTitle";
import {IconButton, Slide} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {CustomInput} from "../../../components/reusable/CustomInput";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ModalAddVoucher = () => {
    const dispatch = useDispatch()
    const [value, setValue ]= useState('')
    const [error, setError ]= useState([])
    const [responseMsg, setResponseMsg ]= useState(null)
    const [loading, setLoading ]= useState()
    const showModal = useSelector(state => state.admin?.vouchers?.modalUserVoucher)

    const closeModal = () => {
        setResponseMsg(null)
        setError(null)
        dispatch(showUserVouchersModal(false))
        dispatch(refreshVouchers(false))
        setLoading(false)
        setValue('')
    }

    const addVoucher = () => {
        setLoading(true)
        setError(false)
        const query = {
            token: localStorage.getItem('jwtToken'),
            serie: value
        }
        booksport.post('/setvoucher',  query)
            .then((res) => {
                setLoading(false)
                setResponseMsg(res.data)
                dispatch(refreshVouchers(true))
                setTimeout(closeModal, 2000)
            })
            .catch((error) => {
                if( error.response.data){
                    const errors = []
                    try {
                        const json = JSON.parse(error.response.data);
                        Object.entries(json).map(([k,v]) => {
                            errors.push(v.join());
                        })
                    } catch (e) {
                       errors.push(error.response.data);
                    }
                    setError(errors)
                }
                setLoading(false)
            })
    }

    return (
            <Dialog
                open={showModal ? showModal : false}
                onClose={() => closeModal()}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                scroll={"body"}
            >
                <DialogTitle sx={{textAlign:'center', marginBottom: '30px'}}>
                   Adauga un cod de discount
                    <IconButton
                        aria-label="close"
                        onClick={() => closeModal()}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px'}}>
                    {loading ?
                        <div className='col-12'>
                            <div className='d-flex align-items-center justify-content-center pt-5 pb-5'>
                                <PulseLoader color='#12803c' size={20} />
                            </div>
                        </div> :
                        <>
                            {responseMsg ?
                                <h3 className="text-green-400 mt-0 mb-4">{responseMsg}</h3>:
                                <>
                                    <CustomInput
                                        fullWidth
                                        focusColor='#12803c'
                                        name="serie"
                                        label="Serie"
                                        value={value}
                                        onChange={evt => setValue(evt.target.value)}
                                    />
                                    {error && error.map(err => {
                                        return (<p key={err} className="text-danger mt-2 small">{err}</p>)
                                    })}
                                    <Button variant='contained' onClick={addVoucher} style={{background: '#12803c', marginTop: '20px', marginBottom: '30px', width: '100%', padding: '10px'}} >Adauga cod de discount</Button>
                                </>
                            }
                        </>
                    }
                </DialogContent>
            </Dialog>
    )
}

export default ModalAddVoucher
