import React from 'react'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import GalleryItem from './GalleryItem'

const ClubGallery = (props) => {
    const {pics, clubId} = props
    return (
        <div className="gallery-wrapper">
            <p className="openGallery">Click pe imagine pentru galeria foto</p>
            <Gallery shareButton={false}>
                {pics.map((picture) => {
                    return(
                        <GalleryItem key={picture.filename} picture={picture} clubId={clubId}/>
                    )
                })}
            </Gallery>
        </div>
    )
}

export default ClubGallery
