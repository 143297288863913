import React, { useState, useEffect } from "react";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import SpinnerContainer from "../../components/loading-spinner";
import booksport from "../../../../api/booksport";
import { AdminRoutes } from "../../../../api/admin-routes";
import TableDocuments from "./components/TableDocuments";
import { useSelector } from "react-redux";

const AdminDocuments = () => {
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const refreshDocuments = useSelector((state) => state.admin.documents?.refresh);

    useEffect(() => {
        getAdminDocuments();
    }, []);

    useEffect(() => {
        refreshDocuments && getAdminDocuments();
    }, [refreshDocuments]);

    const getAdminDocuments = async () => {
        setLoading(true);
        const token = localStorage.getItem("jwtToken");
        try {
            const response = await booksport.get(AdminRoutes.getDocuments, {
                params: { token },
            });
            setDocuments(response.data);
        } catch (err) {
            console.error("Error fetching documents:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-panel">
            <TopNavAdmin title="Documente" />
            <div className="content">
                {loading ? (
                    <SpinnerContainer />
                ) : documents.length > 0 ? (
                    <div className="col-12">
                        <TableDocuments documents={documents} />
                    </div>
                ) : (
                    <div>
                        <h3 className="text-center">Nu aveti documente asociate</h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminDocuments;
