import React, {Component, lazy, Suspense} from 'react'
import booksport from "../../../api/booksport";
import moment from 'moment'
import {Pie} from 'react-chartjs-2';

const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));
const renderLoader = () => <p>Loading</p>;

class Raport extends Component{
    constructor(props){
        super(props)
        this.state = {
            data: null,
        }
    }

    componentDidMount(){
        document.title = 'Booksport - Raport'
        if(this.state.data === null){
            this.getRaport()
        }
    }

    getRaport = () =>{
        var element={
            token: localStorage.getItem('jwtToken'),
            clublocation_id : this.props.location.state.clublocation_id,
            sport_id:this.props.location.state.sport_id
        }
        if(this.state.data === null) {
            booksport.get('/dashboard_admin_report_perclub', {params: element})
                .then((res) =>
                    this.setState({data: res.data})
                )
        }
    }


    render(){
        let data
        if(this.state.data !== null){
            data = this.state.data
        }
        const Cdata = {
            labels: [
                'Ocupat',
                'Disponibil',
            ],
            datasets: [{
                data: [data ? data.procent_incarcare_saptamana_curenta : '',data ? 100 - data.procent_incarcare_saptamana_curenta : ''],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                ]
            }]
        };

        return(
            <div className="main-panel">
                <Suspense fallback={renderLoader()}>
                    <TopNavAdmin title= {'Raport ' + this.props.location.state.club.club_id.name +' - ' + this.props.location.state.club.name}/>
                </Suspense>
                <div className="content p-0-mobile">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 mt-5">
                                <div className="card card-stats c-raport">
                                    <div className="stats w-100 justify-content-end mb-4">
                                        <h4 className="card-title text-right">{data ? moment(data.data_inceput).format('DD.MM.YYYY') +' - '+ moment(data.data_sfarsit).format('DD.MM.YYYY') : ''}</h4>
                                    </div>
                                    <div className="d-flex align-items-center row-info">
                                        <p className="card-category m-0">Ore rezervate</p>
                                        <h4 className="card-title ml-auto">{data ? data.ore_rezervate_saptamana_curenta : ''} ore</h4>
                                    </div>
                                    <div className="d-flex align-items-center row-info">
                                        <p className="card-category m-0">Total disponibil</p>
                                        <h4 className="card-title ml-auto">{data ? data.total_disponibil_sapt_curenta : ''} ore</h4>
                                    </div>
                                    <div className="d-flex align-items-center row-info">
                                        <p className="card-category m-0">Medie rezervare</p>
                                        <h4 className="card-title ml-auto">{data ? data.medie_valoare_rezervare_saptamana_curenta : ''} lei</h4>
                                    </div>
                                    <div className="d-flex align-items-center row-info">
                                        <p className="card-category m-0">Valoare rezervari</p>
                                        <h4 className="card-title ml-auto">{data ? data.valoare_rezervari_saptamana_curenta : ''} lei</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-5">
                                <div className="card card-stats pb-5 mt-0 c-raport">
                                    <h5 className="text-center mt-2 mb-3">Procent incarcare saptamana curenta</h5>
                                    <Pie data={Cdata} options={{ legend: {
                                            display: true,
                                            position: 'bottom'
                                        } }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Raport
