import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useHistory } from 'react-router-dom';
import booksport from "../../../../api/booksport";
import { PulseLoader } from 'react-spinners';
import queryString from 'query-string';

// Styled component to change the focus color to green and adjust height
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const SportFilter = () => {
    const [sportsOptions, setSportsOptions] = useState([]);
    const [sportValue, setSportValue] = useState('');
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const queryParams = queryString.parse(location.search);

    useEffect(() => {
        const locationValue = queryParams.location;
        const radius = queryParams.radius;

        if (locationValue) {
            const fetchSports = async () => {
                try {
                    const res = await booksport.get('/listmenusports', { params: { location_id: locationValue, radius: radius } });
                    const sports = res.data.map(sport => ({ label: `${sport.name} (${sport.cnt})`, value: sport.id }));
                    setSportsOptions(sports);

                    // Set initial sport value from query params or first available option
                    const sportValueFromQuery = queryParams.sport;
                    if (sportValueFromQuery) {
                        setSportValue(sportValueFromQuery);
                    } else if (sports.length > 0) {
                        const defaultSport = sports[0].value;
                        setSportValue(defaultSport);
                        queryParams.sport = defaultSport;
                        history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
                    }

                    setLoading(false);
                } catch (err) {
                    console.error(err.response.data);
                    setLoading(false);
                }
            };

            fetchSports();
        }
    }, [queryParams.location, queryParams.radius]);

    const handleSportChange = (event) => {
        const newSport = event.target.value;
        const currentParams = queryString.parse(location.search);
        const newParams = { ...currentParams, sport: newSport };
        const newQueryString = queryString.stringify(newParams);

        history.replace(`${location.pathname}?${newQueryString}`);
        setSportValue(newSport);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="40px">
                <PulseLoader color='#12803c' size={10} />
            </Box>
        );
    }

    return (
        <FormControl fullWidth>
            <GreenSelect
                value={sportValue}
                onChange={handleSportChange}
                displayEmpty
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                {sportsOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </GreenSelect>
        </FormControl>
    );
};

export default SportFilter;
