import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from '../../store/actions/userActions';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, CircularProgress } from '@mui/material';
import facebookIcon from "../../resources/images/facebook.svg"
import googleIcon from "../../resources/images/google.svg"
import yahooIcon from "../../resources/images/yahoo.svg"
import {CustomInput} from "../adminRefactored/components/reusable/CustomInput";

const LoginForm = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const res = useSelector(state => state.userStatus.res);

    const initialValues = {
        email: '',
        password: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Introdu o adresă de email validă').required('Câmpul email este obligatoriu'),
        password: Yup.string().required('Câmpul parolă este obligatoriu')
    });

    useEffect(() => {
        res?.error && loading && setLoading(false)
    }, [res]);


    const onSubmit = (values, { setSubmitting }) => {
        setLoading(true);
        dispatch(loginUser(values));
        window.dataLayer.push({
            event: 'login'
        });
        setSubmitting(false);
    };

    const handleErrors = () => {
        if (res && res.error) {
            return (
                <div className="has-danger text-danger text-center">
                    {res.error}
                </div>
            );
        }
    };

    const googleRedirect = () => {
        window.location.href = `${process.env.REACT_APP_SOCIAL_LOGIN_API_URL}/google/redirect`;
    }

    const yahooRedirect = () => {
        window.location.href = `${process.env.REACT_APP_SOCIAL_LOGIN_API_URL}/yahoo/redirect`;
    }

    const facebookRedirect = () => {
        window.location.href = `${process.env.REACT_APP_SOCIAL_LOGIN_API_URL}/facebook/redirect`;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="form">
                    <div className="card card-login">
                        <div className="card-header p-0">
                            <div className="bg-gradient-info shadow-info border-radius-lg py-3 text-center py-4"
                                 style={{backgroundImage: 'linear-gradient(to bottom, #12803c, #4CAF50)', borderRadius: '0.5rem'}}>
                                <h4 className="font-weight-bolder text-white mt-1 mb-0" style={{fontSize: 18, fontWeight: 600, letterSpacing: 1}}>Autentificare</h4>
                                <p className="mb-1 text-sm text-white">Bine ai venit!</p>
                            </div>
                        </div>
                        <div className="card-body pl-3 pr-3">
                            <div className="mt-3 mb-4 justify-content-center d-flex flex-column">
                                <div className="btn btn-link d-flex align-items-center justify-content-center w-100 m-0" style={{border: '1px solid #808080', borderRadius: 5}} onClick={() => facebookRedirect()}>
                                    <img height={25} src={facebookIcon} alt="google-login"/>
                                    <p className="p-0 m-0 ml-2" style={{fontWeight: 600}}>Continuă cu Facebook</p>
                                </div>
                                <div className="btn btn-link d-flex align-items-center justify-content-center w-100 m-0 mt-2" style={{border: '1px solid #808080', borderRadius: 5}} onClick={() => googleRedirect()}>
                                    <img height={25} src={googleIcon} alt="google-login"/>
                                    <p className="p-0 m-0 ml-2" style={{fontWeight: 600}}>Continuă cu Google</p>
                                </div>
                                <div className="btn btn-link d-flex align-items-center justify-content-center w-100 m-0 mt-2" style={{border: '1px solid #808080', borderRadius: 5}} onClick={() => yahooRedirect()}>
                                    <img height={25} src={yahooIcon} alt="google-login"/>
                                    <p className="p-0 m-0 ml-2" style={{fontWeight: 600}}>Continuă cu Yahoo</p>
                                </div>
                            </div>
                            <p className="font-weight-bold text-center text-border">sau</p>
                            <div className="mt-3">
                                <Field
                                    as={CustomInput}
                                    type="email"
                                    name="email"
                                    label="Email"
                                    focusColor='#12803c'
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    helperText={<span style={{ color: 'red' }}><ErrorMessage name="email" /></span>}
                                />
                                <Field
                                    as={CustomInput}
                                    type="password"
                                    name="password"
                                    label="Parolă"
                                    focusColor='#12803c'
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    helperText={<span style={{ color: 'red' }}><ErrorMessage name="password" /></span>}
                                />
                                {handleErrors()}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    disabled={loading}
                                    fullWidth
                                    style={{height: '45px', marginBottom: 30, marginTop: 20, backgroundColor: "#12803c"}}
                                >
                                    {loading && !res?.error ? <CircularProgress size={24} color="inherit" /> : 'Autentificare'}
                                </Button>
                                <p className="text-center mb-4"> <Link to="/recover-password">Ai uitat parola?</Link></p>
                            </div>
                        </div>
                        </div>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
