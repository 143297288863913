import React, { useState, useEffect } from 'react'
import moment from 'moment'
import StarRatings from 'react-star-ratings';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import booksport from '../../api/booksport';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import { setCurrentUser } from '../../store/actions/userActions';
import jwt from 'jsonwebtoken';
import { history } from '../../utils/history';
import ReviewComment from './ReviewComment';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useSelector } from 'react-redux';
import { closeModalDeleteComment } from '../../store/actions/comments';
import { useDispatch } from 'react-redux';

const CardReview = (props) => {
    const [newComment, SetNewComment] = useState()
    const [addComment, setAddComment] = useState(false)
    const [comments, setComments] = useState()
    const [refreshComments, setRefreshComments] = useState(false)
    const {review, user, clubname} = props
    const date =  moment(review.created_at).format('DD.MM.YYYY HH:mm')
    const refreshCommentsState = useSelector(state => state.comments.refreshComments)
    const reviewID =  useSelector(state => state.comments.reviewID)
    const dispatch = useDispatch()

    useEffect(() => {
        booksport.get('/getreviewcomment',  {params: {review_id:review.id}})
        .then((res)=> setComments(res.data))
        SetNewComment('')
        setRefreshComments(false)
    }, [refreshComments])

    useEffect(() => {
        if(review.id === reviewID){
            booksport.get('/getreviewcomment',  {params: {review_id:reviewID}})
            .then((res)=> setComments(res.data))
            SetNewComment('')
            setRefreshComments(false)
            dispatch(closeModalDeleteComment())
        }
    }, [refreshCommentsState])

    const addCommentValue = (e) => {
        SetNewComment(e.target.value)
    }

    const showAddComment = () =>{
        setAddComment(!addComment)
    }

    const postComment = () => {
        var curentUser = localStorage.getItem('jwtToken')
        const token = jwt.decode(localStorage.jwtToken,{complete: true});
        var dateNow = new Date().getTime();

        if(token && token.payload.exp < dateNow/1000){
            localStorage.removeItem('jwtToken')
            localStorage.removeItem('usInf')
            setAuthorizationToken()
            setCurrentUser({})
            const location = {
              pathname: '/login',
              state: { from: window.location.pathname }
          }
          history.push(location)
        }
        if (!curentUser) {
          const location = {
              pathname: '/login',
              state: { from: window.location.pathname }
          }
          history.push(location)
        }

        const query = {
            token: token,
            review_id: review.id,
            comment: newComment
        }

        booksport.post('/postreviewcomment',  query)
        .then(() => {
            setRefreshComments(true)
        })
    }

    return (
        <div className="col-12">
            <div className="card-review-club mt-2 mb-2">
                <div className="row">
                    <div className="col-md-4 col-xl-3">
                        <div className="p-2 pr-md-0">
                            <div className="userimage" style={{backgroundColor:review.color}}>
                                <p className='m-0'>{`${review.name.split(' ')[0].charAt(0)}`}{review.name.split(' ')[1] && review.name.split(' ')[1].charAt(0)}</p>
                            </div>
                            <h5 className="name m-0 fw-600 mt-2">{review.name}</h5>
                            <p className="date mb-0 fw-600">{date} {review.created_at !== review.updated_at ? <span className={'edited ml-2'}>(Editat)</span> : ''}</p>
                        </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                        <div className="p-2 pl-md-0">
                            <h5 className='fw-600 mb-1'>{review.title}</h5>
                            <div className='d-flex align-items-center mb-2'>
                                <StarRatings
                                    rating={review.stars}
                                    starRatedColor="#f9bf3b"
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="26px"
                                    starHoverColor="#f9bf3b"
                                    starSpacing='0px'
                                />
                                {review.achizitieverificata === 1 ? <p className='m-0 ml-2 fw-600 d-flex align-items-center verified'> <VerifiedIcon style={{ color: '#090', fontSize:18 }} /> <span className='ml-1'>achizitie verificată</span> </p> : ''}
                            </div>
                            <p className="description">{review.description}</p>
                            <p className='d-flex align-items-center add-comment fw-600' onClick={showAddComment}><AddCommentOutlinedIcon/> <span>Adauga un comentariu</span></p>
                            <SlideDown className={'wrapper-add-comment'}>
                                {addComment ?
                                <div className="wrapper-add-comment" >
                                    <div className="avatar-add-comment">
                                        <div className="avatar">{user ? `${user.first_name.charAt(0)}${user.last_name.charAt(0)}` : <PersonOutlineOutlinedIcon/>}</div>
                                        <p className='m-0 ml-2'>Adauga comentariu</p>
                                    </div>
                                    <textarea placeholder='Scrie comentariul'  name="descriere" value={newComment} id="" onChange={addCommentValue}></textarea>
                                    <div className="btn btn-success" onClick={postComment}>Adauga</div>
                                    <div className="btn btn-outline" onClick={showAddComment}>Anuleaza</div>
                                </div> : ''}
                            </SlideDown>
                            <div className="wrapper-comments">
                                {comments && comments.map((comment) => {
                                    return(
                                        <ReviewComment reviewId={review.id} key={comment.id} comment={comment} clubname={clubname}/>
                                    )
                                })}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardReview
