import React from "react";
import ModalDeleteSavedCard from "./ModalDeleteSavedCard";
import { useDispatch } from "react-redux";
import { showModalDeleteCard } from "../../../../../store/actions/admin/cards";
import Button from "@mui/material/Button";
import CreditCard from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

const UserSavedCard = ({ card }) => {
    const dispatch = useDispatch();
    const userName = JSON.parse(atob(localStorage.getItem("usInf")));
    const cardNumber = card.pan_masked.slice(0, 1) + "*".repeat(7) + card.pan_masked.slice(1);
    const openModalDeleteCard = () => {
        dispatch(showModalDeleteCard(true));
    };


    return (
        <div className="d-flex flex-column align-items-center wrapper-cards">
            <CreditCard
                number={cardNumber}
                name={`${userName.first_name} ${userName.last_name}`}
                expiry={card.exp_date}
                cvc=''
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="text" onClick={() => openModalDeleteCard()} style={{ margin: "10px 0", color: "#f44336" }}>
                    Sterge
                </Button>
            </div>
            <ModalDeleteSavedCard card={card} />
        </div>
    );
};

export default UserSavedCard;
