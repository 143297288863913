export const AdminRoutes = {
    'clubs': '/clubs',
    'adminMain': '/dashboard_admin_main',
    'adminTransactions': '/dashboard_admin_tranzactii',
    'userTransactions': '/dashboard_tranzactii',
    'getAdminUsers': '/dashboard_admin_get_users',
    'getSidebarMenu': '/menu',
    'getAdminVouchers': '/getvouchers',
    'getAdminVoucherFields': '/form_generate_voucher',
    'createAdminVoucher': '/createvouchers',
    'getUserVouchers': '/getuservouchers',
    'getUserSavedCards': '/getcard',
    'deleteUserSavedCard': '/deletecard',
    'getUserBookings': '/dashboard_bookings',
    'cancelPendingBooking': '/delete_schedule_pending',
    'getUserRevies': '/getreview',
    'returnVoucher': '/refundvoucher',
    'deleteVoucher': '/delete-voucher',
    'getDocuments': '/viewDocuments',
    'getDocument': '/getDocument',
    'signDocument': '/signAndUploadDocument',
}
