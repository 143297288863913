import React, {lazy, Suspense, useEffect} from 'react'
import {Helmet} from "react-helmet";

const Footer = lazy(() => import('../reusable/Footer'));
const renderLoader = () => <p>Loading</p>;
const Terms = (props) => {

    useEffect(() => {
        window.dataLayer.push({
         event: 'pageview',
         page: {
           url: window.location.pathname,
           title: 'Terms & conditions'
         }
       });
     }, [])

    return (
        <div className="static-text">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Booksport - Termeni si conditii </title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Citeste cu atentie termenii si conditiile platformei" />
                <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                <meta property="og:site_name" content="booksport.ro" />
                <meta property="og:title" content="Booksport - Termeni si conditii" />
                <meta property="og:description" content="Citeste cu atentie termenii si conditiile platformei" />
            </Helmet>
            <div className="title-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <hr/>
                        </div>
                        <div className="col-md-6 offset-md-6 text-right">
                            <a href="/">Inapoi la pagina principala</a>
                        </div>
                        <div className="col-lg-12 intro">
                            <h1>Termeni si conditii</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <hr className="nosp" />
                    <div className="row c-section">
                        <div className="col-lg-4 cpt"><h2>Informații generale</h2></div>
                        <div className="col-lg-8 cpt cols-2">
                            <p>Siteul www.booksport.ro , (denumit în continuare site-ul web) este deținut de: BOOKSPORTSAPP S.R.L., MUNICIPIUL BUCUREȘTI, SECTOR 3, ȘOS. MIHAI BRAVU, NR.227, MANSARDA, CAM. NR. 2, BL.B1, AP.5 <br/>

                                Acest document (precum și toate celelalte documente menționate aici) reglementează condițiile de utilizarea ale acestui site web ( www.booksport.ro ) și achiziționarea sau achiziția de produse și / sau servicii din acesta (în avans,in condițiile sitului).<br/>

                                În sensul prezentelor condiții, se înțelege că activitatea pe care  BOOKSPORT o dezvoltă prin intermediul site-ului include:<br/>
                                <ul>
                                    <li> Rezervare de terenuri pentru sporturi individuale (tenis, paddle, etc), terenuri pentru sporturi de echipa (fotbal, bachet, etc), sedinte activitati sportive (yoga, pilates, etc) si adiacente (masaj, etc)</li>
                                    <li>Pe lângă citirea acestor Condiții, înainte de accesarea, navigarea și / sau utilizarea acestui site, Utilizatorul trebuie să fi citit inclusiv politica privind cookie-urile și politica de confidențialitate.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                <div className="row c-section">
                    <div className="col-lg-4 cpt"><h2>Protecția datelor Booksport </h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Utilizând acest site web și / sau solicitând achiziția unui produs și / sau serviciu prin intermediul acestuia, Utilizatorul acceptă să fie obligat de acești Termeni și de toate cele de mai sus, deci, dacă nu sunteți de acord în totalitate, nu trebuie sa utilizați acest site web.<br/>

                            De asemenea, utilizatorul este informat că aceste Condiții ar putea fi modificate.<br/>

                            Utilizatorul este responsabil pentru consultarea acestora de fiecare dată când accesează, navighează și / sau utilizează site-ul Web, deoarece se vor aplica cele care sunt în vigoare în momentul în care se solicită achiziția de produse și / sau servicii.<br/>

                            Pentru toate întrebările pe care Utilizatorul le poate avea în legătură cu Condițiile, puteți contacta proprietarul folosind informațiile de contact furnizate mai sus sau, dacă este cazul, folosind formularul de contact.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Utilizatorul</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Accesul, navigarea și utilizarea site-ului Web, conferă condiția utilizatorului (denumită în continuare, indistinct, individual ca Utilizator sau în comun ca Utilizatori), pentru care sunt acceptate, de când începe navigarea site-ului, toate condițiile prezentate aici , precum și modificările ulterioare ale acestora, fără a aduce atingere aplicării reglementărilor legale corespunzătoare de conformitate obligatorie, după caz.<br/>

                            Utilizatorul își asumă responsabilitatea pentru utilizarea corectă a site-ului Web.<br/>

                            Această responsabilitate se va extinde la:<br/>
                            <ul>
                                <li>  Utilizați acest site web doar pentru a cauta și a face cumpărături sau achiziții valabile legal.</li>
                                <li> Nu faceți nicio achiziție falsă sau frauduloasă. Dacă s-ar putea considera în mod rezonabil că s-a făcut o achiziție de acest tip, aceasta ar putea fi anulată și autoritățile relevante informate.</li>
                                <li> Furnizați date de contact veridice și legale, de exemplu, adresa de e-mail, adresa poștală și / sau alte informații (a se vedea avizul legal și condițiile generale de utilizare).</li>
                                <li>Utilizatorul declară că are vârsta de peste <strong>18 ani</strong> și are capacitatea legală de a încheia contracte prin intermediul acestui site Web.</li>
                                <li> Site-ul web se adresează în principal utilizatorilor cu domiciliul în Romania. Booksport nu se asigură că Website-ul respectă legile altor țări, total sau parțial. Booksport declina orice responsabilitate care poate apărea din acest acces și nu asigură livrări sau prestări de servicii în afara Romaniei
                                </li>
                                <li> Utilizatorul poate oficializa, la opțiunea sa, cu Booksport contractul de vânzare a produselor și / sau serviciilor dorite în oricare dintre limbile în care aceste Condiții sunt disponibile pe acest site Web.</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Cumpărare sau procesul de achiziție</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Utilizatorii înregistrați în mod corespunzător pot cumpăra pe site-ul Web prin mijloacele și formularele stabilite.
                            Trebuie să urmați procedura de achiziție și / sau achiziție online a site-ului www.booksport.ro, în timpul căreia mai multe produse și / sau servicii pot fi selectate și adăugate în coș, sau spațiu final de achiziție și, în final, faceți clic pe „plătiți”.</p>
                            De asemenea, Utilizatorul trebuie să completeze și / sau să verifice informațiile solicitate în fiecare etapă.<br/>

                        <p>În continuare, Utilizatorul va primi un e-mail care confirmă faptul că Booksport a primit comanda sau cererea de cumpărare și / sau furnizare a serviciului, adică confirmarea comenzii. Și, în cazul dvs., veți fi informat, de asemenea, prin e-mail în momentul trimiterii achiziției. În cazul dvs., aceste informații pot fi de asemenea puse la dispoziția utilizatorului prin spațiul personal de conectare la site-ul Web.</p>
                            <p>După finalizarea procedurii de cumpărare, Utilizatorul consimte că Website-ul generează o comanda catre furnizorul final care va presta serviciul pentru Utilizator. Confirmarea va fi vazuta in meniul Contul meu din interfata disponibila Utilizatorului dupa autentificarea pe site, cat si pe e-mail. Utilizatorul poate, dacă dorește, să obțină o copie a facturii sale  solicitând-o de la furnizorul de servicii unde a achizitionat serviciul folosind datele de contact ale furnizorului si o poate salva (download) din interfata Contul meu, unde clubul respectiv o incarca (upload).</p>

                                <p>Utilizatorul recunoaște că este la curent, la momentul achiziției, de anumite condiții particulare de vânzare care privesc produsul și / sau serviciul în cauză și care sunt afișate lângă prezentare sau, după caz, imagine a acestuia pe pagina dvs. a site-ului Web , indicând, de exemplu, dar nu exhaustiv și ținând cont de fiecare caz: numele, prețul, componentele, greutatea, cantitatea, culoarea, detaliile produselor sau caracteristicile, modul în care acestea vor fi efectuate și / sau costul beneficilor; și recunoaște că executarea ordinului de achiziție sau achiziție concretizează acceptarea completă și în întregime a condițiilor particulare de vânzare aplicabile fiecărui caz.</p>

                                    <p><strong> Utilizatorul știe că Booksport îi arată pentru interesul său o serie de servicii pe care proprietarul nu le acordă Utilizatorilor direct, dar că vor fi angajate și furnizate de către un terț, altul decât Booksport.</strong></p>

                                        <p>Comunicările, comenzile de cumpărare și plățile care au loc în timpul tranzacțiilor efectuate pe site-ul Web pot fi arhivate și păstrate în evidențele computerizate ale Booksport  cu scopul de a constitui un mijloc de dovedire a tranzacțiilor, în orice caz, respectând condițiile rezonabile de securitate,legile și reglementările în vigoare care în acest sens sunt aplicabile, în special în conformitate cu GDPR și drepturile care ajută Utilizatorii în conformitate cu politica de confidențialitate a acestui site Web (Notificare legală și Condiții generale de utilizare).</p>
                        <p>În vederea facilitării procedurii de facturare și asigurării desfășurării neîntrerupte a tranzacțiilor, Booksport se obligă să transmită clubului, în calitate de intermediar al achiziției, datele de facturare furnizate de client. Aceste date includ numele, prenumele, adresa de domiciliu, localitatea și județul clientului, în cazul facturării pe persoană fizică. În situația facturării pe persoană juridică, Booksport va transmite clubului datele specificate de client în acest scop.</p>

                            <p>Suplimentar, Booksport se angajează să faciliteze accesul clienților la facturile emise de cluburi, sub condiția că acestea utilizează un furnizor de facturare care este integrat cu infrastructura Booksport, incluzând, dar fără a se limita la, furnizori precum Smartbill, Oblio.eu, FGO, etc. Această facilitate este menită să asigure disponibilitatea și accesibilitatea documentelor contabile în mod eficient și transparent, consolidând astfel experiența utilizatorilor și eficientizând managementul tranzacțiilor comerciale prin intermediul platformei Booksport.</p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Disponibilitate</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Toate comenzile de cumpărare primite de către Booksport prin intermediul site-ului Web sunt supuse disponibilității produselor și / sau că nicio circumstanță sau cauză de forță majoră (clauza nouă din prezentele condiții) nu afectează furnizarea de servicii. Dacă există dificultăți în furnizarea de produse sau nu rămân stocuri, Booksport restituie credit in contul utilizatorului pentru alte achizitii sau ramburseaza suma care a fost plătită pentru acel serviciu / produs. Acest lucru se va aplica în mod egal în cazurile în care prestarea unui serviciu devine de nerealizat.</p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Preturi și plată</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Prețurile afișate pe site-ul web sunt cele finale, în lei și includ TVA (in cazul in care respectivul club este platitor de TVA)<br/>
                            Cu toate acestea, dacă nu se indică altfel, prețurile articolelor oferite exclud costurile de transport, care se vor adăuga la suma totală.<br/>
                            În niciun caz, site-ul Web nu va adăuga costuri suplimentare la prețul unui produs sau serviciu automat, ci doar cele pe care Utilizatorul le-a selectat și ales în mod voluntar și liber.<br/>
                            Prețurile se pot modifica oricând, dar eventualele modificări nu vor afecta comenzile sau cumpărăturile pentru care Utilizatorul a primit deja o confirmare a comenzii.<br/>
                            Mijloacele de plată acceptate vor fi: Card de credit sau debit.<br/>
                            Booksport folosește toate mijloacele pentru a garanta confidențialitatea și securitatea datelor de plată transmise de Utilizator în timpul tranzacțiilor prin intermediul site-ului Web. Ca atare, site-ul web utilizează un sistem de plată securizat furnizat de NETOPIA Payments SRL, SSL (Secure Socket Layer).<br/>
                            Cardurile de credit/debit vor fi supuse autorizațiilor de către banca emitenta, iar în cazul în care entitatea respectivă nu autorizează plata, Booksport nu va fi responsabil pentru nicio întârziere sau nerespectare a comenzilor asociate tranzactiei și nu va putea încheia niciun contract cu Utilizatorul.<br/>
                            Odată ce Booksport primește comanda de cumpărare de la Utilizator prin intermediul site-ului Web, se va face o cerere de plata catre furnizorul de servicii de plata cu cardul (NETOPIA Payments SRL) unde Utilizatorul face plata efectiva a comenzii.<br/>
                            Făcând clic pe „plată”, Utilizatorul confirmă că metoda de plată utilizată este a acestuia sau că, după caz, este deținătorul legitim al cardului cadou sau al cardului de credit.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Condiții de folosire a voucherelor</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p><strong>Valabilitate:</strong> Voucherele au un interval de valabilitate specificat. In afara intervalului voucherele nu pot fi utilizate.</p>
                        <p> <strong>Unică Folosire:</strong> Fiecare voucher poate fi folosit o singură dată, dacă nu se specifică altfel.</p>
                        <p><strong>Necombinabil:</strong> Nu se pot combina mai multe vouchere pentru o rezervare.</p>
                        <p> <strong>Fără Restituire:</strong> Dacă valoarea voucherului este mai mare decât suma rezervării, diferența nu va fi restituită.</p>
                        <p> <strong>Rezervări Specifice:</strong> Voucherele sunt valabile doar pentru anumite tipuri de sporturi, servicii sau locații. Aceste restricții vor fi specificate in sectiunea de Vouchere la fiecare voucher adaugat.</p>
                        <p> <strong>Transferabilitate:</strong> Voucherele nu sunt transferabile și nu pot fi vândute terților.</p>
                        <p><strong>Anularea Rezervării</strong>: În cazul anulării unei rezervări făcute cu un voucher, valoarea voucherului poate fi pierdută sau, în funcție de politica internă a fiecărui club, poate fi restituită sub formă de alt voucher pentru o viitoare rezervare.</p>
                        <p> <strong>Modificări:</strong> Orice modificare a rezervării care implică utilizarea unui voucher poate fi supusă unor taxe suplimentare sau poate necesita utilizarea unui nou voucher.</p>
                        <p> <strong>Codul Voucherului:</strong> Pentru a beneficia de avantajele unui voucher, utilizatorul trebuie să introducă codul acestuia în câmpul corespunzător la momentul efectuării rezervării sau în prealabil în secțiunea Vouchere din Dashboard.</p>
                        <p><strong>Dreptul de Refuz:</strong> Cluburile au dreptul de a refuza acceptarea unui voucher dacă suspectează că acesta a fost obținut în mod fraudulos sau folosit în mod necorespunzător.</p>
                        <p><strong>Promoții:</strong> Voucherele nu pot fi combinate cu alte promoții sau reduceri, decât dacă se specifică altfel.</p>
                        <p><strong>Răspundere:</strong> Cluburile nu sunt responsabile pentru pierderea sau furtul voucherelor. În cazul pierderii unui voucher, acesta nu va fi înlocuit.</p>
                        <p><strong>Modificări ale Termenilor: </strong>Cluburile își rezervă dreptul de a modifica aceste condiții fără un aviz prealabil.</p>
                        <p>Utilizarea voucherelor presupune acceptarea acestor termeni și condiții.</p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Recenzii</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Dupa 24 ore de la achizitionarea unui serviciu, Cumparatorului i se va solicita inscrierea unuei recenzii (review) legat de serviciul achizitionat. Solicitarea va fi transmisa catre adresa de email inscrisa de Cumparator in Cont. In acest fel, Cumparatorul contribuie la informarea altor posibili Utilizatori/ Clienti/ Cumparatori de pe Site si se implica activ in dezvoltarea de noi Servicii si in detalierea cat mai completa a caracteristicilor serviciilor.</p>
                        <p>Prin inregistrarea unui anumit Review/Comentariu/Intrebare/Raspuns pe Site, Utilizatorii/Clientii/Cumparatorii acorda Vanzatorului o licenta neexclusiva, perpetuua, irevocabila, nelimitata teritorial si dau dreptul Vanzatorului de a utiliza, reproduce, modifica, adapta, publica, traduce, distribui si de a afisa acest continut.</p>
                        <p>Pe langa o evaluare realista, in momentul inscrierii unei recenzii, Utilizatorul/Clientul/Cumparatorul va adauga si un Rating relevant pentru produsul sau serviciul aferent. Recenziile, impreuna cu Rating-urile corespunzatoare lor, vor influenta Rating-ul general al produsului sau serviciului. Astfel, o recenzie insotita de un Rating mare duce la o crestere a Rating-ului general, iar o recenzie insotita de un Rating mic duce la o scadere a Rating-ului general.</p>
                        <p>Media recenziilor se face prin medie ponderata (1:5) in favoarea recenziilor date pentru achizitiile verificate vs. recenziile date fara achizitia vreunui serviciu.</p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Promoții</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>BOOKSPORTSAPP S.R.L., MUNICIPIUL BUCUREȘTI, SECTOR 3, ȘOS. MIHAI BRAVU, NR.227, MANSARDA, CAM. NR. 2, BL.B1, AP.5, va desfășura diferite promoții destinate tuturor utilizatorilor potențiali ai aplicatiei Booksport, cu scopul de a promova serviciile oferite, descărcări ale aplicației Booksport în Romania de către utilizatori noi (în continuare „Aplicația”).<br/>
                            <ul>
                                <li>Domeniul teritorial și aplicarea promoției: aceste promoții vor fi realizate în Romania, exclusiv pentru utilizatorii aplicației care se află pe teritoriul Romaniei.</li>
                                <li>Domeniu temporal: Perioada de participare la fiecare promoție va începe la data lansării și se va încheia la data indicată în comunicarea fiecăreia dintre promoțiile publicate de Booksport.</li>
                                <li>Natura gratuită a promoției: Promoțiile vor fi realizate sub modalitatea de „gratuit”, adică participarea la aceste Promoții nu va implica nicio cheltuială pentru participant.</li>
                                <li>Comunicarea promoției: Promoțiile vor fi comunicate în perioada în care sunt active.</li>
                                <li>Persoane legitime și mecanisme ale promoției: condițiile de participare la aceste promoții vor fi determinate de particularitatea acestora. În acest fel, fiecare Promoție va avea condiții diferite pe care utilizatorul trebuie să le respecte în fiecare caz. Aceste condiții, pe lângă dinamica de participare, vor fi specificate în comunicarea emisă de Booksport pentru fiecare dintre promoțiile lansate.</li>
                                <li>Rezervări și limitări: Booksport își rezervă dreptul de a respinge înregistrarea acelor participanți ale căror date nu sunt complete sau codurile lor nu îndeplinesc cerințele de participare la Promoții. Booksport își rezervă dreptul de a face modificări care să conducă la bun sfârșit Promoțiile, atunci când există motive de cauză sau de forță majoră care împiedică finalizarea acestuia în modul descris în aceste reguli.</li>
                                <li>Acceptare: Participarea la Promoții presupune, de la sine, acceptarea bazelor prezente. Lipsa acestei acceptări din partea participantului va duce la excluderea participării lor la Promoții.</li>
                                <li>Prelucrarea datelor cu caracter personal: În conformitate cu prevederile Legii 15/1999, a Legii privind protecția datelor cu caracter personal, vă informăm că datele personale pe care ni le furnizați vor fi salvate in locatii cu acces securizat. În conformitate cu prevederile GDPR, la momentul colectării datelor, utilizatorilor va fi solicitat consimțământul expres, astfel încât Booksport să poată folosi datele lor pentru a trimite informații și publicitate prin e-mail sau mijloace echivalente, cum ar fi SMS-urile pe telefoanele mobile.</li>
                            </ul>
                            <br/>
                            Aceste date vor fi păstrate în cea mai strictă confidențialitate, în conformitate cu legislația în vigoare.<br/>
                            Participanții la aceste promoții și utilizatorii înregistrați pot exercita, în legătură cu datele lor personale, drepturile de acces, rectificare, anulare și revocare a consimțământului pentru transferul acestora, printr-un e-mail adresat: <a
                                href="mailto:gdpr@booksport.ro"><strong>gdpr@booksport.ro</strong></a>
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Cumpărare bonus</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Portofelul sau portofelul electronic reprezintă un mijloc de plată, astfel încât titularul portofelului îl poate utiliza în conformitate cu prevederile acestui document. Creditul din portofel este exprimat in PUNCTE. <br/>
                            Portofelul poate fi utilizat doar pe teritoriul național.<br/>
                            Soldul disponibil în portofel nu va genera performanțe financiare.<br/>
                            Portofelul va fi activat în momentul achiziției de către proprietar.<br/>
                            Înainte de a utiliza portofelul, utilizatorul trebuie să se înregistreze în avans în aplicație.<br/>
                            Titularul portofelului poate achiziționa bunuri și servicii oferite de Booksport prin aplicația sa și / sau site-ul web.<br/>
                            Proprietarul va fi pe deplin responsabil pentru utilizarea, stocarea și custodia portofelului dvs., prin urmare, Booksport este scutit de orice răspundere pentru abuzul care ar putea apărea.<br/>
                            Utilizatorii care dețin un portofel au un termen de cinci (5) ani de la data achiziției.<br/>
                            Creditul nu poate fi trimis și / sau transferat unui alt Utilizator, este destinat utilizării exclusive a fiecărui utilizator înregistrat. Creditul trebuie utilizat exclusiv de către Utilizatorul care l-a dobândit. Utilizatorul este de acord că Booksport, în propria sa discreție, are dreptul (dar nu și obligația) de a elimina sau dezactiva contul Utilizatorului, de a bloca adresa de e-mail sau IP sau de a anula accesul sau utilizarea Serviciului ( sau o parte din acesta) de către Utilizator, imediat și fără o notificare prealabilă.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Mediu tehnic pentru corectarea erorii</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Utilizatorul este informat că dacă detectează o eroare la introducerea datelor necesare procesării cererii sale de cumpărare pe site-ul Web, el le poate modifica contactând Booksport prin spațiile de contact activate, pe site-ul Web și, după caz, prin contactarea serviciul pentru clienți și / sau folosind informațiile de contact prevăzute în prima clauză (Informații generale). <br/>
                            Utilizatorul, înainte de a face clic pe „plată”, are acces la coșul de cumparaturi în care își notează cererile de cumpărare și poate face modificări. <br/>
                            De asemenea, utilizatorul este trimis să consulte Condițiile generale de utilizare pentru a face achizitia in cunoscand toate implicatiile legale.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Anulare</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>În cazurile în care Utilizatorul achiziționează produse de pe sau prin intermediul site-ului Web al proprietarului, acesta este asistat de o serie de drepturi, astfel cum sunt enumerate și descrise mai jos: <br/>

                            <ul>
                                <li>Dreptul de anulare</li>
                                <li>Pentru a exercita acest drept de retragere, Utilizatorul trebuie să notifice Booksport decizia sa. Puteți face acest lucru, dacă este cazul, prin interfata de Rezervare din Contul meu.</li>
                                <li>Utilizatorul, indiferent de mijloacele pe care le alege pentru a-și comunica decizia, trebuie să exprime clar și fără echivoc că este intenția sa să renunțe la contractul de cumpărare. Pentru a respecta perioada de retragere, este suficient ca comunicarea care exprimă fără echivoc decizia de abandonare să fie trimisă înainte de expirarea termenului corespunzător.</li>
                                <li>În cazul retragerii, Booksport va rambursa Utilizatorul pentru toate plățile primite, fără nicio întârziere nejustificată și, în orice caz, în cel mult 14 zile calendaristice de la data la care Booksport este informat despre decizia de anulare a Utilizatorului. Booksport va rambursa Utilizatorul in voucher sau folosind aceeași metodă de plată pe care a folosit-o pentru a efectua tranzacția inițială de cumpărare. Această restituire nu va genera niciun cost suplimentar pentru Utilizator.</li>
                                <li>In functie de timpul scurs de la data si ora achizitiei restituirea sumei poate fi partiala sau totala, in functie de politica de rambursare a clubului respectiv. Rambursarea poate fi sub forma unui voucher sau folosind aceeași metodă de plată pe care a folosit-o pentru a efectua tranzacția inițială de cumpărare.</li>
                                <li>În același sens, furnizarea unui serviciu pe care Utilizatorul l-ar putea contracta pe acest site Web este reglementată, deoarece Dreptul de retragere al Utilizatorului nu poate fi folosit atunci când furnizarea serviciului a fost executată în totalitate sau atunci când început. Acordul expres al consumatorului și utilizatorului și confirmarea conditiilor de achizitie din partea sa arata că este conștient de faptul că, după ce contractul a fost executat integral de către Booksport, acesta și-a pierdut dreptul de retragere.</li>
                            </ul>
                          </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Declarația de responsabilitate</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Dacă legea nu prevede altfel, Booksport nu va accepta nicio răspundere pentru următoarele pierderi, indiferent de originea lor: <br/>
                            <ul>
                                <li> orice pierderi care nu erau imputabile niciunei încălcări din partea ta,</li>
                                <li>pierderi de afaceri (inclusiv profituri pierdute, venituri, contracte, economii anticipate, date, pierderea fondului comercial sau cheltuieli inutile efectuate) sau orice altă pierdere indirectă care nu era în mod rezonabil previzibilă de către ambele părți la momentul formalizării contractului de vânzare a produselor între ambele părți.</li>
                            </ul>
                            <br/>




                            De asemenea, Booksport își limitează responsabilitatea în următoarele cazuri:<br/>

                            Booksport aplică toate măsurile referitoare la furnizarea unei afișări fidele a produsului pe site-ul Web, cu toate acestea, nu este responsabil pentru diferențele minime sau inexactitățile care pot exista din cauza lipsei de rezoluție a ecranului, sau a problemelor browserului utilizate sau de alții această natură.<br/>

                            Booksport va acționa cu cea mai mare sârguință pentru a pune la dispoziția companiei însărcinată cu transportul produsului, obiectele comenzii de achiziție. Cu toate acestea, nu este responsabil pentru daunele cauzate de o functionare necorespunzatoare a transportului, în special din motive precum greve, rețineri rutiere și, în general, orice alte elemente specifice sectorului, care au ca rezultat întârzieri, pierderi sau furtul produsului.<br/>

                            Eșecuri tehnice care din cauze fortuite sau de altă natură împiedică o funcționare normală a serviciului prin internet. Lipsa disponibilității site-ului Web pentru întreținere sau din alte motive, ceea ce împiedică disponibilitatea serviciului.<br/>
                            Booksport pune toate mijloacele la dispoziție pentru a efectua procesul de cumpărare, plată și livrare / livrare a produselor, cu toate acestea este scutit de răspundere pentru cauze care nu îi sunt imputabile, eveniment fortuit sau forță majoră.<br/>

                            Booksport nu va fi responsabil pentru utilizarea necorespunzătoare și / sau uzura produselor care au fost utilizate de Utilizator. În același timp, Booksport nu va fi responsabil pentru o returnare eronată făcută de Utilizator. Este responsabilitatea Utilizatorului să returneze produsul corect.<br/>

                            În general, Booksport nu va fi responsabil pentru nicio încălcare sau întârziere la îndeplinirea oricăreia dintre obligațiile asumate, atunci când aceasta se datorează evenimentelor care nu se află sub controlul nostru rezonabil, adică a faptului că acestea sunt datorate forței majore și acest lucru poate include, fără a se limita la:<br/>

                            Greve, blocaje sau alte acțiuni industriale, revoltă, invazie, amenințare sau atac terorist, război (declarat sau nu) sau amenințare sau pregătiri de război.<br/>
                            Incendiu, explozie, furtună, inundații, cutremur, subsidență, epidemie sau orice alt dezastru natural.<br/>
                            Imposibilitatea utilizării trenurilor, bărcilor, avioanelor, transportului cu motor sau a altor mijloace de transport, publice sau private.<br/>
                            Incapacitatea de a utiliza sisteme publice sau private de telecomunicații.<br/>
                            Acte, decrete, legislație, reglementări sau restricții ale oricărui guvern sau autoritate publică.<br/>

                            În acest fel, obligațiile vor fi suspendate în perioada în care cauza de forță majoră continuă, iar Booksport va avea o prelungire în termen pentru a le îndeplini pentru o perioadă de timp egală cu durata evenimentului de forță majoră. Booksport va folosi toate mijloacele rezonabile pentru a găsi o soluție care să ne permită să ne îndeplinim obligațiile, în ciuda cauzei forței majore.

                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Comunicări și notificări</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Utilizând acest site Web, Utilizatorul acceptă faptul că majoritatea comunicărilor cu Booksport sunt electronice (e-mail sau anunțuri publicate pe site-ul Web).
                            În scopuri contractuale, Utilizatorul consimte să utilizeze acest mijloc electronic de comunicare și recunoaște că toate contractele, notificările, informațiile și alte comunicări pe care Booksport le trimite electronic respectă cerințele legale de a fi în scris. Această condiție nu va afecta drepturile utilizatorului recunoscute de lege. <br/>
                            Utilizatorul poate trimite notificări și / sau comunica cu Booksport prin datele de contact prevăzute în aceste condiții și, după caz, prin spațiile de contact ale site-ului. <br/>
                            De asemenea, cu excepția cazului în care se prevede altfel, Booksport poate contacta și / sau notifica Utilizatorul prin e-mailul său sau la adresa poștală furnizată.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Nulitatea</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Dacă oricare dintre aceste condiții a fost declarată nulă printr-o decizie finală emisă de o autoritate competentă, restul clauzelor vor rămâne în vigoare, fără a fi afectate de declarația de nulitate menționată.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Acordul complet</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Aceste Condiții și orice document la care se face referire expresă la acestea constituie întregul acord între Utilizator și Booksport în legătură cu obiectul de cumpărare și înlocuiesc orice alt acord, acord sau promisiune convenită verbal sau în scris de aceleași părți.
                            Utilizatorul și Booksport recunosc că au consimțit la încheierea unui contract fără a se baza pe nicio declarație sau promisiune făcută de cealaltă parte, cu excepția celor menționate în mod expres în aceste condiții.

                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Protecția datelor</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Informațiile sau datele personale pe care Utilizatorul le furnizează Booksport în cadrul unei tranzacții pe site-ul Web, vor fi tratate în conformitate cu prevederile politicilor de protecție a datelor (Condiții generale de utilizare). La accesarea, navigarea și / sau utilizarea site-ului Web, Utilizatorul consimte la procesarea informațiilor și datelor menționate și declară că toate informațiile sau datele furnizate sunt adevărate.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Legislare aplicabilă și jurisdicție</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Accesul, navigarea și / sau utilizarea acestui site web și a contractelor de achiziție a produselor prin intermediul acestuia vor fi reglementate de legislația romana.
                            Orice controversă, problemă sau dezacord care apare sau are legătură cu accesul, navigarea și / sau utilizarea site-ului sau cu interpretarea și executarea acestor Condiții sau cu acordurile de vânzare între Booksport și Utilizator, vor fi transmise către jurisdicția neexclusivă a instanțelor și tribunalelor române în jurisdicția societății Booksport.
                        </p>
                    </div>
                </div>
                <div className="row c-section">
                    <div className="col-lg-8 offset-lg-4 p-0">
                        <div className="col-12"> <hr className="nosp" /></div>
                    </div>
                    <div className="col-lg-4 cpt"><h2>Reclamații</h2></div>
                    <div className="col-lg-8 cpt cols-2">
                        <p>Utilizatorul poate trimite către Booksport reclamațiile, revendicările sau orice alte comentarii pe care dorește să le facă prin intermediul informațiilor de contact furnizate la începutul acestor Condiții (Informații generale). <br/>
                            Utilizatorul în calitate de consumator poate solicita o soluționare extrajudiciară a litigiilor, în conformitate cu Regulamentul UE nr. 524/2013 al Parlamentului European și al Consiliului, din 21 mai 2013, privind soluționarea litigiilor online în materie de consum. Puteți accesa această metodă prin intermediul site-ului web: http://ec.europa.eu/consumers/odr/ .
                        </p>
                    </div>
                </div>
            </div>
            <Suspense fallback={renderLoader()}>
                <Footer/>
            </Suspense>
        </div>
    )
}

export default React.memo(Terms)



