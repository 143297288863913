import React, {useEffect} from 'react'

function ValidateOptions(rezervare){
    const options = {}
    Object.entries(rezervare).map(
        ([k, v]) => {
            if(k.split('_')[0] === 'extra'){
                options[k] = v;
            }
        }
    )
    return Object.keys(options).length !== 0 ? Object.values(options).every(o => o !== null) : false
}

export default ValidateOptions