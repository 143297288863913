import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useHistory } from 'react-router-dom';
import booksport from "../../../../api/booksport";
import { PulseLoader } from 'react-spinners';
import queryString from "query-string";

// Styled component to change the focus color to green and adjust height
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const LocationFilter = () => {
    const [locationsOptions, setLocationsOptions] = useState([]);
    const [locationValue, setLocationValue] = useState('');
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const currentParams = queryString.parse(location.search);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const res = await booksport.get('/get_cities');
                const cities = res.data.map(city => ({ label: city.name, value: city.id }));

                // Get user's current location
                navigator.geolocation.getCurrentPosition((position) => {
                    const { latitude, longitude } = position.coords;
                    const currentLocationValue = `@${latitude},${longitude}`;
                    const currentLocation = { label: 'Locatia curenta', value: currentLocationValue };
                    const allLocations = [currentLocation, ...cities];
                    setLocationsOptions(allLocations);

                    // Get the location value from query params
                    const queryParams = queryString.parse(location.search);
                    const locationValueFromQuery = queryParams.location;

                    if (locationValueFromQuery && locationValueFromQuery.startsWith('@')) {
                        setLocationValue(currentLocationValue);
                    } else if (locationValueFromQuery) {
                        setLocationValue(locationValueFromQuery);
                    } else {
                        const defaultLocation = allLocations[0]?.value;
                        setLocationValue(defaultLocation);
                        queryParams.location = defaultLocation;
                        history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
                    }

                    setLoading(false);
                }, (err) => {
                    console.error(err);
                    setLocationsOptions(cities);

                    // Get the location value from query params
                    const queryParams = queryString.parse(location.search);
                    const locationValueFromQuery = queryParams.location;

                    if (locationValueFromQuery) {
                        setLocationValue(locationValueFromQuery);
                    } else {
                        const defaultLocation = cities[0]?.value;
                        setLocationValue(defaultLocation);
                        queryParams.location = defaultLocation;
                        history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
                    }

                    setLoading(false);
                });
            } catch (err) {
                console.error(err.response);
                setLoading(false);
            }
        };

        fetchCities();
    }, []);

    useEffect(()=>{
        if(currentParams.location?.toString().startsWith('@')){
            if(!currentParams.radius){
                currentParams.radius = '10';
            }
        } else {
            delete currentParams.radius;
        }
        const newQueryString = queryString.stringify(currentParams);
        history.replace(`${location.pathname}?${newQueryString}`);
    }, [location.search])

    const handleLocationChange = (event) => {
        const newLocation = event.target.value;

        currentParams.location = newLocation;

        if (newLocation.toString().startsWith('@')) {
            if (!currentParams.radius) {
                currentParams.radius = '10';
            }
        } else {
            delete currentParams.radius;
        }

        const newQueryString = queryString.stringify(currentParams);
        history.replace(`${location.pathname}?${newQueryString}`);
        setLocationValue(newLocation);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="40px">
                <PulseLoader color='#12803c' size={10} />
            </Box>
        );
    }

    return (
        <FormControl fullWidth>
            <GreenSelect
                value={locationValue}
                onChange={handleLocationChange}
                displayEmpty
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                {locationsOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </GreenSelect>
        </FormControl>
    );
};

export default LocationFilter;
