import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import registerbk from '../../resources/images/register.jpg';
import Navbar from "../navs/Navbar";
import RegisterForm from "../forms/RegisterForm";
import Footer from "../reusable/Footer";

const Register = () => {
    useEffect(() => {
        window.dataLayer.push({
            event: 'pageview',
            page: {
                url: window.location.pathname,
                title: 'Register'
            }
        });
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Booksport - Înregistrare </title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Creeaza cont" />
                <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                <meta property="og:site_name" content="booksport.ro" />
                <meta property="og:title" content="Booksport - Înregistrare" />
                <meta property="og:description" content="Creeaza cont" />
            </Helmet>
            <Navbar key={new Date()} logoW={true} />
            <div className="page-header header-filter"
                 style={{ backgroundImage: `url(${registerbk})`, backgroundSize: 'cover', backgroundPosition: 'top center', height: 'auto', minHeight: '100vh' }}>
                <div className="container wrapper-pannel-content" style={{minHeight: 800}}>
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-sm-8 ml-auto mr-auto">
                         <RegisterForm />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default React.memo(Register);
