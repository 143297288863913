import React, {useState, useEffect} from 'react'
import {Helmet} from "react-helmet";
import Navbar from '../navs/Navbar';
import Footer from '../reusable/Footer';
import booksport from '../../api/booksport';
import {useParams} from 'react-router-dom'
import ClubsWrapper from '../landings/ClubsWrapper';
import { closeMap } from '../../store/actions/clubsFilter';
import { useSelector, useDispatch } from 'react-redux';
import ZoneMap from '../landings/ZoneMap';
import MapControls from '../landings/MapControls';
import { history } from '../../utils/history'

const LandingPage = (props) => {
    const [details, setDetails] = useState()
    const openMap = useSelector(state => state.filterClubs.openMap)
    const { slug } = useParams();
    const dispatch = useDispatch()


    useEffect(() => {
        let q = {
            slug: slug
        }
        booksport.get('/getlanding',  {params: q})
        .then((res) =>
        {
           setDetails(res.data)
        }
        )
    }, [])

    useEffect(() => {
        window.dataLayer.push({
         event: 'pageview',
         page: {
           url: window.location.pathname,
           title: details ? details.page.name : 'Landing page'
         }
       });
     }, [details])

    const closeMapPannel = () => {
        dispatch(closeMap())
    }

    if(details && details.page.publish === 0 ){
        history.push('/')
    }

    return (
        <div>
            <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ `Booksport - ${details && details.page.name}`}</title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description" content={details && details.page && details.page.description.replace(/<[^>]*>?/gm, '')} />
                    <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card, teren" />
                    <meta property="og:site_name" content="booksport.ro" />
                    <meta property="og:title" content={ `Booksport - ${details && details.page.name}`} />
                    <meta property="og:description" content={details && details.page && details.page.description.replace(/<[^>]*>?/gm, '')} />
            </Helmet>
            <Navbar class="clubs" key={new Date()}/>
           <MapControls/>
            <div className="container-fluid content-fixed-head landing">
                <div className="row lp-head bb-green">
                    <div className="col-12">
                        <h1 className='text-center'>{details && details.page && details.page.name}</h1>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="row wrapper">
                    <div className="col-lg-8 pb-0">

                        {details && details.page && details.page.description ? <div className="mt-5" dangerouslySetInnerHTML={{__html: details.page.description}}></div> : '' }
                        <ClubsWrapper locations={details && details.data} showLoc={details && details.page && details.page.display_localitate} showJudet={details && details.page && details.page.display_judet}/>
                        <Footer/>
                    </div>
                    {details && details.map ?
                    <div className={openMap ? "col-lg-4 map active p-0" : "col-lg-4 map p-0"}>
                        <div className="close-map" onClick={() => closeMapPannel()}>Inchide harta<span className="material-icons">close</span></div>
                        <ZoneMap clubs={details && details.data} map={details && details.map}/>
                    </div> : false}
                </div>
            </div>
        </div>
    )
}

export default LandingPage
