import React, {useState, useEffect} from "react";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import booksport from "../../../../api/booksport";
import {AdminRoutes} from "../../../../api/admin-routes";
import SpinnerContainer from "../../components/loading-spinner";
import TableTransactions from "./components/TableTransactions";
import ModalReturnConfirmation from "./components/ModalReturnConfirmation";
import ModalReturnVoucherConfirmation from "./components/ModalReturnVoucherConfirmation";
import {useDispatch, useSelector} from "react-redux";
import {refreshTransactions} from "../../../../store/actions/admin/transactions";


const AdminTransactions = () => {
    const [loading, setLoading] = useState(false)
    const [transactions, setTransactions] = useState([])
    const refresh = useSelector(state => state.admin?.transactions?.refresh)
    const dispatch = useDispatch()

    useEffect(() => {
        getClubTransactions()
    }, []);

    useEffect(() => {
        refresh && getClubTransactions()
    }, [refresh]);


    const getClubTransactions = async () =>{
        setTransactions([])
        setLoading(true)
        const query={
            token: localStorage.getItem('jwtToken'),
        }
        try{
            const response = await booksport.get(`${AdminRoutes.adminTransactions}`, {params: query})
            setTransactions(response.data)
            dispatch(refreshTransactions(false))
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }


    return(
        <div className="main-panel">
            <TopNavAdmin title='Tranzactii'/>
            <div className="content">
                {loading && <SpinnerContainer/>}
                <div className="container-fluid">
                    {transactions && transactions.map(club => {
                        return (
                            <div className="mb-5" key={club.clublocation_id}>
                                <h3 className='font-weight-bold text-center mb-2'>{club.clublocation_name}</h3>
                                {club.plati.length > 0 ?
                                    <TableTransactions key={club.clublocation_id} club={club}/>:
                                    <h4 className='text-center'>Nu au fost efectuate tranzactii</h4>
                                }
                            </div>
                        )
                    })}
                    {transactions && <ModalReturnConfirmation/>}
                    {transactions && <ModalReturnVoucherConfirmation/>}

                </div>
            </div>
        </div>
    )
}

export default AdminTransactions
