import React from 'react'
import StarRatings from 'react-star-ratings'
import cardbk from '../../../resources/images/cards.jpg';
import {useLocation} from 'react-router-dom'
import ClubAvailability from './ClubAvailability';
import queryString from "query-string";

const CardClub = (props) => {
    const {club} = props
    const location = useLocation();
    const currentParams = queryString.parse(location.search);
    const { sport, date } = currentParams;
    const clublink = club.slug !== '' ? `/${club.slug}?date=${date}` : `/club/${club.id}/${sport}?date=${date}`

    return (
        <div className={`col-sm-6 col-xl-4 club-card ${club.promo ? 'promo':''}`}>
            <div className={club.booking === 0 ? "card card-blog opacity-50" : "card card-blog"}>
                <a href={clublink}>
                    <div className="card-header card-header-image">
                        {club.main_card_message && <div className="card-note" style={{backgroundColor:club.main_card_message_bkg_color, color:club.main_card_message_color}}>{club.main_card_message}</div> }
                        <div className="img">
                            <img className="img" src={club && club.filename ? 'https://img.booksport.ro/clubs/'+club.id +'/small/'+club.filename  : cardbk} alt="club"/>
                            <div className="exclusive">Exclusiv</div>
                        </div>
                        <div className="card-title">
                            {club.name} <br/>
                            {club.locationname}
                        </div>
                        <div className="colored-shadow"> </div>
                    </div>
                </a>
                <div className="card-body">
                    {club.review !== 0 && club.booking !== 0?
                        <div className="card-rating">
                            <a className='add-review' href={`${clublink}&r=true`}>
                            <StarRatings
                                    rating={club.review}
                                    starRatedColor={club.promo ? '#E95705' : "#f9bf3b"}
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="20"
                                    starHoverColor="#f9bf3b"
                                    starSpacing='0px'
                                />
                            <span style={{fontStyle: 'normal', color:'#000'}}>({club.nr_review} {club.nr_review > 1 ? 'review-uri' : 'review'})</span> </a>
                    </div> : club.booking !== 0 && <a className='add-review' href={`${clublink}&r=true`}>Adauga un review</a> }
                    <h5 className="card-category mb-0">
                        <span style={{marginRight: '10px'}}>{club.address}</span>
                        <span className="green">{club.elements}</span></h5>
                </div>
                {club.booking === 1 ? <ClubAvailability club={club} clublink={clublink}/> : <div className="card-footer"><p className="navailable green">In curând</p></div>}

          </div>
        </div>
    )
}

export default CardClub
