import React from 'react'
import search from '../../resources/images/search.png'
import pay from '../../resources/images/card.png'
import play from '../../resources/images/play.png'

const ThreeStepsSection = () => {
    return (
        <div className="three-steps">
            <div className='container'>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="step-wrapper">
                            <img className='img-fluid' src={search} alt="search" />
                            <p>Fă o rezervare la clubul favorit fără costuri suplimentare</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="step-wrapper">
                            <img className='img-fluid' src={pay} alt="search" />
                            <p>Plătește rapid cu cardul 100% sigur</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="step-wrapper">
                            <img className='img-fluid' src={play} alt="search" />
                            <p>Bucură-te de activitatea favorită împreună cu prietenii tăi</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>  
    )
}

export default ThreeStepsSection
