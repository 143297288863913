import React, {Component, lazy, Suspense} from 'react'
import {Link} from 'react-router-dom'
import booksport from "../../../api/booksport";

const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));
const renderLoader = () => <p>Loading</p>;

class Terenuri extends Component{
  constructor(props){
    super(props)
    this.state = {
      club: null,
      terenuri: null,
    }
  }

  componentDidMount(){
    document.title = 'Booksport - Terenuri'
  }


  render(){
    var club = this.props.location.state
    var sport= this.props.location.state.sport_id
    var terenuri = null
    var element={
      token: localStorage.getItem('jwtToken'),
      clublocation_id :club.clublocation_id
    }
    if(this.state.club === null){
      booksport.get('/getclubdetails', {params: element})
        .then((res) =>
          this.setState({ club : res.data.club, terenuri:res.data.elements})
        )
        .catch( (err) =>
          this.setState({ succes : false, error : err.response.data})
        )
    }
    if(this.state.club){
      club = this.state.club
    }
    if(this.state.terenuri){
      terenuri = this.state.terenuri
    }
    var current = this.props.location.state

    const TerenuriSelect = [];
    if(terenuri !== null ){
      terenuri.map(item => {
        if(item.sport_id === sport) {
          return (
            TerenuriSelect.push({label: item.name, value: item.id},)
          )
        }
        return false
      })
    }
    return(
      <div className="main-panel">
        <Suspense fallback={renderLoader()}>
          <TopNavAdmin title= {'Terenuri ' + this.props.location.state.club_name + ' ' + club.name}/>
        </Suspense>
        <div className="content p-0-mobile">
          <div className="container-fluid">
            <div className="row">
              <div className="card-body">
                <div className="row">
                  {terenuri && terenuri.map(teren =>{
                    if(teren.sport_id === sport){
                      return(
                        <div key={teren.name} className="col-sm-6 col-md-4 col-xl-3">
                          <div className="card card-stats card-admin">
                            <Link to={{
                              pathname: 'teren',
                              state: { current: current, element_id: teren.id, teren_name:teren.name , sport: sport, terenuri:TerenuriSelect}
                            }}>
                              <div
                                className="card-header card-header-success card-header-icon teren-card">
                                <div className="card-icon small-icon">
                                  <i className="material-icons">view_module</i>
                                </div>
                                <h3 className="card-title p-0 text-capitalize">{teren.name}</h3>
                              </div>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                    return false
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Terenuri
