import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import booksport from '../../../../../api/booksport';
import DialogTitle from "@mui/material/DialogTitle";
import {IconButton, Slide} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {AdminRoutes} from "../../../../../api/admin-routes";
import {refreshCardsList, showModalDeleteCard} from "../../../../../store/actions/admin/cards";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ModalDeleteSavedCard= ({card}) => {
    const showDialog = useSelector(state => state.admin?.cards?.modalDeleteCard)
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(showModalDeleteCard(false))
    }

    const deleteCard = async () =>{
        const query={
            token: localStorage.getItem('jwtToken'),
            payment_instrument_id: card.payment_instrument_id
        }
        try{
            const response = await booksport.get(`${AdminRoutes.deleteUserSavedCard}`, {params: query})
            if(response){
                dispatch(refreshCardsList(true))
                dispatch(showModalDeleteCard(false))
            }
        }catch(err){
            return {error: err.response}
        }
    }


    return (
        <Dialog
            open={showDialog ? showDialog : false}
            onClose={() => closeModal()}
            aria-describedby="add-voucher"
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={"sm"}
            scroll={"body"}
        >
            <DialogTitle sx={{textAlign:'center'}}>
                Sterge cardul
                <IconButton
                    aria-label="close"
                    onClick={() => closeModal()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "rgba(0, 0, 0, 0.5)",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{paddingTop: '10px'}}>
                <p style={{textAlign: 'center'}}>Esti sigur ca vrei sa stergi cardul cu numarul <strong>{card.pan_masked}</strong>? Cardul se poate resalva in contul tau in momentul efectuarii unei rezervari.</p>
            </DialogContent>
            <DialogActions style={{marginBottom: '10px'}}>
                <Button variant='contained' onClick={() => closeModal()} style={{background: '#12803c'}} >Anuleaza</Button>
                <Button variant='contained' onClick={() => deleteCard()} style={{background: '#e95e4e'}} >Sterge</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalDeleteSavedCard
