import React,{Component} from 'react'
import '../resources/scss/main-dashboard.scss'
import {Route,Router,Switch} from "react-router-dom"
import Homepage from './pages/Homepage'
import Login from './pages/Login'
import Register from './pages/Register'
import RecoverPass from "./pages/RecoverPass";
import {PrivateRoute} from "./adminRefactored/layouts/PrivateRoute";
import {history} from "../utils/history";
import DetaliiClub from "./admin/pages/DetaliiClub";
import Sablon from "./admin/pages/Sablon";
import Preturi from "./admin/pages/Preturi";
import Rezervari from "./admin/pages/Rezervari";
import Terenuri from "./admin/pages/Terenuri";
import Teren from "./admin/pages/Teren";
import AdminClub from "./adminRefactored/pages/admin-club";
import Terms from "./pages/Terms";
import NotFound from "./pages/NotFound";
import Cookies from "./pages/Cookies";
import Confidentiality from "./pages/Confidentiality";
import ActivateAccount from "./pages/ActivateAccount";
import ResetPass from "./pages/ResetPass";
import AdminMain from "./adminRefactored/pages/admin-main";
import ClubTerms from "./pages/ClubTerms";
import Rapoarte from "./admin/pages/Rapoarte";
import Raport from "./admin/pages/Raport";
import Zi from "./admin/pages/Zi";
import RegisterClub from "./pages/RegisterClub";
import BlockRezervariClub from "./admin/pages/BlockRezervariClub";
import FinanciarAdmin from "./admin/pages/FinanciarAdmin";
import Financiar from "./admin/pages/Financiar";
import Payment from "./payment/Payment";
import CancelPayment from './payment/CancelPayment'
import Review from './pages/Review'
import ClubsNew from './ClubsRefactored/ClubsNew'
import ElementPage from './elements/ElementPage'
import ClubPage from './ClubsRefactored/ClubPage'
import LandingPage from './pages/LandingPage'
import UserProfile from "./adminRefactored/pages/user-profile";
import AdminTransactions from "./adminRefactored/pages/admin-tranzactii";
import AdminVouchers from "./adminRefactored/pages/admin-vouchers";
import UserVouchers from "./adminRefactored/pages/user-vouchers";
import UserCards from "./adminRefactored/pages/user-saved-cards";
import UserTransactions from "./adminRefactored/pages/user-transactions";
import UserBookings from "./adminRefactored/pages/user-bookings";
import UserMain from "./adminRefactored/pages/user-main";
import Reviews from "./adminRefactored/pages/user-reviews";
import AdminIntegration from "./adminRefactored/pages/admin-integration";
import AdminAcademy from "./adminRefactored/pages/admin-academy";
import PluginIntegration from "./adminRefactored/pages/admin-integration/PluginIntegration";
import BillingIntegration from "./adminRefactored/pages/admin-integration/BillingIntegration";
import AdminDocuments from "./adminRefactored/pages/admin-documents";
import SocialLogin from "./pages/SocialLogin";

class App extends Component{
    render(){
        return(
            <Router history={history} basename={process.env.PUBLIC_URL}>
                <div>
                    <Switch>
                        <Route exact path="/" title="Booksport" component={Homepage}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/register" component={Register}/>
                        <Route path="/register-club" component={RegisterClub}/>
                        <Route path="/recover-password" component={RecoverPass}/>
                        <Route exact path="/terms" component={Terms}/>
                        <Route exact path="/cookies" component={Cookies}/>
                        <Route exact path="/privacy-policy" component={Confidentiality}/>
                        <Route exact path="/clubs" component={ClubsNew} key={window.location.pathname}/>
                        <Route exact path="/terms-club/:clublocation/:sport" component={ClubTerms} key={window.location.pathname}/>
                        <Route exact path="/token/:token/" component={ActivateAccount} key={window.location.pathname}/>
                        <Route exact path="/resetare-parola/:token/" component={ResetPass} key={window.location.pathname}/>
                        <Route exact path="/validare-cont/:token/" component={ActivateAccount} key={window.location.pathname}/>
                        <Route exact path="/payment" component={Payment} key={window.location.pathname}/>
                        <Route exact path="/anulare" component={CancelPayment} key={window.location.pathname}/>
                        <Route path="/review/:id/:clublocation/:element/:rating" component={Review} key={window.location.pathname}/>
                        <Route path="/promo/:slug/" component={LandingPage} key={window.location.pathname}/>
                        <Route path="/social-login" component={SocialLogin}/>
                        <PrivateRoute exact path='/dashboard/admin-club' title='Admin Club' roles={[0,8,10,20,100]} component={AdminClub}/>
                        <PrivateRoute exact path='/dashboard/admin-main' title='Admin Main' roles={[0,8,10,20,100]} component={AdminMain}/>
                        <PrivateRoute exact path='/dashboard/admin-tranzactii' title='Tranzactii' roles={[0,8,10,20,100]} component={AdminTransactions}/>
                        <PrivateRoute exact path='/dashboard/admin-financiar' title='Financiar' roles={[0,8,10,20,100]} component={FinanciarAdmin}/>
                        <PrivateRoute exact path='/dashboard/admin-vouchers' title='Index' roles={[0,8,10,20,100]} component={AdminVouchers}/>
                        <PrivateRoute exact path='/dashboard/financiar' title='Financiar' roles={[0,8,10,20,100]} component={Financiar}/>
                        <PrivateRoute exact path='/dashboard/tranzactii' title='Tranzactii' roles={[0,8,10,20,100]} component={UserTransactions}/>
                        <PrivateRoute exact path='/dashboard/main' title='Dashboard' roles={[0,8,10,20,100]} component={UserMain}/>
                        <PrivateRoute exact path='/dashboard/bookings' title='Rezervari'roles={[0,8,10,20,100]} component={UserBookings}/>
                        <PrivateRoute exact path='/dashboard/bookings/:id' title='Rezervari' roles={[0,8,10,20,100]} component={UserBookings}/>
                        <PrivateRoute exact path="/dashboard/detalii-club" title='Detalii Club' roles={[0,8,10,20,100]} component={DetaliiClub}/>
                        <PrivateRoute exact path="/dashboard/preturi"  title='Preturi' roles={[0,8,10,20,100]} component={Preturi}/>
                        <PrivateRoute exact path="/dashboard/sablon" title='Sablon orar' roles={[0,8,10,20,100]} component={Sablon}/>
                        <PrivateRoute exact path="/dashboard/rezervari" title='Rezervari' roles={[0,8,10,20,100]} component={Rezervari}/>
                        <PrivateRoute exact path="/dashboard/zi" title='Zi' roles={[0,8,10,20,100]} component={Zi}/>
                        <PrivateRoute exact path="/dashboard/terenuri" title='Terenuri' roles={[0,8,10,20,100]} component={Terenuri}/>
                        <PrivateRoute exact path="/dashboard/teren" title='Teren' roles={[0,8,10,20,100]} component={Teren}/>
                        <PrivateRoute exact path="/dashboard/admin-rapoarte" title='Rapoarte' roles={[0,8,10,20,100]} component={Rapoarte}/>
                        <PrivateRoute exact path="/dashboard/admin-raport" title='Raport' roles={[0,8,10,20,100]} component={Raport}/>
                        <PrivateRoute exact path="/dashboard/user" roles={[0,8,10,20,100]} component={UserProfile}/>
                        <PrivateRoute exact path="/dashboard/blocarerezervari" roles={[0,8,10,20,100]} component={BlockRezervariClub}/>
                        <PrivateRoute exact path="/dashboard/admin-academy" roles={[0,8,10,20,100]} component={AdminAcademy}/>
                        <PrivateRoute exact path="/dashboard/cards" roles={[0,8,10,20,100]} component={UserCards}/>
                        <PrivateRoute exact path="/dashboard/review" title='Review' component={Reviews}/>
                        <PrivateRoute exact path="/dashboard/admin-integrare" title='Integrare' component={AdminIntegration}/>
                        <PrivateRoute exact path="/dashboard/admin-integrare/plugin" title='Integrare' component={PluginIntegration}/>
                        <PrivateRoute exact path="/dashboard/admin-integrare/facturare" title='Integrare' component={BillingIntegration}/>
                        <PrivateRoute exact path="/dashboard/vouchers" title='Vouchere' component={UserVouchers}/>
                        <PrivateRoute exact path="/dashboard/admin-documents" title='Documente' component={AdminDocuments}/>
                        <Route exact path='/404' component={NotFound}/>
                        <Route exact path="/:any" component={ClubPage}/>
                        <Route exact path="/:any/:element" component={ElementPage}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App
