import React from 'react'


const CardProgram = ({schedule}) => {
    schedule = JSON.parse(schedule)
    return(
        <div className="col-md-6 col-lg-12">
                <div className="card-wHead card-schedule">
                    <h3>Program</h3>
                    <ul className="program">
                        {schedule &&  schedule.map(day => {
                            return(
                                <li key={Object.keys(day)}>
                                    <span>{Object.keys(day)}</span>
                                    <span style={{marginLeft:'auto'}}>{day[Object.keys(day)]}</span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
        </div>
    )
}


export default CardProgram;
