import React, {useState, useEffect} from 'react'
import TopNavAdmin from '../../../adminRefactored/components/navigations/TopNavAdmin'
import cssimage from '../../../../resources/images/head_booksport.png'
import jsimage from '../../../../resources/images/body_booksport.png'
import example from '../../../../resources/images/exemplu-plugin.png'
import booksport from '../../../../api/booksport'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { HexColorPicker, HexColorInput } from "react-colorful";

const PluginIntegration = () => {
    const [clubs, setClubs] = useState([])
    const [clubselected, setClubselected] = useState()
    const [color, setColor] = useState("#12803c");
    const css = '<link rel="stylesheet" href="https://img.booksport.ro/media/booksport.css">'
    const head = '</head>'
    const body = '</body>'
    const js = '<script src="https://img.booksport.ro/media/booksport.js"></script>'
    const animatedComponents = makeAnimated();

    useEffect(() => {
        getClubs()
    },[])

    const getClubs = () =>{
        let q = {
            token: localStorage.getItem('jwtToken')
        }
        booksport.get('/clubs', q)
            .then(res => {
                const clubArray = []
                res.data.map(club => {
                    clubArray.push({label: club.club_id.name+' '+club.name, value: club.clublocation_id+'_'+club.slug})
                })
                setClubs(clubArray)
                setClubselected(clubArray[0])
            })
    }

    const copyCode = () => {
        navigator.clipboard.writeText(booksportDiv)
        alert('codul html a fost copiat')
    }

    const booksportDiv = `<div id="booksport" club="${clubselected && clubselected.value.split('_')[0]}" slug="${clubselected && clubselected.value.split('_')[1]}" color="${color}"></div>`
    return (
        <div className="main-panel">
            <TopNavAdmin title= 'Integrare plugin Booksport'/>
            <div className="content p-0-mobile">
                <div className="container-fluid">
                    <h3>Pluginul Booksport iți oferă posibilitatea de a afișa în timp real pe site-ul tău orele disponibile pentru rezervări în cadrul platformei.</h3>
                    <p>Integrarea pluginului se face parcurgând următorii pași simpli:</p>
                    <ul>
                        <li>Selectează clubul tău</li>
                        <li>Selectează culoarea dorită pentru a păstra uniformitatea designului site-ului</li>
                        <li>Adaugă în codul html al site-ului, înaintea tag-ului <span className='fw-600'>{head}</span> codul : <span className='fw-600'>{css}</span></li>
                        <li>Adaugă în codul html al site-ului, înaintea tag-ului <span className='fw-600'>{body}</span> codul : <span className='fw-600'>{js}</span></li>
                        <li>Adaugă în pagina, acolo unde dorești să apară plugin-ul, codul generat mai jos în urma selectării clubului și a culorii</li>
                    </ul>
                    <div className="row">
                        <div className="col-12 mt-4">
                            <p className='fw-600'>Exemplu de afișare in site-ul tău</p>
                            <img src={example} alt="Exemplu" className='img-fluid' />
                        </div>
                        <div className="col-12">
                            <p className='fw-600'>Selectează clubul</p>
                            {clubs &&
                                <Select
                                    isSearchable={ false }
                                    options={clubs}
                                    className="w-100"
                                    onChange = {(selectedOption) => setClubselected(selectedOption)}
                                    components={animatedComponents}
                                    placeholder={'Selecteaza clubul'}
                                    value ={clubselected}
                                />}
                        </div>
                        <div className="col-12 mt-5">
                            <p className='fw-600'>Alege culoarea</p>
                            <div className="color-picked">
                                <HexColorInput color={color} onChange={setColor} prefixed />
                                <HexColorPicker color={color} onChange={setColor} />
                            </div>
                        </div>

                        <div className="col-12 mt-5">
                            <p className='fw-600'>Copiază codul generat mai jos și adaugă-l în pagina web în locul în care dorești ca pluginul sa fie afișat</p>
                            <p className='fw-600 code-copy'>{booksportDiv} <span className='copy-code' onClick={() => copyCode()}>Copiază</span></p>
                        </div>

                        <div className="col-12 mt-5">
                            <p className='fw-600'>Exemplu adăugare cod css</p>
                            <img className='img-fluid' src={cssimage} alt="Css code" />
                        </div>
                        <div className="col-12 mt-5">
                            <p className='fw-600'>Exemplu adăugare cod js</p>
                            <img className='img-fluid' src={jsimage} alt="Js code" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PluginIntegration
