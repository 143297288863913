import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware, compose} from 'redux'
import reduxThunk from 'redux-thunk'
import jwt from 'jsonwebtoken'

import App from './components/App'
import reducers from './store/reducers'
import setAuthorizationToken from "./utils/setAuthorizationToken";
import {setCurrentUser} from "./store/actions/userActions";
import HttpsRedirect from 'react-https-redirect';
import * as Sentry from "@sentry/react";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(reduxThunk))
)

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


if(localStorage.jwtToken){
    setAuthorizationToken(localStorage.jwtToken)
    store.dispatch(setCurrentUser(jwt.decode(localStorage.jwtToken)))
}else{
    store.dispatch(setCurrentUser({}))
}

ReactDOM.render(
    <Provider store={store} >
        <HttpsRedirect>
            <App/>
        </HttpsRedirect>
    </Provider>,

    document.querySelector('#root')
)
