import React,{ useState } from 'react';
import StarRatings from 'react-star-ratings';
import { history } from '../../../../../utils/history';
import IconExpired from '../../../../../resources/images/expired.png';

const ExpiredBookingCard = ({booking}) => {
    const [rate, setRate] = useState(0)

    const changeRating = (newRating) => {
        setRate(newRating)
        history.push(`/review/${booking.secureid}/0/0/${newRating}`)
      }

     const footerStyle = booking.review !== 1 ? {marginBottom: 70} : {marginBottom : 0}

    return (
        <div key={booking.secureid} className="col-md-6 col-xl-4" style={{display: 'flex', flexWrap: 'wrap'}}>
        <div className="card card-rezervare" style={{flex: 1}}>
            <div className="card-band-header expirat">Inactiv</div>
            <div className="wrapper-rez-infos">
                <div style={{height: '240px', width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={IconExpired} className="w-100" style={{maxWidth: '120px'}} alt="unpaid"/>
                </div>
                <div className="right" style={{ ...footerStyle, minHeight: 372 }}>
                    <h4>{booking.club} - {booking.sport}</h4>
                    <h5>{booking.item}</h5>
                    <ul>
                        <li>Data  <span>{booking.dataora.replace(/ .*/, '')}</span></li>
                        <li>Ora  <span>{booking.dataora.split(" ").splice(-1)}</span></li>
                        <li>Item rezervat  <span>{booking.element}</span></li>
                        <li>Durata  <span>{booking.minutes} min</span></li>
                        <li>Pret  <span>{booking.price} RON</span></li>
                        <li>Id rezervare  <span>{booking.id}</span></li>
                    </ul>
                    {booking.review === 1 ?
                    <div className="rating mt-3 d-flex align-items-start justify-content-sm-between" style={{minHeight: "70px", marginBottom: 0}}>
                        <p className='m-0 mr-2'>Adaugă un review</p>
                        <span style={{marginRight: '-10px', marginLeft: 'auto'}}>
                            <StarRatings
                                rating={parseFloat(rate)}
                                starRatedColor="#f9bf3b"
                                changeRating={changeRating}
                                numberOfStars={5}
                                name='rating'
                                starDimension="30px"
                                starHoverColor="#f9bf3b"
                                starSpacing='0px'
                            />
                        </span>
                    </div> : false }
                </div>
            </div>
        </div>
    </div>
    )
}

export default ExpiredBookingCard
