import * as React from "react";

export const RenderBills = ({params}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 15}}>
            <div>{params?.row?.nr_comanda}</div>
            {params.row.facturi.length > 0 && params.row.facturi.map(bill => {
                return (
                    <a key={bill.nrfact} href={bill.url} target='_blank' style={{marginLeft: -10}}>
                        <i className="material-icons" style={{fontSize:'13px'}}>get_app</i>
                        {bill.nrfact}
                    </a>
                )
            })}
        </div>
    );
};
