import React,{Component} from 'react'
import { connect } from 'react-redux'
import {closeModal} from "../../../store/actions/modalActions";
import {refreshDay} from "../../../store/actions/refreshDayR";
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import booksport from "../../../api/booksport";
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Switch from "react-switch";
import spinner from "../../../resources/images/loading.gif";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import jwt from "jsonwebtoken";


class ModalElementRezervari extends Component{

  constructor(props){
    super(props)
    this.state = {
      pret: null,
      durata : null,
      status: 2,
      detalii: null,
      error: null,
      succes: false,
      inputValue: null,
      durataobj: null,
      reqobj : null,
      elemreloaded: null,
      colorschema_id : 1,
      recurenta: false,
      minut: null,
      item_id: null,
      selectedOption : null,
      loading:false,
      colors: null
    }
  }

  getcolors = () => {
    const q = {
      token : localStorage.getItem('jwtToken'),
      clublocation_id: this.props.showModal.showModal.location.clublocation_id
    }
    booksport.get('/getcolors',  {params: q})
    .then((res) =>
        this.setState({colors: res.data})
      )
  }

  componentDidMount(){
    this.getcolors()
  }

  closeModal=()=>{
    this.props.closeModal()
    this.refreshDay(false)
  }

  refreshDay=(day)=>{
    this.props.refreshDay(day)
  }


  handleRadioSelect = (event) =>{
    this.setState({
      status : event.target.value
    })
  }

  getValues = (e) => {
    const token = jwt.decode(localStorage.jwtToken,{complete: true});
    var dateNow = new Date().getTime();
    if(token && token.payload.exp < dateNow/1000){
      localStorage.removeItem('jwtToken')
      localStorage.removeItem('usInf')
      return <Redirect to={{ pathname: '/login'}} />
    }
    this.setState({loading: true})
    e.preventDefault();
    var userInfo = JSON.parse(atob(localStorage.getItem('usInf')))
    let element = {
      token: localStorage.getItem('jwtToken'),
      sport_id: this.props.showModal.showModal.location.sport_id,
      clublocation_id: this.props.showModal.showModal.location.clublocation_id,
      element_id: this.props.element.id,
      item_id: this.refs.pret.state.value.value,
      dataora: this.props.showModal.showModal.day_nr + ' ' + this.props.hour + ':' + parseInt(this.refs.min.value),
      minutes : parseInt(this.refs.durata.state.value.value),
      notes: this.refs.details.value,
      element_status_id: this.state.status ? parseInt(this.state.status) : parseInt(this.refs.status_id.value),
      user_id: userInfo.id,
      recurent: this.refs.recurenta ? this.refs.recurenta.state.value : 0
    }
    if(this.state.status === '1' || parseInt(this.state.status) === 1){
      element={
        token: localStorage.getItem('jwtToken'),
        scheduleid :this.refs.elem_id.value,
        clublocation_id: this.props.showModal.showModal.location.clublocation_id,
      }
      booksport.delete('/schedule', {params: element})
        .then((res) =>
          this.setState({succes : true, error: ''})
        )
        .then(
          setTimeout(() => {
            this.reqElement()
            this.setState({
              succes:false,
              loading: false
            })
          }, 1000)
        )
        .catch( (err) =>
          this.setState({ succes : false, error : err.response.data})
        )
    } else{
      let elem = {
        token: localStorage.getItem('jwtToken'),
        sport_id: this.props.showModal.showModal.location.sport_id,
        clublocation_id: this.props.showModal.showModal.location.clublocation_id,
        element_id: this.props.element.id,
        item_id: this.refs.pret.state.value.value,
        dataora: this.props.showModal.showModal.day_nr + ' ' + this.props.hour + ':' + parseInt(this.refs.min.value),
        minutes : parseInt(this.refs.durata.state.value.value),
        notes: this.refs.details.value,
        element_status_id: this.state.status ? parseInt(this.state.status) : 2,
        user_id: userInfo.id,
        recurent: this.refs.recurenta ? this.refs.recurenta.state.value : 0,
        schedule_source_id : 2,
        colorschema_id: parseInt(this.state.colorschema_id),
        avans: 0
      }
      booksport.post('/schedule/store', elem)
        .then((res) =>
          this.setState({
            succes : true, error: ''
          })
        )
        .then(
          setTimeout(() => {
            if(this.state.recurenta){
              this.refs.recurenta.state.value !== null && this.refs.recurenta.state.value.map(reqday => {
                this.refreshDay(reqday.value)
              })
              this.reqElement()
            }else{
              this.reqElement()
            }
            this.setState({
              succes:false,
              loading: false
            })
          }, 1000)
        )
        .catch( (err) =>
          this.setState({ error : err.response.data})
        )
    }
  }

  reqElement = () => {
    let element = {
      token: localStorage.getItem('jwtToken'),
      sport_id: this.props.showModal.showModal.location.sport_id,
      clublocation_id: this.props.showModal.showModal.location.clublocation_id,
      element_id: this.props.element.id,
      dataora: this.props.showModal.showModal.day_nr,
      hour: this.props.hour,
    }

    booksport.get('/getscheduleelement',  {params: element})
      .then((res) =>
        this.setState({ elemreloaded : res.data.elements})
      )
      .then(
        setTimeout(() => {
          this.setState({
            succes:false
          })
        }, 1000)
      )
      .catch( (err) =>
        this.setState({ error : err.response})
      )
    if(this.props.ref_teren ){
      this.refreshDay(this.props.ref_teren)
    }else{
      this.refreshDay(this.props.day_nr)
    }
  }
  handleChangeSelect = (minut, id) => selectedOption => {
    let element = {
      token: localStorage.getItem('jwtToken'),
      item_id: selectedOption.value,
      time: this.props.hour + ':' + minut,
      day: this.props.showModal.showModal.day_nr,
      sport_id:this.props.showModal.showModal.location.sport_id,
      clublocation_id:this.props.showModal.showModal.location.clublocation_id,
      element_id:this.props.element.id,
      id: id ? id : 0
    }

    booksport.get('/getprices',  {params: element})
      .then((res) =>
        this.setState({
          durataobj:res.data
        })
      )
      .then(
        setTimeout(() => {
          this.setState({
            succes:false
          })
        }, 1000)
      )
      .catch( (err) =>
        this.setState({ error : err.response})
      )

  };
    setrecurenta = (recurenta) => {
      this.getRecurentaSelectD(this.state.minut, this.state.item_id, this.state.selectedOption, recurenta)
    }

  getRecurentaSelectD = (minut, id, selectedOption, recurenta) => {
    this.setState({minut: minut, item_id:id, selectedOption, recurenta})

    let element = {
      token: localStorage.getItem('jwtToken'),
      item_id: id,
      day: this.props.showModal.showModal.day_nr,
      hour: this.props.hour,
      min: minut,
      minutes: selectedOption.value,
      sport_id:this.props.showModal.showModal.location.sport_id,
      clublocation_id:this.props.showModal.showModal.location.clublocation_id,
      element_id:this.props.element.id,
      tiprecurenta: recurenta ? 1 : 0,
    }

    booksport.get('/getrecurenta',  {params: element})
        .then((res) =>
            this.setState({
              reqobj:res.data
            })
        )
        .then(
            setTimeout(() => {
              this.setState({
                succes:false
              })
            }, 1000)
        )
        .catch( (err) =>
            this.setState({ error : err.response})
        )

  };

  getRecurentaSelect = (minut, id) => selectedOption => {
    this.setState({minut: minut, item_id:id, selectedOption})
    let element = {
      token: localStorage.getItem('jwtToken'),
      item_id: id,
      day: this.props.showModal.showModal.day_nr,
      hour: this.props.hour,
      min: minut,
      minutes: selectedOption.value,
      sport_id:this.props.showModal.showModal.location.sport_id,
      clublocation_id:this.props.showModal.showModal.location.clublocation_id,
      element_id:this.props.element.id,
      tiprecurenta: 0
    }
    booksport.get('/getrecurenta',  {params: element})
      .then((res) =>
        this.setState({
          reqobj:res.data
        })
      )
      .then(
        setTimeout(() => {
          this.setState({
            succes:false
          })
        }, 1000)
      )
      .catch( (err) =>
        this.setState({ error : err.response})
      )

  };
  setcolor = (value, clublocation, order, status) => selected => {
    let elem = {
      token: localStorage.getItem('jwtToken'),
      clublocation_id: clublocation,
      colorschema_id: value,
      order_id : order
    }

    this.setState({colorschema_id: value})
    if(status === 1){
      booksport.get('/colors', {params: elem})
          .then((res) =>
              this.setState({
                succes : true, error: ''
              })
          )
          .then(
              setTimeout(() => {
                this.reqElement()
                this.setState({
                  succes:false
                })
              }, 1000)
          )
          .catch( (err) =>
              this.setState({ error : err.response.data})
          )
    }
  }
  render(){
    const animatedComponents = makeAnimated();
    let durata = []
    if(this.state.durataobj !== null){
      Object.entries(this.state.durataobj).map(([key, value]) => {
        durata.push({ label: key + ' minute : ' + value.full + ' RON' , value: key },)
        return durata
      })
    }

    let recurenta = []
    if(this.state.reqobj !== null){
      Object.entries(this.state.reqobj).map(([key, value]) => {
        recurenta.push({
          label: moment(value.data).format('DD MMMM YYYY'),
          value: value.data,
          isdisabled: !value.valid,
          isFixed: parseInt(key) === 0
        },)
        return recurenta
      })
    }

    let element = this.props.element
    const preturi = [];
    element.items.map(item => {
      return(
          preturi.push({ label: item.name, value: item.id },)
      )
    })

    if(this.state.elemreloaded !== null && this.state.elemreloaded[0]){
      element = this.state.elemreloaded[0]
    }

      let clasacard = 'col-md-6'

      if(this.props.from && this.props.from === 'teren'){
        clasacard = 'col-12'
      }

    const currentDate = moment().subtract(1, "days")
    const userType = parseInt(JSON.parse(atob(localStorage.getItem('usInf'))).user_type)
    let delrights =  false
    if(currentDate >= moment(this.props.day_nr)){
      if(userType >=20){
        delrights = true
      }
    }else{
      if(userType >= 10){
        delrights = true
      }
    }

      return(
      <div className={clasacard}>
        <div className="card cart-teren">
          <div className="card-header card-header-teren text-center">
            <h4 className='m-0'>{element.name}</h4>
          </div>
          <Tabs>
            <TabList>
              {element.min.map(min => {
                return(
                  <Tab
                    key={element.name + min.minut_curent}
                    className={min.min_detail !== null ? 'red react-tabs__tab' : 'react-tabs__tab'}
                    activ={(min.status!== 0 && this.props.hour !== min.hour) || (min.status!== 0 && min.min !== min.minut_curent) ? 'inactiv' : ''}
                  >
                    {this.props.hour}:{min.minut_curent === 0 ? '00' : min.minut_curent}
                  </Tab>
                )
              })}
            </TabList>

            {element.min.map(min => {
              let status = 0;
              if(min.status !== 0){
                status = 1
              }
              let selectedPrice = {label: 'Selecteaza o optiune de rezervare', value:preturi[0].value}
              if(min.item_id || min.item_id !== null){
                preturi.map(pret => {
                  if(parseInt(min.item_id) === parseInt(pret.value)){
                    return(
                        selectedPrice = {label: pret.label, value:pret.value}
                    )
                  }
                })
              }
              this.handleChangeSelect(min.minut_curent, min.id)
              return(
                <TabPanel key={element.name + min.minut_curent}>
                  <form
                    className={min.min_detail !== null ? 'element-modal rezervat' : 'element-modal'}
                    activ={(min.status!== 0 && this.props.hour !== min.hour) || (min.status!== 0 && min.min !== min.minut_curent) ? 'inactiv' : ''}
                    onSubmit={this.getValues}
                  >

                    {this.state.succes ? <p className="element-succes"><i className="material-icons icon-image-preview">done</i>Am salvat cu succes</p> : ''}
                    {min.min_detail !== null && min.bkgcolor !== null && min.textcolor !== null ? <p className="element-rezervat" style={ { background: min.bkgcolor, color: min.textcolor }}> </p> : ''}
                    {(min.status!== 0 && this.props.hour !== min.hour) || (min.status!== 0 && min.min !== min.minut_curent) ? <p className="element-succes text-danger"><i className="material-icons icon-image-preview">clear</i>Rezervarea activa incepe la ora ora {min.hour}:{min.min === 0 ? min.min + '0' : min.min}</p> : ''}
                    <input type="hidden" value={min.minut_curent} name="min" ref='min'/>
                    <input type="hidden" value={min.id && min.id ? min.id : 1} name="elem_id" ref='elem_id'/>
                    <input type="hidden" value={min.id ? 'update' : 'store'} name="update" ref='update'/>
                    <input type="hidden" value={min && min.element_status_id ? min.element_status_id : 1} name="status_id" ref='status_id'/>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group pt-2 mt-2">
                          <textarea key={min.notes}  className="form-control field-details"  ref='details' defaultValue={min.notes ? min.notes : ''} placeholder="Adauga detalii" disabled={!delrights} ></textarea>
                          <div className="err text-danger mt-1"> { this.state.error &&  JSON.parse(this.state.error).notes ? JSON.parse(this.state.error).notes : ''}</div>
                        </div>
                      </div>
                      {delrights ?
                          <div className="col-12">
                            <div className="rez-colors">
                              {this.state.colors && this.state.colors.map(color => {
                                return(
                                  <div style={{backgroundColor: color.bkgcolor, color: color.textcolor}} className={this.state.colorschema_id === color.id ? "btn active" : "btn"} onClick={this.setcolor(color.id, min.clublocation_id, min.id, status)}>••</div>
                                )
                              })}
                            </div>
                          </div> : ''
                      }
                      <div className="col-12">
                        <div className="form-group mt-0">
                          <Select
                            isDisabled= {!delrights}
                            isSearchable={ false }
                            options={preturi}
                            className="w-100"
                            ref='pret'
                            key = { min.item_id ? min.item_id : ''}
                            onChange={this.handleChangeSelect(min.minut_curent, min.id)}
                            components={animatedComponents}
                            isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
                            defaultValue={ selectedPrice } />
                          <div className="err text-danger mt-1"> { this.state.error &&  JSON.parse(this.state.error).item_id ? JSON.parse(this.state.error).item_id : ''}</div>
                        </div>
                      </div>
                      {this.state.durataobj !== null || min.min_detail !== null?
                        <div className="col-12">
                          <div className="form-group mt-0">
                            <Select
                              isSearchable={ false }
                              isDisabled= {false}
                              options={durata}
                              ref='durata'
                              key = { min.item_id ? min.item_id : ''}
                              onChange={this.getRecurentaSelect(min.minut_curent, min && min.item_id ? min.item_id :  preturi[0].value)}
                              defaultValue ={ { label: min && min.minutes ? min.minutes + ' min ' : 'Durata rezervarii', value: min && min.minutes ? min.minutes : 0 } }
                              className="w-100"
                              components={animatedComponents}
                            />
                            <div className="err text-danger mt-1"> { this.state.error &&  JSON.parse(this.state.error).minutes ? JSON.parse(this.state.error).minutes : ''}</div>
                          </div>
                        </div> : '' }
                      {this.state.reqobj !== null && min.min_detail === null?
                        <div className="col-12">
                          <div className="req-switch d-flex align-items-center justify-content-center mb-2 font-weight-bold">
                            <span className={this.state.recurenta ? 'd-inline-block text-center' : 'd-inline-block text-success text-center'}>Recurenta saptamanala</span>
                            <label className="mb-0">
                              <Switch
                                  onChange={this.setrecurenta}
                                  checked={this.state.recurenta}
                                  handleDiameter = {20}
                                  height={20}
                                  width={50}
                                  uncheckedIcon = {false}
                                  checkedIcon={false}
                                  className= "switch-recurenta"
                              />
                            </label>
                            <span className={this.state.recurenta ? 'd-inline-block text-success text-center' : 'd-inline-block  text-center'}>Recurenta zilnica</span>
                          </div>
                          <div className="form-group mt-0">
                            <Select
                              isDisabled= {false}
                              closeMenuOnSelect={false}
                              isSearchable={ false }
                              options={recurenta}
                              ref='recurenta'
                              key = { min.item_id ? min.item_id : ''}
                              onChange={this.handleChange}
                              className="w-100"
                              components={animatedComponents}
                              isMulti = {true}
                              autoBlur={false}
                              placeholder = {this.state.recurenta ? 'Rezerva recurent zilnic' : 'Rezerva recurent saptamanal'}
                              isOptionDisabled={(option) => option.isdisabled}
                            />
                            <div className="err text-danger mt-1"> { this.state.error &&  JSON.parse(this.state.error).minutes ? JSON.parse(this.state.error).minutes : ''}</div>
                          </div>
                        </div> : '' }
                    </div>
                    <div className="row mb-5">
                      {min.status !== 0 && parseInt(min.schedule_source_id) !== 1 ?
                        <div className="col-3">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input className="form-check-input" disabled={!delrights} type="radio" value={1} ref="status"  onChange={e => this.handleRadioSelect(e)}  defaultChecked = { min.min_detail === null ? 'defaultChecked' : ''}
                                     name={'status'+element.name}/>
                              Liber
                              <span className="circle">
                                            <span className="check"></span>
                                        </span>
                            </label>
                          </div>
                        </div> : ''
                      }
                      {parseInt(min.schedule_source_id) !== 1 ?
                      <div className="col-4">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input className="form-check-input" disabled={!delrights} value={2} type="radio" ref="status" onChange={e => this.handleRadioSelect(e)} defaultChecked = { (min && min.element_status_id === 2) || this.state.status === 2 ? 'defaultChecked' : ''} name={'status'+element.name}/>
                            Rezervat
                            <span className="circle">
                                            <span className="check"></span>
                                        </span>
                          </label>
                        </div>
                      </div>
                          : ' ' }
                      {parseInt(min.schedule_source_id) !== 1 ?
                      <div className="col-5">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input className="form-check-input" disabled={!delrights} value={3} type="radio" ref="status" onChange={e => this.handleRadioSelect(e)} defaultChecked = { min && min.element_status_id === 3 ? 'defaultChecked' : ''}
                                   name={'status'+element.name} />
                            Indisponibil
                            <span className="circle">
                                            <span className="check"></span>
                                        </span>
                          </label>
                        </div>
                      </div>
                          : ' ' }
                    </div>
                    <div className="err text-danger mt-1"> { this.state.error &&  JSON.parse(this.state.error).error ? JSON.parse(this.state.error).error : ' '}</div>
                     <div className="err text-danger mt-1"> { this.state.error &&  JSON.parse(this.state.error).status ? JSON.parse(this.state.error).status : ' '}</div>
                    {parseInt(min.schedule_source_id) !== 1 ?
                        <div className="card-footer text-right mr-0">
                          <button className="btn btn-danger mr-2 ml-auto" onClick={this.closeModal}>Inchide
                            <div className="ripple-container"></div>
                          </button>
                          {delrights ?
                              <button className="btn btn-success" type="submit" > {this.state.loading ? <img style={{width:20}} src={spinner} alt="loading"/> : 'Salveaza' }
                                <div className="ripple-container"></div>
                              </button> : false
                          }
                        </div> : <div className="text-center mb-5">Rezervarile din aplicatie nu pot fi modificate</div>
                    }
                  </form>
                </TabPanel>
              )
            })}
          </Tabs>
        </div>
      </div>
    )
  }
}

const mapStateToProps =(state) =>{
  return {
    showModal: state.showModal,
    refreshDay: state.refreshDay
  }
}

export default connect(mapStateToProps,{closeModal,refreshDay,}) (ModalElementRezervari)
