import React, {Component, lazy, Suspense} from 'react'
import {connect} from 'react-redux'
import {getClubsByToken} from "../../../store/actions/userActions";
import {Link} from "react-router-dom";

const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));
const renderLoader = () => <p>Loading</p>;

class Rapoarte extends Component{
    constructor(props){
        super(props)
        this.state = {
            club: null,
            sporturi: null,
        }
    }

    componentDidMount(){
        this.props.getClubsByToken()
        document.title = 'Booksport - Rapoarte'
    }


    render(){
        return(
            <div className="main-panel">
                <Suspense fallback={renderLoader()}>
                    <TopNavAdmin title= {'Rapoarte'}/>
                </Suspense>
                <div className="content p-0-mobile">
                    <div className="container-fluid">
                        <div className="row">
                            {this.props.clubs  && this.props.clubs.map(club => {
                                return(
                                    <div className="col-12 col-md-6 col-xl-4" key={club.name + club.club_id.name}>
                                        <div className="card card-stats card-admin mt-0 mb-4">
                                                <div className="card-header card-header-success card-header-icon teren-card d-block">
                                                    <h5 className="card-title mt-4 mb-0 text-capitalize mh-5">{club.club_id.name + ' - ' + club.name}</h5>
                                                    <div className="row">
                                                        {club.sports.length === 1 ?
                                                            <div className="col-12 col-md-6 offset-md-3 text-center">
                                                                    <Link to={{
                                                                        pathname: 'admin-raport',
                                                                        state: { sport_id:club.sports[0].id, clublocation_id:club.clublocation_id, club:club }
                                                                    }}>
                                                                        <div className="btn btn-success w-100 mb-4 tw-btn">
                                                                            <h5 className="card-title p-0 text-capitalize">{club.sports[0].name}</h5>
                                                                        </div>
                                                                    </Link>
                                                            </div> :

                                                            club.sports.map(sport => {
                                                                return(
                                                                    <div className="col-6 text-center" key={sport.name}>
                                                                            <Link to={{
                                                                                pathname: 'admin-raport',
                                                                                state: { sport_id:sport.id, clublocation_id:club.clublocation_id, club:club }
                                                                            }}>
                                                                                <div className="btn btn-success w-100 mb-4 tw-btn">
                                                                                    <h5 className="card-title p-0 text-capitalize">{sport.name}</h5>
                                                                                </div>
                                                                            </Link>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        clubs: state.userStatus.clubs
    }
}

export default connect(mapStateToProps, {getClubsByToken}) (Rapoarte)
