import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, TextField, IconButton, InputAdornment, Checkbox, FormControlLabel } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import booksport from "../../api/booksport";
import RegistrationConfirm from "../panels/RegistrationConfirm";
import {CustomInput} from "../adminRefactored/components/reusable/CustomInput";
import {CustomCheckbox} from "../adminRefactored/components/reusable/CustomCheckbox";
const RegisterForm = ({ status }) => {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const handleErrors = (fieldName) => {
        if (error) {
            const errorObject = JSON.parse(error);
            if (errorObject[fieldName]) {
                return (
                    <div className="has-danger text-danger" style={{ fontSize: '12px', marginTop: '-10px' }}>
                        {errorObject[fieldName].map(errorMessage => (
                            <div>{errorMessage}</div>
                        ))}
                    </div>
                );
            }
        }
        return null;
    };

    const onSubmit = async (values) => {
        window.dataLayer.push({
            event: 'register'
        });
        setLoading(true);
        try {
            await booksport.post('/register', values);
            setSuccess(true);
        } catch (err) {
            setError(err.response.data);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                password_confirmation: '',
                terms: false
            }}
            validationSchema={Yup.object({
                email: Yup.string().email('Adresa de email nu este validă').required('Introdu adresa de email'),
                password: Yup.string().required('Introdu parola'),
                password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Parolele introduse sunt diferite').required('Introdu parola'),
                terms: Yup.boolean().oneOf([true], 'Termenii și condițiile sunt obligatorii').required('Termenii și condițiile sunt obligatorii')
            })}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, values, handleChange, errors, touched }) => (
                <Form className="form">
                    {!success ? (
                        <div className="card card-login">
                            <div className="card-header p-0">
                                <div className="bg-gradient-info shadow-info border-radius-lg py-3 text-center py-4"
                                     style={{ backgroundImage: 'linear-gradient(to bottom, #12803c, #4CAF50)', borderRadius: '0.5rem' }}>
                                    <h4 className="font-weight-bolder text-white mt-1 mb-0" style={{ fontSize: 18, fontWeight: 600, letterSpacing: 1 }}>Înregistrare</h4>
                                    <p className="mb-1 text-sm text-white">Bine ai venit!</p>
                                </div>
                            </div>
                            <div className="card-body pl-3 pr-3">
                                <div className="mt-3">
                                    <Field
                                        as={CustomInput}
                                        type="email"
                                        name="email"
                                        label="Email"
                                        focusColor='#12803c'
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="email" /></span>}
                                    />
                                    {handleErrors("email")}
                                    <Field
                                        as={CustomInput}
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        label="Parolă"
                                        focusColor='#12803c'
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="password" /></span>}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {handleErrors("password")}
                                    <Field
                                        as={CustomInput}
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="password_confirmation"
                                        label="Repetă parola"
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        focusColor='#12803c'
                                        helperText={<span style={{ color: 'red', fontSize: '0.75rem' }}><ErrorMessage name="password_confirmation" /></span>}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        edge="end"
                                                    >
                                                        {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {handleErrors("password_confirmation")}
                                    <FormControlLabel
                                        control={
                                            <CustomCheckbox
                                                name="terms"
                                                checked={values.terms}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={
                                            <span style={{ fontSize: 14 }}>
                                            Sunt de acord cu <Link to="/terms">termenii și condițiile</Link>
                                        </span>
                                        }
                                    />
                                    {errors && touched && errors.terms && touched.terms && (
                                        <div style={{ color: 'red', fontSize: 12, marginTop: '-10px' }}>{errors.terms}</div>
                                    )}
                                    {handleErrors("terms")}
                                    {handleErrors()}
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                        disabled={loading}
                                        fullWidth
                                        style={{ height: '45px', marginBottom: 30, marginTop: 20, backgroundColor: "#12803c" }}
                                    >
                                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Creează cont'}
                                    </Button>
                                    <p className="text-center mb-4">Ai deja cont? Loghează-te <Link to="/login">aici</Link></p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <RegistrationConfirm />
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default RegisterForm;
