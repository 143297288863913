
const initialState = {
    county: null,
    city: null,
    countyPJ: null,
    cityPJ: null,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {...state, res:action.payload}
        case 'REGISTER':
            return {...state, res:action.payload}
        case 'REGISTER_CLUB':
            return {...state, res:action.payload}
        case 'RECOVER_PASS':
            return {...state, res:action.payload}
        case 'RESET_ERRORS':
            return {...state, res:action.payload}
        case 'SET_ROLES':
            return {...state, res:action.payload}
        case 'CLUBS':
            return {...state, clubs:action.payload}
        case'SET_COUNTY':
            return {...state, county: action.payload.county}
        case'SET_CITY':
            return {...state, city: action.payload.city}
        case'SET_COUNTY_PJ':
            return {...state, countyPJ: action.payload.county}
        case'SET_CITY_PJ':
            return {...state, cityPJ: action.payload.city}
        case 'SHOW_LOADER_WINDOW':
            return {...state, loaderWindow:action.payload}
        default :
            return state
    }
}
