import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../navs/Navbar';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import booksport from '../../api/booksport';
import loginbk from "../../resources/images/login-bk.jpg";
import ClubDescription from './components/ClubDescription';
import CardMapClub from '../cards/CardMapClub';
import CardAmenities from '../cards/CardAmenities';
import CardProgram from '../cards/CardProgram';
import Footer from '../reusable/Footer';
import ClubTable from './components/ClubTable';
import AvailableHoursClub from './components/AvailableHoursClub';
import ElementsSlider from '../elements/ElementsSlider';
import ModalBooking from "../modals/ModalBooking";
import DateCarousel from "./components/ClubDateCarousel";
import ClubDatePicker from "./components/ClubDatePicker";
import queryString from "query-string";
import dayjs from 'dayjs';
import {Typography} from "@mui/material";

const ClubPage = () => {
    const location = useLocation();
    const { any, element } = useParams();
    const [clubDetails, setclubDetails] = useState();
    const history = useHistory();
    const today = dayjs().startOf('day');
    const queryParams = queryString.parse(location.search);
    const date = queryParams.date ? dayjs(queryParams.date, 'YYYY-MM-DD', true) : today;

    const updateQueryParams = useCallback((date) => {
        const newDate = date.format('YYYY-MM-DD');
        const currentParams = queryString.parse(location.search);
        const newParams = { ...currentParams, date: newDate };
        const newQueryString = queryString.stringify(newParams);
        history.replace(`${location.pathname}?${newQueryString}`);
    }, [history, location.pathname, location.search]);

    useEffect(() => {
        const minDate = today;
        const maxDate = today.add(14, 'day');

        if (!date.isValid() || date.isBefore(minDate, 'day') || date.isAfter(maxDate, 'day') || !queryParams.date) {
            updateQueryParams(today);
        }
    }, [date, queryParams.date, today, updateQueryParams]);

    useEffect(() => {
        getElementFromSlug();
    }, [any, element]);

    useEffect(() => {
        if (clubDetails) {
            window.dataLayer.push({
                event: 'pageview',
                page: {
                    url: window.location.pathname,
                    title: `${clubDetails.club_name} ${clubDetails.location_name}`
                }
            });
        }
    }, [clubDetails]);

    const getElementFromSlug = () => {
        const q = {
            slug: any,
            element_slug: element
        };
        booksport.get('/getclub_from_slug', { params: q })
            .then(res => {
                if(!res.data.clublocation_id){
                    window.location.href = '/404';
                } else {
                    const q = {
                        clublocation_id: res.data.clublocation_id,
                        sport_id: res.data.sport_id,
                        element_id: res.data.element_id
                    };
                    booksport.get('/getclubdetails_for_site', { params: q })
                        .then(response => {
                            setclubDetails(response.data);
                        })
                        .catch(err => console.log(err));
                }
            })
            .catch(err => {
                window.location.href = '/404';
            });
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Booksport - ${clubDetails?.club_name} ${clubDetails?.location_name}`}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={`${clubDetails?.description}`} />
                <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card, teren" />
                <meta property="og:site_name" content="booksport.ro" />
                <meta property="og:title" content={`${clubDetails?.club_name} ${clubDetails?.location_name}`} />
                <meta property="og:description" content={`${clubDetails?.description}`} />
            </Helmet>

            <Navbar class="club"/>
            <div className="headClub"  style={{background: `url(${clubDetails && clubDetails.main_image.length !== 0 ? 'https://img.booksport.ro/clubs/'+clubDetails.id +'/big/'+clubDetails.main_image[0].filename  : loginbk}) rgba(0, 0, 0, 0.4)`}}>
                <div className="container" style={{marginBottom: '50px'}}>
                    <h1>{clubDetails && `${clubDetails.club_name} ${clubDetails.location_name}`}</h1>
                    <p>{clubDetails && clubDetails.address}</p>
                    {clubDetails && clubDetails.promo ? <Typography style={{textTransform: 'uppercase', background: '#E95705',
                        color: 'white',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        padding: '5px 30px',
                        borderRadius: '30px',
                        fontSize: '12px',
                        lineHeight: '24px'
                    }}>Exclusiv prin booksport</Typography> : '' }
                </div>
            </div>
            {clubDetails &&
                <div className="container c-club-page">
                    <div className="row">
                        <div className="col-lg-9">
                            {clubDetails && clubDetails.carduri === 1 ?
                                <ElementsSlider
                                    cardreview={clubDetails && clubDetails.cardreview}
                                    elements={clubDetails && clubDetails.elements}
                                    clubslug={any} date={date}
                                    clublocation_id={clubDetails && clubDetails.id}
                                    sport_id={clubDetails && clubDetails.sport_id}/> : ''}
                            <div className="wrapper-date-picker">
                                <ClubDatePicker/>
                            </div>
                            <div className="wrapper-date-carousel mt-4 d-none d-sm-block">
                                <DateCarousel/>
                            </div>
                            <div className="wrapper-availability">
                                <AvailableHoursClub
                                    key={date}
                                    clublocation_id={clubDetails && clubDetails.id}
                                    element={clubDetails && clubDetails.element_id}
                                    booking={clubDetails && clubDetails.booking}
                                    sport={clubDetails && clubDetails.sport_id}
                                    date={date.format('YYYY-MM-DD')}
                                    clubname ={clubDetails && clubDetails.club_name}
                                    clublocationname={clubDetails && clubDetails.location_name}
                                    elementname={clubDetails && clubDetails.element_name}/>
                            </div>
                            <h5 className="fw-600 text-center title-available-hours"><p>Sloturi disponibile</p> </h5>
                            <ClubTable
                                key={date}
                                booking={clubDetails && clubDetails.booking}
                                clubname ={clubDetails && clubDetails.club_name}
                                clublocationname={clubDetails && clubDetails.location_name}
                                clublocation={clubDetails && clubDetails.id}
                                sport={clubDetails && clubDetails.sport_id} date={date.format('YYYY-MM-DD')}
                                element={clubDetails && clubDetails.element_id} />
                            <div className="club-desc pb-4 pt-3">
                                {clubDetails && <ClubDescription clubDetails={clubDetails}/>}
                            </div>
                        </div>
                        <div className="col-lg-3 sidebar-club">
                            <div className="card">
                                <CardMapClub clubname={clubDetails && clubDetails.club_name} lat={clubDetails && clubDetails.lat ? clubDetails.lat : ''} lng={clubDetails && clubDetails.lng ? clubDetails.lng : ''} />
                            </div>
                            <div className="row">
                                {clubDetails && clubDetails.amenities && <CardAmenities amenities={clubDetails.amenities}/> }
                                {clubDetails && clubDetails.orarjson && <CardProgram schedule={clubDetails.orarjson}/> }
                            </div>
                        </div>
                    </div>
                </div> }
            <ModalBooking />
            <Footer/>
        </div>
    );
};

export default ClubPage;
