import React, { useEffect, useState } from 'react'
import booksport from '../../api/booksport';
import down from '../../resources/images/down-gif.gif'
import TableCellTerenSite from "../admin/tables/TableCellTerenSite";
import { useSelector } from 'react-redux';
import moment from 'moment'
import ModalBooking from "../modals/ModalBooking";

const ElementTable = (props) => {
    const {clublocation, date, sport, booking, element, clubname} = props
    const [schedule, setSchedule] = useState()
    const [hours, setHours] = useState([])
    const [data, setData] = useState(date)

    useEffect(() => {
       if(!data) setData(moment().format('YYYY-MM-DD'))
       getClubSchedule()
    }, [data, getClubSchedule])

    const getClubSchedule = () => {
        let q = {
            clublocation_id : clublocation,
            sport_id: sport,
            dataora: data,
            element_id: element
        }
        booksport.get('/schedules_day_site',  {params: q})
            .then((res) => {
                let hours = []
                for (var i = res.data.start_hour; i <= res.data.stop_hour; i++) {
                    hours.push(i);
                }
                setHours(hours)
                setSchedule(res.data)
            })
            .catch(err => console.log(err))
    }


    return (
        <div className="card orar">
                {booking === 0 ?  <div className="inactive">In curând</div> : ''}
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 d-lg-none swipe-an">
                            Swipe for more
                            <img src={down} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="wrapper-schedule">
                    <div className="row-teren head">
                        <div className="name  cellHour">Ora</div>
                        {hours && hours.map(hour => {
                            return (
                                <div key={hour} className="cellHour">{ hour < 10 ? '0'+hour : hour}</div>
                            )
                        })}
                    </div>
                    {schedule && schedule.terenuri && schedule.terenuri.map(teren => {
                        return(
                            <div key={teren.element_id} className="row-teren">
                                <div className="name cellHour">{teren.element_name}</div>
                                {teren.rezervari && teren.rezervari.map(rezervare => {
                                    return (
                                        <div key={rezervare.hour} className="cellHour">
                                            {rezervare.element && rezervare.element.map (element => {
                                              return <TableCellTerenSite booking={schedule.booking} clubName ={clubname}  terenName={teren.element_name} terenId={teren.element_id} key={Math.random()} element ={element} date={data} hour={rezervare.hour} clublocation_id={clublocation} sport_id={sport}/>
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className="legend">
                    <p><span className="bullet white"> </span>Liber</p>
                    <p><span className="bullet ocupat"> </span>Ocupat</p>
                    <p><span className="bullet indisponibil"> </span>Indisponibil</p>
                </div>
                <ModalBooking/>
            </div>
    )
}

export default ElementTable
