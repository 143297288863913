import React,{Component} from 'react'
import Navbar from '../navs/Navbar'
import passwordRecover from '../../resources/images/forgot-password.jpeg'
import RecoverPassword from '../forms/RecoverPassword'
import Footer from "../reusable/Footer";
import {Helmet} from "react-helmet";

class RecoverPass extends Component{

    render(){
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Booksport - Recuperare parola </title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description" content="Recupereaza parola" />
                    <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                    <meta property="og:site_name" content="booksport.ro" />
                    <meta property="og:title" content="Booksport - Recuperare parola" />
                    <meta property="og:description" content="Recupereaza parola" />
                </Helmet>
                <Navbar key={new Date()} logoW={true}/>
                <div className="page-header header-filter"
                     style={{backgroundImage: `url(${passwordRecover})`, backgroundSize: 'cover', backgroundPosition: 'top center'}}>
                    <div className="container wrapper-pannel-content"  style={{minHeight: 800}}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                                <RecoverPassword/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default React.memo(RecoverPass)
