import React from 'react';

const RegistrationConfirm = () => {
    return (
        <div className="card card-login card-hidden">
            <h4 className="mb-3 mt-4 text-center">Activare cont</h4>
            <div className="card-body ">
                <p className="text-center mt-4">A fost trimis un link de activare către adresa de email. Fără activarea contului nu poate avea loc autentificarea.</p>
            </div>
            <div className="card-footer justify-content-center">
                <a className="btn btn-success mb-4" href="/">Prima pagină</a>
            </div>
        </div>
    );
};

export default RegistrationConfirm;
