import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, CircularProgress } from '@mui/material';
import booksport from "../../api/booksport";
import {CustomInput} from "../adminRefactored/components/reusable/CustomInput";

const RecoverPassword = () => {
    const [sent, setSent] = useState(null);
    const [loading, setLoading] = useState(false);

    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Introdu o adresă de email validă').required('Câmpul email este obligatoriu')
    });

    const onSubmit = (values, { setSubmitting }) => {
        setLoading(true);
        window.dataLayer.push({ event: 'pass_recover' });
        booksport.post('/generate_token', values)
            .then((res) => {
                setSent(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                setSubmitting(false);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="form">
                    <div className="card card-login card-hidden">
                        <div className="card-header p-0">
                            <div className="bg-gradient-info shadow-info border-radius-lg py-3 text-center py-4"
                                 style={{backgroundImage: 'linear-gradient(to bottom, #12803c, #4CAF50)', borderRadius: '0.5rem'}}>
                                <h4 className="font-weight-bolder text-white mt-1 mb-0" style={{fontSize: 18, fontWeight: 600, letterSpacing: 1}}>Recuperare parola</h4>
                                <p className="mb-1 text-sm text-white">Bine ai revenit!</p>
                            </div>
                        </div>
                        <div className="pl-3 pr-3">
                            {sent === null && (
                                <Field
                                    as={CustomInput}
                                    type="email"
                                    name="email"
                                    label="Email"
                                    focusColor='#12803c'
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    helperText={<span style={{ color: 'red' }}><ErrorMessage name="email" /></span>}
                                />
                            )}
                            {sent ? (
                                <h3 className="text-center mt-4 green">{sent}</h3>
                            ) : (
                                <p className="text-center mt-4">Vei primi un email cu un link de resetare</p>
                            )}
                        </div>
                        <div className="card-footer justify-content-center">
                            {sent ? (
                                <a className="btn btn-success mt-5 mb-4" href="/">Prima pagina</a>
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    disabled={loading || isSubmitting}
                                    fullWidth
                                    style={{ height: '45px', marginBottom: 30 }}
                                >
                                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Trimite'}
                                </Button>
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default RecoverPassword;
