import * as React from 'react';
import { DataGrid, roRO, GridToolbar } from '@mui/x-data-grid';
import ServicesModal from "../../admin-vouchers/components/ServicesModal";

const columns = [
    { field: 'id', headerName: 'Ref comanda', minWidth: 130, renderCell: (params) => (
            <div style={{display: "flex", alignItems: 'center', flexDirection:'column', margin: 0}}>
                <p style={{marginBottom: '5px'}}>{params.row.nr_comanda}</p>
                {params.row.facturi?.map(bill => {
                    return(
                        <p style={{display:"flex", width: '100%', margin: 0, alignItems: 'center'}}>
                            <i className="material-icons" style={{fontSize:'17px', color: '#12803c', marginRight: 5}}>get_app</i>
                            <a href={bill.url}>{bill.nrfact}</a>
                        </p>
                    )
                })}
            </div>
        ), flex: 1 },
    { field: 'data_platii', headerName: 'Data platii', minWidth: 130, flex: 1 },
    { field: 'error_message', headerName: 'Status tranzactie', minWidth: 160, flex: 1 },
    { field: 'card', headerName: 'Card', minWidth: 130, flex: 1 },
    { field: 'last_name', headerName: 'Nume', minWidth: 130, flex: 1 },
    { field: 'first_name', headerName: 'Prenume', minWidth: 130, flex: 1 },
    { field: 'price_processed', headerName: 'Suma procesata', minWidth: 80, renderCell: (params) => (`${params.row.price_processed} RON`), flex: 1 },
    { field: 'clubname', headerName: 'Nume Club', minWidth: 130, flex: 1 },
    { field: 'data_rezervare', headerName: 'Data rezervare', minWidth: 130, flex: 1 },
    { field: 'minutes', headerName: 'Minute', minWidth: 130, flex: 1 },
];


export default function TableTransactionsUser({transactions}) {
    return (
        transactions &&  <div className='transactions-table' style={{ height: '80vh', width: '100%', minHeight:'600px' }}>
            <DataGrid
                disableColumnMenu
                slots={{ toolbar: GridToolbar }}
                rows={transactions}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[10]}
                getRowHeight={() => 'auto'}
                localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
    );
}
