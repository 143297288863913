import React, {useState, useEffect} from 'react'
import moment from "moment";
import {AdminRoutes} from "../../../../api/admin-routes";
import SpinnerContainer from "../../components/loading-spinner";
import booksport from "../../../../api/booksport";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import CardAdminUsers from "./components/CardAdminUsers";

const AdminMain = () => {
    const [loading, setLoading] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        getDashboard()
    }, [])

    const getDashboard = async () =>{
        setLoading(true)
        const  query ={
            token: localStorage.getItem('jwtToken'),
        }
        try{
            const response = await booksport.get(`${AdminRoutes.adminMain}`, {params: query})
            setData(response.data)
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }



    return(
        <div className="main-panel">
            <TopNavAdmin title= {'Cifrele zilei ' + moment().format('DD.MM.YYYY')}/>
           <div className="content p-0-mobile">
                {loading && <SpinnerContainer/>}
               <div className="container-fluid">
                    {data && data.map(clublocation => {
                        return(
                            <div key={clublocation.clublocation_name} className="row mb-5">
                                <div className="col-12 card-club-admin pb-4">
                                    <div className="card-header card-header-tabs card-header-admin m-0">
                                        <div className="row align-items-center">
                                                <div className="col-8">
                                                    <span>{clublocation.clublocation_name}</span>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <span>{clublocation.sport_name}</span>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="row pt-5 pb-5">
                                        <div className="col-12">
                                            <h5 className='font-weight-bold m-0 pb-2'>Utilizatori administrare</h5>
                                            <CardAdminUsers users={clublocation.admin_users}/>
                                        </div>
                                    </div>
                                    <div className="row pt-2 pb-4">
                                        <div className="col-md-6 col-sm-6 col-xl-4">
                                            <div className="card card-stats">
                                                <div className="card-header card-header-warning card-header-icon">
                                                    <div className="card-icon">
                                                        <i className="material-icons">event_available</i>
                                                    </div>
                                                    <p className="card-category">Ore rezervate</p>
                                                    <h3 className="card-title">{clublocation.daily_data ? clublocation.daily_data.ore_rezervate_azi : ''} ore</h3>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="stats">
                                                        Totalul orelor rezervate astazi
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xl-4">
                                            <div className="card card-stats">
                                                <div className="card-header card-header-success card-header-icon">
                                                    <div className="card-icon">
                                                        <i className="material-icons">date_range</i>
                                                    </div>
                                                    <p className="card-category">Ore disponibile</p>
                                                    <h3 className="card-title">{clublocation.daily_data ? clublocation.daily_data.total_ore_disponibile_azi : ''} ore</h3>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="stats">
                                                        Totalul orelor disponibile astazi
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xl-4">
                                            <div className="card card-stats">
                                                <div className="card-header card-header-danger card-header-icon">
                                                    <div className="card-icon">
                                                        <i className="material-icons">storage</i>
                                                    </div>
                                                    <p className="card-category">Procent incarcare</p>
                                                    <h3 className="card-title">{clublocation.daily_data ? clublocation.daily_data.procent_incarcare_azi : ''} %</h3>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="stats">
                                                        Procent de incarcare al clubului
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xl-4">
                                            <div className="card card-stats">
                                                <div className="card-header card-header-info card-header-icon">
                                                    <div className="card-icon">
                                                        <i className="material-icons">monetization_on</i>
                                                    </div>
                                                    <p className="card-category">Valoare rezervari</p>
                                                    <h3 className="card-title">{clublocation.daily_data ? clublocation.daily_data.valoare_rezervari_azi : ''} lei</h3>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="stats">
                                                        Valoarea totala a rezervarilor
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xl-4">
                                            <div className="card card-stats">
                                                <div className="card-header card-header-warning card-header-icon">
                                                    <div className="card-icon">
                                                        <i className="material-icons">monetization_on</i>
                                                    </div>
                                                    <p className="card-category">Valoare medie</p>
                                                    <h3 className="card-title">{clublocation.daily_data ? clublocation.daily_data.medie_valoare_rezervare_azi : ''} lei</h3>
                                                </div>
                                                <div className="card-footer">
                                                    <div className="stats">
                                                        Media valorilor rezervarilor astazi
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AdminMain
