import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {useLocation} from "react-router-dom";
import moment from 'moment';
import queryString from 'query-string';
import { PulseLoader } from 'react-spinners';
import { openModalRezerva } from '../../../store/actions/modalActions';
import { showLoaderWindow } from '../../../store/actions/loader';
import booksport from '../../../api/booksport';
import { useHistory } from "react-router-dom";

const AvailableHoursClub = ({ sport, clublocation_id, date, clubname, clublocationname, booking }) => {
    const [availability, setAvailability] = useState(null);
    const currentDate = date;
    const [hour, setHour] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setHour(queryString.parse(location.search).h || settime());
    }, [location]);

    useEffect(() => {
        let isMounted = true;
        if (availability === null && hour) {
            const clubData = {
                sport_id: parseInt(sport),
                clublocation_id: parseInt(clublocation_id),
                date: currentDate,
                time: hour,
                dayfull: 1,
            };
            booksport
                .get('/listclubavailability', { params: clubData })
                .then((res) => {
                    if (isMounted) {
                        setAvailability(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return () => {
            isMounted = false;
        };
    }, [availability, sport, clublocation_id, currentDate, hour]);

    const settime = () => {
        const start = moment();
        const remainder = 30 - (start.minute() % 30);
        const time = moment(start).add(remainder, 'minutes').format('HH:mm');
        return time;
    };

    const handleModal = (modalData, selectedHour) => {
        const clubData = {
            sport_id: parseInt(sport),
            clublocation_id: parseInt(clublocation_id),
            date: currentDate,
            time: selectedHour,
            clubname: `${clubname} ${clublocationname}`,
        };
        dispatch(openModalRezerva(modalData, clubData));
    };

    const getRezOptions = (selectedHour, e) => {
        const token = localStorage.getItem('jwtToken');
        token && dispatch(showLoaderWindow(true));

        if (token == null) {
            const location = {
                pathname: '/login',
                state: { from: window.location.pathname },
            };
            history.push(location);
        } else {
            const clubData = {
                sport_id: parseInt(sport),
                clublocation_id: parseInt(clublocation_id),
                date: date,
                time: selectedHour,
            };

            booksport
                .get('/listelementavailability', { params: clubData })
                .then((res) => {
                    handleModal(res.data, selectedHour);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const available = availability !== null && availability.length > 0;
    const loading = availability === null;

    return (
        <div className='clubAvailableHours'>
            <h5 className="fw-600 text-center title-available-hours">
                <p>Ore libere: {moment(date).format('DD.MM.YYYY')} -{' '}
                <span className='text-uppercase text-green-400'>{moment(date).format('dddd')}</span></p>
            </h5>
            <div className='listHours'>
                {loading ? (
                    <div className='col-12'>
                        <div className='d-flex align-items-center justify-content-center pt-5 pb-5'>
                            <PulseLoader color='#12803c' size={20} />
                        </div>
                    </div>
                ) : !available && booking === 1 ? (
                    <p className='navailable'>Ocupat</p>
                ) : (
                    availability &&
                    availability.map((hour) => (
                        <div key={hour.ora} className='wrapper-btn-book'>
              <span
                  key={hour.ora}
                  className='btn btn-book'
                  onClick={() => getRezOptions(hour.ora)}
              >
                {hour.ora}
              </span>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default AvailableHoursClub;
