import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import {IconButton, Slide} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import booksport from "../../../../../api/booksport";
import {AdminRoutes} from "../../../../../api/admin-routes";
import {useDispatch, useSelector} from "react-redux";
import {refreshBookings} from "../../../../../store/actions/admin/bookings";
import {toast, ToastContainer} from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CancelPendingBooking = ({booking, completed}) => {
    const [showModal, setShowModal] = useState(false)
    const refreshBookingsSelector = useSelector(state => state.admin.bookings?.refreshBookings)
    const [loading, setLoading] = useState(false); // Add loading state
    const dispatch = useDispatch()

    useEffect(() => {
        refreshBookingsSelector && setShowModal(false)
    }, [refreshBookingsSelector]);


    const cancelPending = async () =>{
        const query={
            token: localStorage.getItem('jwtToken'),
            secure_scheduleid: booking.secureid
        }
        try{
            setLoading(true);
            const response = await booksport.delete(`${AdminRoutes.cancelPendingBooking}`, {params: query})
            if(response.status >= 204) {
                setShowModal(false)
                dispatch(refreshBookings(true))
            }
        }catch(err){
            setShowModal(false)
            toast.error('A aparut o eroare la stergerea rezervarii. Va rugam incercati.',{
                onClose: () => {
                    dispatch(refreshBookings(true))
                }
            });
        }finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Button variant='contained' onClick={() => setShowModal(true)} style={{background: '#f08f00', margin:'0 10px', width:'100%'}}> {loading ? '...' : (
                <>
                    Anuleaza
                </>
            )}</Button>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Dialog
                open={showModal ? showModal : false}
                onClose={() => setShowModal(false)}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                scroll={"body"}
            >
            <DialogTitle sx={{textAlign:'center'}}>
                Anulare rezervare
            </DialogTitle>
            <DialogContent>
                <p style={{marginBottom: 0}}>Esti sigur ca vrei sa anulezi rezervarea?</p>
            </DialogContent>
            <DialogActions style={{marginBottom: '10px', paddingRight:'10px'}}>
                <Button variant='contained' onClick={() => setShowModal(false)} style={{background: '#12803c'}} >Inchide</Button>
                <Button variant='contained' onClick={() => cancelPending()} style={{background: '#e95e4e'}} disabled={loading}>Anuleaza</Button>
            </DialogActions>
            </Dialog>
        </>
    )
}

export default CancelPendingBooking
