import React from 'react';
import TopNavAdmin from '../../../adminRefactored/components/navigations/TopNavAdmin';

const PDFViewer = () => {
    const pdfUrl = "https://img.booksport.ro/docs/integrare_api_booksport.pdf"; // Replace with your PDF URL or path

    return (
        <div className="main-panel">
            <TopNavAdmin title="Integrare sisteme de facturare" />
            <div className="content p-0-mobile">
                <div className="container-fluid">
                    <div style={{ width: '100%', height: '90vh' }}>
                        <embed
                            src={pdfUrl}
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PDFViewer;
