import React, { useState, useEffect } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import cardbk from '../../resources/images/cards.jpg';
import L from 'leaflet'
import marker from '../../resources/images/map-icon_green.svg'
import booksport from '../../api/booksport';
import moment from 'moment'

const MapSection = () => {
    const position = [45.9442858, 25.0094303]
    const [clubs, setClubs] = useState()
    const windowWidth = window.innerWidth


    useEffect(() => {
        booksport.get('/getallclubs')
        .then(res => setClubs(res.data))
    }, [])


    const pointerIcon = new L.Icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [40, 40],
        shadowSize: [68, 95],
        shadowAnchor: [20, 92],
    })

    return (
        <div className="map-section">
            <div className="container">
                <div className="map-wrapper">
                    <h3>Unde ne poți găsi</h3>
                    <Map center={position} zoom={windowWidth > 992 ? 7 : 6} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"                        />
                        {
                            clubs && clubs.map(club => {

                                const point = [club.lat,club.lng]
                                let bk = club.img ? 'https://img.booksport.ro/clubs/'+club.clublocation_id +'/small/'+club.img  : cardbk
                                let clublink = `/${club.slug}`
                                return (
                                    <Marker position={point} key={club.clublocation_id} icon={pointerIcon} zIndexOffset={3000}>
                                        <Popup>
                                            <a href={clublink}>
                                                <div className="card" style={{background: `url(${bk}) rgba(0, 0, 0, 0.4)`}}>
                                                {club.clublocation_name}
                                                </div>
                                            </a>
                                        </Popup>
                                    </Marker>
                                )
                            })
                        }

                    </Map>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    )
}

export default MapSection
