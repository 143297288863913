import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken'
import setAutorizationToken from "../../../utils/setAuthorizationToken";
import {setCurrentUser} from "../../../store/actions/userActions";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        var curentUser = localStorage.getItem('jwtToken')
        const token = jwt.decode(localStorage.jwtToken,{complete: true});
        var dateNow = new Date().getTime();
        if(token && token.payload.exp < dateNow/1000){
            localStorage.removeItem('jwtToken')
            localStorage.removeItem('usInf')
            setAutorizationToken()
            setCurrentUser({})
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        if (!curentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        var userInfo = JSON.parse(atob(localStorage.getItem('usInf')))
        if ( roles && roles.indexOf(userInfo.user_type) === -1) {
            return <Redirect to={{ pathname: '/'}} />
        }
        // authorised so return component
        return <Component {...props} />
    }} />
)
