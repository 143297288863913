import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import homebg from '../../resources/images/login-bk.jpg';
import logoImg from '../../resources/images/logo.svg';
import logoW from '../../resources/images/logo-w.svg';
import { useDispatch } from 'react-redux';
import { logOut } from '../../store/actions/userActions';
import { history } from '../../utils/history';
import jwt from 'jsonwebtoken';
import OpenSearch from '../search/OpenSearch';
import ClubFilters from "../ClubsRefactored/components/ClubFilters";

const Navbar = ({ class: className, logoW: whiteLogo, showFilters }) => {
    const [navOpen, setNavOpen] = useState(false);
    const [scrollDirection, setScrollDirection] = useState('up');
    const [isScrolled, setIsScrolled] = useState(window.scrollY > 0); // Check initial scroll position
    const [belowFold, setBelowFold] = useState(window.scrollY > 100); // Check if scrolled below fold
    const dispatch = useDispatch();

    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            if (body.classList.contains('fixed')) {
                body.classList.remove('fixed');
            }
            if (navOpen) {
                body.classList.add('fixed');
            }
        }
    }, [navOpen]);

    const handleClick = () => {
        setNavOpen(!navOpen);
    };

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(logOut());
        history.push('/');
    };

    const isAuthenticated = localStorage.getItem('jwtToken');
    const user = localStorage.getItem('usInf');
    const token = jwt.decode(localStorage.jwtToken, { complete: true });
    const dateNow = new Date().getTime();
    let currentUser = false;
    if (token && token.payload.exp < dateNow / 1000) {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('usInf');
        currentUser = false;
    } else if (token && token.payload.exp > dateNow / 1000) {
        currentUser = true;
    }

    const userLinks = (
        <ul className="nav">
            <li className="social-links" style={{ margin: 0 }}>
                <OpenSearch white={belowFold && isScrolled ? false : whiteLogo} />
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/login" onClick={handleLogout}>
                    <i className="material-icons">input</i>
                    <p>Deconectare</p>
                </Link>
            </li>
            <li className="nav-item">
                <a
                    className="nav-link"
                    href={user && user !== null ? `/${JSON.parse(atob(user)).dashboard_url}` : ''}
                >
                    <i className="material-icons">dashboard</i>
                    <p>Dashboard</p>
                </a>
            </li>
        </ul>
    );

    const guestLinks = (
        <ul className="nav">
            <OpenSearch white={belowFold && isScrolled ? false : whiteLogo} />
            <li className="nav-item">
                <Link className="nav-link" to="/login">
                    <i className="material-icons">input</i>
                    <p>Autentificare</p>
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/register">
                    <i className="material-icons">assignment_turned_in</i>
                    <p>Inregistrare</p>
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link club-btn" to="/register-club">
                    <i className="material-icons">add_task</i>
                    <p>Inscrie-ti clubul</p>
                </Link>
            </li>
        </ul>
    );

    useEffect(() => {
        let lastScrollPosition = window.scrollY;

        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;
            setIsScrolled(currentScrollPosition > 0);
            setBelowFold(currentScrollPosition > 100); // Set below fold if scrolled more than 100px

            const isMobile = window.innerWidth <= 991;
            const disableScrollBehavior = className === 'clubs' && !isMobile;

            if (!disableScrollBehavior) {
                if (currentScrollPosition > lastScrollPosition && currentScrollPosition > 100) {
                    setScrollDirection('down');
                } else if (currentScrollPosition < lastScrollPosition && currentScrollPosition > 100) {
                    setScrollDirection('up');
                }
            }

            lastScrollPosition = currentScrollPosition;
        };

        // Initial check
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [className]);

    return (
        <div>
            <div className={`navigation-deskt ${className || ''} ${belowFold && scrollDirection === 'down' ? 'slide-up' : ''} ${belowFold && scrollDirection === 'up' ? 'slide-down' : ''} ${belowFold && isScrolled ? 'scrolled' : ''}`}>
                <div className="d-flex w-100 nav-wrapper">
                    <Link to="/">
                        <img className="logo" src={belowFold && isScrolled ? logoImg : (whiteLogo ? logoW : logoImg)} alt="Logo" />
                    </Link>
                    <div className="social-links d-lg-none mobile-links">
                        <OpenSearch white={belowFold && isScrolled ? false : whiteLogo} />
                    </div>
                    <button className="navbar-toggler" type="button" onClick={handleClick}>
                        <span className="sr-only">Toggle navigation</span>
                        <span className="navbar-toggler-icon"> </span>
                        <span className="navbar-toggler-icon"> </span>
                        <span className="navbar-toggler-icon"> </span>
                    </button>
                    {currentUser && isAuthenticated !== null ? userLinks : guestLinks}
                </div>
                {showFilters && <ClubFilters />}
            </div>
            <div className={`sidebar nav-mobile ${navOpen ? 'active' : ''}`}>
                <div className="logo">
                    <a href="/" className="simple-text logo-normal">
                        <img width="200" src={logoImg} alt="Logo" />
                    </a>
                </div>
                <div className="sidebar-wrapper">
                    {currentUser && isAuthenticated ? userLinks : guestLinks}
                </div>
                <div className="sidebar-background" style={{ backgroundImage: `url(${homebg})` }}></div>
            </div>
            <div className={`overlay ${navOpen ? '' : 'hidden'}`} onClick={handleClick}></div>
        </div>
    );
};

export default Navbar;
