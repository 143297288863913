import React from 'react'
import ClubCard from './ClubCard'

const Zone = (props) => {
    const {zone, showLoc, showJudet} = props
    return (
        <>
            {showLoc === 1 && showJudet === 1 ? <div className='col-12'><h3 className='mb-4 fw-500'>{zone.localitate.date.localitate} ({zone.localitate.date.short})</h3></div> : '' }
            {showLoc === 1 && showJudet === 0 ? <div className='col-12'><h3 className='mb-4 fw-500'>{zone.localitate.date.localitate}</h3></div> : '' }
           
            {zone.localitate.clubs.map(club => {
               return <ClubCard key = {club.id} club={club}/>
            })}
        </>
    )
}

export default Zone
