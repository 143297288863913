export const erazeBooking = () => {
    return{
        type: 'DELETE_ALL',
    }
}

export const setElement = (elementId) => {
    return{
        type: 'SET_ELEMENT',
        element : elementId,
    }
}

export const setItem = (itemId) => {
    return{
        type: 'SET_ITEM',
        item : itemId,
    }
}

export const setPrice = (price) => {
    return{
        type: 'SET_PRICE',
        price,
    }
}

export const showPayment = (show) => {
    return{
        type: 'SHOW_PAYMENT',
        show,
    }
}

export const setExtraOptionState = (name, value) => {
    return{
        type: 'SET_EXTRA_OPTIONS',
        option: {name, value}
    }
}

export const setCardOption = (card) => {
    return{
        type: 'SET_CARD',
        card
    }
}

export const setSaveCard = (save) => {
    return{
        type: 'SET_SAVE_CARD',
        save
    }
}

export const setTerms = (terms) => {
    return{
        type: 'SET_TERMS',
        terms
    }
}

export const setVoucher = (voucher) => {
    return{
        type: 'SET_VOUCHER',
        voucher
    }
}

export const setShowConfirmation = (show) => {
    return{
        type: 'SET_SHOW_CONFIRMATION',
        show
    }
}

export const setTermsError = (error) => {
    return{
        type: 'SET_TERMS_ERROR',
        error
    }
}

export const refreshTotal = (refresh) => {
    return{
        type: 'REFRESH_TOTAL',
        refresh
    }
}

export const refreshVouchers= (refresh) => {
    return{
        type: 'REFRESH_VOUCHERS',
        refresh
    }
}

export const deleteExtraOptionState = (name) => {
    return{
        type: 'DELETE_EXTRA_OPTION',
        option: {name}
    }
}

export const deleteItem = () => {
    return{
        type: 'DELETE_ITEM',
    }
}

export const deletePrice = () => {
    return{
        type: 'DELETE_PRICE',
    }
}