import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import loginbk from '../../resources/images/login.jpg';
import Navbar from '../navs/Navbar';
import booksport from "../../api/booksport";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../../store/actions/userActions";
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import { history } from "../../utils/history";
import {Button} from "@mui/material";
import { Link } from "react-router-dom";
import {PulseLoader} from "react-spinners";

const SocialLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const isAuthenticated = useSelector(state => state.userInfo.isAuth);
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const dispatch = useDispatch()

    useEffect(() => {
        if (token) {
            setLoading(true);
            getUserByToken();
        } else {
            window.location.href = '/'
        }
    }, [token]);

    const getUserByToken = async () => {
        try {
            const response = await booksport.post('/getuser', { token });
            if (response.data) {
                setLoading(false);
                localStorage.setItem('jwtToken', token)
                localStorage.setItem('usInf', response.data)
                localStorage.setItem('isAuth', true)
                dispatch(setCurrentUser(response.data))
                setAuthorizationToken()
            }
        } catch (err) {
            setLoading(false);
            setError("Ceva nu a mers bine. Te rugam sa incerci din nou apasand butonul de mai jos.");
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            const { from } = window.location.state || { from: { pathname: '/' } };
            history.push(from);
        }
    }, [isAuthenticated]);

    return (
        <div>
            <Helmet>
                {/* Your Helmet content */}
            </Helmet>
            <Navbar key={new Date()} logoW={true} />
            <div className="page-header header-filter"
                 style={{ backgroundImage: `url(${loginbk})`, backgroundSize: 'cover', backgroundPosition: 'top center' }}>
                <div className="container wrapper-pannel-content" style={{ minHeight: 800 }}>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                            <div className="card card-login">
                                <div className="card-body pl-3 pr-3">
                                    {loading && (
                                        <div className="text-center pt-5 pb-5">
                                            <PulseLoader color='#12803c' size={20} />
                                            <h3>Vei fi redirectionat in cateva secunde</h3>
                                        </div>
                                    )}
                                    {error && (
                                        <div className="text-center pt-5 pb-5">
                                            <h4>{error}</h4>
                                            <Button
                                                component={Link} to="/login"
                                                variant="contained"
                                                color="success"
                                                fullWidth
                                                style={{height: '45px', marginTop: 20, backgroundColor: "#12803c"}}
                                            >
                                                Catre login
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SocialLogin;
