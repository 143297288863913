import React, {useState, useEffect} from 'react'
import booksport from '../../../api/booksport'
import {PulseLoader} from "react-spinners";

const Prices = (props) => {
    const {clublocation, sport} = props
    const[prices, setPrices] = useState()
    const[loading, setLoading] = useState(true)

    useEffect(() => {
     getPrices()
        // eslint-disable-next-line 
    }, [])

    const getPrices = () => {
        const q={
            clublocation_id: clublocation,
            sport_id: sport
        }
        booksport.get('/getclubprices', {params: q})
        .then((res) =>{
            setPrices(res.data)
            setLoading(false)
            }
        )
    }

    return (
        <div className="row prices-front">
            <div className="col-12">
                {loading ? <div style={{minHeight: 100, width: '100%', display:'flex', justifyContent:'center', alignItems: 'center'}}>
                        <PulseLoader color="#12803c"/>
                    </div> :
                    <div className="table-responsive mb-5">
                    <table className="table table-prices table-hover">
                        <thead className="text-success" id="stick">
                        <tr>
                            <th>Pret</th>
                            <th>Denumire</th>
                            <th>ZILE</th>
                            <th>UM</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            prices  && prices.map(rowpret => {
                                return (
                                    <tr key={rowpret.price + rowpret.days} height='100'>
                                        <td>{rowpret.price + ' ' + rowpret.currency}</td>
                                        <td>{rowpret.details}</td>
                                        <td>{rowpret.days}</td>
                                        <td>{rowpret.um}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                }
            </div>
        </div>
    )
}

export default Prices
