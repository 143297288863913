import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { useSelector, useDispatch } from "react-redux";
import {
    setSelectedCity,
    setSelectedCityPJ,
} from "../../../../../store/actions/userActions";
import booksport from "../../../../../api/booksport";

const CountySelect = ({ pj, user, error }) => {
    const [cities, setCities] = useState([])
    const selcounty = useSelector(pj ? state => state.userStatus.countyPJ : state => state.userStatus.county)
    const selcity = useSelector(pj ? state => state.userStatus.cityPJ : state => state.userStatus.city)
    const dispatch = useDispatch()
    const userCounty = pj ? user.userpj?.county_id : user.county
    const userCity = pj ? user.userpj?.localitate_id : user.city

    useEffect(() => {
        userCounty && getCities(userCounty)
    }, [])

    useEffect(() => {
        getCities(selcounty)
    }, [selcounty])

    const setSelCity = (value) => {
        if (pj) {
            dispatch(setSelectedCityPJ(value))
        } else {
            dispatch(setSelectedCity(value))
        }
    }

    const getCities = async (county) => {
        if (county) {
            try {
                let query = {
                    id_judet: county,
                }
                const response = await booksport.get('/get_city', { params: query })
                if (response.data) {
                    response.data.map(oras => {
                        oras.id === userCity && setSelCity(oras.id)
                    })
                    setCities(response.data)
                }
            } catch (err) {
                return { error: err.response }
            }
        }
    }

    return (
        <div className="col-12 col-md-6 col-lg-4 mt-4">
            <FormControl fullWidth error={error && !selcity}>
                <InputLabel sx={{
                    '&.Mui-focused.MuiInputLabel-shrink': {
                        color: '#12803c'
                    }
                }} id="county-label">Oras/Localitate</InputLabel>
                <Select
                    defaultValue=""
                    labelId="county-label"
                    id="demo-simple-select"
                    value={selcity ? selcity : ''}
                    label="Oras/Localitate"
                    onChange={(e) => setSelCity(e.target.value)}
                    sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#12803c',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#12803c',
                        },
                        '&.Mui-focused .MuiInputLabel-outlined': {
                            color: '#12803c',
                        }
                    }}
                >
                    {cities.map(count => {
                        return <MenuItem key={count.id} value={count.id}>{count.name}</MenuItem>
                    })}
                </Select>
                {error && !selcity && <FormHelperText>Campul Oras/Localitate este obligatoriu</FormHelperText>}
            </FormControl>
        </div>
    );
}

export default CountySelect;
