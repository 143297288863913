import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import booksport from '../../../api/booksport';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PulseLoader } from 'react-spinners';
import moment from "moment";
import CardClub from './CardClub';
import queryString from "query-string";

const ClubsWrapper = () => {
    const [clubs, setClubs] = useState([]);
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState();
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const clubsWrapper = useRef(null);
    const location = useLocation();
    const currentParams = queryString.parse(location.search);
    const { location: locationId, sport, date, hour, radius } = currentParams;
    const allParamsAvailable = sport && locationId && date && hour;
    const [paramsReady, setParamsReady] = useState(false);

    useEffect(() => {
        if (allParamsAvailable) {
            setParamsReady(true);
        }
    }, [allParamsAvailable]);

    useEffect(() => {
        if (paramsReady) {
            setInitialLoading(true);
            setClubs([]);
            if (!moment(date).isBefore(moment(), 'day')) {
                getClubs(0);
            }
            scrollToTop();
        }
    }, [sport, locationId, radius, paramsReady]);

    useEffect(() => {
        if (!initialLoading && clubs.length > 0 && clubsWrapper.current && clubsWrapper.current.clientHeight <= window.innerHeight && page < lastPage) {
            getClubs(page);
        }
    }, [clubs, initialLoading, page, lastPage]);

    const getClubs = useCallback(async (page) => {
        try {
            setLoading(true);
            const q = {
                user_id: localStorage.getItem('usInf') ? JSON.parse(atob(localStorage.getItem('usInf'))).id : 99999999,
                sport_id: sport,
                location_id: locationId,
                radius: radius ? radius : 20,
                date: date,
                time: hour,
                page: page + 1
            };
            const response = await booksport.get('/listclubs', { params: q });
            const newClubs = response.data.clubs.data;
            setClubs((prevClubs) => [...prevClubs, ...newClubs]);
            setLastPage(response.data.clubs.last_page);
            setPage(page + 1);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setInitialLoading(false);
        }
    }, [sport, locationId, radius, paramsReady]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="row" ref={clubsWrapper}>
            {initialLoading ? (
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-center pt-5 pb-5 wrapperloader">
                        <PulseLoader color='#12803c' size={20} />
                    </div>
                </div>
            ) : (
                clubs.length === 0 ? (
                    loading ? (
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-center pt-5 pb-5 wrapperloader">
                                <PulseLoader color='#12803c' size={20} />
                            </div>
                        </div>
                    ) : (
                        <div className="zero-res col-12 text-center">Nu au fost găsite rezultate pentru selecția ta</div>
                    )
                ) : (
                    <InfiniteScroll
                        dataLength={clubs.length}
                        next={() => getClubs(page)}
                        hasMore={page < lastPage}
                        loader={
                            <div className="col-12" key={page}>
                                <div className="d-flex align-items-center justify-content-center pt-5 pb-5">
                                    <PulseLoader color='#12803c' size={20} />
                                </div>
                            </div>
                        }
                    >
                        {clubs.map(club => (
                            <CardClub key={club.id} club={club} />
                        ))}
                    </InfiniteScroll>
                )
            )}
        </div>
    );
};

export default ClubsWrapper;
