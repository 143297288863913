export default (state = {}, action) => {
    switch (action.type) {
        case 'ADD_ITEMS':
            return {...state, items: action.items}
        case 'SHOW_MODAL':
            return {...state, modal: action.show}
        case 'SHOW_USER_MODAL':
            return {...state, modalUserVoucher: action.show}
        case 'REFRESH_VOUCHERS':
            return {...state, refresh: action.refresh}
        default :
            return state
    }
}
