import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { DateTime } from 'luxon';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useHistory, useLocation } from 'react-router-dom';

const DateCarousel = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const history = useHistory();
    const currentDate = DateTime.local(); // Set the current date
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    useEffect(() => {
        const dateFromURL = params.get('date') ? DateTime.fromISO(params.get('date')) : currentDate;
        const selectedSlideIndex = renderSlides().findIndex((slide) => {
            const startDate = DateTime.fromISO(slide.props.startdate);
            const endDate = DateTime.fromISO(slide.props.enddate);

            if (startDate.isValid && endDate.isValid && dateFromURL.isValid) {
                return dateFromURL >= startDate && dateFromURL <= endDate;
            }

            return false;
        });

        setActiveSlideIndex(selectedSlideIndex !== -1 ? selectedSlideIndex : 0);
        // eslint-disable-next-line
    }, [params]);

    const handleDateChange = (date) => {
        const maxDate = currentDate.plus({ weeks: 2 });

        // Format both dates to include only the day part (yyyy-MM-dd)
        const formattedDate = date.toFormat('yyyy-MM-dd');
        const formattedCurrentDate = currentDate.toFormat('yyyy-MM-dd');

        if (formattedDate < formattedCurrentDate || date > maxDate) return;

        history.push(`${history.location.pathname}?date=${formattedDate}`);
    };

    const renderSlides = () => {
        const slides = [];

        const startOfWeek = currentDate.startOf('week');
        const endOfWeek = currentDate.endOf('week');

        slides.push(
            <div
                className="carousel-slide"
                key={startOfWeek.toFormat('yyyy-MM-dd')}
                startdate={startOfWeek.toISO()}
                enddate={endOfWeek.toISO()}
            >
                {generateWeekSlide(startOfWeek, endOfWeek)}
            </div>
        );

        for (let week = 1; week <= 2; week++) {
            const startDate = currentDate.startOf('week').plus({ weeks: week });
            const endDate = currentDate.endOf('week').plus({ weeks: week });
            slides.push(
                <div
                    className="carousel-slide"
                    key={startDate.toFormat('yyyy-MM-dd')}
                    startdate={startDate.toISO()}
                    enddate={endDate.toISO()}
                >
                    {generateWeekSlide(startDate, endDate)}
                </div>
            );
        }

        return slides;
    };

    const generateWeekSlide = (startDate) => {
        const daysInWeek = [];
        const setDate = params.get('date') ? DateTime.fromISO(params.get('date')) : currentDate

        for (let i = 0; i < 7; i++) {
            const date = startDate.plus({ days: i });
            const isPastDate = date < currentDate.startOf('day');
            const activeDate = date.hasSame(setDate, 'day');
            const today = date.hasSame(currentDate, 'day');
            const maxDate = currentDate.plus({ weeks: 2 });
            const dayClass = `day ${activeDate ? 'current-date' : ''}${isPastDate || date > maxDate ? 'disabled' : ''}`;

            daysInWeek.push(
                <div
                    key={date.toFormat('yyyy-MM-dd')}
                    className={dayClass}
                    onClick={() => handleDateChange(date)}
                >
                    <div className="day-name">{date.setLocale('ro').toFormat('ccc')}</div>
                    <div className="date">{date.setLocale('ro').toFormat('dd.MM')}</div>
                    {today && <div style={{ color: '#12803c', fontSize: 12 }}>astazi</div>}
                </div>
            );
        }

        return <div className="week-days">{daysInWeek}</div>;
    };

    return (
        <div className="date-carousel-container">
            <div className="carousel-container">
                <Carousel
                    selectedItem={activeSlideIndex}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    renderArrowPrev={(onClickHandler, hasPrev) =>
                        hasPrev && (
                            <div className="arrow prev" onClick={onClickHandler}>
                                <ArrowBackIosIcon />
                            </div>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext) =>
                        hasNext && (
                            <div className="arrow next" onClick={onClickHandler}>
                                <ArrowForwardIosIcon />
                            </div>
                        )
                    }
                >
                    {renderSlides()}
                </Carousel>
            </div>
        </div>
    );
};

export default DateCarousel;
