import React, {useState, useEffect} from "react";
import TopNavAdmin from "../../components/navigations/TopNavAdmin";
import SpinnerContainer from "../../components/loading-spinner";
import booksport from "../../../../api/booksport";
import {AdminRoutes} from "../../../../api/admin-routes";
import UserSavedCard from "./components/UserSavedCard";
import {useDispatch, useSelector} from "react-redux";
import {refreshCardsList} from "../../../../store/actions/admin/cards";

const UserCards = () => {
    const [loading, setLoading] = useState(true)
    const [cards, setCards] = useState([])
    const refreshCards = useSelector(state => state.admin.cards?.refreshCards)
    const dispatch = useDispatch()

    useEffect(() => {
        refreshCards && getUserSavedCards()
    },[refreshCards])

    useEffect(() => {
        getUserSavedCards()
    },[])

    const getUserSavedCards = async () =>{
        setLoading(true)
        const query={
            token: localStorage.getItem('jwtToken'),
        }
        try{
            const response = await booksport.get(`${AdminRoutes.getUserSavedCards}`, {params: query})
            setCards(response.data)
            dispatch(refreshCardsList(false))
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }

    const renderCards = () => {
        return (
            cards && cards.length > 0 ?
                <div className="row">
                    {cards.map(card => {
                        return <UserSavedCard key={card.pan_masked} card={card}/>
                    })}
                </div> :
                <div className="col-12">
                    <h3>Nu aveti carduri salvate. Cardurile se pot salva in momentul rezervarii.</h3>
                </div>
        )
    }

    return(
        <div className="main-panel">
            <TopNavAdmin title='Carduri salvate'/>
            <div className="content">
                <div className="container-fluid">
                    {loading ? <SpinnerContainer/> : renderCards() }
                </div>
            </div>
        </div>
    )
}

export default UserCards
