import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, Button, Typography, useMediaQuery, useTheme, IconButton, Skeleton } from '@mui/material';
import booksport from "../../api/booksport";
import cardbk from '../../resources/images/cards.jpg';
import StarRatings from "react-star-ratings";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const CustomDot = ({ onClick, ...rest }) => {
    const { active } = rest;
    return (
        <li
            style={{
                display: 'inline-block',
                marginRight: '8px',
                cursor: 'pointer',
            }}
            onClick={() => onClick()}
        >
            <Button
                size="small"
                style={{
                    minWidth: 'auto',
                    padding: 0,
                    fontSize: '24px',
                    color: active ? '#12803c' : '#c4c4c4'
                }}
            >
                {active ? '●' : '○'}
            </Button>
        </li>
    );
};

const arrowStyle = {
    backgroundColor: '#f5f5f5',
    borderRadius: '50%',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
};

const LeftArrow = ({ onClick }) => (
    <IconButton
        onClick={onClick}
        style={{
            ...arrowStyle,
            marginRight: '16px'
        }}
        onMouseEnter={(e) => e.currentTarget.firstChild.setAttribute('style', 'fill: black;')}
        onMouseLeave={(e) => e.currentTarget.firstChild.setAttribute('style', 'fill: darkgrey;')}
    >
        <svg viewBox="0 0 10 16" width="12" height="12" style={{ fill: 'darkgrey' }}>
            <path d="M4.511 8L10 13.704 7.398 16 0 8l7.398-8L10 2.296z"></path>
        </svg>
    </IconButton>
);

const RightArrow = ({ onClick }) => (
    <IconButton
        onClick={onClick}
        style={arrowStyle}
        onMouseEnter={(e) => e.currentTarget.firstChild.setAttribute('style', 'fill: black;')}
        onMouseLeave={(e) => e.currentTarget.firstChild.setAttribute('style', 'fill: darkgrey;')}
    >
        <svg viewBox="0 0 10 16" width="12" height="12" style={{ fill: 'darkgrey' }}>
            <path d="M5.489 8L0 2.296 2.602 0 10 8l-7.398 8L0 13.704z"></path>
        </svg>
    </IconButton>
);

const CarouselComponent = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [clubs, setClubs] = useState([]);
    const [loading, setLoading] = useState(true);
    const carouselRef = useRef(null);

    useEffect(() => {
        booksport.get('/listpromoclubs')
            .then(res => {
                setClubs(res.data?.clubs || []);
                setLoading(false);
            })
            .catch(err => setLoading(false));
    }, []);

    const renderSkeletons = () => (
        Array.from(new Array(5)).map((_, index) => (
            <Box key={index} className='col-12 club-card mb-3'>
                <div className="card card-blog">
                    <Skeleton variant="rectangular" width="100%" height={300} />
                    <Box sx={{ padding: '16px' }}>
                        <Skeleton variant="text" width="80%" height={30} />
                        <Skeleton variant="text" width="60%" height={20} />
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton variant="rectangular" width="100%" height={30} />
                    </Box>
                </div>
            </Box>
        ))
    );

    if (loading) {
        return (
            <Box className="container" position="relative" sx={{ marginTop: { xs: '50px', lg: '100px' } }}>
                <Box sx={{ marginLeft: '-15px', marginRight: '-15px' }}>
                    <div className="col-12">
                        <Typography variant='h3' sx={{
                            color: '#12803c',
                            fontWeight: 400,
                            fontSize: { xs: '22px', md: '34px' },
                            margin: '20px 0 30px 0',
                            fontFamily: 'Poppins, sans-serif'
                        }}>Cluburi exclusive</Typography>
                    </div>
                    <Carousel
                        ref={carouselRef}
                        responsive={responsive}
                        showDots={isMobile}
                        customDot={<CustomDot />}
                        arrows={false}
                        renderDotsOutside={true}
                        dotListClass="custom-dots"
                    >
                        <Box display="flex" justifyContent="center">
                            {renderSkeletons()}
                        </Box>
                    </Carousel>
                </Box>
                <style jsx>{`
                    .custom-dots {
                        margin-bottom: ${isMobile ? '-25px' : '0'};
                    }
                `}</style>
            </Box>
        );
    }

    if (!loading && clubs.length === 0) {
        return null;
    }

    return (
        <Box className="container" position="relative" sx={{ marginTop: { xs: '50px', lg: '100px' } }}>
            <Box sx={{ marginLeft: '-15px', marginRight: '-15px' }}>
                <div className="col-12">
                    <Typography variant='h3' sx={{
                        color: '#12803c',
                        fontWeight: 400,
                        fontSize: { xs: '22px', md: '34px' },
                        margin: '20px 0 30px 0',
                        fontFamily: 'Poppins, sans-serif'
                    }}>Cluburi exclusive</Typography>
                </div>
                <Carousel
                    ref={carouselRef}
                    responsive={responsive}
                    showDots={isMobile}
                    customDot={<CustomDot />}
                    arrows={false}
                    renderDotsOutside={true}
                    dotListClass="custom-dots"
                    autoPlay
                    infinite={true}
                    autoPlaySpeed={5000}
                    transitionDuration={1000}
                >
                    {clubs.map(club => (
                        <div key={club.id} className='col-12 club-card mb-3'>
                            <div className={club.booking === 0 ? "card card-blog opacity-50" : "card card-blog"}>
                                <a href={`/${club.slug}`}>
                                    <div className="card-header card-header-image">
                                        {club.main_card_message && (
                                            <div className="card-note" style={{ backgroundColor: club.main_card_message_bkg_color, color: club.main_card_message_color }}>
                                                {club.main_card_message}
                                            </div>
                                        )}
                                        <img className="img" src={club && club.filename ? 'https://img.booksport.ro/clubs/' + club.id + '/small/' + club.filename : cardbk} alt="club" />
                                        <Box className="card-title" sx={{ bottom: '15px' }}>
                                            {club.name} <br />
                                            {club.locationname}
                                        </Box>
                                        <Box className="sport" sx={{
                                            position: 'absolute',
                                            color: '#ffffff',
                                            bottom: '15px',
                                            right: '10px',
                                            backgroundColor: '#E95705',
                                            fontWeight: 600,
                                            padding: '0 15px',
                                            borderRadius: '30px'
                                        }}> {club.sport}</Box>
                                        <div className="colored-shadow"> </div>
                                    </div>
                                </a>
                                <div className="card-body">
                                    {club.review !== 0 ?
                                        <div className="card-rating">
                                            <StarRatings
                                                rating={club.review}
                                                starRatedColor="#f9bf3b"
                                                numberOfStars={5}
                                                name='rating'
                                                starDimension="20"
                                                starHoverColor="#f9bf3b"
                                                starSpacing='0px'
                                            />
                                            <span style={{ fontStyle: 'normal', color: '#000' }}>({club.nr_review} {club.nr_review > 1 ? 'review-uri' : 'review'})</span>
                                        </div> : <div style={{ minHeight: '24px' }}></div>}
                                    <h5 className="card-category mb-0" style={{ minHeight: '50px' }}>
                                        <span style={{ marginRight: '10px' }}>{club.address}</span>
                                        <span className="green" style={{ backgroundColor: '#651716' }}>{club.elements}</span>
                                    </h5>
                                    <Box sx={{ fontSize: '16px', textTransform: 'uppercase', color: '#E95705', fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>{club.localitate}</Box>
                                </div>
                                <div className="card-footer">
                                    <a href={`/${club.slug}`} className="text-right btn-more w-100 mt-2 mb-2 text-center" style={{ margin: '20px 5px' }}>Vezi disponibilități</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
                {!isMobile && clubs.length > 0 && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <LeftArrow onClick={() => carouselRef.current.previous()} />
                        <RightArrow onClick={() => carouselRef.current.next()} />
                    </Box>
                )}
            </Box>
            <style jsx>{`
                .custom-dots {
                    margin-bottom: ${isMobile ? '-25px' : '0'};
                }
            `}</style>
        </Box>
    );
};

export default CarouselComponent;
