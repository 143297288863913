import React, {useEffect, useState} from 'react'
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../../api/booksport";
import {deleteExtraOptionState, setItem, setPrice, showPayment} from "../../../../store/actions/rezervareActions";
import {PulseLoader} from "react-spinners";
import moment from "moment";
import {refreshVouchers} from "../../../../store/actions/admin/vouchers";

const ItemSelect = ({club, date, hour}) =>{
    const [items, setItems] = useState([])
    const [selectedItem,setSelectedItem] = useState(null);
    const element = useSelector(state => state.rezervare.element)
    const sportId = useSelector(state => state.showModal.rezerva?.clubData?.sport_id)
    const item = useSelector(state => state.rezervare.item)
    const rezervare = useSelector(state => state.rezervare)
    const dispatch = useDispatch()


    useEffect(() => {
       club && element && getItemsFromApi()
        setSelectedItem(null)
        // eslint-disable-next-line 
    }, [element])

    useEffect(() => {
        item === null && dispatch(setPrice(null))
        // eslint-disable-next-line 
    },[item])

    const setItemSelection = (selectedOption) => {
        if(item !== selectedOption.value){
            !rezervare?.price && dispatch(showPayment(false))
            dispatch(refreshVouchers())
            setSelectedItem(selectedOption)
            dispatch(setItem(selectedOption.value))
            Object.entries(rezervare).map(
                ([k, v]) => {
                    if(k.split('_')[0] === 'extra'){
                        dispatch(deleteExtraOptionState(k))
                    }
                }
            )
        }
    }

    const getItemsFromApi = () => {
        const itms = []
        const getItem = {
            clublocation_id: club.id,
            dataora: date+ ' ' + hour,
            sport_id: sportId,
            element_id: element
        }

        booksport.get('/get_items',  {params: getItem})
            .then((res) =>{
                if(res.data.length === 1) setItemSelection({
                    label: res.data[0].name ,
                    value: res.data[0].id,
                })
                res.data.map(item => {
                    itms.push({
                        label: item.name ,
                        value: item.id,
                    },)
                })
                setItems(itms)
                }
            )
            .catch( (err) =>
                console.log(err)
            )
    }

    return(
        items.length !==0 ?
        <div className="col-12">
            <Select
                isSearchable={ false }
                className="w-100 mb-2"
                options={items}
                placeholder = {'Selectează o opțiune'}
                onChange = {(selOption) => setItemSelection(selOption)}
                value = {selectedItem}
            />
        </div> : <div className="mt-1 w-100 d-flex justify-content-center"><PulseLoader color='#12803c' size={12}/></div>

    )
}

export default ItemSelect
