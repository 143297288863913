import React, {Component, lazy, Suspense} from 'react'
import booksport from "../../../api/booksport";
import moment from 'moment'
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";

const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));
const renderLoader = () => <p>Loading</p>;

class Financiar extends Component{
    constructor(props){
        super(props)
        this.state = {
            months: null,
            financiar: null,
            selmonth : null
        }
    }

    componentDidMount(){
        document.title = 'Booksport - Financiar'
        if(this.state.months === null){
            this.getMonths()
        }
    }

    getMonths = () =>{
        var element={
            token: localStorage.getItem('jwtToken'),
            clublocation_id : this.props.location.state.clublocation_id,
        }
        if(this.state.months === null) {
            booksport.get('/dashboard_admin_financiar_months', {params: element})
                .then((res) =>
                {
                    this.setState({months: res.data, selmonth: {label:res.data[0].an_luna, value:res.data[0].an_luna}})
                    this.getFinanciar( {label:res.data[0].an_luna, value:res.data[0].an_luna})
                }
                )
        }
    }

    getFinanciar = (selectedValue) => {
        var element={
            token: localStorage.getItem('jwtToken'),
            clublocation_id : this.props.location.state.clublocation_id,
            an_luna: selectedValue.value
        }
        booksport.get('/dashboard_admin_financiar', {params: element})
            .then((res) =>
                this.setState({financiar: res.data, selmonth: selectedValue})

            )
    }




    render(){
        const animatedComponents = makeAnimated();
        let months = []
        if(this.state.months !== null){
            this.state.months.map(month => {
                return(
                    months.push({label: month.an_luna , value: month.an_luna})
                )
            })
        }
        return(
            <div className="main-panel">
                <Suspense fallback={renderLoader()}>
                    <TopNavAdmin title= {'Financiar ' + this.props.location.state.club.club_id.name +' - ' + this.props.location.state.club.name}/>
                </Suspense>
                <div className="content p-0-mobile">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <Select
                                    isSearchable={ false }
                                    isDisabled= {false}
                                    options={months}
                                    ref='months'
                                    onChange={this.getFinanciar}
                                    className="w-100"
                                    components={animatedComponents}
                                    value={this.state.selmonth}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-5">
                                <div className="table-responsive">
                                     <table className="table table-prices table-hover">
                                    <thead className="text-success" id="stick">
                                    <tr>
                                        <th>Luna</th>
                                        <th>An</th>
                                        <th>Tip document</th>
                                        <th>Descarca</th>
                                        <th>Observatii</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.financiar && this.state.financiar.map(row => {
                                            let date = new Date(row.luna+'-01-'+ row.an)
                                            return(
                                                <tr key={row.headtext}>
                                                    <td className="text-capitalize">{moment(date).format('MMMM')}</td>
                                                    <td>{row.an}</td>
                                                    <td>{row.headtext}</td>
                                                    <td>  <a className="mr-1" target="_blank" rel="noopener noreferrer" href={row.link}><i className="material-icons" style={{fontSize:'17px'}}>get_app</i>{row.text}</a></td>
                                                    <td>{row.obs}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Financiar
