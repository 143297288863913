import React, {useEffect, useState} from 'react'
import { isMobile } from 'react-device-detect';
import logo_small from '../../resources/images/logo-small.svg'
import CloseIcon from '@mui/icons-material/Close';
import {isIOS, isAndroid} from "react-device-detect";

const AppBanner = (props) => {
    const [open, setOpen] = useState(false)


    const getCookie = () =>{
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('app='))
            ?.split('=')[1];
        return cookieValue;
    }

    const goToLink = () => {
        setCookie()
       const link =  isIOS ? 'https://apps.apple.com/us/app/booksport/id1585479595' : 'https://play.google.com/store/apps/details?id=ro.Booksport.Booksport'
        window.location.href = link
    }

    const setCookie = () => {
        document.cookie = `app=true;max-age=604800;Secure`
    }

    const closeBanner = () =>{
        setOpen(false)
        setCookie()
    }

    useEffect(() => {
        const hasCookie = getCookie()
        if (hasCookie == null) {
            if(isMobile){
                setTimeout(() => {
                    setOpen(true)
                }, "2000")
            }
        }

    },[])

    return (
        <div className={open ? 'app-banner open' : 'app-banner'}>
            <CloseIcon style={{fontSize: 22}} onClick={closeBanner}/>
            <img className="logo-app" src={logo_small} alt="app logo"/>
            <div className="app-description">
                <p className="title-app">Booksport</p>
                <p>Descarca aplicatia</p>
            </div>
            <button onClick={goToLink}>Descarca</button>
        </div>
    )
}

export default AppBanner
