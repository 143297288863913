import React, {Component, lazy, Suspense} from 'react'
import {connect} from 'react-redux'
import {openModal} from "../../../store/actions/modalActions";
import {disRefresh} from "../../../store/actions/refreshActions";
import makeAnimated from "react-select/animated/dist/react-select.cjs";
import Select from 'react-select'

const ModalTeren = lazy(() => import('../modals/ModalTeren'));
const SliderTableTeren = lazy(() => import('../sliders/SliderTableteren'));
const TopNavAdmin = lazy(() => import('../../adminRefactored/components/navigations/TopNavAdmin'));
const renderLoader = () => <p>Loading</p>;

class Teren extends Component{

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state ={
      club: null,
      terenuri: null,
      element_id : this.props.location.state.element_id,
      teren_name: this.props.location.state.teren_name
    }
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  refresh=()=>{
    this.props.disRefresh()
  }

  changeTeren=(selectedOption)=>{
    this.setState({element_id:selectedOption.value, teren_name: selectedOption.label});
  }

  componentDidMount(){
    document.title = 'Booksport - Teren'
  }

  render(){
    var current = this.props.location.state
    var dateNow = new Date().getTime();

    var terenuriSelect = null
    if(this.props.location.state.terenuri){
      terenuriSelect = this.props.location.state.terenuri;
    }
    const animatedComponents = makeAnimated();

    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: "#12803c",
        color: "white",
        minHeight: 45,
        borderRadius: 5,
        boxShadow: state.isFocused ? null : null,
        borderColor: "transparent"
      }),
    };

    return(
      <div className="main-panel">
        <Suspense fallback={renderLoader()}>
          <TopNavAdmin title= {'Rezervari ' + this.state.teren_name}/>
        </Suspense>
        <div className="content p-0-mobile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4 teren-sel">
                <div className="form-group bmd-form-group m-0 p-0">
                  <Select
                    isSearchable={ false }
                    options={terenuriSelect}
                    ref='terenuri'
                    onChange={this.changeTeren}
                    defaultValue ={ { label: this.state.teren_name, value: this.state.element_id } }
                    className="w-100 change-teren"
                    components={animatedComponents}
                    styles={customStyles}
                  />
                </div>
              </div>
            </div>
            <Suspense fallback={renderLoader()}>
              <SliderTableTeren key={this.props.refresh.refresh ? dateNow : this.state.teren_name} current={current} element_id={this.state.element_id} />
            </Suspense>
          </div>
        </div>
        <Suspense fallback={renderLoader()}>
          <ModalTeren showModal={this.props.showModal}/>
        </Suspense>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    showModal:state.showModal.showModal,
    refresh: state.refresh
  }
}


export default connect(mapStateToProps,{openModal,disRefresh}) (Teren)
