import React, {useEffect, useState} from 'react'
import booksport from "../../../../api/booksport";
import visa from "../../../../resources/images/visa.svg";
import mastercard from "../../../../resources/images/mastercard.svg";
import {useDispatch} from "react-redux";
import {setCardOption, setSaveCard} from "../../../../store/actions/rezervareActions";


const CardListing = () =>{
    const [cards, setCards] = useState(null)
    const [selectedCard, setSelectedCard] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        booksport.get('/getcard',  {params: {token: localStorage.getItem('jwtToken')}})
            .then((res) =>{
                if(res.data.length){
                    setCards(res.data)
                    setSelectedCard(res.data[0].payment_instrument_id)
                    dispatch(setCardOption(res.data[0].payment_instrument_id))
                    }
                }
            )
            .catch( (err) =>
                console.log(err)
            )
        // eslint-disable-next-line 
    },[])

    const setCard = (card) => {
        setSelectedCard(card)
        dispatch(setCardOption(card))
        parseInt(card) !== 0 && dispatch(setSaveCard(false))
    }


    return(
        cards && <div className="col-12 mt-4 mb-4">
            <div className="row">
                <div className="col-12"><h5 className="mb-0">Card</h5></div>
                {
                    cards && cards.map((card) => {
                        let image = parseInt(card.pan_masked.charAt(0)) === 4 ? visa : mastercard
                        return(
                            <div key ={card.pan_masked} className="col-12 card-option">
                                <div className="form-group w-100">
                                    <div className="form-check mt-0 w-100 pb-0 mb-0">
                                        <div className="form-check w-100 mb-0 ">
                                            <label className="form-check-label">
                                                <input className="form-check-input" value={card.payment_instrument_id} checked={selectedCard === card.payment_instrument_id}  onChange={() => setCard(card.payment_instrument_id)} type="radio" name="card" />
                                                {parseInt(card.pan_masked.charAt(0)) === 4 ? 'Visa' : 'Mastercard'} {card.pan_masked} <br/> Expira in {card.exp_date}
                                                <span className="circle">
                                                    <span className="check"> </span>
                                                </span>
                                            </label>
                                            <img className="img-card" src={image} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                            <div className="col-12 card-option">
                                <div className="form-group">
                                    <div className="form-check mt-0 mb-0">
                                        <div className="form-check w-100 mb-0">
                                            <label className="form-check-label">
                                                <input className="form-check-input" onChange={() => setCard(0)}  type="radio" name="card" value={0} checked = { parseInt(selectedCard) === 0 || parseInt(selectedCard) === 1}/>
                                                Achita cu alt card
                                                <span className="circle">
                                                <span className="check"> </span>
                                            </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
        </div>
    )


}

export default CardListing
