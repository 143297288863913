export const startFilter = () => {
  return{
    type: 'START_FILTER',
    startFilter : true,
  }
}

export const stopFilter = () => {
  return{
    type: 'STOP_FILTER',
    startFilter : false,
  }
}

export const openMap = () => {
  return{
    type: 'OPEN_MAP',
    openMap : true,
  }
}

export const closeMap = () => {
  return{
    type: 'CLOSE_MAP',
    openMap : false,
  }
}

