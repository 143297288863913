import React,{Component} from 'react'
import {connect} from 'react-redux'
import {openModal} from "../../../store/actions/modalActions";
import moment from "moment/moment";


class TableCellTeren extends Component{
  handleModal = () => {
    if(!this.props.showModal){
      this.props.openModal(this.props.hour, this.props.location_name.current, this.props.day_name, this.props.day_nr, this.props.ref_teren)
    }
  }
  render(){
      let clasa = 'cell'
      let libere = 0
      let po = 0
      let o = 0
      let notes = [];

      const currentDate = moment().subtract(1, "days")
      const userType = parseInt(JSON.parse(atob(localStorage.getItem('usInf'))).user_type)

      this.props.hour.element.forEach(element => {
          let ocupate = 0
          element.min.forEach(min => {
              if(min.id && min.id !== null){
                  ocupate += 1
              }
          })
          if(ocupate === element.min.length){
              o+=1
          }
          if(ocupate === 0){
              libere += 1
          }
          if(ocupate !== 0 && ocupate < element.min.length){
              po+=1
          }
      });

      this.props.hour.element.forEach(element => {
          element.min.map((min, index, content) => {
              if (min.notes !== null && min.notes !== undefined){
                  if(content[index].notes === content[0].notes){
                      return(
                          notes = [<span className="halfcell ocupat full" style={ { background: min.bkgcolor, color: min.textcolor }}>{content[0].notes.substring(0,15)}{content[0].notes.length > 15 ? ' ...' : ''}</span>]
                      )
                  }else {
                      return(
                          notes.push(<span className="halfcell ocupat full" style={ { background: min.bkgcolor, color: min.textcolor }}>{min.notes.substring(0,15)}{min.notes.lenght > 15 ? " ..." : ''}</span>)
                      )
                  }
              } else{
                  notes.push(<span className="halfcell liber">liber</span>)
              }
          });
      });

      if(o === this.props.hour.element.length){
          clasa = ((currentDate >= moment(this.props.day_nr)) && userType < 20) ? 'cell libere0 past' : 'cell libere0';
          return(
              <span  className={clasa} onClick={this.handleModal}>
                <div className="wrapper-td">
                    <span className="c-time">{this.props.hour.hour}</span>
                    <span className="day">{this.props.day_name.split(" ").splice(-1)}</span>
                </div>
                <p className="values w-multiple">
                  { notes.every( (val, i, arr) => val === arr[0] ) ? notes[0] : notes}
                </p>
            </span>
          )
      }else if(libere === this.props.hour.element.length){
          clasa = ((currentDate >= moment(this.props.day_nr)) && userType < 20) ? 'cell libereall past' : 'cell libereall';
          return(
              <span  className={clasa} onClick={this.handleModal}>
                <div className="wrapper-td">
                    <span className="c-time">{this.props.hour.hour}</span>
                    <span className="day">{this.props.day_name.split(" ").splice(-1)}</span>
                </div>
                <p className="values">liber</p>
            </span>
          )
      }else{
          clasa = ((currentDate >= moment(this.props.day_nr)) && userType < 20) ? 'cell liberesome teren past' : 'cell liberesome teren';
          return(
              <span  className={clasa} onClick={this.handleModal}>
                <div className="wrapper-td">
                    <span className="c-time">{this.props.hour.hour}</span>
                    <span className="day">{this.props.day_name.split(" ").splice(-1)}</span>
                </div>
                <p className="values w-multiple">
                  {notes}
                </p>
            </span>
          )
      }
  }
}

const mapStateToProps = (state) => {
  return{showModal:state.showModal.showModal}
}

export default connect(mapStateToProps,{openModal}) (TableCellTeren)
