import React from 'react'
import StarRatings from 'react-star-ratings';
import { history } from '../../../../../utils/history';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import { openModalDeleteReview } from '../../../../../store/actions/review';
import { useDispatch } from 'react-redux';

const CardReview = ({review}) => {

    const userStorage = localStorage.getItem('usInf')
    const user = userStorage ? JSON.parse(atob(localStorage.getItem('usInf'))) : null
    const dispatch = useDispatch()

    const editReview = () => {
        let location
        if(!review.secureid){
            location = {
                pathname: `/review/MDFvWU9PQTNmQ0QvaExtVEU1Y3hvdz09/${review.clublocation_id}/${review.element_id}/${review.stars}`,
                state: { review }
            }
        }else{
            location = {
                pathname: `/review/${review.secureid}/${review.clublocation_id}/${review.element_id}/${review.stars}`,
                state: { review }
            }
        }

        history.push(location)
    }

    const openModalReview = () => {
        dispatch(openModalDeleteReview(review.reviewhash))
    }

    return (
        <div className="col-12" key={review.id}>
            <div  className="card wrapper-review pb-3">
                <div className="review-logo">
                    {review.logosrc ?
                        <img className='logo' alt='logo' src={review.logosrc}></img> :
                        <div className="review-avatar" style={{backgroundColor: user.color}}>
                            <p>{user ? `${user.first_name.charAt(0)}${user.last_name.charAt(0)}` : <PersonOutlineOutlinedIcon/>}</p>
                        </div> }
                    <p className='m-0 mt-2 fw-600 text-success'>{review.sportname && review.sportname} {review.numelement && review.numelement} </p>
                    <p className='m-0 mb-2 fw-500'>{review.created_at}</p>
                </div>
                <div className="review-content">
                    <p className='m-0 mb-2 fw-600'>{review.clubname}</p>
                    <StarRatings
                        rating={review.stars}
                        starRatedColor="#f9bf3b"
                        numberOfStars={5}
                        name='rating'
                        starDimension="30px"
                        starHoverColor="#f9bf3b"
                        starSpacing='0px'
                    />
                    {review.orderid && review.orderid !== 0 ?  <p className='fw-600 mt-2 mb-0 text-success d-flex align-items-center'> <VerifiedIcon style={{ color: '#090', fontSize:18, marginRight: 5 }} /> Review pentru comanda {review.orderid}</p> : ''}
                    {review.title ? <h4 className='fw-600 mt-2'>{review.title}</h4> : ''}
                    {review.description ?
                        <p className='review-description mt-2'>{review.description}</p> : '' }
                    <div className="d-flex align-items-center review-actions">
                        <p className="text-success mt-4 mr-4" onClick={editReview}>Editeaza</p>
                        <p className="text-danger mt-4" onClick={openModalReview}>Sterge</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardReview
