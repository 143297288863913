export const showModalReturn = (show, amount, url) => {
    return{
        type: 'SHOW_MODAL',
        payload: {show, amount, url}
    }
}

export const showModalReturnVoucher = (show, encrypted, amount) => {
    return{
        type: 'SHOW_MODAL_VOUCHER',
        payload: {show, encrypted, amount}
    }
}

export const refreshTransactions = (refresh) => {
    return{
        type: 'REFRESH_TRANSACTIONS',
        payload: refresh
    }
}
