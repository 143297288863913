import React from 'react'
import aboutIlu from '../../resources/images/Logo_H.svg'

const AboutUs = () => {
    return (
        <div className='about-us-section'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h3 className='mt-0 mb-3'>Ce este Booksport?</h3>
                        <p>Booksport s-a născut din dorința de a facilita iubitorilor de sport accesul în bazele sportive din toată țara. </p>
                        <p>În prezent, Booksport este prima și cea mai mare platformă din România axata exclusiv pe rezervari online de terenuri sportive și activități conexe. Am reușit să aducem sportul mai aproape de oameni printr-un sistem inteligent de rezervări cu plata online, eficientizând întregul proces.</p>
                        <p>Sistemul nostru digitalizat ajută utilizatorul să rezerve o ședință în mai puțin de 5 minute și totodată, oferă siguranța disponibilității cluburilor pentru rezervările efectuate. </p>

                    </div>
                    <div className="col-lg-6">
                        <div className="d-flex align-items-center justify-content-center h-100 pl-5 pr-5">
                            <img className='img-fluid' src={aboutIlu} alt="about-us" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
