import React,{Component} from 'react'
import booksport from "../../../api/booksport";

class HoursColumn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      start: null,
      stop: null
    };
  }

  componentDidMount() {
    var club = this.props.club
    var sport = this.props.sport
    var element = {
      token: localStorage.getItem('jwtToken'),
      clublocation_id: club,
      sport_id : sport
    }
    booksport.get('/getcluborar', {params: element})
      .then((res) =>
        this.setState({start: res.data.start_hour, stop: res.data.stop_hour})
      )
      .catch((err) =>
        this.setState({succes: false})
      )
  }

  render() {
    let hours = []
    for (var i = this.state.start; i <= this.state.stop; i++) {
      hours.push(i);
    }
      return (
       hours.map(hour => {
         return (
           <div key={hour} className="cell time">{hour}:00</div>
         )
       })
      )
    }
}

export default HoursColumn