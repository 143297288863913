import React, { useState, useEffect } from 'react'
import TopNavAdmin from '../../components/navigations/TopNavAdmin'
import booksport from "../../../../api/booksport";
import SpinnerContainer from "../../components/loading-spinner";
import {AdminRoutes} from "../../../../api/admin-routes";
import ExpiredBookingCard from "./components/ExpiredBookingCard";
import ActiveBookingCard from "./components/ActiveBookingCard";
import {useDispatch, useSelector} from "react-redux";
import {refreshBookings} from "../../../../store/actions/admin/bookings";

const UserBookings = () => {
    const [activeBookings, setActiveBookings] = useState([])
    const [expiredBookings, setExpiredBookings] = useState([])
    const [loading, setLoading] = useState(true)
    const refreshBookingsSelector = useSelector(state => state.admin.bookings?.refreshBookings)
    const dispatch = useDispatch()

    useEffect(() => {
        refreshBookingsSelector && getBookingsFromApi()
    }, [refreshBookingsSelector]);

    useEffect(() => {
       getBookingsFromApi()
    }, []);


    const getBookingsFromApi = async () =>{
        setLoading(true)
        const query={
            token: localStorage.getItem('jwtToken'),
        }
        try{
            const response = await booksport.get(`${AdminRoutes.getUserBookings}`, {params: query})
            const { active, expirate } = response.data;
            active && setActiveBookings(active);
            expirate && setExpiredBookings(expirate);
            refreshBookingsSelector && dispatch(refreshBookings(false))
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }

    const renderBookings = () => {
            if (activeBookings.length === 0 && expiredBookings.length === 0){
                return(
                    <div className="row">
                        <div className="col-12">
                            <h3>Nu exista rezervari active</h3>
                        </div>
                    </div>
                    )
            } else {
                return (
                    <>
                        <div className="row">
                            {activeBookings.map(activeBooking => (
                                <ActiveBookingCard key={activeBooking.id} booking={activeBooking} />
                            ))}
                            {expiredBookings.map(expiredBooking => (
                                <ExpiredBookingCard key={expiredBooking.id} booking={expiredBooking} />
                            ))}
                        </div>
                    </>
                );
            }
    }

    return(
        <div className="main-panel">
            <TopNavAdmin title= 'Rezervarile mele'/>
            <div className="content p-0-mobile">
                <div className="container-fluid">
                    {loading ? <SpinnerContainer/> : renderBookings() }
                </div>
            </div>
        </div>
    )
}

export default UserBookings
