import React, { useEffect, useState, useRef } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { closeSearchPanel } from '../../store/actions/search';
import booksport from '../../api/booksport';
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '../../resources/images/search-icon.svg';

const SearchForm = () => {
    const open = useSelector(state => state.search.openPanel);
    const dispatch = useDispatch();
    const [clubs, setClubs] = useState([]);
    const [clubslug, setCLubslug] = useState('');
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                closeSearch();
            }
        };

        if (open) {
            getClubs();
            if (inputRef.current) {
                inputRef.current.focus();
            }
            window.addEventListener('keydown', handleEsc);
            document.body.classList.add('fixed'); // Prevent body scroll
        } else {
            window.removeEventListener('keydown', handleEsc);
            document.body.classList.remove('fixed'); // Re-enable body scroll
        }

        // Cleanup function to run when the component unmounts or the dependencies change
        return () => {
            window.removeEventListener('keydown', handleEsc);
            document.body.classList.remove('fixed'); // Ensure body scroll is re-enabled on unmount
        };
    }, [open]);

    const getClubs = async () => {
        const clubsres = await booksport.get('/getclubs');
        let clubsArray = [];
        clubsres.data.forEach(club => {
            clubsArray.push({ label: `${club.name} (${club.short}) - ${club.sport}`, value: club.slug });
        });
        setClubs(clubsArray);
    }

    const closeSearch = () => {
        setCLubslug('');
        setInputValue('');
        dispatch(closeSearchPanel());
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (clubslug) {
            window.location.href = `/${clubslug}`;
        }
    }

    const handleInputChange = (event, value) => {
        setInputValue(value);
    }

    const handleSuggestionSelect = (event, suggestion) => {
        if (suggestion) {
            setCLubslug(suggestion.value);
            setInputValue(suggestion.label);
            window.location.href = `/${suggestion.value}`;
        }
    }

    return (
        <Modal open={open} onClose={closeSearch} closeAfterTransition>
            <Slide direction="down" in={open} mountOnEnter unmountOnExit>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        bgcolor: 'white',
                        padding: '20px 10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="container-fluid p-0">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                bgcolor: 'transparent',
                                borderRadius: 1,
                                padding: {lg: '0 20px 0 5px'}
                            }}
                        >
                            <Button
                                href={clubslug ? `/${clubslug}` : ''}
                                sx={{
                                    padding: 0,
                                    height: '100%',
                                    minWidth: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <img src={SearchIcon} alt="Search" style={{ height: 25, width: 25, color: 'green' }} />
                            </Button>
                            <form onSubmit={handleFormSubmit} style={{ flexGrow: 1, display: 'flex', margin: 0 }}>
                                <Autocomplete
                                    freeSolo
                                    fullWidth
                                    options={clubs}
                                    getOptionLabel={(option) => option.label}
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    onChange={handleSuggestionSelect}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Caută club"
                                            variant="outlined"
                                            inputRef={inputRef}
                                            autoFocus
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <></>
                                                ),
                                                style: {
                                                    padding: '10px',
                                                    fontSize: '18px',
                                                    fontFamily: 'Poppins, sans-serif',
                                                    border: 'none', // Remove the border
                                                },
                                            }}
                                            sx={{
                                                '& fieldset': {
                                                    border: 'none', // Remove the border
                                                },
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props} key={option.value} style={{ cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>
                                            {option.label}
                                        </li>
                                    )}
                                    ListboxProps={{
                                        style: {
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                            fontFamily: 'Poppins, sans-serif', // Use Poppins font
                                        },
                                    }}
                                />
                            </form>
                            <Button
                                onClick={closeSearch}
                                sx={{
                                    color: 'black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: 0,
                                    height: '100%',
                                    minWidth: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <p className='d-none d-md-block' style={{ fontSize: 18, margin: 0 }}>ÎNCHIDE</p>
                                <CloseIcon size={18} />
                            </Button>
                        </Box>
                    </div>
                </Box>
            </Slide>
        </Modal>
    );
}

export default SearchForm;
