import React,{Component} from 'react'
import {connect} from 'react-redux'
import {openModal} from "../../../store/actions/modalActions";
import TopNavAdmin from '../../adminRefactored/components/navigations/TopNavAdmin'
import {disRefresh} from "../../../store/actions/refreshActions";
import SliderTableZi from "../sliders/SliderTableZi";
import booksport from "../../../api/booksport";
import ModalTeren from "../modals/ModalTeren";

class Zi extends Component{

    constructor(props) {
        super(props);
        this.state = {
            terenuri: null
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    refresh=()=>{
        this.props.disRefresh()
    }

    componentDidMount(){
        document.title = 'Booksport - Rezervari'
        if(this.state.terenuri === null){
            this.getTerenuri()
        }
    }

    getTerenuri = () => {
        var element={
            token: localStorage.getItem('jwtToken'),
            clublocation_id : this.props.location.state.clublocation_id
        }
            booksport.get('/getclubdetails', {params: element})
                .then((res) =>
                    this.setState({terenuri:res.data.elements})
                )
    }


    render(){
        var current = this.props.location.state
        var dateNow = new Date().getTime();
        let terenuri
        if(this.state.terenuri !== null){
            terenuri = this.state.terenuri
        }

        return(
            <div className="main-panel">
                <TopNavAdmin title='Rezervari'/>
                <div className="content p-0-mobile">
                    <div className="container-fluid">
                        <SliderTableZi key={this.props.refresh.refresh ? dateNow : ''} current={current} terenuri={terenuri} />
                    </div>
                </div>
                <ModalTeren showModal={this.props.showModal}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        showModal:state.showModal.showModal,
        refresh: state.refresh
    }
}


export default connect(mapStateToProps,{openModal,disRefresh}) (Zi)
