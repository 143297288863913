
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from "@mui/material/FormHelperText";
import dayjs from "dayjs";
import booksport from "../../../../../api/booksport";
import {AdminRoutes} from "../../../../../api/admin-routes";
import LoadingSpinner from "../../../components/loading-spinner";
import VouchersFormItems from "./VocherFormItems";
import {useDispatch, useSelector} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import {refreshVouchers, showVouchersModal} from "../../../../../store/actions/admin/vouchers";
import {CustomInput} from "../../../components/reusable/CustomInput";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Numele este obligatoriu'),
    valabilitate_de_la: Yup.date().required('Campul "Valabilitate de la" este obligatoriu')
        .typeError('Valoarea datei nu este una valida'),
    valabilitate_pana_la: Yup.date().required('Campul "Valabilitate pana la" este obligatoriu')
        .typeError('Valoarea datei nu este una valida')
        .min(
            Yup.ref('valabilitate_de_la'),
            '"Valabilitate pana la" trebuie sa fie dupa  "Valabilitate de la"'
        ),
    tip: Yup.number().required('Tipul este obligatoriu'),
    uses: Yup.number().required('Campul "numar utilizari" este obligatoriu'),
    valoare: Yup.number()
        .typeError('Valoarea trebuie sa fie un numar')
        .positive('Valoarea trebuie sa fie pozitiva')
        .required('Valoarea este obligatorie')
        .when('tip', {
            is: 0,
            then: Yup.number().max(100, 'Valoarea trebuie sa fie maxim 100'),
        })
        .when('tip', {
            is: 1,
            then: Yup.number().max(1000, 'Valoarea trebuie sa fie maxim 1000'),
        }),
    item_ids: Yup.array()
        .min(1, "Serviciile sunt obligatorii")
        .required("Serviciile sunt obligatorii"),
});

const CreateVouchersForm = () => {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState(null);
    const [showErrMessage, setShowErrMessage] = useState(false);
    const selectedItems = useSelector(state => state.admin.vouchers?.items);
    const dispatch = useDispatch()

    useEffect(() => {
        getFormItems()
    }, []);

    useEffect(() => {
        handleItemsChange(selectedItems)
    }, [selectedItems])


    const handleSubmit = (values) => {
        values['token'] = localStorage.getItem('jwtToken')
        values['emitent'] = JSON.parse(atob(localStorage.getItem('usInf'))).id
        values['tip'] = parseInt(values.tip)
        values['uses'] = parseInt(values.uses)
        createVoucher(values)
    };

    const initialValues = {
        name: '',
        valabilitate_de_la: selectedStartDate,
        valabilitate_pana_la: selectedEndDate,
        tip: '',
        uses: 1,
        valoare: '',
        item_ids: []
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleItemsChange = (items) => {
        formik.setFieldValue('item_ids', items);
    };

    const handleStartDateChange = (date) => {
        formik.setFieldValue('valabilitate_de_la', dayjs(date).format('YYYY-MM-DD'));
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        formik.setFieldValue('valabilitate_pana_la', dayjs(date).format('YYYY-MM-DD'));
        setSelectedEndDate(date);
    };

    const createVoucher = async (values) => {
        setLoading(true)
        try{
            const response = await booksport.post(`${AdminRoutes.createAdminVoucher}`, values)
            if(response.status === 200) {
                setSelectedStartDate(null)
                setSelectedEndDate(null)
                formik.resetForm()
                dispatch(showVouchersModal(false))
                dispatch(refreshVouchers(true))
            }
        }catch(err){
            dispatch(showVouchersModal(false))
            toast.error('A survenit o eroare la crearea codului discount. Va rugam reincercati.');
        }
        setLoading(false)
    }

    const getFormItems = async () => {
        setLoading(true)
        const query = {
            token :localStorage.getItem('jwtToken')
        }
        try{
            const response = await booksport.get(`${AdminRoutes.getAdminVoucherFields}`, {params: query})
            setItems(response.data)
            response.data.length === 0 && setShowErrMessage(true)
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }

    return (
        loading ? <LoadingSpinner/> :
            <>
                {showErrMessage ?
                  <h3 className="text-bold text-danger text-center">Optiunea de creare coduri discount nu este activa pentru contul curent.</h3> :
                    <>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row mt-5">
                                <div className="col-12 mb-3">
                                    <CustomInput
                                        fullWidth
                                        focusColor='#12803c'
                                        name="name"
                                        label="Nume"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Valabilitate de la"
                                            name="valabilitate_de_la"
                                            value={selectedStartDate}
                                            onChange={handleStartDateChange}
                                            onBlur={formik.handleBlur}
                                            format="DD.MM.YYYY"
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: formik.touched.valabilitate_de_la && Boolean(formik.errors.valabilitate_de_la),
                                                    helperText: formik.touched.valabilitate_de_la && formik.errors.valabilitate_de_la,
                                                    sx: {
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: "green"
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "green"
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Valabilitate pana la"
                                            name="valabilitate_pana_la"
                                            value={selectedEndDate}
                                            onChange={handleEndDateChange}
                                            onBlur={formik.handleBlur}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: formik.touched.valabilitate_pana_la && Boolean(formik.errors.valabilitate_pana_la),
                                                    helperText: formik.touched.valabilitate_pana_la && formik.errors.valabilitate_pana_la,
                                                    sx: {
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: "green"
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "green"
                                                        }
                                                    }
                                                }
                                            }}
                                            format="DD.MM.YYYY"
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <FormControl fullWidth>
                                        <InputLabel sx={{ "&.Mui-focused": {
                                                color: '#12803c'
                                            },}} id="tip-label">Tip</InputLabel>
                                        <Select
                                            labelId="tip-label"
                                            name="tip"
                                            label="Tip"
                                            value={formik.values.tip}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.tip && Boolean(formik.errors.tip)}
                                            sx ={{
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#12803c',
                                                }
                                            }}
                                        >
                                            <MenuItem value="1">Valoare (RON)</MenuItem>
                                            <MenuItem value="0">Procent (%)</MenuItem>
                                        </Select>
                                        <FormHelperText style={{color: 'red'}}>{formik.touched.tip && formik.errors.tip}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <CustomInput
                                        fullWidth
                                        name="valoare"
                                        label="Valoare"
                                        focusColor='#12803c'
                                        type="number"
                                        value={formik.values.valoare}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.valoare && Boolean(formik.errors.valoare)}
                                        helperText={formik.touched.valoare && formik.errors.valoare}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <FormControl fullWidth>
                                        <InputLabel sx={{ "&.Mui-focused": {
                                                color: '#12803c'
                                            },}} id="uses-label">Numar utilizari</InputLabel>
                                        <Select
                                            labelId="uses-label"
                                            name="uses"
                                            label="Numar utilizari"
                                            value={formik.values.uses}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.uses && Boolean(formik.errors.uses)}
                                            sx ={{
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#12803c',
                                                }
                                            }}
                                        >
                                            <MenuItem value="0">Utilizatori nelimitati</MenuItem>
                                            <MenuItem value="1">Utilizator unic</MenuItem>
                                        </Select>
                                        <FormHelperText style={{color: 'red'}}>{formik.touched.uses && formik.errors.uses}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="col-12 mb-5">
                                    <span className="small">Aplicabil pentru urmatoarele servicii</span>
                                    {items && <VouchersFormItems data = {items}/>}
                                    {formik.touched.item_ids && Boolean(formik.errors.item_ids) && <span className="text-danger small">{formik.errors.item_ids}</span>}
                                </div>
                            </div>

                            {!loading && <Box mt={2}>
                                <Button type="submit" variant='contained' style={{background: '#12803c'}} >Genereaza cod discount</Button>
                            </Box>}
                        </form>
                    </> }
            </>

    );
};

export default CreateVouchersForm;
