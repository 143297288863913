import React, {useState, useEffect, useCallback} from 'react'

const HomepageText = () => {
    const textArray = ['Sportul', 'Relaxarea ', 'Sănătatea '];
    const [index, setIndex] = useState(0);
    const [current, setCurrent] = useState(textArray[index]);

    useEffect(() => {
        setCurrent(textArray[index])
    }, [index])

    useEffect(() => {
        const interval = setTimeout(() => {
            setIndex(index === textArray.length - 1 ? 0 : index + 1)       
        }, 5500);
    }, [current])

    return(
        <span>{current}</span>
    )
}

export default HomepageText
