import {combineReducers} from 'redux'
import modalReducer from './modalReducer'
import userReducer from './userReducer'
import refresh from './refreshReducer'
import auth from './auth'
import refreshDay from './refreshDayReducer'
import filterClubs from './clubsFilterReducer'
import review from './review'
import comments from './comments'
import search from './search'
import rezervare from "./rezervareReducer";
import loader from './loader'
import admin from './admin'
import {reducer as formReducer} from 'redux-form'

export default combineReducers({
    showModal: modalReducer,
    form: formReducer,
    userStatus: userReducer,
    userInfo: auth,
    refresh: refresh,
    refreshDay: refreshDay,
    filterClubs: filterClubs,
    review: review,
    comments,
    search,
    rezervare,
    loader,
    admin
})
