export const showModalDeleteCard = (show) => {
    return{
        type: 'SHOW_MODAL_DELETE_CARD',
        show
    }
}

export const refreshCardsList = (refresh) => {
    return{
        type: 'REFRESH_CARDS_LIST',
        refresh
    }
}

export const setSelectedPaymentCard = (card) => {
    return{
        type: 'SET_PAYMENT_CARD',
        card
    }
}
