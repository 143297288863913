import React from 'react'
import StarRatings from 'react-star-ratings'
import cardbk from '../../resources/images/cards.jpg';
import moment from 'moment';

const ClubCard = (props) => {
    const {club} = props
    const clublink = `/${club.slug}?date=${moment().format('YYYY-MM-DD')}&h=${moment().format('HH:mm')}`

    return (
        <div className='col-sm-6 col-xl-4 club-card'>
            <div className={club.booking === 0 ? "card card-blog opacity-50" : "card card-blog"}>
                <a href={clublink}>
                    <div className="card-header card-header-image">
                        {club.main_card_message && <div className="card-note" style={{backgroundColor:club.main_card_message_bkg_color, color:club.main_card_message_color}}>{club.main_card_message}</div> }
                        <img className="img" src={club && club.filename ? 'https://img.booksport.ro/clubs/'+club.id +'/small/'+club.filename  : cardbk} alt="club"/>
                        <div className="card-title">
                            {club.name} <br/>
                            {club.locationname}
                        </div>
                        <div className="colored-shadow"> </div>
                    </div>
                </a>
                <div className="card-body">
                    {club.review !== 0 ? 
                        <div className="card-rating">
                            <a className='add-review' href={`${clublink}&r=true`}>
                            <StarRatings
                                    rating={club.review}
                                    starRatedColor="#f9bf3b"
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="20"
                                    starHoverColor="#f9bf3b"
                                    starSpacing='0px'
                                />
                            <span style={{fontStyle: 'normal', color:'#000'}}>({club.nr_review} {club.nr_review > 1 ? 'review-uri' : 'review'})</span> </a>
                    </div> : <a className='add-review' href={`${clublink}&r=true`}>Adauga un review</a> }
                    <h5 className="card-category mb-0">{club.address}<span className="green">{club.elements}</span></h5>
                </div>    
                <a href={clublink} className="text-right btn-more mt-2 mb-4">Vezi disponibilități</a>
          </div>
        </div>
    )
}

export default ClubCard
