import React from 'react'
import Zone from './Zone'

const ClubsWrapper = (props) => {
    const {locations, showLoc, showJudet} = props

    return (
        <div className='row mt-5'>
            {locations && locations.map( location => {
                return (<Zone key={location.localitate.date.localitate} showLoc={showLoc} zone={location} showJudet={showJudet}/>)
            })}
        </div>
        
    )
}

export default ClubsWrapper
