import React, {useEffect} from 'react'

function RefreshPage() {
    const MINUTE_MS = 60000*30;

    useEffect(() => {
    const interval = setInterval(() => {
       window.location.reload()
    }, MINUTE_MS);

    return () => clearInterval(interval);
    }, [MINUTE_MS])
  return (
    <div></div>
  )
}

export default RefreshPage
