import React from 'react'
import Checkbox from '@mui/material/Checkbox';

export const CustomCheckbox = (props) => (
    <Checkbox
        {...props}
        sx={{
            '&.Mui-checked': {
                color: '#12803c',
            },
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }}
    />
);
