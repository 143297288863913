import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {closeModalRezerva} from "../../store/actions/modalActions";
import ElementSelect from "./modal_contents/selects/ElementSelect";
import moment from "moment";
import ItemSelect from "./modal_contents/selects/ItemSelect";
import {erazeBooking} from "../../store/actions/rezervareActions";
import OptionsSelection from "./modal_contents/OptionsSelection";
import DurationSelect from "./modal_contents/selects/DurationSelect";
import CardListing from "./modal_contents/savedCards/CardListing";
import SaveCard from "./modal_contents/SaveCard";
import Terms from "./modal_contents/Terms";
import SendSummaryButton from "./modal_contents/SendSummaryButton";
import ShowTotal from "./modal_contents/ShowTotal";
import VouchersSelect from "./modal_contents/selects/VouchersSelect";
import ConfirmationPannel from "./modal_contents/ConfirmationPannel";
import {showLoaderWindow} from "../../store/actions/loader";
import booksport from "../../api/booksport";
import {IconButton, Slide} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {styled} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const StyleDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        overflowY: "visible",
    },
    [theme.breakpoints.down("sm")]: {
        "& .MuiDialog-container .MuiDialog-paper": {
            borderRadius: 0,
            maxWidth: '100vw',
            margin: 0
        },
        "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: '100vw',
            },
        },
        "& .MuiDialogContent-root": {
            padding: '20px 10px'
        },
        "& .MuiDialogTitle-root": {
            padding: '30px 10px'
        }
    },
}));
const ModalBooking = () => {
    const modalState = useSelector(state => state.showModal)
    const rezervare = useSelector(state => state.rezervare)
    const clubId = useSelector(state => state.showModal.rezerva?.clubData?.clublocation_id)
    const sportId = useSelector(state => state.showModal.rezerva?.clubData?.sport_id)
    const data = useSelector(state => state.showModal.rezerva?.clubData?.date)
    const hour = useSelector(state => state.showModal.rezerva?.clubData?.time)
    const [showModal, setShowModal] = useState(false)
    const [clubData, setClubData] = useState(null)
    const [user, setUser] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        let isMounted = true;
        if(modalState.rezerva?.openModal){
            if(localStorage.getItem('usInf')){
                setUser(JSON.parse(atob(localStorage.getItem('usInf'))));
            }
            setShowModal(modalState.rezerva?.openModal)
            dispatch(showLoaderWindow(false))
            getClubData(isMounted)
        } else {
            setShowModal(false)
        }
        return () => {
            isMounted = false;
        };
    }, [modalState])

    const closeModalComponent = () => {
        dispatch(erazeBooking())
        dispatch(closeModalRezerva())
        setShowModal(false)
    }

    const getClubData = (isMounted) => {
        let q = {
            sport_id : sportId,
            clublocation_id: clubId,
        }
        booksport.get('/getclubdetails_for_site',  {params: q})
            .then((response) => {
                if(isMounted){
                    setClubData(response.data)
                }
            })
            .catch(err => console.log(err))
    }

    const uservalid = user && user?.first_name && user?.last_name && user?.address && user?.city && user?.county && user?.phone &&  user?.nickname

    return(
        <StyleDialog
            open={showModal ? showModal : false}
            aria-describedby="modal-booking"
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={"md"}
            scroll={"body"}
        >
            <div className="modalRezerva">
                <DialogTitle sx={{textAlign:'left'}}>
                    {rezervare.showConfirmation ?
                        <div style={{fontSize: '22px', lineHeight: '20px'}}>Confirma selectia</div>
                        : user &&  user.user_type === 0 &&
                        <div className="row">
                            <div className="col-12" style={{fontSize: '22px', lineHeight: '20px', marginBottom: '10px'}}>{modalState?.rezerva?.clubData?.clubname}</div>
                            <div className="col-5"><p style={{fontSize: "16px", margin: 0}}>Ora: {modalState?.rezerva?.clubData?.time}</p></div>
                            <div className="col-7"><p style={{fontSize: "16px", margin: 0}}>Data: {moment(modalState?.rezerva?.clubData?.date,  'YYYY-MM-DD').format('DD.MM.YYYY') }</p></div>
                        </div>}
                    <IconButton
                        aria-label="close"
                        onClick={() => closeModalComponent()}
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: "rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px', overflow: "visible"}} >
                    {rezervare.showConfirmation && <ConfirmationPannel club = {clubData} date={data} hour={hour}/>}
                    {user &&  user.user_type !== 0 ?
                        <div className="row">
                            <div className="col-12 text-center">
                                <h4 className="mt-2 mb-3 font-weight-bold">Utilizator incompatibil</h4>
                                <p className="mb-4">Utilizatorii de tip administrator nu pot face rezervari. Va rugam sa va conectati cu alt utilizator.</p>
                            </div>
                        </div> : uservalid ?
                            <div className={rezervare.showConfirmation ? 'hidden' : ''}>
                                <div className="row">
                                    <ElementSelect elements={modalState?.rezerva?.data}/>
                                    {rezervare.element && clubData && <ItemSelect club = {clubData} date={data} hour={hour}/>}
                                    {rezervare.item && clubData && <DurationSelect club = {clubData} date={data} hour={hour}/> }
                                    {rezervare.price && clubData && <OptionsSelection club = {clubData} />}
                                    {clubData?.vouchere && rezervare.price?.split('_')[1] === 'f' ? <VouchersSelect time={hour} date={data}/> : null }
                                    {rezervare.showpayment &&
                                        <>
                                            <CardListing title={true}/>
                                            <SaveCard/>
                                            <Terms club = {clubData}/>
                                        </>
                                    }
                                    {rezervare.price && <ShowTotal club = {clubData} date={data} hour={hour}/>}
                                    <SendSummaryButton/>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h4 className="mt-2 mb-3 font-weight-bold">Actualizare informatii</h4>
                                    <p className="m-0">Pentru a putea rezerva, este necesara actualizarea informatiilor din sectiunea Dashboard -> Contul meu -> Date personale.</p>
                                    <p className="m-0">Actualizeaza datele apasand butonul de mai jos.</p>
                                    <a className="btn btn-success mb-5 mt-5" href="/dashboard/user">Catre contul meu</a>
                                </div>
                            </div>
                    }
                </DialogContent>
            </div>
        </StyleDialog>
    )
}

export default ModalBooking
