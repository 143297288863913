import React, { useState, useEffect } from 'react'
import TopNavAdmin from '../../../adminRefactored/components/navigations/TopNavAdmin'
import booksport from '../../../../api/booksport'
import Pagination from '@mui/material/Pagination';
import ModalConfirmDeleteReview from '../../../modals/ModalConfirmDeleteReview';
import { useSelector } from 'react-redux';
import CardReview from "./components/CardReview";
import {AdminRoutes} from "../../../../api/admin-routes";
import {refreshCardsList} from "../../../../store/actions/admin/cards";
import SpinnerContainer from "../../components/loading-spinner";

const Reviews = () => {
    const [reviews, setReviews] = useState()
    const user = JSON.parse(atob(localStorage.getItem('usInf'))).id
    const [page, setPage] = useState(1)
    const stateReview = useSelector(state => state.review)
    const refresh = stateReview.review ? stateReview.review.refresh : null
    const [loading, setLoading] = useState()


    useEffect(() => {
       getReviewsFromApi(0)
    }, [])


    useEffect(() => {
        if(refresh){
            reviews.data.length > 1 ?
                getReviewsFromApi(page)
                :
                getReviewsFromApi(page - 1)
        }
    }, [refresh])

    const handleChange = (event, value) => {
        setPage(value)
        getReviewsFromApi(value)
    };

    const getReviewsFromApi = async (page) =>{
        setLoading(true)
        const query={
            user_id: user,
            page: page
        }
        try{
            const response = await booksport.get(`${AdminRoutes.getUserRevies}`, {params: query})
            setReviews(response.data)
            setPage(response.data.current_page)
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }

    return(
        <div className="main-panel">
            <TopNavAdmin title= 'Review-urile mele'/>
            <div className="content p-0-mobile">
                <div className="container-fluid">
                    {loading ? <SpinnerContainer/> :
                    <div className="row">
                        {
                            reviews && reviews.data.length > 0 ?  reviews.data.map(review => {
                                    return(
                                        <CardReview key={review.id} review={review}/>
                                    )
                                }) :
                                <h3>Nu aveți niciun review adăugat</h3> }
                    </div> }
                    {reviews && reviews.last_page > 1 ?
                        <div className="pagination mt-5 mb-5 d-flex justify-content-center">
                            <Pagination page={page} count={reviews && reviews.last_page} color="primary"  showFirstButton showLastButton onChange={handleChange} />
                        </div> : ''}
                    <ModalConfirmDeleteReview/>
                </div>
            </div>
        </div>
    )
}

export default Reviews
