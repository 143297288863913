import React, {useState, useEffect} from "react";
import {PulseLoader} from "react-spinners";


const SpinnerContainer = () => {

    return(
        <div className="spinner-container">
            <PulseLoader color='#12803c'/>
        </div>
    )
}

export default SpinnerContainer
