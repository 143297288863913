import React, { useEffect, useState } from 'react'
import {Helmet} from "react-helmet";
import Navbar from '../navs/Navbar';
import {useParams} from 'react-router-dom'
import booksport from '../../api/booksport';
import loginbk from "../../resources/images/login-bk.jpg";
import moment from 'moment';
import queryString from "query-string";
import {addDays} from "date-fns";
import DatePicker, {registerLocale} from "react-datepicker";
import ro from "date-fns/locale/ro";
import CardMapClub from '../cards/CardMapClub';
import CardAmenities from '../cards/CardAmenities';
import CardProgram from '../cards/CardProgram';
import Footer from '../reusable/Footer';
import ElementTable from './ElementTable';
import AvailableHours from './AvailableHours'
import { history } from '../../utils/history';
import { useLocation } from 'react-router-dom';
import ElementDescription from './ElementDescription';
import SkipPreviousRoundedIcon from '@mui/icons-material/SkipPreviousRounded';
import SkipNextRoundedIcon from '@mui/icons-material/SkipNextRounded';

const ElementPage = (props) => {
    const { search } = useLocation();
    const date = queryString.parse(search).date
    const hour = queryString.parse(search).h
    const[data, setData ] = useState(date ? new Date(date) : new Date())
    const { any, element } = useParams();
    const [clubdetails, setClubdetails] = useState()

    useEffect(() => {
        if(!hour){
            if(!date) {
                history.push(`${history.location.pathname}?date=${moment().format('YYYY-MM-DD')}&h=${settime()}`)
            }else {
            history.push(`${history.location.pathname}?date=${date}&h=${settime()}`) }
        }
        if(moment(queryString.parse(props.location.search).date,  'YYYY-MM-DD').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')){
            props.history.push(props.match.params.any + '?date=' + moment().format('YYYY-MM-DD')+ '&h='+queryString.parse(props.location.search).h)
        }
        getElementFromSlug()
    }, [])

    const getElementFromSlug = () => {
        let q = {
            slug :any,
            element_slug: element
        }
        booksport.get('/getclub_from_slug',  {params: q})
            .then(res => {
                let q = {
                    clublocation_id: res.data.clublocation_id,
                    sport_id:res.data.sport_id,
                    element_id: res.data.element_id
                }
                booksport.get('/getclubdetails_for_site',  {params: q})
                .then((response) => {
                    setClubdetails(response.data)
                })
                .catch(err => console.log(err))
            })
            .catch(err => console.log(err))
    }

    const settime = () => {
        const start = moment();
        const remainder = 30 - (start.minute() % 30);
        const time = moment(start)
            .add(remainder, "minutes")
            .format("HH:mm");
        return time
    }

    registerLocale("ro", ro); // register it with the name you want
        const ExampleCustomInput = ({ value, onClick }) => (
            <p className="custom-input" onClick={onClick}>
                {value ? value : moment(date).format('DD.MM.YYYY')}
                <svg className="icon icon--chevron icon--chevron-down" viewBox="0 0 10 16">
                    <path d="M5.489 8L0 2.296 2.602 0 10 8l-7.398 8L0 13.704z"> </path>
                </svg>
            </p>
        );

    const handleChange = date => {
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
        const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date )
        props.history.push(`${history.location.pathname}?date=${year }-${month}-${day}&h=${queryString.parse(props.location.search).h}`)
        setData(date)
    };

    const nextDate = () => {
        const max = moment().add(14, 'days').format('YYYY-MM-DD')
        let next = moment(data).add(1, 'days').format('YYYY-MM-DD')
        if(next <= max){
            props.history.push(`${history.location.pathname}?date=${next}&h=${queryString.parse(props.location.search).h}`)
            setData(new Date(next))
        }
    }

    const prevDate = () => {
        const current = moment().format('YYYY-MM-DD')
        let prev = moment(data).subtract(1, 'days').format('YYYY-MM-DD')
        if(current <= prev){
            props.history.push(`${history.location.pathname}?date=${prev}&h=${queryString.parse(props.location.search).h}`)
            setData(new Date(prev))
        }
    }

    return (
        <div>
            <Helmet>
                    <meta charSet="utf-8" />
                    <title>{ `Booksport - ${clubdetails && clubdetails.club_name} ${clubdetails && clubdetails.element_name}`}</title>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="description"  />
                    <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                    <meta property="og:site_name" content="booksport.ro" />
                    <meta property="og:title"/>
                    <meta property="og:description" />
                </Helmet>
                <Navbar class="clubs club" key={new Date()}/>
                <div className="headClub"  style={{background: `url(${clubdetails && clubdetails.element_main_image.length !== 0 ? 'https://img.booksport.ro/clubs/'+clubdetails.id +'/big/'+clubdetails.main_image[0].filename  : loginbk}) rgba(0, 0, 0, 0.4)`}}>
                    <div className="container" style={{marginBottom: '50px'}}>
                        <h1>{clubdetails && clubdetails.element_name}</h1>
                        <p>{clubdetails && clubdetails.address}</p>
                    </div>
                </div>
                {clubdetails &&
                <div className="container c-club-page">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="wrapper-availability">
                                <div className="daypicker ">
                                <SkipPreviousRoundedIcon style={{fontSize: 50, marginRight: 20}} onClick={() => prevDate()}/>
                                    <DatePicker
                                        locale="ro"
                                        calendarClassName="datepicker"
                                        showPopperArrow ={false}
                                        todayButton="Selecteaza data de azi"
                                        onChange={handleChange}
                                        placeholderText="Data"
                                        minDate={new Date()}
                                        maxDate={addDays(new Date(), 14)}
                                        dateFormat="dd.MM.yyyy"
                                        popperPlacement='bottom'
                                        selected={data}
                                        customInput={<ExampleCustomInput />}
                                    />
                                     <SkipNextRoundedIcon style={{fontSize: 50, marginLeft: 20}} onClick={() => nextDate()}/>
                                </div>
                                <AvailableHours key={Math.random()} clublocation_id={clubdetails && clubdetails.id} element={clubdetails && clubdetails.element_id} booking={clubdetails && clubdetails.booking} sport={clubdetails && clubdetails.sport_id} date={date} clubname ={clubdetails && clubdetails.club_name} clublocationname={clubdetails && clubdetails.location_name} elementname={clubdetails && clubdetails.element_name}/>
                            </div>
                            <ElementTable key={Math.random()} booking={clubdetails && clubdetails.booking} clubname ={clubdetails && clubdetails.club_name} clublocationname={clubdetails && clubdetails.location_name} clublocation={clubdetails && clubdetails.id} sport={clubdetails && clubdetails.sport_id} date={date} element={clubdetails && clubdetails.element_id} />

                            <div className="card club-desc pb-4 pt-3">
                                <h3 className='border-bottom-0'>{clubdetails && clubdetails.element_name}</h3>
                                {clubdetails && <ElementDescription cardreview={clubdetails && clubdetails.cardreview} clubdetails={clubdetails}/>}

                            </div>
                        </div>
                        <div className="col-lg-3 sidebar-club">
                            <div className="card">
                                    <CardMapClub clubname={clubdetails && clubdetails.club_name} lat={clubdetails && clubdetails.lat ? clubdetails.lat : ''} lng={clubdetails && clubdetails.lng ? clubdetails.lng : ''} />
                            </div>
                            <div className="row">
                                    <CardAmenities amenities={clubdetails && clubdetails.amenities}/>
                                    <CardProgram orar={clubdetails && clubdetails.orar}/>
                             </div>
                        </div>
                    </div>
                </div> }
                <Footer/>
        </div>
    )
}

export default ElementPage
