import React, { useEffect, useState} from 'react'
import booksport from '../../api/booksport';
import { openModalRezervaTable } from '../../store/actions/modalActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import queryString from "query-string";
import { useLocation } from 'react-router-dom';
import { history } from '../../utils/history';
import ModalBooking from "../modals/ModalBooking";

const AvailableHours = (props) => {
    const { clublocation_id, sport, clubname, clublocationname, booking, element, elementname } = props;
    const [availability, setAvailability] = useState()
    const [loading, setLoading] = useState(true)
    const { search } = useLocation();
    let date = queryString.parse(search).date
    let hour = queryString.parse(search).h
    const rezerva = useSelector(state => state.showModal.rezerva)
    const dispatch = useDispatch()

    useEffect(() => {
        if(!hour){
            if(!date) {
                history.push(`${history.location.pathname}?date=${moment().format('YYYY-MM-DD')}&h=${settime()}`)
            }else {
            history.push(`${history.location.pathname}?date=${date}&h=${settime()}`) }
        }
       getAvailableElementHours()
    }, [date, getAvailableElementHours, hour])

    const settime = () => {
        const start = moment();
        const remainder = 30 - (start.minute() % 30);
        const time = moment(start)
            .add(remainder, "minutes")
            .format("HH:mm");
        return time
    }

    const getAvailableElementHours = () => {
        setLoading(true)
        var clubData = {
            sport_id: parseInt(sport),
            clublocation_id: parseInt(clublocation_id),
            date: date,
            time: hour,
            dayfull: 1,
            element_id: element
        }
       booksport.get('/listclubavailability', {params: clubData})
       .then(res => {
        setAvailability(res.data)
        setLoading(false)
       })
    }

    const getRezOptions = (hour,e) => {
        var token = localStorage.getItem('jwtToken');
        var user = null;

        if(localStorage.getItem('usInf')){
            user= JSON.parse(atob(localStorage.getItem('usInf')));
        }
        if(user && user.user_type !== 0){
            return false
        }

        if(token == null ){
            const location = {
                pathname: '/login',
                state: { from: window.location.pathname }
            }
            history.push(location)
        }else{
            var clubData = {
                sport_id : parseInt(sport),
                clublocation_id: parseInt(clublocation_id),
                date: date,
                time: hour,
            }

            booksport.get('/listelementavailability', {params: clubData})
                .then((res) =>{
                    handleModal(res.data, hour)}
                )
                .catch((err) =>
                    console.log(err)
                )
        }
    }

    const handleModal = (modalData, hour) => {
        var clubData = {
            sport_id: parseInt(sport),
            clublocation_id: parseInt(clublocation_id),
            date: date,
            time: hour,
            clubname: clubname + ' - ' + clublocationname,
            teren:  {label:elementname, value:element}
        }
        dispatch(openModalRezervaTable(modalData, clubData))
    }

    return (
        <div className='clubAvailableHours'>
                <p className="fw-600">Ore libere: {moment(date).format('DD.MM.YYYY')} - <span className='text-uppercase text-green-400'>{moment(date).format('dddd')}</span></p>
                <div className="listHours">
                    {loading ? <div className='col-12'>
                      <div className='d-flex align-items-center justify-content-center pt-5 pb-5'>
                        <PulseLoader color='#12803c' size={20} />
                      </div>
                    </div> :
                        availability && availability.length === 0 && booking === 1 ? <p className="navailable">Ocupat</p> :
                            availability && availability.map(hour =>
                                <div key={hour.ora} className="wrapper-btn-book">
                                     <span key={hour.ora} className="btn btn-book" onClick={() => getRezOptions(hour.ora)}>{hour.ora}</span>
                                </div>
                            )}
                </div>
            <ModalBooking/>
        </div>
    )
}

export default AvailableHours
