export const setrefresh = (day) => {
    return{
        type: 'SET_REFRESH',
        setRefresh : day
    }
}

export const disRefresh = () => {
    return{
        type: 'DIS_REFRESH',
        setRefresh : false
    }
}
