import booksport from '../api/booksport'

export default function setAuthorizationToken() {
    var token =  localStorage.getItem('jwtToken')
    if(token){
        booksport.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }else{
        delete  booksport.defaults.headers.common['Authorization']
    }
}
