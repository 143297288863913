import React,{useEffect, useRef, useState} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Reviews from '../../cards/Reviews';
import ClubGallery from './ClubGallery';
import loginbk from "../../../resources/images/login-bk.jpg";
import queryString from "query-string";
import { useLocation } from 'react-router-dom';
import Prices from './Prices';

const ClubDescription = ({clubDetails}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const tabsRef = useRef(null)
    const { search } = useLocation();
    const review = queryString.parse(search).r
    const scrollToRef = () => tabsRef.current.scrollIntoView({block: "start", inline: "nearest"});

    useEffect(() => {
        if(review){
            setTabIndex(1)
            scrollToRef()
        }
    }, [])

    return (
        <div ref={tabsRef}>
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    <Tab>Descriere</Tab>
                    {clubDetails.booking === 1 && <Tab>Review-uri {clubDetails.nr_review ? `(${clubDetails.nr_review})` : ''}</Tab>}
                    <Tab>Preturi</Tab>
                </TabList>

                <TabPanel>
                    <div className="desc" dangerouslySetInnerHTML={{ __html: clubDetails.description }}>
                    </div>
                    <div className="pics" >
                        {clubDetails.images_gallery && clubDetails.images_gallery.length > 0 ?<ClubGallery pics={clubDetails.images_gallery} clubId={clubDetails.id}/>: <img className="img-fluid" src={clubDetails.main_image && clubDetails.main_image.length !== 0 ? 'https://img.booksport.ro/clubs/'+clubDetails.id +'/big/'+clubDetails.main_image[0].filename  : loginbk} alt=""/>}
                    </div>
                </TabPanel>
                {clubDetails.booking === 1 &&  <TabPanel>
                    <Reviews ratingNr={clubDetails.nr_review} rating={clubDetails.review} club={clubDetails.id} clubname={`${clubDetails.club_name} ${clubDetails.location_name}` }/>
                </TabPanel> }
                <TabPanel>
                    <Prices clublocation={clubDetails.id} sport={clubDetails.sport_id}/>
                </TabPanel>
            </Tabs>
        </div>
    )
}

export default ClubDescription
