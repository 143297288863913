import React, {useEffect} from 'react'
import Navbar from '../navs/Navbar';
import ClubsWrapper from './components/ClubsWrapper';
import { Helmet } from 'react-helmet';
import LMap from './components/LMap';
import { useSelector, useDispatch } from 'react-redux';
import { closeMap } from '../../store/actions/clubsFilter';
import ClubFilters from './components/ClubFilters';
import ModalBooking from "../modals/ModalBooking";
import SearchForm from "../search/SearchForm";

const ClubsNew = () => {
    const openMap = useSelector(state => state.filterClubs.openMap)
    const dispatch = useDispatch()

    const closeMapPannel = () => {
        dispatch(closeMap())
    }
    useEffect(() => {
        window.dataLayer.push({
         event: 'pageview',
         page: {
           url: window.location.pathname,
           title: 'Clubs selection'
         }
       });
     }, [])

    return (
        <div>
             <Helmet>
              <meta charSet="utf-8" />
              <title>{ 'Booksport - Selectie cluburi' }</title>
              <link rel="canonical" href={window.location.href} />
              <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
              <meta property="og:site_name" content="booksport.ro" />
              <meta property="og:title" content="Booksport - Selectie cluburi" />
              <meta property="og:description" content="Alege din lista de cluburi disponibile si rezerva-ti terenul online" />
          </Helmet>
          <Navbar class="clubs" showFilters={true}/>
          <div className="container-fluid content-fixed-head">
                <div className="clubs-wrapper col-lg-8 pb-0">
                    <ClubsWrapper/>
                </div>
                <div className={openMap ? "col-lg-4 map active p-0" : "col-lg-4 map p-0"}>
                    <div className="close-map" onClick={() => closeMapPannel()}>Inchide harta<span className="material-icons">close</span></div>
                    <LMap/>
                </div>
            </div>
            <ModalBooking/>
            <SearchForm/>
        </div>
    )
}

export default ClubsNew
