import isEmpty from 'lodash/isEmpty'

const initialState = {
    isAuth: false,
    user: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return {
                isAuth:!isEmpty(action.user),
                user: action.user
            }
        case 'REGISTER':
            return {...state, [action.payload.id]:action.payload}
        default :
            return state
    }
}
