import React, {useEffect, useState} from 'react'
import CheckboxCell from "./CheckboxCell";



const ExtraOptionCheckbox = (props) =>{
    const{option} = props

    return(
        <div className="col-12">
            <>
                { option.placeholder && <p className="mt-2 mb-2">{option.placeholder}</p> }
                <div className="options">
                    {option.options.map( check => {
                        return <CheckboxCell key={check.value} check={check}/>
                    })}
                </div>

            </>
        </div>

    )
}

export default ExtraOptionCheckbox