import React, { useState, useEffect } from 'react'
import TopNavAdmin from '../../components/navigations/TopNavAdmin'
import booksport from "../../../../api/booksport";
import CardVoucher from "./components/CardVoucher";
import ModalAddVoucher from "./components/ModalAddVoucher";
import {useDispatch, useSelector} from "react-redux";
import {showUserVouchersModal} from "../../../../store/actions/admin/vouchers";
import {ColorButton} from "../../components/reusable/ColorButton";
import SpinnerContainer from "../../components/loading-spinner";
import {AdminRoutes} from "../../../../api/admin-routes";

const UserVouchers = () => {
    const [vouchers, setVouchers] = useState([])
    const [loading, setLoading] = useState(true)
    const refreshVcs = useSelector(state => state.admin.vouchers?.refresh)
    const dispatch = useDispatch()

    useEffect(() => {
        getVouchersFromApi()
    },[refreshVcs])


    const getVouchersFromApi = async () =>{
        setLoading(true)
        const query={
            token: localStorage.getItem('jwtToken'),
        }
        try{
            const response = await booksport.get(`${AdminRoutes.getUserVouchers}`, {params: query})
            setVouchers(response.data)
        }catch(err){
            return {error: err.response}
        }
        setLoading(false)
    }

    const openVoucherModal = () => {
        dispatch(showUserVouchersModal(true))
    }

    const renderVouchers = () => {
        return (
            vouchers && vouchers.length > 0 ?
            <div className="row">
                {vouchers.map(voucher => {
                    return <CardVoucher key={voucher.serie} voucher={voucher}/>
                })}
            </div> :
            <div className="col-12">
                <h3>Nu aveti coduri adaugate in cont.</h3>
            </div>
        )
    }

    return(
        <div className="main-panel">
            <TopNavAdmin title= 'Coduri de discount'/>
            <div className="content p-0-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-3 offset-md-9">
                            <ColorButton variant='contained' style={{marginBottom: '20px', marginTop: 20}} onClick={openVoucherModal} >Adauga cod</ColorButton>
                        </div>
                    </div>
                    {loading ? <SpinnerContainer/> : renderVouchers() }
                </div>
            </div>
            <ModalAddVoucher/>
        </div>
    )
}

export default UserVouchers
