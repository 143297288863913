import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import {Slide} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CancelPaidBooking = ({booking}) => {
    const [showModal, setShowModal] = useState(false)
    const [disableButton, setDisableButton] = useState(checkTimeAndShowMessage());

    useEffect(() => {
        const interval = setInterval(() => {
            const shouldShowMessage = checkTimeAndShowMessage();
            setDisableButton(shouldShowMessage);
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    function checkTimeAndShowMessage() {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        return currentHour >= 0 && currentHour < 3;
    }
    const cancelBooking = () => {
        window.location = `${booking?.refund_url}${window.location.href}`
    }

    return (
        <>
            <Button variant='contained' disabled={disableButton} style={{background: disableButton ? '#d3d3d3': '#f08f00', margin:'0 10px', width:'100%', flexDirection: 'column', fontSize: 12, lineHeight: '20px'}} onClick={() => setShowModal(true)}>Anuleaza cu<br/><span className="small" style={{lineHeight: '14px'}}>ramburs {parseInt(booking.refund_amount) !== 0 && 'pe card'} {booking.refund_amount} RON</span></Button>
            <Dialog
                open={showModal ? showModal : false}
                onClose={() => setShowModal(false)}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                scroll={"body"}
            >
                <DialogTitle sx={{textAlign:'center'}}>
                    Anulare rezervare
                </DialogTitle>
                <DialogContent>
                    <p style={{marginBottom: 0}}>Esti sigur ca vrei sa anulezi rezervarea?</p>
                    <p style={{marginBottom: 0}}>Anularea rezervarii va avea loc cu o rambursare {parseInt(booking.refund_amount) !== 0 && <strong>PE CARD</strong>} de <strong>{booking.refund_amount} RON</strong></p>
                </DialogContent>
                <DialogActions style={{marginBottom: '10px', paddingRight:'10px'}}>
                    <Button variant='contained' onClick={() => setShowModal(false)} style={{background: '#12803c'}} >Inchide</Button>
                    <Button variant='contained' onClick={() => cancelBooking()} style={{background: '#e95e4e'}} >Anuleaza</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default CancelPaidBooking
