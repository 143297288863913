export default (state = {}, action) => {
    switch (action.type) {
        case 'SET_SIDEBAR':
            return {...state, menu: action.sidebar}
        case 'OPEN_SIDEBAR':
            return {...state, sidebarStatus: action.status}
        default :
            return state
    }
}
