import React, { useState, useEffect } from 'react';

const MessageRefundUnavailable = () => {
    const [showMessage, setShowMessage] = useState(checkTimeAndShowMessage());

    useEffect(() => {
        const interval = setInterval(() => {
            const shouldShowMessage = checkTimeAndShowMessage();
            setShowMessage(shouldShowMessage);
        }, 60000); // Check every minute

        return () => clearInterval(interval);
    }, []);

    function checkTimeAndShowMessage() {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        return currentHour >= 0 && currentHour < 3;
    }

    return (
        showMessage && <p className="text-danger text-center m-0 mb-2 w-100" style={{fontSize: 18}}>În intervalul 00:00-03:00, nu se pot procesa rambursări!</p>
    )
}

export default MessageRefundUnavailable;
