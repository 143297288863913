import React, { useState } from 'react'
import moment from 'moment'
import booksport from '../../api/booksport';
import {useDispatch} from 'react-redux'
import { openModalDeleteComment } from '../../store/actions/comments';

const ReviewComment = (props) => {
    const {comment, clubname, reviewId} = props
    const [editing, setEditing] = useState(false);
    const [stateComment, setStateComment] = useState(comment.comment);
    const userCode = localStorage.getItem('usInf')
    const userId = userCode ? JSON.parse(atob(userCode)).id : null
    const dispatch = useDispatch()

    const changeCommentValue = (e) => {
        setStateComment(e.target.value)
    }

    const saveComment = () => {
        const query = {
            token: localStorage.getItem('jwtToken'),
            review_id:reviewId,
            comment: stateComment,
            reviewcomment_id: comment.id
        }

        booksport.post('/postreviewcomment',  query)
        .then(() => {
            setEditing(false)
        })
    }


    const deleteComment = () => {
        dispatch(openModalDeleteComment(comment.reviewcommenthash, reviewId))
    }

    return (
       <div key={comment.id} className="comment-card">
            <p className= {comment.user_type === 1 ? 'm-0 fw-600 text-green' : 'm-0 fw-600'}>{comment.name} {comment.user_type === 1 ? `(${clubname})` : ''}</p>
            <p className="date m-0">{moment(comment.created_at).format('DD.MM.YYYY hh:mm')} {comment.created_at !== comment.updated_at ? <span className={'edited ml-2'}>(Editat)</span> : ''}</p>
            {editing ?
               <textarea  name="comment" value={stateComment} id="" onChange={changeCommentValue}></textarea>
            : <p className='comment'>{stateComment}</p> }
            {userId && userId === comment.user_id && !editing ?
            <p>
                <span className='btn-comment mr-3' onClick={() => setEditing(true)}>Editează</span>
                <span className='btn-comment text-danger' onClick={() => deleteComment()}>Sterge</span>
            </p>
            : ''}
            {editing ? <span className='btn-comment' onClick={() => saveComment()}>Salvează</span> : '' }

        </div>
    )
}

export default ReviewComment
