import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import { PulseLoader } from 'react-spinners';

// Styled component to change the focus color to green and adjust height
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const HourFilter = () => {
    const [hoursOptions, setHoursOptions] = useState([]);
    const [selectedHour, setSelectedHour] = useState('');
    const location = useLocation();
    const history = useHistory();
    const queryParams = queryString.parse(location.search);

    useEffect(() => {
        const generateHours = (selectedDate) => {
            const now = DateTime.now();
            const isToday = selectedDate === now.toFormat('yyyy-MM-dd');
            const hours = [];

            for (let i = 0; i < 24; i++) {
                const hourStr = i.toString().padStart(2, '0') + ':00';
                const halfHourStr = `${hourStr.slice(0, 2)}:30`;

                if (isToday) {
                    if (DateTime.fromFormat(hourStr, 'HH:mm') > now) {
                        hours.push({ label: hourStr, value: hourStr });
                    }
                    if (DateTime.fromFormat(halfHourStr, 'HH:mm') > now) {
                        hours.push({ label: halfHourStr, value: halfHourStr });
                    }
                } else {
                    if (i >= 7 && i < 24) {
                        hours.push({ label: hourStr, value: hourStr });
                        hours.push({ label: halfHourStr, value: halfHourStr });
                    }
                }
            }
            return hours;
        };

        const selectedDate = queryParams.date || DateTime.now().toFormat('yyyy-MM-dd');
        const currentHour = DateTime.now().toFormat('HH:mm');
        let selectedHour = queryParams.hour;

        const hours = generateHours(selectedDate);
        setHoursOptions(hours);

        const isValidHour = (hour) => {
            return hours.some(h => h.value === hour);
        };

        if (!selectedHour || !isValidHour(selectedHour) || (selectedDate === DateTime.now().toFormat('yyyy-MM-dd') && selectedHour < currentHour)) {
            const nextClosestHour = hours.find(hour => hour.value > currentHour) || hours[0];
            selectedHour = nextClosestHour.value;
            queryParams.hour = selectedHour;
            history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
        }

        setSelectedHour(selectedHour);
    }, [queryParams.date, queryParams.hour, history, location.pathname]);

    const handleHourChange = (event) => {
        const newHour = event.target.value;
        const queryParams = queryString.parse(location.search);
        queryParams.hour = newHour;
        history.push(`${location.pathname}?${queryString.stringify(queryParams)}`);
        setSelectedHour(newHour);
    };

    if (!hoursOptions.length) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="40px">
                <PulseLoader color='#12803c' size={10} />
            </Box>
        );
    }


    return (
        <FormControl fullWidth>
            <GreenSelect
                value={selectedHour}
                onChange={handleHourChange}
                displayEmpty
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                {hoursOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </GreenSelect>
        </FormControl>
    );
};

export default HourFilter;
