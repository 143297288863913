import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import cardbk from '../../resources/images/cards.jpg';
import {useParams} from 'react-router-dom'
import StarRatings from 'react-star-ratings';

const ElementsSlider = (props) =>  {
    const {elements, date, hour, clublocation_id, cardreview} = props
    const { any } = useParams();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1.5,
            slidesToSlide: 1 // optional, default to 1.
          }
      };

    return (
        <div className="wrapper-club-elements">
            <Carousel swipeable={true} draggable={true} responsive={responsive} transitionDuration={500} removeArrowOnDeviceType={["tablet", "mobile"]}>
                {elements.map(element => {
                    return(
                        <a className='card card-element' key={element.slug} href={`${any}/${element.slug}?date=${date}&h=${hour}`}  style={{background: `url(https://img.booksport.ro/clubs/${clublocation_id}/instructori/${element.main_image[0].filename})`}}>
                            <p className={cardreview === 1 ? "title" : 'title mb-3'}>{element.name}</p>
                            {cardreview === 1 ? 
                            <StarRatings
                                rating={parseFloat(element.review)}
                                starRatedColor="#f9bf3b"
                                numberOfStars={5}
                                name='rating'
                                starDimension="15px"
                                starHoverColor="#f9bf3b"
                                starSpacing='0px'
                            /> : '' }
                        </a>
                    )
                })}
           </Carousel>
        </div>
    )
}

export default ElementsSlider
