import React,{Component} from 'react'


class CardAmenities extends Component{
    render(){
        return(
            <div className="col-md-6 col-lg-12">
                <div className="card-wHead">
                    <h3>Facilitati</h3>
                    <ul className="amenities">
                        {this.props.amenities && this.props.amenities.map(amenity => {
                            return(
                                <li key={amenity.name}><img className="cardli-ico" src={'https://img.booksport.ro/amenities/' + amenity.img + '.svg'} alt=""/><span>{amenity.name}</span></li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}


export default CardAmenities;
