import React from 'react'
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from 'react-redux';
import { closeModalDeleteReview } from '../../store/actions/review';
import booksport from '../../api/booksport';

const ModalConfirmDeleteReview = () => {
    const reviewState = useSelector(state => state.review)
    const reviewHas = reviewState.review
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(closeModalDeleteReview(false))
    }

    const deleteReview = () => {
        const query = {
            token: localStorage.getItem('jwtToken'),
            reviewhash: reviewHas && reviewHas.reviewHash
        }
        booksport.get('/deletereview',  {params: query})
        .then(() => dispatch(closeModalDeleteReview(true)))
    }

    return (
        <Modal show={reviewHas && reviewHas.openModal} animation={true} className="modal-anulare">
                    <div className="modal-confirm-anulare">
                            <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                                <i className="material-icons">clear</i>
                            </button>
                            <h4>Confirmare ștergere</h4>
                            <p>Ești sigur că vrei să ștergi review-ul?</p>
                        <div className="wrapper-btns d-flex">
                            <button className="btn btn-warning text-center w-100 btn btn-book" rel="noopener noreferrer" onClick={deleteReview}>Da</button>
                            <button className="btn btn-success text-center w-100" target="_blank" rel="noopener noreferrer" onClick={closeModal}>Nu</button>
                        </div>
                    </div>
        </Modal>
    )
}

export default ModalConfirmDeleteReview
