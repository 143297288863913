import React, {useEffect, useState} from 'react'
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {refreshTotal, setExtraOptionState, showPayment} from "../../../../store/actions/rezervareActions";
import validateOptions from "../../../reusable/ValidateOptions";

const ExtraOptionSelect = (props) =>{
    const{option, name} = props
    const [options, setOptions] = useState()
    const [selectedOption, setSelectedOption] = useState()
    const rezervare = useSelector(state => state.rezervare)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setExtraOptionState(name, null))
        let values = []
        option.options.map(selOption => values.push({label: `${selOption.name} ${selOption.display_price === 1 ? '(' + selOption.price + ' RON)' : ''}`, value: selOption.value}))
        setOptions(values)
        values.map(value => {
            if(option.default_option && option.default_option !== '' && parseInt(option.default_option) === value.value){
                setExtraOption(value)
            }
        })
        // eslint-disable-next-line 
    },[])

    const setExtraOption = (selOption) => {
        dispatch(setExtraOptionState(name, selOption.value))
        setSelectedOption(selOption)
        dispatch(refreshTotal(true))
        const valid = validateOptions(rezervare)
        valid ? dispatch(showPayment(true)) : dispatch(showPayment(false))
    }

    return(
        <div className="col-12">
            <Select
                isSearchable={ false }
                className="w-100 mb-2"
                options={options}
                placeholder = {option.placeholder}
                onChange = {(selOption) => setExtraOption(selOption)}
                value = {selectedOption}
            />
        </div>

    )
}

export default ExtraOptionSelect
