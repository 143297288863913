import React, { useState, useEffect } from "react";
import {ExpandMore} from "@mui/icons-material";
import {Checkbox} from "@mui/joy";
import {ChevronRight} from "@mui/icons-material";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {addVoucherItems} from "../../../../../store/actions/admin/vouchers";
import {useDispatch} from "react-redux";


//BFS algorithm to find node by his ID
const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue.length > 0) {
        const currNode = queue.shift();
        if (currNode.id === targetId) {
            return currNode;
        }
        if (currNode.children) {
            queue.push(...currNode.children);
        }
    }
    return []; // Target node not found
};

const VoucherFormItems = ({data}) => {
    const [selectedNodes, setSelectedNodes] = useState([]);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(addVoucherItems(selectedNodes))
    }, [selectedNodes]);

    // Retrieve all ids from node to his children's
    function getAllIds(node, idList = []) {
        idList.push(node.id);
        if (node.children) {
            node.children.forEach((child) => getAllIds(child, idList));
        }
        return idList;
    }
    // Get IDs of all children from specific node
    const getAllChild = (id) => {
        return getAllIds(bfsSearch(data, id));
    };

    // Get all father IDs from specific node
    const getAllFathers = (id, list = []) => {
        const node = bfsSearch(data, id);
        if (node.parent) {
            list.push(node.parent);

            return getAllFathers(node.parent, list);
        }

        return list;
    };

    function isAllChildrenChecked(node, list) {
        const allChild = getAllChild(node.id);
        const nodeIdIndex = allChild.indexOf(node.id);
        allChild.splice(nodeIdIndex, 1);

        return allChild.every((nodeId) =>
            selectedNodes.concat(list).includes(nodeId)
        );
    }

    const handleNodeSelect = (event, nodeId) => {
        event.stopPropagation();
        const allChild = getAllChild(nodeId);
        const fathers = getAllFathers(nodeId);

        if (selectedNodes.includes(nodeId)) {
            // Need to de-check
            setSelectedNodes((prevSelectedNodes) =>
                prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
            );
        } else {
            // Need to check
            const ToBeChecked = allChild;
            for (let i = 0; i < fathers.length; ++i) {
                if (isAllChildrenChecked(bfsSearch(data, fathers[i]), ToBeChecked)) {
                    ToBeChecked.push(fathers[i]);
                }
            }
            setSelectedNodes((prevSelectedNodes) =>
                [...prevSelectedNodes].concat(ToBeChecked)
            );
        }
    };

    const handleExpandClick = (event) => {
        // prevent the click event from propagating to the checkbox
        event.stopPropagation();
    };

    const renderTree = (nodes) => (
        <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            onClick={handleExpandClick}
            label={
                <div style={{display:"flex", alignItems: 'center', padding: '10px 0'}}>
                    <Checkbox
                        checked={selectedNodes.indexOf(nodes.id) !== -1}
                        tabIndex={-1}
                        onClick={(event) => handleNodeSelect(event, nodes.id)}
                        style={ { marginRight: 10}}
                        sx = {{
                            '.Joy-checked': {
                                '&.MuiCheckbox-checkbox': {
                                    backgroundColor: '#12803c',
                                }
                            }
                        }}
                    />
                    {nodes.name}
                </div>
            }
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    return (
        <TreeView
            multiSelect
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            selected={selectedNodes}
            sx={{
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: '4px',
                minHeight: '45px'
            }}
        >
            {data.map((node) => renderTree(node))}
        </TreeView>
    );
}

export default VoucherFormItems
