import {Button, styled} from "@mui/material";

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#12803c'),
    backgroundColor: '#12803c',
    '&:hover': {
        backgroundColor: '#12803c',
    },
    marginTop: '3rem',
    width: '100%'
}));
