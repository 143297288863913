export default (state = {}, action) => {
    switch (action.type) {
        case 'SET_REFRESH':
            return {refresh: action.setRefresh}
        case 'DIS_REFRESH':
            return {refresh: action.setRefresh}
        default :
            return state
    }
}
