export default (state = {}, action) => {
    switch (action.type) {
        case 'SHOW_MODAL_DELETE_CARD':
            return {...state, modalDeleteCard: action.show}
        case 'REFRESH_CARDS_LIST':
            return {...state, refreshCards: action.refresh}
        case 'SET_PAYMENT_CARD':
            return {...state, paymentCard: action.card}
        default :
            return state
    }
}
