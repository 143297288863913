import React,{useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {logOut} from "../../../../store/actions/userActions";

import logoImg from '../../../../resources/images/logo.svg'
import backgroundImage from '../../../../resources/images/sidebar.png'
import {history} from "../../../../utils/history";
import booksport from "../../../../api/booksport";
import {AdminRoutes} from "../../../../api/admin-routes";
import {setSidebarItems, setSidebarStatus} from "../../../../store/actions/admin/sidebar";

const getMenuIcon = (name) => {
    switch(name) {
        case 'Dashboard':
            return 'home'
        case 'Contul meu':
            return 'manage_accounts'
        case 'Rezervari':
            return 'library_books'
        case 'Tranzactii':
            return 'receipt_long'
        case 'Cardurile mele':
            return 'credit_card'
        case 'Discount-uri':
            return 'sell'
        case 'Reviewurile mele':
            return 'reviews'
        case 'Admin club':
            return 'admin_panel_settings'
        case 'Financiar':
            return 'payments'
        case 'Rapoarte':
            return 'summarize'
        case 'Integrare':
            return 'sync_alt'
        case 'Tutoriale':
            return 'school'
        default:
            return 'library_books'
    }
}


const Navbar =() => {
    const [menuItems, setMenuItems] = useState([]);
    const location = useLocation();
    const storeSidebar = useSelector((state) => state.admin.sidebar?.menu);
    const sidebarStatus = useSelector((state) => state.admin.sidebar?.sidebarStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSidebarStatus(false));
        storeSidebar?.length > 0 ? setMenuItems(storeSidebar) : getMenuItems();
    }, []);

    useEffect(() => {
        const mainPanel = document.querySelector('.main-panel .content');
        if (mainPanel) {
            if (mainPanel.classList.contains('fixed')) {
                mainPanel.classList.remove('fixed');
            }
            if (sidebarStatus) {
                mainPanel.classList.add('fixed');
            }
        }
    }, [sidebarStatus]);

    const logoutUser = (e) => {
        e.preventDefault();
        dispatch(logOut());
        dispatch(setSidebarItems(null));
        history.push('/');
    };

    const closeSidebar = () => {
        dispatch(setSidebarStatus(false));
    };


    const getMenuItems = async () => {
        const query = {token: localStorage.getItem('jwtToken')}
        try{
            const response = await booksport.get(`${AdminRoutes.getSidebarMenu}`, {params: query})
            const menu = response.data;
            menu.push({name: 'deconectare', url: '/'})
            dispatch(setSidebarItems(menu))
            setMenuItems(menu)
        }catch(err){
            return {error: err.response}
        }
    }


    const lastSeg = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const adminChilds = ['terenuri', 'teren', 'rezervari', 'sablon', 'detalii-club', 'preturi', 'zi'];
    const userinfo = JSON.parse(atob(localStorage.getItem('usInf')));


    return(
        <>
            <div className={sidebarStatus ? 'sidebar active' : 'sidebar'} style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="logo"><a href="/" className="simple-text logo-normal"> <img width="210" src={logoImg} alt=""/></a></div>
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        <li className="nav-link user-nav">
                            <p className="text-center">
                                <span>{userinfo.first_name} {userinfo.last_name}</span>
                                <span>{userinfo.email.substring(0,30)}</span>
                            </p>
                        </li>

                        {menuItems.length > 0 && menuItems.map( link =>
                            <li
                                key={link.name}
                                className={
                                    location.pathname.startsWith(`/${link.url}`) ||
                                    (adminChilds.indexOf(lastSeg) > -1 && link.url === 'dashboard/admin-club') ||
                                    (!isNaN(lastSeg) && link.url === 'dashboard/bookings')
                                        ? 'nav-item active'
                                        : 'nav-item'
                                }
                            >
                                {link.name === 'deconectare' ?
                                    <Link  className={`nav-link logout`} onClick={logoutUser} to={ "/"}>
                                        <i className="material-icons">logout</i>
                                        <p>{link.name}</p>
                                    </Link> :
                                    <Link  className={`nav-link`} to={ "/" + link.url}>
                                        <i className="material-icons">{getMenuIcon(link.name)}</i>
                                        <p>{link.name}</p>
                                    </Link>
                                }
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <div className={sidebarStatus ? 'overlay' : 'overlay hidden' } onClick={closeSidebar}> </div>
        </>
    )
}


export default Navbar
