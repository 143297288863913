export const setSidebarItems = (sidebar) => {
    return{
        type: 'SET_SIDEBAR',
        sidebar
    }
}

export const setSidebarStatus = (status) => {
    return{
        type: 'OPEN_SIDEBAR',
        status
    }
}
