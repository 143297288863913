import React, {useState, useEffect} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {IconButton, Slide} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import booksport from "../../../../../api/booksport";
import {AdminRoutes} from "../../../../../api/admin-routes";
import {refreshVouchers} from "../../../../../store/actions/admin/vouchers";
import {useDispatch} from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DeleteVoucher = ({hash, name, series, deleted}) => {
    const [showDialog, setShowDialog] = useState(false)
    const dispatch = useDispatch()

    const deleteVoucher = async () =>{
        const query={
            encrypted_id: hash,
        }
        try{
            const response = await booksport.post(`${AdminRoutes.deleteVoucher}`,  query)
            if (response.status === 200) {
                dispatch(refreshVouchers(true))
                setShowDialog(false)
            }
        }catch(err){
            setShowDialog(false)
        }
    }

    return (
        hash ? <>
            <Button variant='contained' fullWidth onClick={() => setShowDialog(true)} style={{background: '#e95e4e'}}>sterge</Button>
            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-describedby="add-voucher"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                scroll={"body"}
            >
                <DialogTitle sx={{textAlign:'center', marginBottom: '30px'}}>
                    Stergere cod discount
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowDialog(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{fontWeight: 500}}>
                    <p>Esti sigur ca vrei sa stergi codul pentru discount?</p>
                    <p>Codul cu numele <strong>{name}</strong> si seria <strong>{series}</strong> va fi sters.</p>
                </DialogContent>
                <DialogActions sx={{marginTop: '30px', padding: '16px 24px', justifyContent:'flex-end'}}>
                    <Button variant='contained' onClick={() => deleteVoucher()} style={{background: '#e95e4e'}}>Sterge</Button>
                    <Button variant='contained' onClick={() => setShowDialog(false)} style={{background: '#12803c'}}>Inchide</Button>
                </DialogActions>
            </Dialog>
        </> : deleted && <p style={{textAlign: 'center', color:'#f44336', fontWeight:600, width: '100%', margin: 0}}>STERS</p>

    );
}

export default DeleteVoucher
