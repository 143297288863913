import React from "react";
import {Link} from "react-router-dom";


const ClubCard = ({club, sport}) => {
    return(
        <div className='row'>
            <div className="col-lg-12">
                <div className="card-club-admin pb-4">
                    <div className="col-12">
                        <div className="card-header card-header-tabs card-header-admin">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <span>{club.club_id.name} - {club.name}</span>
                                </div>
                                <div className="col-6 text-right">
                                    <span>{sport.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6 rezervari">
                                    <div className="card card-stats card-admin">
                                        <div
                                            className="card-header card-header-warning card-header-icon">
                                            <div className="card-icon small-icon">
                                                <i className="material-icons">alarm</i>
                                            </div>
                                            <br />
                                            <h3 className="card-title">Rezervări</h3>
                                        </div>
                                        <div className="card-footer text-center rezervari-btns">
                                            <div className="btn btn-success">
                                                <Link to={{
                                                    pathname: 'terenuri',
                                                    state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, location_name: club.name }
                                                }}>
                                                    {sport.element_name}
                                                </Link>
                                            </div>
                                            <div className="btn btn-success btn-zi">
                                                <Link to={{
                                                    pathname: 'zi',
                                                    state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, location_name: club.name }
                                                }}>
                                                    Zi
                                                </Link>
                                            </div>
                                            <div className="btn btn-success">
                                                <Link to={{
                                                    pathname: 'rezervari',
                                                    state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, location_name: club.name }
                                                }}>
                                                    Săptămână
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="card card-stats card-admin">
                                        <Link to={{
                                            pathname: 'sablon',
                                            state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, location_name: club.name }
                                        }}>
                                            <div
                                                className="card-header card-header-success card-header-icon">
                                                <div className="card-icon small-icon">
                                                    <i className="material-icons">view_comfy</i>
                                                </div>
                                                <br />
                                                <h3 className="card-title">Sablon orar</h3>
                                            </div>
                                        </Link>
                                        <div className="card-footer d-none d-md-block text-center">
                                            <div className="stats btn btn-success">
                                                <Link to={{
                                                    pathname: 'sablon',
                                                    state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, location_name: club.name }
                                                }}>Preseteaza disponibilitatea</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card-stats card-admin">
                                        <Link to={{
                                            pathname: 'blocarerezervari',
                                            state: { clublocation_id: club.clublocation_id}
                                        }}>
                                            <div className="card-header card-header-danger card-header-icon">
                                                <div className="card-icon small-icon">
                                                    <i className="material-icons">highlight_off</i>
                                                </div>
                                                <br />
                                                <h3 className="card-title">Blocare rezervări</h3>
                                            </div>
                                        </Link>

                                        <div className="card-footer d-none d-md-block text-center">
                                            <div className="stats btn btn-success">
                                                <Link to={{
                                                    pathname: 'blocarerezervari',
                                                    state: { clublocation_id: club.clublocation_id}
                                                }}>Blocare rezervări</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card-stats card-admin">
                                        <Link to={{
                                            pathname: 'detalii-club',
                                            state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, location_name: club.name }
                                        }}>
                                            <div
                                                className="card-header card-header-info card-header-icon">
                                                <div className="card-icon small-icon">
                                                    <i className="material-icons">info_outline</i>
                                                </div>
                                                <br />
                                                <h3 className="card-title">Informatii Club</h3>
                                            </div>
                                        </Link>
                                        <div className="card-footer d-none d-md-block text-center">
                                            <div className="stats btn btn-success">
                                                <Link to={{
                                                    pathname: 'detalii-club',
                                                    state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, location_name: club.name }
                                                }}>Informatii club</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <div className="card card-stats card-admin">
                                        <Link to={{
                                            pathname: 'preturi',
                                            state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, sport_name:sport.name, location_name: club.name }
                                        }}>
                                            <div className="card-header card-header-danger card-header-icon">
                                                <div className="card-icon small-icon">
                                                    <i className="material-icons">credit_card</i>
                                                </div>
                                                <br />
                                                <h3 className="card-title">Preturi</h3>
                                            </div>
                                        </Link>

                                        <div className="card-footer d-none d-md-block text-center">
                                            <div className="stats btn btn-success">
                                                <Link to={{
                                                    pathname: 'preturi',
                                                    state: { club_id: club.club_id.id, club_name:club.club_id.name, clublocation_id: club.clublocation_id, sport_id:sport.id, sport_name:sport.name, location_name: club.name }
                                                }}>Vizualizare preturi</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClubCard
