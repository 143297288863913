import * as React from 'react';
import { DataGrid, roRO, GridToolbar } from '@mui/x-data-grid';
import {RenderBills} from "./Bills";
import {ReturnButton} from "./Returns";
import {ReturnVoucher} from "./ReturnVoucher";

export default function TableTransactions({club}) {
    const transactions = club.plati
    const columns = [
        { field: 'nr_comanda',
            headerName: 'Ref comanda',
            minWidth: 130,
            renderCell: (params => <RenderBills params={params}/>), flex: 1 },
        { field: 'data_platii', headerName: 'Data platii', minWidth: 130, flex: 1 },
        { field: 'error_message', headerName: 'Status tranzactie', minWidth: 160, flex: 1 },
        { field: 'card', headerName: 'Card', minWidth: 130, flex: 1 },
        { field: 'last_name', headerName: 'Nume', minWidth: 130, flex: 1 },
        { field: 'first_name', headerName: 'Prenume', minWidth: 130, flex: 1 },
        { field: 'price_processed', headerName: 'Suma procesata', minWidth: 130, flex: 1 },
        { field: 'data_rezervare', headerName: 'Data rezervare', minWidth: 130, flex: 1 },
        { field: 'minutes', headerName: 'Minute', minWidth: 130, flex: 1 },
        { field: 'voucher_refund', headerName: 'Ramburs cod discount', minWidth: 200, renderCell: (params =>params.row.voucher_refund && <ReturnVoucher active={club.vouchere} params={params}/>), flex: 1  },
        { field: 'refund_link', headerName: 'Ramburs', minWidth: 200, renderCell: (params => params.row.refundlink && <ReturnButton params={params}/>), flex: 1  },];

    return (
        transactions &&  <div className='transactions-table' style={{ height: '80vh', minHeight: 600, width: '100%' }}>
            <DataGrid
                disableColumnMenu
                slots={{ toolbar: GridToolbar }}
                rows={transactions}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[10]}
                getRowHeight={() => 'auto'}
                localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
    );
}
