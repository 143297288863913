import React, {useState, useEffect} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../../../api/booksport";
import { PulseLoader } from 'react-spinners'
import { FormControlLabel } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import {CustomInput} from "../../../components/reusable/CustomInput";
import {IOSSwitch} from "../../../components/reusable/IosSwitch";
import {ColorButton} from "../../../components/reusable/ColorButton";
import CountySelect from "./CountySelect";
import CitySelect from "./CitySelect";
import {showModalDeleteAccount} from "../../../../../store/actions/modalActions";
import ModalDeleteAccount from "../../../../modals/ModalDeleteAccount";



const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Câmpul prenume este obligatoriu').nullable(),
    last_name: Yup.string().required('Câmpul nume este obligatoriu').nullable(),
    nickname: Yup.string().required('Câmpul nickname este obligatoriu').nullable(),
    address: Yup.string().required('Câmpul adresa este obligatoriu').nullable(),
    phone: Yup.string().required('Câmpul telefon este obligatoriu').nullable()
        .matches(phoneRegExp, 'Numărul nu este valid')
        .min(10, "Numarul este prea scurt")
        .max(10, "Numarul este prea lung"),
})
const validationSchemaPJ = Yup.object().shape({
    first_name: Yup.string().required('Câmpul prenume este obligatoriu').nullable(),
    last_name: Yup.string().required('Câmpul nume este obligatoriu').nullable(),
    nickname: Yup.string().required('Câmpul nickname este obligatoriu').nullable(),
    address: Yup.string().required('Câmpul adresa este obligatoriu').nullable(),
    phone: Yup.string().required('Câmpul telefon este obligatoriu').nullable()
        .matches(phoneRegExp, 'Numărul nu este valid')
        .min(10, "Numarul este prea scurt")
        .max(10, "Numarul este prea lung"),
    firma: Yup.string().required('Câmpul firma este obligatoriu').nullable(),
    adresa: Yup.string().required('Câmpul adresa este obligatoriu').nullable(),
    cui: Yup.string().required('Câmpul cui este obligatoriu').nullable(),
    regcom: Yup.string().required('Câmpul registrul comertului este obligatoriu').nullable(),
    banca: Yup.string().nullable(),
    iban: Yup.string().nullable(),
})

const EditProfileForm = () => {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)
    const [pj, setPj] = useState(false)
    const [ivalues, setIvalues] = useState(null)
    const [countyError, setCountyError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [countyErrorPJ, setCountyErrorPJ] = useState(false)
    const [cityErrorPJ, setCityErrorPJ] = useState(false)
    const selCounty = useSelector(state => state.userStatus.county)
    const selCity = useSelector(state => state.userStatus.city)
    const selCountyPJ = useSelector(state => state.userStatus.countyPJ)
    const selCityPJ = useSelector(state => state.userStatus.cityPJ)
    const dispatch = useDispatch()

    useEffect(() => {
        getUser()
    }, [])

    const openModal = () => {
        dispatch(showModalDeleteAccount(true))
    }

    const getUser = async () => {
        const token = localStorage.getItem('jwtToken')
        const response = await booksport.get('/user', {params: {token}})
        setUser(response.data.user)
        setPj(response.data.user.factureazapj === 1)
        setInitialValues(response.data.user)
    }

    const handleSubmit = async(values) => {
        if(pj){
            selCountyPJ == null ? setCountyErrorPJ(true) : values.county_id = selCountyPJ
            selCityPJ == null ? setCityErrorPJ(true) : values.localitate_id = selCityPJ
            selCounty == null ? setCountyError(true) : values.county = selCounty
            selCity == null ? setCityError(true) : values.city = selCity
            if(selCountyPJ && selCityPJ && selCounty && selCity){
                setLoading(true)
                updateUser(values)
            }

        } else {
            selCounty == null ? setCountyError(true) : values.county = selCounty
            selCity == null ? setCityError(true) : values.city = selCity
            if(selCounty && selCity){
                setLoading(true)
                updateUser(values)
            }
        }
    }

    const updateUser = async(values) => {
        setLoading(true)
        values.token = localStorage.getItem('jwtToken');
        values.factureazapj = pj ? 1 : 0
        let notPJValues = null

        if(!pj){
            const {firma, adresa, cui, regcom, banca, iban,  ...pjValues} = values;
            notPJValues = pjValues
        }

        const query = pj ? values : notPJValues

        try{
            const response = await booksport.post('/user_update', query)
            if(response.data){
                await localStorage.setItem('usInf',response.data)
                setUser(JSON.parse(atob(response.data)))
                setInitialValues(JSON.parse(atob(response.data)))
                setPj(JSON.parse(atob(response.data)).factureazapj)
                toast.success('Am salvat cu succes');
            }
            setLoading(false)
        }catch(err){
            toast.error('A aparut o eroare la salvarea datelor, Va rugam incercati mai tarziu');
            setLoading(false)
        }
    }

    const setInitialValues = (user) => {
        const initialValues = {
            first_name: user.first_name ? user.first_name : '' ,
            last_name: user.last_name ? user.last_name : '',
            phone: user?.phone == null ? '' : user.phone,
            address: user.address ? user.address : '',
            nickname:  user.nickname ? user.nickname : '',
            firma: user.userpj?.firma ? user.userpj.firma : '',
            adresa: user.userpj?.adresa ? user.userpj.adresa : '',
            cui: user.userpj?.cui ? user.userpj.cui : '',
            regcom: user.userpj?.regcom ? user.userpj.regcom : '',
            banca: user.userpj?.banca ? user.userpj.banca : '',
            iban: user.userpj?.iban ? user.userpj.iban : '',
        }
        setIvalues(initialValues)
        formik.setValues(initialValues)
    }

    const formik = useFormik({
        initialValues: ivalues,
        validationSchema: pj ? validationSchemaPJ : validationSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        },
    });

    const setUserPj = () => {
        setPj(!pj);
    };

    const userType = parseInt(JSON.parse(atob(localStorage.getItem('usInf'))).user_type)

    return(
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            {user && ivalues && formik.values ?
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4 mt-4">
                                <CustomInput
                                    name = 'first_name'
                                    focusColor='#12803c'
                                    fullWidth
                                    label="Prenume"
                                    onChange={formik.handleChange}
                                    value={formik.values.first_name}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 mt-4">
                                <CustomInput
                                    name = 'last_name'
                                    focusColor='#12803c'
                                    fullWidth
                                    label="Nume"
                                    onChange={formik.handleChange}
                                    value={formik.values.last_name}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 mt-4">
                                <CustomInput
                                    name = 'phone'
                                    focusColor='#12803c'
                                    fullWidth
                                    label="Telefon"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 mt-4">
                                <CustomInput
                                    name = 'nickname'
                                    focusColor='#12803c'
                                    fullWidth
                                    label="Nickname"
                                    onChange={formik.handleChange}
                                    value={formik.values.nickname}
                                    error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                                    helperText={formik.touched.nickname && formik.errors.nickname}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 mt-4">
                                <CustomInput
                                    name = 'address'
                                    focusColor='#12803c'
                                    fullWidth
                                    label="Adresa"
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                    error={formik.touched.address && Boolean(formik.errors.address)}
                                    helperText={formik.touched.address && formik.errors.address}
                                />
                            </div>
                            <CountySelect pj={false} user={user} error={countyError}/>
                            <CitySelect pj={false} user={user} error={cityError}/>
                        </div>
                        {userType === 0 && <div className="col-12 mt-4">
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        checked={pj}
                                        onChange={setUserPj}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Bifeaza pentru eliberarea facturii pe persoana juridica cu datele de mai jos"
                            />
                        </div> }
                        {pj ?
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4 mt-4">
                                    <CustomInput
                                        name = 'firma'
                                        focusColor='#12803c'
                                        fullWidth
                                        label="Firma"
                                        onChange={formik.handleChange}
                                        value={formik.values.firma}
                                        error={formik.touched.firma && Boolean(formik.errors.firma)}
                                        helperText={formik.touched.firma && formik.errors.firma}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-4">
                                    <CustomInput
                                        name = 'adresa'
                                        focusColor='#12803c'
                                        fullWidth
                                        label="Adresa"
                                        onChange={formik.handleChange}
                                        value={formik.values.adresa}
                                        error={formik.touched.adresa && Boolean(formik.errors.adresa)}
                                        helperText={formik.touched.adresa && formik.errors.adresa}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-4">
                                    <CustomInput
                                        name = 'cui'
                                        focusColor='#12803c'
                                        fullWidth
                                        label="CUI"
                                        onChange={formik.handleChange}
                                        value={formik.values.cui}
                                        error={formik.touched.cui && Boolean(formik.errors.cui)}
                                        helperText={formik.touched.cui && formik.errors.cui}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-4">
                                    <CustomInput
                                        name = 'regcom'
                                        focusColor='#12803c'
                                        fullWidth
                                        label="Registrul comertului"
                                        onChange={formik.handleChange}
                                        value={formik.values.regcom}
                                        error={formik.touched.regcom && Boolean(formik.errors.regcom)}
                                        helperText={formik.touched.regcom && formik.errors.regcom}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-4">
                                    <CustomInput
                                        name = 'banca'
                                        focusColor='#12803c'
                                        fullWidth
                                        label="Banca"
                                        onChange={formik.handleChange}
                                        value={formik.values.banca}
                                        error={formik.touched.banca && Boolean(formik.errors.banca)}
                                        helperText={formik.touched.banca && formik.errors.banca}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 mt-4">
                                    <CustomInput
                                        name = 'iban'
                                        focusColor='#12803c'
                                        fullWidth
                                        label="IBAN"
                                        onChange={formik.handleChange}
                                        value={formik.values.iban}
                                        error={formik.touched.iban && Boolean(formik.errors.iban)}
                                        helperText={formik.touched.iban && formik.errors.iban}
                                    />
                                </div>
                                <CountySelect pj={true} user={user} error={countyErrorPJ}/>
                                <CitySelect pj={true} user={user} error={cityErrorPJ}/>
                            </div>
                            : null
                        }

                        <div className="row align-items-center">
                            <div className="col-12 col-md-4">
                                <ColorButton variant="contained" size="large" type="submit" disabled = {loading}>
                                    {loading ?
                                        <PulseLoader color='#ffffff' size={10}></PulseLoader>
                                        : "Salveaza" }
                                </ColorButton>
                            </div>
                            {userType === 0 && <div className="col-12 col-md-4 offset-md-4 mt-5 text-center text-md-right">
                                <div className="text-danger btn btn-link" onClick={openModal}>Sterge contul</div>
                            </div>}
                        </div>
                    </form>
                    <ModalDeleteAccount/>
                </>
                :
                <div className='d-flex align-items-center justify-content-center pt-5 pb-5'>
                    <PulseLoader color='#12803c' size={20} />
                </div>}
        </>
    )
}

export default EditProfileForm
