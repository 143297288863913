import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

const AdminUsersTable = ({ users }) => {

    const renderUsers = () => {
        return users.map((user) => (
            <TableRow key={user.clb_user_relation_id}>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.user_type_name}</TableCell>
            </TableRow>
        ));
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, minHeight: 100 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Prenume</TableCell>
                        <TableCell>Nume</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Tip</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{renderUsers()}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default AdminUsersTable;
