import React, {useEffect, useState} from 'react'
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import booksport from "../../../../api/booksport";
import {refreshTotal, setPrice} from "../../../../store/actions/rezervareActions";
import {PulseLoader} from "react-spinners";

const DurationSelect = ({club, date, hour}) =>{
    const [slots, setSlots] = useState([])
    const [selectedSlot,setSelectedSlot] = useState(null);
    const element = useSelector(state => state.rezervare.element)
    const sportId = useSelector(state => state.showModal.rezerva?.clubData?.sport_id)
    const item = useSelector(state => state.rezervare.item)
    const price = useSelector(state => state.rezervare.price)
    const dispatch = useDispatch()


    useEffect(() => {
        let isMounted = true;
        item && getSlotsFromApi(isMounted)
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line 
    }, [element, item])

    const setSlotSelection = (selectedOption) => {
        if(selectedOption.value !== price){
            setSelectedSlot(selectedOption)
            dispatch(setPrice(selectedOption.value))
            dispatch(refreshTotal(true))
        }
    }

    const getSlotsFromApi = (isMounted) => {
        const slts = []
        const getPrices = {
            item_id: item,
            time: hour,
            day: date,
            sport_id: sportId,
            clublocation_id: club.id,
            element_id: element
        }
        booksport.get('/getprices',  {params: getPrices})
            .then((res) =>{
                if(Object.keys(res.data).length === 1 && isMounted) setSlotSelection({label:   Object.keys(res.data)[0] + ' minute : ' + Object.values(res.data)[0].full + ' RON', value:Object.keys(res.data)[0]+'_f'})

                Object.entries(res.data).map(([key, value]) => {
                    if(value.partial){
                        slts.push({
                            label: `${key} minute : ${value.full} RON - plată integrală`,
                            value: `${key}_f`,
                        },{
                            label:`${key} minute : ${value.partial} RON - plată partială (avans)` ,
                            value: `${key}_p`,
                        })
                    }else{
                        slts.push({
                            label: `${key} minute : ${value.full} RON`,
                            value: `${key}_f`,
                        })
                    }
                    if(isMounted){
                        setSlots(slts)
                    }
                })
            })
            .catch( (err) =>
                console.log(err)
            )
    }

    return(
        slots.length !==0 ?
            <div className="col-12">
                <Select
                    isSearchable={ false }
                    className="w-100 mb-2"
                    options={slots}
                    placeholder = {'Selectează o opțiune'}
                    onChange = {(selOption) => setSlotSelection(selOption)}
                    value = {selectedSlot}
                />
            </div> : <div className="mt-1 w-100 d-flex justify-content-center"><PulseLoader color='#12803c' size={12}/></div>

    )
}

export default DurationSelect
