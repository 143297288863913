import React, {useState, useEffect} from 'react'
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from 'react-redux';
import { closeModalDeleteComment, refreshComments } from '../../store/actions/comments';
import booksport from '../../api/booksport';

const ModalConfirmDeleteComment = () => {
    const state = useSelector(state => state.comments)
    const dispatch = useDispatch()
    const [open, setOpen ]= useState()
    const commentHash = state.commentHash
    const reviewID = state.reviewID

    useEffect(() => {
        setOpen(state.openModal && state.openModal)
    }, [state])

    const closeModal = () => {
        setOpen(false)
        dispatch(closeModalDeleteComment())
    }

    const deleteComment = () => {
        const query = {
            token: localStorage.getItem('jwtToken'),
            reviewcommenthash: commentHash
        }
        booksport.get('/deletereviewcomment',  {params: query})
        .then(() => dispatch(refreshComments(reviewID)))
        .then(() => closeModal())
    }


    return (
        <Modal show={open} animation={true} className="modal-anulare">
                    <div className="modal-confirm-anulare">
                            <button type="button" className="close" aria-label="Close" onClick={() => closeModal()}>
                                <i className="material-icons">clear</i>
                            </button>
                            <h4>Confirmare ștergere</h4>
                            <p>Ești sigur că vrei să ștergi comentariul?</p>
                        <div className="wrapper-btns d-flex">
                            <button className="btn btn-warning text-center w-100 btn btn-book" rel="noopener noreferrer" onClick={() => deleteComment()}>Da</button>
                            <button className="btn btn-success text-center w-100" target="_blank" rel="noopener noreferrer" onClick={() => closeModal()}>Nu</button>
                        </div>
                    </div>
        </Modal>
    )
}

export default ModalConfirmDeleteComment
