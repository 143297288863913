import React,{ useState } from 'react';
import ServicesModal from "../../admin-vouchers/components/ServicesModal";

const CardVoucher = ({voucher}) => {
    return (
        <div className="col-md-6 col-xl-4">
            <div className="card card-rezervare">
                <div className="card-band-header expirat">{voucher.name}</div>
                <div className="wrapper-voucher-infos mb-2">
                    <div className="col-12">
                        <div className="row row-voucher">
                            <div className="col-6">Serie</div>
                            <div className="col-6 font-weight-bold text-green-400">{voucher.serie}</div>
                        </div>
                        <div className="row row-voucher">
                            <div className="col-6">Discount</div>
                            <div className="col-6 font-weight-bold">{voucher.valoare}</div>
                        </div>
                        {
                            voucher.clublocation_name && <div className="row row-voucher">
                                <div className="col-6">Valabil pentru</div>
                                <div className="col-6 font-weight-bold">{voucher.clublocation_name}</div>
                            </div>
                        }
                        <div className="row row-voucher">
                            <div className="col-6">Valabil de la</div>
                            <div className="col-6 font-weight-bold">{voucher.valabilitate_de_la}</div>
                        </div>
                        <div className="row row-voucher">
                            <div className="col-6">Valabil pana la</div>
                            <div className="col-6 font-weight-bold">{voucher.valabilitate_pana_la}</div>
                        </div>
                        {voucher.items.length > 0 ?
                            <div className="row pt-3 pb-2">
                                <div className="col-12 text-center"><ServicesModal services={voucher.items}/></div>
                            </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardVoucher
