import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setSaveCard} from "../../../store/actions/rezervareActions";


const SaveCard = () =>{
    const [value, setValue] = useState(false)
    const cardSelected = useSelector(state => state.rezervare.card)
    const saveCard = useSelector(state => state.rezervare.saveCard)
    const dispatch = useDispatch()

    useEffect(() => {
        setValue(saveCard)
    },[saveCard])

    const handleCheckboxChange = () => {
        dispatch(setSaveCard(!value))
        setValue(!value)
    }

    return(
        !cardSelected || cardSelected === 0 ?
        <div className="col-12 save-card">
            <div className="form-group mb-0 pb-0">
                <div className="form-check ml-1">
                    <label className="form-check-label">
                        <input name="savecard" type="checkbox" checked={value ? value : false} onChange={handleCheckboxChange} className="form-check-input" />
                        <span className="form-check-sign"><span className="check"> </span></span> Salveaza cardul in contul tau
                    </label>
                </div>
            </div>
        </div> : <></>
    )
}

export default SaveCard
