import React, {createRef, useState} from 'react'
import { Item } from 'react-photoswipe-gallery'
import reactImageSize from 'react-image-size';

const GalleryItem = (props) => {
    const {picture, clubId} = props
    const [width, setWidth] = useState()
    const [height, setHeight] = useState()
    const imageUrl = `https://img.booksport.ro/clubs/${clubId}/big/${picture.filename}`
    
    reactImageSize(imageUrl)
    .then(({ width, height }) => {
        setHeight(height)
        setWidth(width)
    })
    .catch((errorMessage) => console.log(errorMessage));

    return (
        <Item
            original={`https://img.booksport.ro/clubs/${clubId}/big/${picture.filename}`}
            width= {width}
            height= {height}
            >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={`https://img.booksport.ro/clubs/${clubId}/big/${picture.filename}`} />
            )}
        </Item>
    )
}

export default GalleryItem
