import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {deleteExtraOptionState, refreshTotal, setExtraOptionState} from "../../../../store/actions/rezervareActions";


const CheckboxCell = ({check}) =>{
    const [value, setValue] = useState(false)
    const dispatch = useDispatch()

    const handleCheckboxChange = () => {
        setValue(!value)
        !value ? dispatch(setExtraOptionState(`check_${check.value}`, 1 )) : dispatch(deleteExtraOptionState(`check_${check.value}`))
        dispatch(refreshTotal(true))

    }


    return(
        <div className="form-group mb-0 pb-0" key={check.value}>
            <div className="form-check ml-1">
                <label className="form-check-label">
                    <input name="savecard" type="checkbox" checked={value} onChange={handleCheckboxChange} className="form-check-input" />
                    <span className="form-check-sign"><span className="check"> </span></span>{`${check.name} - ${check.display_price === 1 ? check.price : ''} RON`}
                </label>
            </div>
        </div>
    )


}

export default CheckboxCell
