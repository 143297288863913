import React, { useState, useEffect, useCallback } from 'react';
import {
    FormControl,
    MenuItem,
    Select,
    Box,
    Button,
    TextField
} from '@mui/material';
import { styled } from '@mui/system';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { useHistory } from 'react-router-dom';
import booksport from "../../api/booksport";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with custom parse format
dayjs.extend(customParseFormat);
dayjs.locale('ro'); // Set dayjs to use the Romanian locale

const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '50px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '50px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '50px',
        display: 'flex',
        alignItems: 'center',
    },
});

const GreenTextField = styled(TextField)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '50px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '50px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '50px',
        display: 'flex',
        alignItems: 'center',
    },
});

const HomepageBookingCard = () => {
    const [startDate, setStartDate] = useState(dayjs().hour() === 23 ? dayjs().add(1, 'day') : dayjs());
    const [location, setLocation] = useState({ label: "Bucuresti", value: 9000 });
    const [hours, setHours] = useState([]);
    const [selectedHour, setSelectedHour] = useState(null);
    const [sports, setSports] = useState([]);
    const [selectedSport, setSelectedSport] = useState(null);
    const [cities, setCities] = useState([]);
    const [coordinates, setCoordinates] = useState(null);

    const history = useHistory();

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const res = await booksport.get('/get_cities');
                setCities(res.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchInitialData();
        updateHours(startDate);
    }, [startDate]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setCoordinates({ lat: position.coords.latitude, long: position.coords.longitude });
            setLocation({ label: "Locatia curenta", value: `@${position.coords.latitude},${position.coords.longitude}` });
        });
    }, []);

    useEffect(() => {
        if (location) {
            fetchSports(location.value);
        }
    }, [location]);

    const fetchSports = async (locationId) => {
        try {
            const res = await booksport.get('/listmenusports', { params: { location_id: locationId, radius: 20 } });
            setSports(res.data);
            if (res.data.length > 0) {
                setSelectedSport({ label: res.data[0].name, value: res.data[0].id });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const updateHours = useCallback((selectedDate) => {
        const hoursList = generateHours(selectedDate);
        setHours(hoursList);
        setSelectedHour(hoursList[0]);
    }, []);

    const generateHours = (selectedDate) => {
        const now = dayjs();
        const isToday = dayjs(selectedDate).isSame(now, 'day');
        const hoursList = [];

        for (let i = 0; i < 24; i++) {
            const hourStr = i.toString().padStart(2, '0') + ':00';
            const halfHourStr = `${hourStr.slice(0, 2)}:30`;

            if (isToday) {
                if (dayjs(hourStr, 'HH:mm').isAfter(now)) {
                    hoursList.push({ label: hourStr, value: hourStr });
                }
                if (dayjs(halfHourStr, 'HH:mm').isAfter(now)) {
                    hoursList.push({ label: halfHourStr, value: halfHourStr });
                }
            } else {
                if (i >= 7 && i < 24) {
                    hoursList.push({ label: hourStr, value: hourStr });
                    hoursList.push({ label: halfHourStr, value: halfHourStr });
                }
            }
        }
        return hoursList;
    };

    const handleDateChange = (date) => {
        setStartDate(date);
        updateHours(date);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const locationValue = location.value;
        const sport = selectedSport ? selectedSport.value : null;
        const hour = selectedHour ? selectedHour.value : null;
        const date = startDate.format('YYYY-MM-DD');
        const radius = locationValue.toString().startsWith('@') ? 20 : null;
        history.push(`/clubs?date=${date}&hour=${hour}&location=${locationValue}${radius ? `&radius=${radius}` : ''}&sport=${sport}`);
    };

    const sportsOptions = sports.map(sport => ({
        label: `${sport.name} (${sport.cnt})`,
        value: sport.id
    }));

    const locationOptions = cities.map(city => ({
        label: city.name,
        value: city.id
    }));

    if (coordinates) {
        locationOptions.unshift({ label: "Locatia curenta", value: `@${coordinates.lat},${coordinates.long}` });
    }

    const hoursOptions = hours;

    return (
        <Box className="col-md-12 ml-auto mr-auto">
            <Box className="card">
                <Box className="card-body">
                    <form onSubmit={handleSubmit}>
                        <Box className="row align-items-center">
                            <Box className="col-lg-3 mb-2 mb-lg-0">
                                <FormControl fullWidth>
                                    <GreenSelect
                                        value={location.value}
                                        onChange={(e) => setLocation(locationOptions.find(option => option.value === e.target.value))}
                                        displayEmpty
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            PopoverClasses: {
                                                paper: 'scrollable-menu',
                                            }
                                        }}
                                    >
                                        {locationOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </GreenSelect>
                                </FormControl>
                            </Box>
                            <Box className="col-lg-3 mb-2 mb-lg-0">
                                <FormControl fullWidth>
                                    <GreenSelect
                                        value={selectedSport ? selectedSport.value : ''}
                                        onChange={(e) => setSelectedSport(sportsOptions.find(option => option.value === e.target.value))}
                                        displayEmpty
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            PopoverClasses: {
                                                paper: 'scrollable-menu',
                                            }
                                        }}
                                    >
                                        {sportsOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </GreenSelect>
                                </FormControl>
                            </Box>
                            <Box className="col-lg-2 mb-2 mb-lg-0">
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
                                        <DatePicker
                                            value={startDate}
                                            onChange={handleDateChange}
                                            minDate={dayjs()}
                                            maxDate={dayjs().add(14, 'day')}
                                            textField={(params) => (
                                                <GreenTextField
                                                    {...params}
                                                    fullWidth
                                                    label="Data"
                                                    variant="outlined"
                                                    inputProps={{ ...params.inputProps, readOnly: true }} // Make input read-only
                                                />
                                            )}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    sx: {
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: "green"
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "green"
                                                        },
                                                        "& .MuiInputBase-root": {
                                                            height: '50px',
                                                        },
                                                        "& .MuiOutlinedInput-input": {
                                                            height: '50px',
                                                        },
                                                    }
                                                }
                                            }}
                                            format="DD.MM.YYYY"
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>
                            <Box className="col-lg-2 mb-2 mb-lg-0">
                                <FormControl fullWidth>
                                    <GreenSelect
                                        value={selectedHour ? selectedHour.value : ''}
                                        onChange={(e) => setSelectedHour(hoursOptions.find(option => option.value === e.target.value))}
                                        displayEmpty
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            PopoverClasses: {
                                                paper: 'scrollable-menu',
                                            }
                                        }}
                                    >
                                        {hoursOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </GreenSelect>
                                </FormControl>
                            </Box>
                            <Box className="col-lg-2 d-flex align-items-center">
                                <Button type="submit" variant="contained" color="success" fullWidth style={{height: '50px'}}>
                                    Caută
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};

export default HomepageBookingCard;
