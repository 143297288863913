import React from "react";
import AdminUsersTable from "./AdminUsersTable";

const CardAdminUsers = ({ users }) => {

    return (
            <AdminUsersTable users={users}/>
        )
};

export default CardAdminUsers;
